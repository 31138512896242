import React from 'react';
import { Typography, Grid } from '@mui/material';

const Swedanam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Swedanam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
           <strong> What is Swedanam? </strong>
          </Typography>
          <Typography variant="body1">
         <p>Swedanam is the fomentation or sudation therapy in Ayurveda involving the procedure to induce sweat or perspiration in the body using steam generated from medicated herbal decoctions. It is one among the preparatory procedures of Panchakarma to open up the channels of the body to aid in the effective elimination of toxins. Fomentation is given after abhyanga or an Ayurvedic full body massage for an enhanced detoxification.</p>
          <p>The therapy is highly beneficial for relieving muscular tension and inflammation, promoting blood circulation and to clear the energy passages. Swedana is also recommended for disorders pertaining to the vitiation of Vataand Kapha doshas.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Swedanam </strong>
          </Typography>
          <Typography variant="body1">
            <ul className='vw-ul'>
              <li>Balances Vata and Kapha humor in the body</li>
              <li>Increases Circulation</li>
              <li>Rejuvenates and Revitalizes the skin</li>
              <li>Improves digestion</li>
              <li>Reduces stress, mental exertion, anxiety, etc.
</li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Procedure of Swedanam </strong>
          </Typography>
          <Typography variant="body1">
           <p>First of all, herbs and oils are selected according to the condition and dosha predominance. Then oil should be gently applied to the whole body by covering the head and eyes part using wet cotton. Ayurveda believes that eyes and head should be protected from overheat. After applying medicated oil, a person has to sit or lay down in the chamber where steam emanates from boiling herbal decoction. Treatment is continued until the whole body is sweat very well. After the completion of the procedure, a person has to take a shower with lukewarm water.</p>
          </Typography>
        </Grid>
        
      </Grid>
    </div>
    </>
  );
};

export default Swedanam;
