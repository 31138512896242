import React from 'react';
import './Sidebar.css';
import {
  Dashboard,
  Description,
  Category,
  ShoppingBasket,
  AddBox,
  PeopleAlt,
  Feedback,
  Settings,
  ExitToApp,
  AssignmentInd,
  AccountCircle,
  People,
  ViewList, // Icon for pagelist
  Create, // Icon for add blog
  FormatListBulleted, // Icon for bloglist
  Collections, // Icon for media
  LocalOffer, // Icon for tags
  Loyalty, // Icon for coupon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

function Sidebar() {
  const adminDashboardSidebar = [
    {
      id: 0,
      heading: 'Dashboard',
      subHeadings: [{
        subId: 0,
        name: 'Home',
        route: '/Home',
        iconTag: <Dashboard className="sidebarIcon" />,
        isActive: false
      }]
    },
    {
      id: 1,
      heading: 'Content Management',
      subHeadings: [
        {
          subId: 1,
          name: 'Add Page',
          route: '/AddPages',
          iconTag: <Description className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 2,
          name: 'Page List',
          route: '/PageList',
          iconTag: <ViewList className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 3,
          name: 'Add Blog',
          route: '/Blog',
          iconTag: <Create className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 4,
          name: 'Blog List',
          route: '/BlogList',
          iconTag: <FormatListBulleted className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 5,
          name: 'Media',
          route: '/MediaLibrary',
          iconTag: <Collections className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
    {
      id: 2,
      heading: 'Product / Services',
      subHeadings: [
        {
          subId: 6,
          name: 'Categories',
          route: '/ProductCategoriesPage',
          iconTag: <Category className="sidebarIcon" />,
          isActive: false
        },
        {
          subId: 7,
          name: 'All Product / Services',
          route: '/ProductListPage',
          iconTag: <ShoppingBasket className="sidebarIcon" />,
          isActive: false
        },
        {
          subId: 8,
          name: 'Add Product / Services',
          route: '/AddProductPage',
          iconTag: <AddBox className="sidebarIcon" />,
          isActive: false
        },
        {
          subId: 9,
          name: 'Tags',
          route: '/ProductTagsPage',
          iconTag: <LocalOffer className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
    {
      id: 3,
      heading: 'Order Management',
      subHeadings: [
        {
          subId: 10,
          name: 'Order',
          route: '/AllOrders',
          iconTag: <People className="sidebarIcon" />,
          isActive: false
        },
        {
          subId: 11,
          name: 'Coupon',
          route: '/CouponCard',
          iconTag: <Loyalty className="sidebarIcon" />,
          isActive: false
        },
        {
          subId: 12,
          name: 'Users',
          route: '/UsersList',
          iconTag: <AssignmentInd className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
    {
      id: 4,
      heading: 'Customer Management',
      subHeadings: [
        {
          subId: 13,
          name: 'All Customer',
          route: '/CustomersList',
          iconTag: <PeopleAlt className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 14,
          name: 'Add Customer',
          route: '/PatientProfilepage',
          iconTag: <AccountCircle className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
    {
      id: 5,
      heading: 'Notifications',
      subHeadings: [
        // {
        //   subId: 15,
        //   name: "Mail",
        //   route: "/MailPage",
        //   iconTag: <MailOutline className="sidebarIcon" />,
        //   isActive: false
        // }, 
        {
          subId: 16,
          name: 'Feedback',
          route: '/FeedbackPage',
          iconTag: <Feedback className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
    {
      id: 6,
      heading: 'Settings',
      subHeadings: [
        {
          subId: 17,
          name: 'General',
          route: '/AdminProfilePage',
          iconTag: <Settings className="sidebarIcon" />,
          isActive: false
        }, {
          subId: 18,
          name: 'Logout',
          route: '/',
          iconTag: <ExitToApp className="sidebarIcon" />,
          isActive: false
        }
      ]
    },
  ];

  // const [selectedSubId, setSelectedSubId] = useState(0);

  // useEffect(() => {
  //   localStorage.setItem('sideBarSubHeadingId', JSON.stringify(sideBarId));
  // }, [sideBarId])


  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        {adminDashboardSidebar.map((sidebar) => (
          <div className="sidebarMenu" key={sidebar.id}>
            <h3 className="sidebarTitle">{sidebar.heading}</h3>
            <ul className="sidebarList">
              {sidebar.subHeadings.map((subHeading) => (
                <Link key={subHeading.subId} to={`${subHeading.route}`} className="link">
                  <li
                    className={`sidebarListItem ${subHeading.subId === JSON.parse(secureLocalStorage.getItem('sideBarSubHeadingId'))
                      ? 'active'
                      : ''}`}
                    onClick={() => secureLocalStorage.setItem('sideBarSubHeadingId', JSON.stringify(subHeading.subId))}
                  >
                    {subHeading.iconTag}
                    {subHeading.name}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;

