import React from 'react';
import '../css/Blogdetailpage.css';
//import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// import { Link } from 'react-router-dom';
import { Typography, Grid } from "@mui/material";

export default function Blogdetailpage1() {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Kapha Dosha and Type 2 Diabetes </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

      <img src="./images/kapadosha.png" style={{ width: '100%' }} alt="Kapha Dosha and Type 2 Diabetes" />
      
      <Grid container spacing={2}>
        <Grid item xs={12}>

      <div className="form-group">
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
        <br />
        <Typography variant="body1">
            Ayurveda, which originated in ancient India, is a standard healing
            practice with origins dating back to 1000 BC. It was first classified
            as religious or spiritual medicine, but by the 6th to 4th centuries
            BC, the religious treatment approach had disappeared and it evolved to
            become methodically structured.
        </Typography>
        <br />
        <Typography variant="body1">
            Translated from Sanskrit as “life science,” Ayurveda integrates
            physical (prakriti), spiritual (purusha), and physiologic processes to
            produce a balanced mind-body-soul relationship. The major purpose of
            Ayurveda is to maintain balance inside oneself between the five basic
            elements (Panchamahabhutas) of earth, water, fire, air, and ether or
            vacuum via the notion of the three life forces or doshas. The doshas
            are comprised of vata, which symbolises ether and air, pitta, which
            represents fire, and kapha, which represents earth and water. 1 The
            doshas of the body are vata, pitta, and kapha, whereas the doshas of
            the mind are rajas and tamas.
          </Typography>
          <br />
          <Typography variant="body1">
            Ayurveda defines good health as finding a balance between the doshas.
            Each living being has a structural and physiological propensity toward
            various doshas, and such imbalances can lead to sickness.
          </Typography>
          <br />
          <Typography variant="body1">
            The use of Ayurveda in type 2 diabetes, a chronic, yet treatable
            disease, has shown marked improvement on patient outcomes in numerous
            case reports. Type 2 diabetes involves a lack of sensitivity to
            insulin and the subsequent inability of the body to regulate blood
            glucose levels. Risk factors for type 2 diabetes are widely known and
            include being overweight or obese (body mass index [BMI] of 25 or
            higher), family history of diabetes, lack of physical activity, poor
            diet, including excessive calorie consumption, low fiber intake, high
            consumption of saturated and trans fats, high glycemic load, high
            glycemic index, cigarette smoking, and alcohol consumption.
          </Typography>
          <br />
          <Typography variant="body1">
          The ayurvedic treatment of Type 2 Diabetes is as follows:
          </Typography>
          <br />
          <Typography variant="body1">
            1. Udwartana is a powder massage often used for slimming and treatment
            of obesity that can be done daily.
          </Typography>
          <br />
          <Typography variant="body1">
            2. Dhanyamladhara is often used in Ayurveda to combat obesity,
            inflammation, muscular pain, neuropathy, hemiplegia, and rheumatic
            complaints. It is derived from the word cereals (dhanya) and vinegar
            (amla). Dhanyamla involves preparation of navara rice, horse gram,
            millet, citrus fruits, and dried ginger. During the treatment, the
            body is covered with this preparation and then by a heated cloth. The
            duration of the treatment is 45 to 50 minutes depending upon the
            condition of the patient.
          </Typography>
          <br />
          <Typography variant="body1">
            3. Snehapana is a process of full body internal and external
            lubrication via drinking ghee and animal fat oil.
          </Typography>
          <br />
          <Typography variant="body1">
            4. Abhyanga is a warm oil massage. The oil is often premedicated with
            herbs for specific conditions.
          </Typography>
          <br />
          <Typography variant="body1">
            5. Bashpasweda is a steam chamber in which the patient sits while
            steam emanates from a boiling herbal decoction.
          </Typography>
          <br />
          <Typography variant="body1">
            6. Vamana (induced vomiting) is targeted to expel increased kapha dosha from the body.
          </Typography>
          <br />
          <Typography variant="body1">
            7. Virechana is the second procedure in the sequence of Panchakarma
            (Ayurveda Detoxification Program) that involves using plant medicines
            that have a laxative effect, mainly aimed at reducing pitta dosha and
            toxic accumulation in the gastrointestinal tract, liver, and gallbladder.
          </Typography>
          <br />
          <Typography variant="body1">
            8. Yogavasti is a type of medication given by enema, aiding in
            diminishing extra vata dosha present in the body. Vata is the force
            behind the elimination and retention of faeces, urine, bile, and other
            excreta.
          </Typography>
          <br />
          <Typography variant="body1">
            9. Shirodhara is a form of Ayurveda therapy that involves gently
            pouring liquids over the forehead and can be one of the steps involved
            in Panchakarma.
          </Typography>
          <br />
          <Typography variant="body1">
            Since, both the cause and treatment of type 2 diabetes are related to
            diet and lifestyle, the ancient medicinal practice of Ayurveda, which
            is rooted in diet and lifestyle, is a promising traditional approach
            to the treatment of type 2 diabetes. Thus, the use of Ayurveda in
            treating type 2 diabetes should benefit more than just those who are
            suffering from diabetes.
            </Typography>

          {/*<div>
            <Link to="/Blogdetailpage" className="link2nn">{'<< before'}</Link>
            <Link to="/blogdetailpage2" className="link2n">{'Next >>'}</Link>
          </div> */}

          {/* <div className="box-bg-1">
           

            <textarea
              className="bd-form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="My Comment"
            ></textarea>
          </div>

          <div className="blg-detail-row">
            <input type="text" className="form-control" placeholder="Name" />
            <input type="text" className="form-control" placeholder="Email" />
          </div>
          <button type="" className="post-btn">
            POST COMMENT
          </button> */}
        </Box>
      </div>
      </Grid>
      </Grid>
    </div>
    </>
  );
}