/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Menorrhagia = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Menorrhagia </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Menorrhagia ? </strong>
        </Typography>
            <Typography variant="body1">
            Menorrhagia is a medical condition characterized by abnormally heavy or
prolonged menstrual bleeding. It is a common gynecological problem that can
significantly impact a woman's quality of life. Symptoms of menorrhagia include
excessive menstrual bleeding that requires changing sanitary protection frequently
(such as soaking through a pad or tampon every hour), passing large blood clots,
prolonged periods lasting more than seven days, and experiencing fatigue or
shortness of breath due to blood loss. The causes of menorrhagia can vary and
may include hormonal imbalances, uterine fibroids, polyps, adenomyosis,
endometriosis, pelvic inflammatory disease, blood clotting disorders, or certain
medications.
</Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Menorrhagia</strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Menorrhagia include:
              <ul className='vw-ul'>
                <li>Soaking through one or more sanitary pads or tampons every hour for several consecutive hours</li>
                <li>Needing to use double sanitary protection to control your menstrual flow </li>
                <li>Needing to wake up to change sanitary protection during the night </li>
                <li>Bleeding for longer than a week</li>
                <li>Passing blood clots larger than a quarter</li>
                <li>Restricting daily activities due to heavy menstrual flow</li>
                <li>Symptoms of anemia, such as tiredness, fatigue or shortness of breath</li>
             
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Menorrhagia</strong>
        </Typography>
            <Typography variant="body1">
              During your menstrual cycle, if an egg is not fertilized, the uterine lining breaks down, and bleeds. The egg and the uterine lining are then shed during your period.
              Hormone problems or conditions that affect the uterus can result in heavy bleeding. Other diseases or bleeding disorders can also cause it.
              Hormone problems include:
              <ul className='vw-ul'>
                <li>Imbalance of estrogen and progesterone or other hormones</li>
                <li>Fibroids (non cancerous)</li>
                <li>Pregnancy problems (such as a miscarriage or ectopic pregnancy)</li>
                <li>Use of an intrauterine device (IUD)</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Menorrhagia </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Menorrhagia include:
              <ul className='vw-ul'>
                <li>Pregnancy (to be ruled out in all women who are sexually active and of reproductive age) </li>
                <li>Pelvic pain </li>
                <li>Pelvic diseases, such as fibroids or adenomyosis</li>
                <li>Sexually transmitted infections (STIs)</li>
                <li>Hormonal disturbances which prevent normal ovarian function</li>
                <li>Bleeding disorders, like a platelet deficiency</li>
                <li>Chronic illness of the kidneys or diabetes</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
        </Typography>
            <Typography variant="body1">
              While Menorrhagia cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li><b>Lifestyle changes:</b> Use a menstrual cup, try a heating pad, wear period panties to bed, get plenty of rest, exercise.</li>
                <li><b>Diet and supplements:</b> Eat food which have - Iron, Vitamin C., Drink alot of water, Some early evidence suggests that certain herbal remedies may help with heavy menstrual bleeding.</li>
              </ul>
            </Typography>
           
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Menorrhagia;
