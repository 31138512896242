import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/pageActionTypes';
import pageApi from '../../apis/pageApi';

function* pages(action) {
  try {
    const pagesResponse = yield call(pageApi.getAll, action);
    yield put({ type: type.ALL_PAGES_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_PAGES_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesLoggedInUserPages(action) {
  try {
    const pagesResponse = yield call(pageApi.loggedInUserPage, action);
    yield put({ type: type.LOGGED_IN_USER_PAGES_ID_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_PAGES_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesCreate(action) {
  try {
    const pagesResponse = yield call(pageApi.create, action);
    yield put({ type: type.PAGES_CREATE_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.PAGES_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesGetBy(action) {
  try {
    const pagesResponse = yield call(pageApi.getBy, action);
    yield put({ type: type.PAGES_GET_BY_ID_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.PAGES_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesUpdateBy(action) {
  try {
    const pagesResponse = yield call(pageApi.updateBy, action);
    yield put({ type: type.PAGES_UPDATE_BY_ID_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.PAGES_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesUnshowBy(action) {
  try {
    const pagesResponse = yield call(pageApi.unshowBy, action);
    yield put({ type: type.PAGES_UNSHOW_BY_ID_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.PAGES_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* pagesDeleteBy(action) {
  try {
    const pagesResponse = yield call(pageApi.deleteBy, action);
    yield put({ type: type.PAGES_DELETE_BY_ID_SUCCESS, pagesResponse: pagesResponse.data });
  } catch (e) {
    yield put({
      type: type.PAGES_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myPageSaga() {
  yield takeEvery(type.ALL_PAGES_REQUESTING, pages);
  yield takeEvery(type.LOGGED_IN_USER_PAGES_ID_REQUESTING, pagesLoggedInUserPages);
  yield takeEvery(type.PAGES_CREATE_REQUESTING, pagesCreate);
  yield takeEvery(type.PAGES_GET_BY_ID_REQUESTING, pagesGetBy);
  yield takeEvery(type.PAGES_UPDATE_BY_ID_REQUESTING, pagesUpdateBy);
  yield takeEvery(type.PAGES_UNSHOW_BY_ID_REQUESTING, pagesUnshowBy);
  yield takeEvery(type.PAGES_DELETE_BY_ID_REQUESTING, pagesDeleteBy);
}

export default myPageSaga;
