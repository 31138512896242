/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Raktamoksham = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Raktamoksham</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Raktamoksham ? </strong>
          </Typography>
          <Typography variant="body1">
            <p>
              Raktamokshana derives its name from Rakta meaning 'blood' and
              mokshana meaning 'liberation'. This treatment literally 'releases'
              impure blood from the system. The legendary surgeon Sushruta is
              said to have been a major votary of this therapy, and his school
              actually combines the two Vasti treatments, and lists
              Raktamokshana as the fifth main Panchakarma. Toxin build-up in the
              bloodstream is a major cause of diseases and this method involves
              careful extraction of toxins from the human body.
            </p>

            <p>
              Precise surgical instruments are used for the treatment, with
              strategic incisions made in prescribed areas. Another important
              form of this treatment is the use of leeches. In general, this
              treatment is not recommended for children, people above the age of
              70, anaemic people and pregnant women. This treatment is extremely
              helpful for patients suffering from eczema and other skin
              disorders, swellings and inflammations.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Raktamoksham </strong>
          </Typography>
          <Typography variant="body1">
          <p>Ayurveda stated that the Raktamokshana treatment works both as a curative and preventive treatment. The benefits of this ancient treatment technique are:</p>
          <ul className='vw-ul'>
              <li>Raktamokshana alleviates acute pain in one's body.</li>
              <li>It helps in reducing the redness of one's skin.</li>
              <li>This treatment gets rid of skin pigmentation and patchy dry skin.</li>
              <li>It aids in combating various bone-related disorders like rheumatoid arthritis, psoriatic arthritis, gouty arthritis, osteoarthritis and pericarditis.</li>
              <li>Using it, one can remove scars and wounds from his/her body.</li>
              <li>It gets rid of various skin disorders like eczema, allergies and inflammations.</li>
              <li>Patients can also get relief from sciatica and tonsillitis with this Ayurvedic treatment.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>What Are the Procedures of Raktamokshana Treatments?</strong>
          </Typography>
          <Typography variant="body1">
          <p>One can do the Raktamokshana treatment using various methods like applying leeches to specific areas or puncturing the veins. We have already discussed the various types of Raktamokshana previously in this blog. Now, we will discuss the procedures to perform this Ayurvedic treatment based on the four different types of Raktamokshana treatments:</p>
          <p><b>1. Siravyadhana</b></p>
          <p><b>Step 1: </b>An Ayurvedic practitioner generally performs this procedure in the morning or late afternoon. He/she selects a prominent vein for puncture.</p>
          <p><b>Step 2: </b>An antiseptic is used to clean the site where the puncture has been done.</p>
          <p><b>Step 3: </b>The practitioner covers his/her patient's eyes using a cotton pad during this treatment.</p>
          <p><b>Step 4: </b>He/she can also use a tourniquet to tie just over the puncture site for the patient.</p>
          <p><b>Step 5: </b>An Ayurvedic practitioner uses a sterile needle to prick one’s skin.</p>
          <p><b>Step 6: </b>Furthermore, he/she collects this blood that flows out after pricking in a glass measuring jar.</p>
          <p><b>Step 7: </b>This tourniquet will be removed from the patient's arms once the blood stops flowing naturally</p>
          <p><b>Step 8: </b>Next, the Ayurvedic doctor will remove the needle and clean this punctured site with antiseptic.</p>

          <p><b>2. Pracchana</b></p>
          <p><b>Step 1: </b>Typically, an Ayurvedic practitioner performs this procedure in the morning or afternoon. He/she selects an area where he or she will make cuts and treats it with antiseptic.</p>
          <p><b>Step 2: </b>In addition, the doctor also washes the site with medicated liquid.</p>
          <p><b>Step 3: </b>Then the practitioner covers the patient's eyes with cotton pads and a bandage.</p>
          <p><b>Step 4: </b>The Ayurvedic doctor will then use a sterile blade or lancet to make several cuts over the selected area.</p>
          <p><b>Step 5: </b>The oozing blood coming out from these cuts is wiped off immediately.</p>
          <p><b>Step 6: </b>After this procedure, the practitioner cleans the cut area with an antiseptic and applies herbal medicine.</p>

          <p><b>3. Jalaukavacharana</b></p>
          <p><b>Step 1: </b>Just like the previous two methods, Ayurvedic doctors perform this method in the morning and late afternoon. First, he/she will apply antiseptic to a selected area.</p>
          <p><b>Step 2: </b>Next, he/she will wash this site with medicated liquid.</p>
          <p><b>Step 3: </b>Like Siravyadhana, here also, the practitioner will cover the patient's eyes with cotton pads in this procedure. </p>
          <p><b>Step 4: </b>One or two needles are pricked on the skin to let out a drop of blood.</p>
          <p><b>Step 5: </b>Then, the practitioner applies one or two leeches to the area where he had pricked the needle.</p>
          <p><b>Step 6: </b>He/she will remove those leeches from the patient's skin once the leeches are enlarged and satiated.</p>
          <p><b>Step 7: </b>After the Ayurvedic doctor removes the leeches from the skin, he or she will clean the skin and disinfect it with herbal Ayurvedic medicine.</p>
  
          <p><b>4. Shrungavacharana</b></p>
          <p>This method is not that popular; hence it is less used in Ayurvedic centres and hospitals in the modern day.</p>
          <p><b>Step 1: </b>In this procedure, an Ayurvedic doctor applies cow's horns on a patient's selected body part.</p>
          <p><b>Step 2: </b>The doctor will select a fleshy area and clean it with antiseptic before using the horn.</p>
          <p><b>Step 3: </b>Next, the Ayurvedic practitioner will prick this area before putting this instrument on one’s skin.</p>
          <p><b>Step 4: </b>This instrument sucks the air out and creates a vacuum. Blood oozes out into this vacuum.</p>
          <p><b>Step 5: </b>One can use a bottle gourd or any other conical-shaped vegetable in place of using the animal horns to create the same effect.</p>            
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Who Should Be Avoided? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>Pregnant women.</li>
          <li>A person suffering from haemorrhoids.</li>
          <li>Anaemic person.</li>
          <li>Emaciated person.</li>
          </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Raktamoksham;
