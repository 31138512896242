/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Osteoporosis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Osteoporosis </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Osteoporosis?</strong>
        </Typography>
            <Typography variant="body1">
            Osteoporosis is a progressive bone disease characterized by decreased bone density
and increased risk of fractures. It occurs when the body loses too much bone or fails to
produce enough new bone, leading to fragile and weak bones. Osteoporosis often
develops silently over many years, and it is more common in women after menopause,
although men can also be affected. Risk factors include aging, hormonal changes, low
calcium and vitamin D intake, sedentary lifestyle, smoking, excessive alcohol
consumption, and certain medical conditions or medications. Osteoporosis may not
cause symptoms until a fracture occurs, typically in the spine, hip, or wrist. </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Osteoporosis </strong>
        </Typography>
        <Typography variant="body1">
        There typically are no symptoms in the early stages of bone loss. But once your bones have been weakened by osteoporosis, you might have signs and symptoms that include:
        <ul className='vw-ul'>
        <li>Back pain, caused by a fractured or collapsed vertebra</li>
        <li>Loss of height over time</li>
        <li>A stooped posture</li>
        <li>A bone that breaks much more easily than expected</li>
        </ul>
        </Typography>
   
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Causes of Osteoporosis </strong>
        </Typography>
            <Typography variant="body1">
              Osteoporosis can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Lack of weight-bearing exercise </li>
                <li>Poor diet </li>
                <li>Heavy smoking, drinking </li>
              </ul>
            </Typography>
      
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Risk Factors for Osteoporosis </strong>
        </Typography>
        <Typography variant="body1">
        Some risk factors for osteoporosis are out of your control, including:
        <ul className='vw-ul'>
        <li>Women are much more likely to develop osteoporosis than are men.</li>
        <li>The older you get, the greater your risk of osteoporosis.</li>
        <li>Having a parent or sibling with osteoporosis puts you at greater risk, especially if your mother or father fractured a hip. </li>
        <li>Men and women who have small body frames tend to have a higher risk because they might have less bone mass to draw from as they age. </li>
        </ul>
        </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
        <Typography variant="body1">
        Exercise and making sure you get enough calcium and vitamin D in your diet are usually all you’ll need to prevent osteoporosis. Your provider will help you find a combination of treatments that’s best for you and your bone health.
        <ul className='vw-ul'>
        <li>Always wear your seatbelt.</li>
        <li>Wear the right protective equipment for all activities and sports.</li>
        <li>Make sure your home and workspace are free from clutter that could trip you or others.</li>
        <li>Always use the proper tools or equipment at home to reach things. Never stand on chairs, tables or countertops. </li>
        <li>Follow a diet and exercise plan that’s healthy for you. </li>
        </ul>
        </Typography>
        
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Osteoporosis;
