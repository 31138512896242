import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/mediaActionTypes';
import mediaApi from '../../apis/mediaApi';

function* media(action) {
  try {
    const mediaResponse = yield call(mediaApi.getAll, action);
    yield put({ type: type.ALL_MEDIA_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_MEDIA_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaLoggedInUserMedia(action) {
  try {
    const mediaResponse = yield call(mediaApi.loggedInUserMedia, action);
    yield put({ type: type.LOGGED_IN_USER_MEDIA_ID_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_MEDIA_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaCreate(action) {
  try {
    const mediaResponse = yield call(mediaApi.create, action);
    yield put({ type: type.MEDIA_CREATE_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.MEDIA_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaGetBy(action) {
  try {
    const mediaResponse = yield call(mediaApi.getBy, action);
    yield put({ type: type.MEDIA_GET_BY_ID_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.MEDIA_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaUpdateBy(action) {
  try {
    const mediaResponse = yield call(mediaApi.updateBy, action);
    yield put({ type: type.MEDIA_UPDATE_BY_ID_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.MEDIA_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaUnshowBy(action) {
  try {
    const mediaResponse = yield call(mediaApi.unshowBy, action);
    yield put({ type: type.MEDIA_UNSHOW_BY_ID_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.MEDIA_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* mediaDeleteBy(action) {
  try {
    const mediaResponse = yield call(mediaApi.deleteBy, action);
    yield put({ type: type.MEDIA_DELETE_BY_ID_SUCCESS, mediaResponse: mediaResponse.data });
  } catch (e) {
    yield put({
      type: type.MEDIA_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myMediaSaga() {
  yield takeEvery(type.ALL_MEDIA_REQUESTING, media);
  yield takeEvery(type.LOGGED_IN_USER_MEDIA_ID_REQUESTING, mediaLoggedInUserMedia);
  yield takeEvery(type.MEDIA_CREATE_REQUESTING, mediaCreate);
  yield takeEvery(type.MEDIA_GET_BY_ID_REQUESTING, mediaGetBy);
  yield takeEvery(type.MEDIA_UPDATE_BY_ID_REQUESTING, mediaUpdateBy);
  yield takeEvery(type.MEDIA_UNSHOW_BY_ID_REQUESTING, mediaUnshowBy);
  yield takeEvery(type.MEDIA_DELETE_BY_ID_REQUESTING, mediaDeleteBy);
}

export default myMediaSaga;
