/* eslint-disable no-console */
/* eslint-disable no-undef */
import * as type from '../actionTypes/cartActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
  count: 0,
};

export default function cartReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.CART_CREATE_OR_UPDATE_CART_REQUESTING:
      return (newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      });
    case type.CART_CREATE_OR_UPDATE_CART_SUCCESS:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
        count: state.count++,
      });

    case type.CART_CREATE_OR_UPDATE_CART_FAILURE:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      });

    case type.CART_GET_CART_REQUESTING:
      return (newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      });

    case type.CART_GET_CART_SUCCESS:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      });

    case type.CART_GET_CART_FAILURE:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      });

    case type.CLEAR_CART_BY_ID_REQUESTING:
      return (newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      });

    case type.CLEAR_CART_BY_ID_SUCCESS:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      });
    case type.CLEAR_CART_BY_ID_FAILURE:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      });

    case type.DELETE_ITEM_FROM_CART_BY_REQUESTING:
      return (newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      });

    case type.DELETE_ITEM_FROM_CART_BY_SUCCESS:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      });
    case type.DELETE_ITEM_FROM_CART_BY_FAILURE:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      });

    case type.UPDATE_CART_ITEM_QUANTITY_REQUESTING:
      console.log('cartupdate data', action);
      return (newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      });

    case type.UPDATE_CART_ITEM_QUANTITY_SUCCESS:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      });
    case type.UPDATE_CART_ITEM_QUANTITY_FAILURE:
      return (newState = {
        ...state,
        loader: false,
        data: action.cartResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      });
    default:
      return newState;
  }
}
