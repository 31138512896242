import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
// import "../css/Sevicesnproduct.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ProductSlideShow() {

  const navigate = useNavigate();

  const categories = [

    {
      title: 'Arishtas And Asavaas',
      image: '/images/Arishta.jpg',
      route: 'arishtas-asavaas',
    },

    {
      title: 'Choornas',
      image: '/images/Choornas.jpg',
      route: 'choornas',
    },

    {
      title: 'Ghrutham',
      image: '/images/Ghrutham.png',
      route: 'ghrutham',
    },

    {
      title: 'Kashayam',
      image: '/images/Kashayam.jpg',
      route: 'Kashayam',
    },

    {
      title: 'Leham',
      image: '/images/leham.png',
      route: 'leham',
    },

    {
      title: 'Medicated Oils',
      image: '/images/Medicated-Oils.png',
      route: 'medicated-oils',
    },

    {
      title: 'Ointment & Spray',
      image: '/images/Oinment & Spray.jpg',
      route: 'ointment-and-spray',
    },

    {
      title: 'Tablets & Capsules',
      image: '/images/tablets.png',
      route: 'tablets-and-capsules',
    },

    // Categories array here
  ];


  const handleCategoryClick = (route) => {
    navigate(`/category-list/${encodeURIComponent(route)}`);
  };

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },

    ],
  };

  return (

    <div className="us-container">
      <h2 className="ayurvedic-vw-h">Product Category</h2>

      <Slider {...settings}>
        {categories.map((category, index) => (
          <Card key={index} sx={{ borderWidth: '1px', marginBottom: '20px' }}>
            <Box sx={{ padding: '15px', borderRadius: '15px' }}>
              <CardActionArea onClick={() => handleCategoryClick(category.route)} >

                <CardMedia
                  component="img"
                  height="270"
                  padding="20px"
                  borderRadius="5%"
                  image={category.image}
                  alt="green iguana"
                  sx={{ borderRadius: '15px' }}
                />

                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 'bold', fontSize: '18px' }}
                    className="product-c-name"
                  > {category.title} </Typography>

                  <Typography variant="body2" color="text.secondary">
                    <button
                      className="snp-btn"
                      onClick={() => handleCategoryClick(category.route)}
                    > View Products </button>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Box>
          </Card>
        ))}

      </Slider>
    </div>
  );
}
