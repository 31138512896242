export const ALL_NOTIFICATIONS_REQUESTING = 'ALL_NOTIFICATIONS_REQUESTING';
export const ALL_NOTIFICATIONS_SUCCESS = 'ALL_NOTIFICATIONS_SUCCESS';
export const ALL_NOTIFICATIONS_FAILURE = 'ALL_NOTIFICATIONS_FAILURE';

export const NOTIFICATIONS_CREATE_REQUESTING = 'NOTIFICATIONS_CREATE_REQUESTING';
export const NOTIFICATIONS_CREATE_SUCCESS = 'NOTIFICATIONS_CREATE_SUCCESS';
export const NOTIFICATIONS_CREATE_FAILURE = 'NOTIFICATIONS_CREATE_FAILURE';

export const NOTIFICATIONS_UNSHOW_BY_ID_REQUESTING = 'NOTIFICATIONS_UNSHOW_BY_ID_REQUESTING';
export const NOTIFICATIONS_UNSHOW_BY_ID_SUCCESS = 'NOTIFICATIONS_UNSHOW_BY_ID_SUCCESS';
export const NOTIFICATIONS_UNSHOW_BY_ID_FAILURE = 'NOTIFICATIONS_UNSHOW_BY_ID_FAILURE';


export const NOTIFICATIONS_DELETE_BY_ID_REQUESTING = 'NOTIFICATIONS_DELETE_BY_ID_REQUESTING';
export const NOTIFICATIONS_DELETE_BY_ID_SUCCESS = 'NOTIFICATIONS_DELETE_BY_ID_SUCCESS';
export const NOTIFICATIONS_DELETE_BY_ID_FAILURE = 'NOTIFICATIONS_DELETE_BY_ID_FAILURE';
