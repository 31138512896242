/* eslint-disable no-undef */
import * as type from '../actionTypes/userActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  errors: [], // fixed typo here
  data: null,
  registerData: null,
  showModal: false,
};

export default function userReducer(state = initialState, action) {
  console.log("hello from user reducer");
  let newState = { ...state };
  switch (action.type) {
    case type.USERS_LOGIN_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [], // fixed typo here
      };
    case type.USERS_LOGIN_SUCCESS:
      return newState = {
        ...state,
        data: action.usersResponse.user,
        loader: false,
        successful: true,
        errors: null, // fixed typo here
      };
    case type.USERS_LOGIN_FAILURE:
      return newState = {
        ...state,
        successful: false,
        data: action.users || null,
        errors: action.error, // fixed typo here
        loader: false,
      };
    case type.USERS_CREATE_REQUESTING:
      return newState = {
        ...state,
        registerData: null,
        loader: true,
        successful: false,

        errors: [],
        messages: [{ body: 'Signing up...', time: new Date() }],
      };
    case type.USERS_CREATE_SUCCESS:
      return newState = {
        ...state,
        registerData: action.usersResponse.user,
        loader: false,
        successful: true,
        errors: null, // fixed typo here
        showModal: true,
      };
    case type.USERS_CREATE_FAILURE:
      return newState = {
        ...state,
        registerData: null,
        data: action.users || null,
        successful: false,
        errors: action.error, // fixed typo here
        loader: false,
        showModal: false,
      };
    case type.HIDE_MODAL:
      return newState = {
        ...state,
        showModal: false,
      };
    case type.ALL_USERS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST
      };
    case type.ALL_USERS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.usersResponse,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED
      };
    case type.ALL_USERS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.users || null,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE
      };
    case type.USERS_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST
      };
    case type.USERS_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.usersResponse,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED
      };
    case type.USERS_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.users || null,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE
      };
    case type.USERS_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST
      };
    case type.USERS_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.usersResponse,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED
      };
    case type.USERS_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.users || null,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE
      };
    case type.USERS_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST
      };
    case type.USERS_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.usersResponse,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED
      };
    case type.USERS_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.users || null,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE
      };
    case type.USERS_DELETE_USER_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST
      };
    case type.USERS_DELETE_USER_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.usersResponse,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED
      };
    case type.USERS_DELETE_USER_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.users || null,
        message: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE
      };
    default:
      return newState;
  }
};
