import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const ExtremeObesity = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Extreme Obesity
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is Extreme Obesity?
            </Typography>
            <Typography variant="body1">
            Extreme obesity, also known as morbid obesity, is a condition characterized by a body mass index
(BMI) of 40 or higher. Symptoms of extreme obesity include excessive weight gain, difficulty in
performing physical activities, shortness of breath, joint pain, sleep apnea, fatigue, and increased
risk of various health complications. The causes of extreme obesity are multifactorial and can
include a combination of genetic, environmental, behavioral, and metabolic factors. Factors such
as unhealthy diet, sedentary lifestyle, hormonal imbalances, certain medications, and
psychological factors can contribute.            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of Extreme Obesity
            </Typography>
            <Typography variant="body1">
              Some common symptoms of Extreme Obesity include:
              <ul>
                <li>sweating, tiredness,difficulty breathing</li>
                <li>difficulty with physical activity</li>
                <li>joint and back pain</li>
                <li>sleep problems, including snoring
</li>
                <li>sleep problems, including snoring
                </li>
                <li>high blood pressure and other symptoms of metabolic syndrome</li>
                <li>a high buildup of fat around the body</li>
                <li>a BMI of 40 or above</li>
              </ul>
            </Typography>
     
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Extreme Obesity
            </Typography>
            <Typography variant="body1">
              Extreme Obesity can be caused by various factors, including:
              <ul>
                <li>Eating disorders.</li>
                <li>Genetics.</li>
                <li>Sedentary lifestyle.</li>
                <li>Intake of drugs such as steroid hormones.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for Extreme Obesity
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Extreme Obesity include:
              <ul>
                <li>Heart attacks,High blood pressure,Lack of sleep,Anxiety.</li>
                <li>Metabolic syndrome.</li>
                <li>Reproductive problems.</li>
                <li>Heart disease and blood lipid abnormalities.</li>
                <li>Type 2 diabetes.</li>
                <li>Sleep apnea (when a person stops breathing in sleep for some time).</li>
                <li>Gall bladder stones.</li>
                <li>Obesity hypoventilation syndrome.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
            There is no single way to lose weight or prevent weight gain. The best option will depend on the individual. However, dietary strategies and exercise can help.              <ul>
                <li>favoring whole or unprocessed foods</li>
                <li>consuming plenty of fresh fruits and vegetables
</li>
                <li>avoiding trans fats and saturated fats and opting for healthy fats, such as from safflower or corn oils</li>
                <li>limiting the intake of sugar and sweetened foods
</li>
                <li>monitoring the caloric intake, if a healthcare professional recommends this</li>
                <li>eating only at regular times</li>
                <li>eating more slowly & and in smaller plate</li>
                <li>avoiding triggers and habits that lead to overeating, such as having some cake with coffee at break times
</li>
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExtremeObesity;
