import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const productApi = {
  getAll: (params) => {
    const uri = HOST_ADDRESS + envVars.ALL_PRODUCTS;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  loggedInUserProducts: (params) => {
    const uri = HOST_ADDRESS + envVars.LOGGED_IN_USER_PAGES_ID;
    return axios.post(`${uri}?userId=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.PRODUCTS_CREATE;
    return axios.post(uri, params.payload).then((response) => {
      // eslint-disable-next-line no-console, no-undef
      console.log('DID IT HIT ?', uri, params, response);
      return response;
    });
  },
  getBy: (params) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('HHHHHHHHHHHH', params);
    const uri = HOST_ADDRESS + envVars.PRODUCTS_GET_BY_ID;
    return axios.get(`${uri}${params?.payload?.productId}`).then((response) => {
      return response;
    });
  },
  updateBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PRODUCTS_UPDATE_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PRODUCTS_UNSHOW_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PRODUCTS_DELETE_BY_ID;
    return axios.delete(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  currentMonthProductsVsPreviousMonth: (params) => {
    const uri = HOST_ADDRESS + envVars.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH;
    return axios.post(uri, params.payload).then((response) => {
      // eslint-disable-next-line no-console, no-undef
      console.log('DID IT HIT ?', uri, params, response);
      return response;
    });
  },
};

export default productApi;
