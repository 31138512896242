import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { ordersGetByAction } from '../../../redux/actions/orderActions';

import Layout from './Layout';

const COMPLIMENTARY_STATUS = {
  in_cart_only: 'In cart',
  awaiting_manufacturers_response: 'Awaiting manufacturer\'s response',
  in_transit: 'In transition',
  out_for_delivery: 'Out for delivery',
  delivered: 'Delivered'
};

function OrderInfo() {
  const orderByIdSelector = useSelector(state => state?.orders?.data);
  const [orderByIdFromSelector, setOrderByIdFromSelector] = useState([]);
  const { orderId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getAnOrderInfoPayload = {
      id: orderId,
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAnOrderById() {
      await dispatch(ordersGetByAction(getAnOrderInfoPayload));
    }

    fetchAnOrderById();
  }, [orderId]);

  useEffect(() => {
    if (orderByIdSelector && Object.keys(orderByIdSelector).length !== 0)
      setOrderByIdFromSelector(orderByIdSelector);
  }, [orderByIdSelector]);

  return (
    <Layout>
      <div style={{ marginTop: '80px', marginLeft: '80px' }}>
        <Typography variant="h5" gutterBottom>
          <strong>Order Details</strong>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" gutterBottom>
                <strong>Order Information</strong>
              </Typography>
              <Typography variant="body" gutterBottom>
                <strong>Order ID:</strong> {orderByIdFromSelector?.order_no}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Order Name:</strong> {orderByIdFromSelector?.order_name}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Customer Name:</strong> {orderByIdFromSelector?.belongs_to?.firstname} {orderByIdFromSelector?.belongs_to?.lastname}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Creation Date:</strong> {orderByIdFromSelector?.createdAt?.split('T')[0]}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Modification Date:</strong> {orderByIdFromSelector?.updatedAt?.split('T')[0]}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Status:</strong> {COMPLIMENTARY_STATUS[orderByIdFromSelector?.status]}
              </Typography>
              <br />
              <Typography variant="p" gutterBottom>
                <strong>Total:</strong> {orderByIdFromSelector?.itemsGrandTotalCost}
              </Typography>
              {/* <Typography variant="body1" gutterBottom>
                <strong>Quantity:</strong> {orderByIdFromSelector.quantity}
              </Typography> */}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" gutterBottom>
                <strong>Billing Address</strong>
              </Typography>
              <Typography variant="p" gutterBottom>
                {orderByIdFromSelector.billing_address}
              </Typography>
            </Paper>
            <br />
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" gutterBottom>
                <strong>Shipping Address</strong>
              </Typography>
              <Typography variant="p" gutterBottom>
                {orderByIdFromSelector.shipping_address}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <Button component={Link} to="/AllOrders" variant="contained" color="primary">
          Back to All Orders
        </Button>
      </div>
    </Layout>
  );
}

export default OrderInfo;
