import React from 'react';
import PropTypes from 'prop-types'; 
import { Outlet, Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// eslint-disable-next-line no-unused-vars
const ProtectedRoutes = ({ allowedRoles }) => {
  const ROLES = ['User', 'Editor', 'Admin'];
  const user = { role: 'Admin' };
  let isAuthenticated = secureLocalStorage.getItem('token') ? true : false;
  const userHasRequiredRole =
    user && ROLES.includes(secureLocalStorage.getItem('Role')) ? true : false;

  if (!isAuthenticated) {
    return <Navigate to='/' />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to='/access-denied' />;
  }

  return <Outlet />;
};

ProtectedRoutes.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoutes;
