/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerRequest, hideModal } from '../redux/actions/userActions';
import RegisterForm from '../../src/Components/Form/RegisterForm';

// eslint-disable-next-line no-useless-escape
const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-unused-vars
const phoneValidator = /^\d{10}$/;
// eslint-disable-next-line no-unused-vars
const passwordValidator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
const nameValidator = /^[A-Za-z\s]+$/;
const addressValidator = /^[A-Za-z0-9\s]+$/;

export default function Membership() {
  const [registerPage, setRegisterPage] = useState({
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
    address: '',
    password: '',
    confirmpassword: '',
  });

  const [registerPageErrors, setRegisterPageErrors] = useState({
    usernameError: '',
    firstnameError: '',
    lastnameError: '',
    emailError: '',
    phonenumberError: '',
    addressError: '',
    passwordError: '',
    confirmpasswordError: '',
  });

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state.users);

  function handleInput(e) {
    const { name, value } = e.target;
    setRegisterPage({ ...registerPage, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Resetting errors state before validation
    setRegisterPageErrors({
      usernameError: '',
      firstnameError: '',
      lastnameError: '',
      emailError: '',
      phonenumberError: '',
      addressError: '',
      passwordError: '',
      confirmpasswordError: '',
    });

    // Validation
    let hasError = false;

    if (registerPage.username.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        usernameError: 'Please enter a username',
      }));
      hasError = true;
    } else if (!nameValidator.test(registerPage.username.trim())) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        usernameError: 'Username can only contain letters and spaces',
      }));
      hasError = true;
    }

    if (registerPage.firstname.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        firstnameError: 'Please enter a first name',
      }));
      hasError = true;
    } else if (!nameValidator.test(registerPage.firstname.trim())) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        firstnameError: 'First name can only contain letters and spaces',
      }));
      hasError = true;
    }

    if (registerPage.lastname.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        lastnameError: 'Please enter a last name',
      }));
      hasError = true;
    } else if (!nameValidator.test(registerPage.lastname.trim())) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        lastnameError: 'Last name can only contain letters and spaces',
      }));
      hasError = true;
    }

    if (registerPage.email.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        emailError: 'Please enter an email',
      }));
      hasError = true;
    } else if (!emailValidator.test(registerPage.email.trim())) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        emailError: 'Please enter a valid email',
      }));
      hasError = true;
    }

    if (registerPage.phonenumber.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        phonenumberError: 'Please enter a phone number',
      }));
      hasError = true;
    }

    if (registerPage.address.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        addressError: 'Please enter an address',
      }));
      hasError = true;
    } else if (!addressValidator.test(registerPage.address.trim())) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        addressError: 'Address can only contain letters, numbers, and spaces',
      }));
      hasError = true;
    }

    if (registerPage.password.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        passwordError: 'Please enter a password',
      }));
      hasError = true;
    }

    if (registerPage.confirmpassword.trim() === '') {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        confirmpasswordError: 'Please confirm your password',
      }));
      hasError = true;
    } else if (registerPage.password.trim() !== registerPage.confirmpassword.trim()) {
      setRegisterPageErrors((prevErrors) => ({
        ...prevErrors,
        confirmpasswordError: 'Passwords do not match',
      }));
      hasError = true;
    }

    // If there are no errors, proceed with registration
    if (!hasError) {
      const payload = {
        username: registerPage.username,
        firstname: registerPage.firstname,
        lastname: registerPage.lastname,
        email: registerPage.email,
        phone: registerPage.phonenumber,
        address: registerPage.address,
        password: registerPage.password,
      };

      await dispatch(registerRequest(payload));
      console.log('hello from register');
    }
  };

  const redirectHandler = async () => {
    await dispatch(hideModal({ data: 'dfdjfhd' }));
    await navigate('/login');
  };

  return (
    <RegisterForm
      handleInput={handleInput}
      handleSubmit={handleSubmit}
      registerPage={registerPage}
      registerPageErrors={registerPageErrors}
      store={store}
      redirectHandler={redirectHandler}
      users={users}
    />
  );
}
