/* eslint-disable no-console */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  MenuItem
} from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { productsCreateAction } from '../../../redux/actions/productsActions';
import {
  categoriesAction,
  // categoriesUpdateByAction 
} from '../../../redux/actions/categoryActions';
import { mediaCreateAction } from '../../../redux/actions/mediaActions';
import { tagsAction } from '../../../redux/actions/tagActions';

import Layout from './Layout';
import './AddproductPage.css';

const AddProductPage = () => {
  const categorySelector = useSelector(state => state?.categories?.data);
  const tagSelector = useSelector(state => state?.tags?.data);
  const mediaSelector = useSelector(state => state?.media?.data?.media);

  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [indications, setIndications] = useState('');
  const [dosage, setDosage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [regularPrice, setRegularPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [sku, setSku] = useState('');
  const [stock, setStock] = useState('');
  const [tag, setTag] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [categoriesFromSelector, setCategoriesFromSelector] = useState('');
  const [tagsFromSelector, setTagsFromSelector] = useState('');
  const [addProductFeedback, setAddProductFeedback] = useState(<></>);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAllCategories() {
      await dispatch(categoriesAction());
    }
    async function fetchAllTags() {
      await dispatch(tagsAction());
    }

    fetchAllCategories();
    fetchAllTags();
  }, [dispatch]);

  useEffect(() => {
    const formData = new FormData();

    const uploadMedia = async () => {
      formData.append('image', imageFile);
      formData.append('Role', secureLocalStorage.getItem('Role'));
      formData.append('userId', JSON.parse(secureLocalStorage.getItem('users'))._id);
      formData.append('email', JSON.parse(secureLocalStorage.getItem('users')).email);

      formData.append('belongs_to', JSON.parse(secureLocalStorage.getItem('users'))._id);
      formData.append('belongs_to_type', 'Page');

      formData.append('belongs_to_type_id', categorySelector?._id);
      formData.append('isDeleted', false);
      formData.append('content_type', 'media');
      if (imageFile)
        await (dispatch(mediaCreateAction(formData)));
      setImageFile(null);
    };

    console.log('>>>>>>>> imageFile', imageFile);
    if (categorySelector && Array.isArray(categorySelector) && categorySelector?.length !== 0) {
      uploadMedia();
      setCategoriesFromSelector(categorySelector);
    }
  }, [categorySelector]);

  useEffect(() => {
    if (tagSelector && Array.isArray(tagSelector) && tagSelector?.length !== 0)
      setTagsFromSelector(tagSelector);
  }, [tagSelector]);

  console.log('>>>>>> mediaSelector', mediaSelector);

  useEffect(() => {
    const updateProductLinkedMediaId = async () => {
      const updateProductPayload = {
        Role: secureLocalStorage.getItem('Role'),
        belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
        email: JSON.parse(secureLocalStorage.getItem('users')).email,
        content_type: 'product',
        isDeleted: false,

        product_name: productName,
        description: description,
        reference: reference,
        ingredients: ingredients,
        indications: indications,
        dosage: dosage,
        sku: sku,
        stock: stock,
        regular_price: regularPrice,
        sale_price: salePrice,
        categories: categoriesIds,
        tags: tagIds,
        linkedMedia: mediaSelector?._id
      };

      console.log('>>>>>> updateProductPayload', updateProductPayload);


      await dispatch(blogsUpdateByAction(updateProductPayload));

      // Reset the form
      // setProductName('');
      // setDescription('');
      // setReference('');
      // setIngredients('');
      // setIndications('');
      // setDosage('');
      // setImageFile([]);
      // setCategories([]);
      // setCategoriesIds([]);
      // setRegularPrice('');
      // setSalePrice('');
      // setSku('');
      // setStock('');
      // setTag([]);
      // setTagIds([]);

    };

    if (mediaSelector && Object.keys(mediaSelector).length !== 0) {
      updateProductLinkedMediaId();
    }
  }, [mediaSelector]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  const handleIngredientsChange = (event) => {
    setIngredients(event.target.value);
  };

  const handleIndicationsChange = (event) => {
    setIndications(event.target.value);
  };

  const handleDosageChange = (event) => {
    setDosage(event.target.value);
  };

  const handleImageChange = (event) => {
    // const files = Array.from(event.target.files);
    const file = event.target.files[0];

    // if (files?.length > 0) {
    setImageFile(file);
    // }
  };

  const handleRegularPriceChange = (event) => {
    setRegularPrice(event.target.value);
  };

  const handleSalePriceChange = (event) => {
    setSalePrice(event.target.value);
  };

  const handleSkuChange = (event) => {
    setSku(event.target.value);
  };

  const handleStockChange = (event) => {
    setStock(event.target.value);
  };

  const handleTagChange = (event) => {
    const latestTagId = event.target.value[tag.length]._id;
    const latestTagName = event.target.value[tag.length].name;
    console.log('latestTagId', latestTagId, '||', tag);
    console.log('latestTagName', latestTagName, '||', tagIds);
    if (!tag.includes(latestTagName)) {
      setTag(previousTags => [...previousTags, latestTagName]);
      setTagIds(previousTags => [...previousTags, latestTagId]);
    } else {
      setTag(tag.filter(tag => tag !== latestTagName));
      setTagIds(tagIds.filter(tag => tag !== latestTagId));
    }
  };

  const handleCategoriesChange = (event) => {
    const latestCategoryId = event.target.value[categories.length]._id;
    const latestCategoryName = event.target.value[categories.length].name;
    if (!categories.includes(latestCategoryName)) {
      setCategories(previousCategories => [...previousCategories, latestCategoryName]);
      setCategoriesIds(previousCategories => [...previousCategories, latestCategoryId]);
    } else {
      setCategories(categories.filter(category => category !== latestCategoryName));
      setCategoriesIds(categoriesIds.filter(category => category !== latestCategoryId));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here you can add the logic to save the product data to your database or perform any other actions

    const addProductPayload = {
      Role: secureLocalStorage.getItem('Role'),
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      content_type: 'product',
      isDeleted: false,

      product_name: productName,
      description: description,
      reference: reference,
      ingredients: ingredients,
      indications: indications,
      dosage: dosage,
      sku: sku,
      stock: stock,
      regular_price: regularPrice,
      sale_price: salePrice,
      categories: categoriesIds,
      tags: tagIds,
      // linkedMedia: imageFile, after dispatching 
    };

    await dispatch(productsCreateAction(addProductPayload));

    setAddProductFeedback(
      <div className="add_product_feedback" style={{ color: '#2e6930' }}>
        Your product has been published.
      </div>
    );

    // // Reset the form
    setProductName('');
    setDescription('');
    setReference('');
    setIngredients('');
    setIndications('');
    setDosage('');
    setImageFile([]);
    setCategories([]);
    setCategoriesIds([]);
    setRegularPrice('');
    setSalePrice('');
    setSku('');
    setStock('');
    setTag([]);
    setTagIds([]);

    setTimeout(() => {
      setAddProductFeedback(<></>);
    }, 3000);
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6>Add Product</h6>

          <div maxWidth="lg" style={{ border: '1px solid #dddddd', padding: '20px 20px 30px 20px', marginTop: '20px' }}>
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <form onSubmit={handleSubmit} className="add-pdt-content">
                  <TextField className="add-pdt"
                    label="Product Name"
                    fullWidth
                    value={productName}
                    onChange={handleProductNameChange}
                    margin="normal"
                    required={true}
                  />
                  <TextField className="add-pdt"
                    label="Description"
                    fullWidth
                    multiline
                    /*  rows={4} */
                    value={description}
                    onChange={handleDescriptionChange}
                    margin="normal"
                    required={true}
                  />

                  <TextField className="add-pdt"
                    label="Reference"
                    fullWidth
                    multiline
                    /*  rows={4} */
                    value={reference}
                    onChange={handleReferenceChange}
                    margin="normal"
                    required={true}
                  />

                  <TextField className="add-pdt"
                    label="Ingredients"
                    fullWidth
                    multiline
                    /*  rows={4} */
                    value={ingredients}
                    onChange={handleIngredientsChange}
                    margin="normal"
                    required={true}
                  />

                  <TextField className="add-pdt"
                    label="Indications"
                    fullWidth
                    multiline
                    /*  rows={4} */
                    value={indications}
                    onChange={handleIndicationsChange}
                    margin="normal"
                    required={true}
                  />

                  <TextField className="add-pdt"
                    label="Dosage"
                    fullWidth
                    multiline
                    /*  rows={4} */
                    value={dosage}
                    onChange={handleDosageChange}
                    margin="normal"
                    required={true}
                  />

                  <TextField className="add-pdt"
                    label="SKU"
                    fullWidth
                    value={sku}
                    onChange={handleSkuChange}
                    margin="normal"
                    required={true}
                  />
                  <TextField className="add-pdt"
                    label="Stock"
                    fullWidth
                    value={stock}
                    onChange={handleStockChange}
                    margin="normal"
                    required={true}
                  />
                  <TextField className="add-pdt"
                    label="Categories"
                    fullWidth
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected?.join(', '),
                    }}
                    value={categories || []}
                    onChange={handleCategoriesChange}
                    margin="normal"
                    required={true}
                  >
                    {Array.isArray(categoriesFromSelector) && categoriesFromSelector.map(aCategory => (
                      <MenuItem key={aCategory._id} value={aCategory}>
                        {aCategory.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField className="add-pdt"
                    label="Regular Price"
                    fullWidth
                    value={regularPrice}
                    onChange={handleRegularPriceChange}
                    margin="normal"
                    required={true}
                  />
                  <TextField className="add-pdt"
                    label="Sale Price"
                    fullWidth
                    value={salePrice}
                    onChange={handleSalePriceChange}
                    margin="normal"
                    required={true}
                  />
                  <TextField className="add-pdt"
                    label="Tags"
                    fullWidth
                    value={tag || []}
                    onChange={handleTagChange}
                    margin="normal"
                    required={true}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected?.join(', '),
                    }}
                  >
                    {Array.isArray(tagsFromSelector) && tagsFromSelector.map(aTag => (
                      <MenuItem key={aTag._id} value={aTag}>
                        {aTag.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <input className="add-pdt"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-file-input"
                    type="file"
                    // multiple
                    onChange={handleImageChange}
                  />
                  <label htmlFor="image-file-input">
                    <Button variant="contained" color="primary" component="span" className="btn-bg" style={{ marginLeft: '12px', marginTop: '10px', marginBottom: '10px' }}>
                      Upload Images
                    </Button>
                  </label>
                  <br />
                  <span style={{ display: 'flex' }}>
                    <div>
                      <Button type="submit" variant="contained" color="primary" className="btn-bg" style={{ marginLeft: '12px' }}>
                        Publish
                      </Button>
                    </div>
                    {addProductFeedback}
                  </span>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  backgroundColor: '#FFFFFF',
                }}
              >

                {imageFile && imageFile?.length > 0 && (
                  <Card style={{ marginTop: '3rem', paddingTop: '3rem' }}>
                    <CardMedia
                      component="img"
                      src={URL.createObjectURL(imageFile[0])}
                      style={{
                        maxHeight: 100,
                        objectFit: 'contain',
                      }}
                    />

                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {productName}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Regular Price: {regularPrice}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Sale Price: {salePrice}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                {imageFile && imageFile?.length > 1 && (
                  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    {imageFile.slice(1).map((file, index) => (
                      <Grid item xs={6} key={index}>
                        <Card>
                          <CardMedia
                            component="img"
                            src={URL.createObjectURL(file)}
                            style={{
                              height: 100,
                              objectFit: 'contain',
                            }}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProductPage;
