/* eslint-disable no-undef */
// reducers/contactReducer.js
import * as type from "../types";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_CONTACT_REQUESTING:
      console.log("reducer contact");
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case type.CREATE_CONTACT_SUCCESS:
      console.log("reducer contact");
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case type.CREATE_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;
