/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Virechana
 = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Virechana</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Virechana ?</strong>
          </Typography>
          <Typography variant="body1">
          <p>Virechana is one of the panchakarma procedures. </p>
          <p>Panchakarma therapy includes the elimination of increasing doshas from the body to maintain the condition of health for a longer time. Virechana is also one of the Panchakarma therapies in which purgation is induced by drugs to eliminate the excess of Pitta dosha from the body. </p>
          <p>The evaluation of Virechana is based on various parameters like shuddhi (cleansing), vaigiki (number of times of stool passed), Manaki (quantity of stool) laingiki (symptoms based assessment) and Antyaki (assessment of ending of purgation) Shuddhi. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Virechana </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>Flushes out the toxins and excess mucus from the GK tract and it improves digestion.</li>
          <li>Clears congestion from the liver and improves metabolism and fat digestion.</li>
          <li>Gets relief in many gastrointestinal disorders.</li>
          <li>It improves the condition of skin disorders like rashes and allergy.</li>
          <li>It helps in treating diseases like constipation, piles, acidity, ulcers, liver, jaundice, inflammation.</li>
          <li>Cleanses body from poisoning.</li>
          <li>Relieves from headache, anemia, pain in the large intestine.</li>
          <li>It helps manage diabetes and heart diseases.</li>
          <li>Reduces gynecological disorders.</li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Procedure of Virechana </strong>
          </Typography>
          <Typography variant="body1">
          <p>In this detoxification therapy, all vitiated doshas and toxins are eliminated through the return. It includes intake of Ayurvedic and herbal medicines which reduce doshas and toxins from the body and collect them to the abdomen. Mainly, Pitta is situated at the level of intestine. So, it is the best way to expel it through the anal route. The Ayurvedic medicines which are used for the procedure may vary from person to person depending patient's digestion strength. </p>
          <p><strong>Three-step of Virechanakarma </strong></p>
          <ul className='vw-ul'>
          <li>Poorvakarma is the preparatory procedures. It includes Deepana and Pachana followed by snehapana (oral intake of medicated ghee) for 3-7 days as per the condition of the patient. This is followed by Abhyana (external use of oil) and swedana (sudation) for 3 days.</li>
          <li>Pradhanakarma is the administration of the drug of virechana as per disease. The drug used for virechana is Triphala prepared from Haritaki, Vibhitaki, and Amalaki. Castor oil is also added 30-35 ml according to the requirement.</li>
          <li>Pashchatakarma is known as samsarjanakarma. In this procedure, the patient is advised for the specific dietary regimen for 3-7 days depending on the number of bouts of bowel administered during virechana treatment</li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Who Should Be Avoided? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>Patients below the age of 18 and above 80 years are excluded.</li>
          <li>In children</li>
          <li>Patients suffering from diabetes mellitus should not be included.</li>
          <li>Patients of status eczematous conditions are also avoided.</li>
          <li>Routine and microscopic tests of urine and blood are to be done..</li>
          </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Virechana
;
