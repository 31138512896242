/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Cervical = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Cervical </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Cervical?</strong>
        </Typography>
          <Typography variant="body1">
            Cervical spondylitis, also known as cervical spondylosis or neck
            arthritis, is a degenerative condition affecting the cervical spine
            (neck region). It is characterized by the wear and tear of the
            spinal discs and joints, leading to pain and stiffness in the neck.
            Common symptoms include neck pain that may radiate to the shoulders
            and arms, limited neck movement, headaches, muscle weakness, and
            tingling or numbness in the arms or hands. The main causes of
            cervical spondylitis are age-related degeneration, repetitive neck
            movements, poor posture, and spinal injuries.{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of Cervical</strong>
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Cervical include:
            <ul className='vw-ul'>
              <li>
                Pain in the neck that may travel to your arms or shoulders
              </li>
              <li>A grinding feeling when you move your neck</li>
              <li>Headaches</li>
              <li>A grinding feeling when you move your neck</li>
              <li>Weakness in your arms and legs</li>
              <li>Numbness in your shoulders, arms, or hands</li>
              <li>Stiffness in the neck</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Causes of Cervical</strong>
          </Typography>
          <Typography variant="body1">
            As people age, the structures that make up the backbone and neck
            gradually develop wear and tear. These changes can include:{' '}
            <ul className='vw-ul'>
              <li>
                <b>Dehydrated disks:</b> Disks act like cushions between the
                vertebrae of the spine. By the age of 40, most people's spinal
                disks begin drying out and shrinking. As the disks become
                smaller, there is more bone-on-bone contact between the
                vertebrae.
              </li>
              <li>
                <b>Bone spurs:</b> As the disks break down, the body may produce
                extra amounts of bone in a misguided effort to strengthen the
                spine. These bone spurs can sometimes pinch the spinal cord and
                nerve roots.
              </li>
              <li>
                <b>Herniated diskss:</b> Cracks also appear on the exterior of
                the spinal disks. The soft interior of a disk can squeeze
                through these cracks. Sometimes, it presses on the spinal cord
                and nerve roots.
              </li>
              <li>
                <b>Stiff ligaments:</b> Ligaments are cords of tissue that
                connect bone to bone. Spinal ligaments can stiffen with age,
                making the neck less flexible.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Cervical</strong>
          </Typography>
          <Typography variant="body1">
            Risk factors for cervical include:{' '}
            <ul className='vw-ul'>
              <li>Cervical occurs commonly as part of aging.</li>
              <li>
                Jobs that involve repetitive neck motions, awkward positioning
                or a lot of overhead work put extra stress on the neck.
              </li>
              <li>
                Previous neck injuries appear to increase the risk of cervical
                spondylosis.
              </li>
              <li>
                Some individuals in certain families will experience more of
                these changes over time.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Prevention and Management</strong>
          </Typography>
          <Typography variant="body1">
            You may not be able to prevent cervical spondylosis, but these steps
            may reduce your risk:{' '}
            <ul className='vw-ul'>
              <li>Stay physically active.</li>
              <li>Use good posture.</li>
              <li>
                Prevent neck injuries by always using the right equipment and
                the right form when exercising or playing sports.
              </li>
              <li>Avoid trauma to your neck</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Cervical;
