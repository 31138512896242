/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import '../css/Accountdetailspage.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import UserSection from '../Components/Common/Usersection';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

export default function AccountDetailsPage() {
  const [user, setUser] = useState(JSON.parse(secureLocalStorage.getItem('users')));
  const [formData, setFormData] = useState({
    firstName: user.firstname ? user.firstname : '',
    lastName: user.lastname ? user.lastname : '',
    displayName: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : '',
    email: user.email ? user.email : '',
    currentPassword:  '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userData, setUserData] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    // Make API request to get user data
    axios
      .get('http://localhost:5000/api/user/' + user._id)
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // Send POST request to backend with updated user information
    axios
      .post('http://localhost:5000/api/updateUser/' + user._id, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        displayName: formData.displayName,
        email: formData.email,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      })
      .then((response) => {
        setSuccessMessage(response.data.message);
        setUpdatedUser(response.data.data);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div className="us-container">
    <h1 className="us-header">Account Details </h1>
      <Grid container spacing={0}>
        <Grid item md={3} sm={12} xs={12}>
          <UserSection />
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <Box>
            <Box className="account-details-container">
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              <form onSubmit={handleFormSubmit}>
                <TextField
                  className="outlined-basic"
                  label="First name"
                  variant="outlined"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <TextField
                  className="outlined-basic-1"
                  label="Last name"
                  variant="outlined"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <TextField
                  className="lg-input"
                  label="Display name"
                  variant="outlined"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleChange}
                />
                <TextField
                  className="lg-input"
                  label="Email address"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  className="lg-input"
                  label="Current password"
                  variant="outlined"
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
                <TextField
                  className="lg-input"
                  label="New password"
                  variant="outlined"
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                />
                <TextField
                  className="lg-input"
                  label="Confirm password"
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <div className='at-btn'>
                <button className='account-btn' type="submit">SAVE CHANGES</button>
                </div>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
