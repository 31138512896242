import React from "react";
import { Container, Typography } from "@mui/material";

const CommingSoon = () => {
  return (
    <Container
      maxWidth="sm"
      style={{ marginTop: "30px", marginBottom: "35px" }}
    >
      <Typography variant="h3" align="center" gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        We are working hard to bring you something amazing. Stay tuned!
      </Typography>
    </Container>
  );
};

export default CommingSoon;
