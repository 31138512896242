import React from 'react';
import '../css/Shipingadr.css';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Usersection from '../Components/Common/Usersection';
import { Typography } from '@mui/material';


export default function Shippingadr() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
    <Grid container spacing={0}>


    <Grid item md={3} sm={12} xs={12}>
    <Usersection />
    </Grid>

{/* -----------------usersection------------------------------ */}

<Grid item md={9} sm={12} xs={12}>
<Box sx={{marginTop:'5%',padding:'2rem',backgroundColor:'', marginRight:'2rem',
 textAlign:'left'
}}>
 <Typography align="left" className='billingadr'>Shipping address</Typography>
    <Box className="badr-container">
    <TextField className="outlined-basic" label="First name" variant="outlined" />{''}
    <TextField className="outlined-basic-1" label="Last name" variant="outlined" />
    {/* <TextField className="outlined-basic" label="Last name" variant="outlined" /> */}

    <FormControl fullWidth>
        <InputLabel className="lg-input">Country/Origin</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          className="lg-input"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
    </FormControl>
    <TextField className="lg-input" label="Street address" variant="outlined" />
    <TextField className="lg-input" label="Street address-Housenumber" variant="outlined" />
    <TextField className="lg-input" label="Town/City" variant="outlined" />
    <TextField className="lg-input" label="State" variant="outlined" />
    <TextField className="lg-input" label="ZIP Code" variant="outlined" />
    <button className='badr-btn'>SAVE ADDRESS</button>


    </Box>
</Box>
</Grid>



</Grid>
    </div>
  );
}
