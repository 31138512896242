import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/cartActionTypes';
import cartApi from '../../apis/cartApi';

function* cartCreateOrUpdateCart(action) {
  try {
    const cartResponse = yield call(cartApi.createOrUpdateCart, action);
    yield put({ type: type.CART_CREATE_OR_UPDATE_CART_SUCCESS, cartResponse: cartResponse?.data });
  } catch (e) {
    yield put({
      type: type.CART_CREATE_OR_UPDATE_CART_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* cartGetCart(action) {
  try {
    const cartResponse = yield call(cartApi.getCart, action);
    yield put({ type: type.CART_GET_CART_SUCCESS, cartResponse: cartResponse?.data });
  } catch (e) {
    yield put({
      type: type.CART_GET_CART_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* cartClearBy(action) {
  try {
    const cartResponse = yield call(cartApi.clearBy, action);
    yield put({ type: type.CLEAR_CART_BY_ID_SUCCESS, cartResponse: cartResponse?.data });
  } catch (e) {
    yield put({
      type: type.CLEAR_CART_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* cartDeleteItemFromCartBy(action) {
  try {
    const cartResponse = yield call(cartApi.deleteItemBy, action);
    yield put({ type: type.DELETE_ITEM_FROM_CART_BY_SUCCESS, cartResponse: cartResponse?.data });
  } catch (e) {
    yield put({
      type: type.DELETE_ITEM_FROM_CART_BY_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* cartUpdateCartItemQuantity(action) {
  try {
    const cartResponse = yield call(cartApi.updateCartItemQuantity, action);
    yield put({ type: type.UPDATE_CART_ITEM_QUANTITY_SUCCESS, cartResponse: cartResponse?.data });
  } catch (e) {
    yield put({
      type: type.UPDATE_CART_ITEM_QUANTITY_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myCartSaga() {
  yield takeEvery(type.CART_CREATE_OR_UPDATE_CART_REQUESTING, cartCreateOrUpdateCart);
  yield takeEvery(type.CART_GET_CART_REQUESTING, cartGetCart);
  yield takeEvery(type.CLEAR_CART_BY_ID_REQUESTING, cartClearBy);
  yield takeEvery(type.DELETE_ITEM_FROM_CART_BY_REQUESTING, cartDeleteItemFromCartBy);
  yield takeEvery(type.UPDATE_CART_ITEM_QUANTITY_REQUESTING, cartUpdateCartItemQuantity);
}

export default myCartSaga;
