import React from 'react';
// import Divider from "@mui/material/Divider";
import {
  Routes,
  Route,
  Link,
  useMatch,
} from 'react-router-dom';
// import "./Blogdetailpage.css";



export default function Topics() {
  const match = useMatch('/topics/*');
  let url = match.pathnameBase;
 // let path = match.pathname;
  return (
    <div>
      <h2>Topics</h2>
      <ul>
        <li>
          <Link to={`${url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>
      <Routes>
        <Route exact path="/" element={<Topic />} />
      </Routes>
    </div>
  );
}

function Topic() {
  const match = useMatch('/topics/*');


  switch (match.pathname) {
    case '/topics/components':
      return <div>HELLO FROM COMPONENTS</div>;
    case '/topics/rendering':
      return <div>HELLO FROM RENDERING</div>;
    case '/topics/props-v-state':
      return <div>HELLO FROM PROPS VS STATE</div>;
    default:
      return (
        <div>
          <h1>NO MATCHING COMPONENT FOUND</h1>
        </div>
      );
  }
}
