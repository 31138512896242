import * as type from '../actionTypes/categoryActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function categoryReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_CATEGORIES_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_CATEGORIES_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_CATEGORIES_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_CATEGORIES_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.LOGGED_IN_USER_CATEGORIES_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_CATEGORIES_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.CATEGORIES_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.CATEGORIES_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.CATEGORIES_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.CATEGORIES_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.CATEGORIES_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.CATEGORIES_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.CATEGORIES_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.CATEGORIES_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.CATEGORIES_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.CATEGORIES_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.CATEGORIES_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.CATEGORIES_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.CATEGORIES_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.CATEGORIES_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.CATEGORIES_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.FIND_MOST_SOLD_CATEGORY_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.FIND_MOST_SOLD_CATEGORY_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.FIND_MOST_SOLD_CATEGORY_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.FIND_TOP_CATEGORIES_SOLD_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.FIND_TOP_CATEGORIES_SOLD_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.FIND_TOP_CATEGORIES_SOLD_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.categoriesResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };


    default:
      return newState;
  }
}