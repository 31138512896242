import * as type from '../actionTypes/productActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function productReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_PRODUCTS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_PRODUCTS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_PRODUCTS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_PRODUCTS_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.LOGGED_IN_USER_PRODUCTS_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_PRODUCTS_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

      case type.PRODUCTS_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.PRODUCTS_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.PRODUCTS_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

      
    case type.PRODUCTS_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.PRODUCTS_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.PRODUCTS_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.PRODUCTS_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.PRODUCTS_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.PRODUCTS_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.PRODUCTS_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.PRODUCTS_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.PRODUCTS_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.PRODUCTS_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.PRODUCTS_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.PRODUCTS_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.productsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

      case type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_REQUESTING:
        return newState = {
          ...state,
          loader: true,
          messages: [{ time: new Date() }],
          status: envVars.REDUCER_REQUEST,
        };
  
      case type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_SUCCESS:
        return newState = {
          ...state,
          loader: false,
          data: action.productsResponse,
          messages: [{ time: new Date() }],
          status: envVars.REDUCER_SUCCEEDED,
        };
  
      case type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_FAILURE:
        return newState = {
          ...state,
          loader: false,
          data: action.productsResponse,
          messages: [{ time: new Date() }],
          status: envVars.REDUCER_FAILURE,
        };
    default:
      return newState;
  }
}