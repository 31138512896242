import React, { useState } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Grid,
} from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { customersCreateAction } from '../../../redux/actions/customerActions';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: 'auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {
      margin: theme.spacing(2, 0),
    },
  },
  columnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '0 0 calc(50% - 10px)',
    },
  },
}));

const PatientProfilePage = () => {
  const classes = useStyles();

  const [profileData, setProfileData] = useState({
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    website: '',
    bio: '',
    avatar: null,
    password: '',
    sessions: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    phone: '',
  });

  const dispatch = useDispatch();

  const handleProfileDataChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProfileData((prevData) => ({
        ...prevData,
        avatar: reader.result,
      }));
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const customerCreationPayload = {
      username: profileData.username,
      first_name: profileData.firstname,
      last_name: profileData.lastname,
      customer_email: profileData.email,
      phone_number: profileData.phone,
      biographical_information: profileData.bio,
      address_line_one: profileData.addressLine1,
      address_line_two: profileData.addressLine2,
      city: profileData.city,
      pincode: profileData.pincode,
      state: profileData.state,
      country: profileData.country,
      password: profileData.password,
      sessions: profileData.sessions,
      isDeleted: false,
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      created_by: JSON.parse(secureLocalStorage.getItem('users'))._id,
      no_of_transactions: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email
    };

    dispatch(customersCreateAction(customerCreationPayload));
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content r-txt">
          <h6 style={{ marginBottom: '20px', borderBottom: 'none', fontSize: '25px' }}>Profile </h6>

          <Paper className={classes.root}>
            <Avatar
              alt="Profile Picture"
              src={profileData.avatar}
              className={classes.avatar}
            />

        <form className={classes.form} onSubmit={handleSubmit}>
          <Typography variant="h6" className={classes.heading}>
            Profile Information
          </Typography>
          <Grid container spacing={3} className={classes.columnContainer}>
            <Grid item xs={4}>
              <TextField
                label="Username"
                name="username"
                value={profileData.username}
                onChange={handleProfileDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="First Name"
                name="firstname"
                value={profileData.firstname}
                onChange={handleProfileDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Last Name"
                name="lastname"
                value={profileData.lastname}
                onChange={handleProfileDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Email"
                name="email"
                value={profileData.email}
                onChange={handleProfileDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Phone Number"
                name="phone"
                value={profileData.phone}
                onChange={handleProfileDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
            <TextField
            label="Biographical Information"
            name="bio"
            value={profileData.bio}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          </Grid>
          
          <Typography variant="h6" className={classes.heading}>
          Contact Details
          </Typography>
          <Grid container spacing={3} className={classes.columnContainer}>
          <Grid item xs={4}>
          <TextField
            label="Address Line 1"
            name="addressLine1"
            value={profileData.addressLine1}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            label="Address Line 2"
            name="addressLine2"
            value={profileData.addressLine2}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            label="City"
            name="city"
            value={profileData.city}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            label="Pincode"
            name="pincode"
            value={profileData.pincode}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            label="State"
            name="state"
            value={profileData.state}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            label="Country"
            name="country"
            value={profileData.country}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          </Grid>
          <Typography variant="h6" className={classes.heading}>
            Account Management
          </Typography>
          <Grid container spacing={2} className={classes.columnContainer}>
          <Grid item xs={6}>
          <TextField
            label="New Password"
            name="password"
            value={profileData.password}
            onChange={handleProfileDataChange}
            fullWidth
            type="password"
          />
          </Grid>
          <Grid item xs={6}>
          <TextField
            label="Sessions"
            name="sessions"
            value={profileData.sessions}
            onChange={handleProfileDataChange}
            fullWidth
          />
          </Grid>
          </Grid>
          <Button variant="contained" color="primary" type="submit" className="btn-bg">
            Update Profile
          </Button>
        </form>
      </Paper>
      </div>
      </div>
    </Layout>
  );
};

export default PatientProfilePage;
