import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const GlomerularDisease = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Glomerular Disease
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is Glomerular Disease?
            </Typography>
            <Typography variant="body1">
            Glomerular disease refers to a group of conditions that affect the glomeruli, which
are tiny filters in the kidneys responsible for filtering waste and excess fluids from
the blood. These diseases can disrupt normal kidney function and lead to
problems like proteinuria (excessive protein in urine), hematuria (blood in urine),
and decreased urine output. The causes of glomerular disease can vary and
include autoimmune disorders, infections, genetic factors, and certain
medications. Common symptoms include swelling (edema) in the legs, ankles, or
face, fatigue, high blood pressure, and changes in urine color or frequency.
Diagnosis involves medical tests such as urine analysis, blood tests, and kidney
biopsy to determine the underlying cause and guide treatment.            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of Glomerular Disease
            </Typography>
            <Typography variant="body1">
              Some common symptoms of Glomerular Disease include:
              <ul>
                <li>Foamy urine (a sign of protein in your urine [proteinuria]).</li>
                <li>Pink or light brown urine (a sign of blood in your urine [hematuria]).</li>
                <li>Swelling in your face/around eye (especially in the morning), hands, feet or ankles (especially at the end of the day). This swelling is called edema.</li>
                <li>High blood pressure (hypertension).</li>
                
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Glomerular Disease
            </Typography>
            <Typography variant="body1">
              Glomerular Disease can be caused by various factors, including:
              <ul>
                <li>Infection or a medication or chemical that is harmful to your kidney.</li>
                <li>Diseases that affect the entire body, including the kidneys.
</li>
                <li>Diseases that cause swelling or scarring of the nephron or glomerulus.</li>
               
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
         
            <Typography variant="h4" gutterBottom>
              Risk Factors for Glomerular Disease
            </Typography>
            <Typography variant="body1">
            If Glomerular Disease continues to get worse, it can lead to serious kidney problems, such as: 
              <ul>
                <li>Acute kidney injury </li>
                <li>Chronic kidney disease </li>
                <li>Nephrotic syndrome (which is similar to but not the same as glomerulonephritis)
                </li>
                <li>Kidney infection</li>
                <li>Fluid build-up</li>
              </ul>
            </Typography>
            <hr />
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
            You can make lifestyle changes to keep yourself healthy to reduce your risk of developing diseases that affect your kidneys. These changes include:              <ul>
                <li>Keep your salt intake low; don’t add extra salt to foods.</li>
                <li>Keep your blood pressure well managed. Target is 120/80 mmHg.</li>
                <li>Manage your blood sugar if you have diabetes. Take all your prescribed medications and follow all management goals discussed with your healthcare provider.</li>
                <li>Stop smoking.</li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </Container>
  );
};

export default GlomerularDisease;
