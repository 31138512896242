import React from 'react';
import { Typography, Grid } from '@mui/material';

const AllergyRhinitis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Allergy Rhinitis </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Rhinitis?</strong>
          </Typography>
          <Typography variant="body1">
            Allergic rhinitis, commonly known as hay fever, is an inflammatory condition of the nasal passages
            caused by an allergic reaction to airborne allergens. It is characterized by symptoms such as
            sneezing, nasal congestion, runny nose, itching, and watery eyes. These symptoms typically occur
            seasonally, in response to pollen from trees, grasses, or weeds, but can also be triggered by
            year-round indoor allergens like dust mites, pet dander, or mold spores. The
            immune system overreacts to these allergens, releasing histamines that lead to
            the symptoms. Other factors, such as genetics, exposure to tobacco smoke, and
            pollution, can also contribute to the development of allergic rhinitis
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of Rhinitis</strong>
          </Typography>
          <Typography variant="body1">
            Some common symptoms of rhinitis include:
            <ul className='vw-ul'>
              <li>Nasal congestion</li>
              <li>Runny nose</li>
              <li>Sneezing</li>
              <li>Itchy or watery eyes</li>
              <li>Postnasal drip</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Causes of Rhinitis</strong>
          </Typography>
          <Typography variant="body1">
            Rhinitis can be caused by various factors, including:
            <ul className='vw-ul'>
              <li>Allergens such as pollen, dust mites, or pet dander</li>
              <li>Infections, such as the common cold</li>
              <li>Exposure to irritants like cigarette smoke</li>
              <li>Changes in weather or temperature</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Rhinitis</strong>
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing rhinitis include:
            <ul className='vw-ul'>
              <li>A family history of allergies or rhinitis</li>
              <li>Being exposed to environmental triggers</li>
              <li>Having other allergic conditions, such as asthma or eczema</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fifth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Prevention and Management </strong>
          </Typography>
          <Typography variant="body1">
            While rhinitis cannot always be prevented, some strategies that may help manage the symptoms include:
            <ul className='vw-ul'>
              <li>Avoiding triggers, such as allergens or irritants</li>
              <li>Using nasal saline rinses to keep the nasal passages clear</li>
              <li>Keeping the indoor environment clean and free of dust and mold</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default AllergyRhinitis;
