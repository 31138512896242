import * as type from '../actionTypes/pageActionTypes';

export const pagesAction = (payload) => {
  return {
    type: type.ALL_PAGES_REQUESTING,
    payload
  };
};
export const pagesLoggedInUserPagesAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_PAGES_ID_REQUESTING,
    payload
  };
};
export const pagesCreateAction = (payload) => {
  return {
    type: type.PAGES_CREATE_REQUESTING,
    payload
  };
};
export const pagesGetByAction = (payload) => {
  return {
    type: type.PAGES_GET_BY_ID_REQUESTING,
    payload
  };
};
export const pagesUpdateByAction = (payload) => {
  return {
    type: type.PAGES_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const pagesUnshowByAction = (payload) => {
  return {
    type: type.PAGES_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const pagesDeleteByAction = (payload) => {
  return {
    type: type.PAGES_DELETE_BY_ID_REQUESTING,
    payload
  };
};
