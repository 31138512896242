export const ALL_BLOGS_REQUESTING = 'ALL_BLOGS_REQUESTING';
export const ALL_BLOGS_SUCCESS = 'ALL_BLOGS_SUCCESS';
export const ALL_BLOGS_FAILURE = 'ALL_BLOGS_FAILURE';

export const LOGGED_IN_USER_BLOGS_ID_REQUESTING = 'LOGGED_IN_USER_BLOGS_ID_REQUESTING';
export const LOGGED_IN_USER_BLOGS_ID_SUCCESS = 'LOGGED_IN_USER_BLOGS_ID_SUCCESS';
export const LOGGED_IN_USER_BLOGS_ID_FAILURE = 'LOGGED_IN_USER_BLOGS_ID_FAILURE';

export const BLOGS_CREATE_REQUESTING = 'BLOGS_CREATE_REQUESTING';
export const BLOGS_CREATE_SUCCESS = 'BLOGS_CREATE_SUCCESS';
export const BLOGS_CREATE_FAILURE = 'BLOGS_CREATE_FAILURE';


export const BLOGS_GET_BY_ID_REQUESTING = 'BLOGS_GET_BY_ID_REQUESTING';
export const BLOGS_GET_BY_ID_SUCCESS = 'BLOGS_GET_BY_ID_SUCCESS';
export const BLOGS_GET_BY_ID_FAILURE = 'BLOGS_GET_BY_ID_FAILURE';


export const BLOGS_UPDATE_BY_ID_REQUESTING = 'BLOGS_UPDATE_BY_ID_REQUESTING';
export const BLOGS_UPDATE_BY_ID_SUCCESS = 'BLOGS_UPDATE_BY_ID_SUCCESS';
export const BLOGS_UPDATE_BY_ID_FAILURE = 'BLOGS_UPDATE_BY_ID_FAILURE';


export const BLOGS_UNSHOW_BY_ID_REQUESTING = 'BLOGS_UNSHOW_BY_ID_REQUESTING';
export const BLOGS_UNSHOW_BY_ID_SUCCESS = 'BLOGS_UNSHOW_BY_ID_SUCCESS';
export const BLOGS_UNSHOW_BY_ID_FAILURE = 'BLOGS_UNSHOW_BY_ID_FAILURE';


export const BLOGS_DELETE_BY_ID_REQUESTING = 'BLOGS_DELETE_BY_ID_REQUESTING';
export const BLOGS_DELETE_BY_ID_SUCCESS = 'BLOGS_DELETE_BY_ID_SUCCESS';
export const BLOGS_DELETE_BY_ID_FAILURE = 'BLOGS_DELETE_BY_ID_FAILURE';
