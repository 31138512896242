import React from 'react';
import { Typography, Grid } from '@mui/material';

const Arthritis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Arthritis</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Arthritis? </strong>
        </Typography>
            <Typography variant="body1">
              Arthritis is a term used to describe a group of inflammatory
              conditions that affect the joints, causing pain, stiffness, and
              swelling. The two most common types are osteoarthritis and
              rheumatoid arthritis. Osteoarthritis is caused by wear and tear of
              the joint cartilage over time, while rheumatoid arthritis is an
              autoimmune disorder where the immune system mistakenly attacks the
              joints. Symptoms of arthritis include joint pain, stiffness,
              reduced range of motion, swelling, and tenderness. Other forms of
              arthritis can be caused by infection, injury, or genetic factors.
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Arthritis </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Arthritis include:
              <ul className='vw-ul'>
                <li>Runny nose</li>
                <li>Stuffy nose</li>
                <li>Sneezing</li>
                <li>Loss of smell and taste</li>
                <li>Cough</li>
                <li>Sore throat</li>
                <li>Watery eyes</li>
                <li>Headache</li>
                <li>Fatigue</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Arthritis</strong>
            </Typography>
            <Typography variant="body1">
              Arthritis can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Infection</li>
                <li>Allergy</li>
                <li>Drug induced</li>
                <li>Irritant induced</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Arthritis </strong>
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Arthritis include:
              <ul className='vw-ul'>
                <li>
                  <b>For infectious Arthritis:</b> Young age Working in places like hospitals where chances of
                  infections are more Deficient Immunity.
                </li>
                <li>
                  <b>For Allergic Arthritis:</b> Family history of allergy Presence of other allergic
                  conditions Exposure to smoking and pollution Exposure to pets Obesity.
                </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
        <Typography variant="body1">
         While Arthritis cannot always be prevented, some strategies that may help manage the symptoms include:
         <ul className='vw-ul'>
                <li>Stay away from substances you are allergic to</li>
                <li>Stay away from pets</li>
                <li>Wash hands frequency</li>
                <li>Stay away from ill people</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Arthritis;
