import * as type from '../actionTypes/productActionTypes';

export const productsAction = (payload) => {
  return {
    type: type.ALL_PRODUCTS_REQUESTING,
    payload
  };
};
export const productsLoggedInUserProductsAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_PRODUCTS_ID_REQUESTING,
    payload
  };
};

export const productsCreateAction = (payload) => {
  return {
    type: type.PRODUCTS_CREATE_REQUESTING,
    payload
  };
};

export const productsGetByAction = (payload) => {
  // eslint-disable-next-line no-console, no-undef
  console.log('dddddddddddddd', payload);
  return {
    type: type.PRODUCTS_GET_BY_ID_REQUESTING,
    payload
  };
};
export const productsUpdateByAction = (payload) => {
  return {
    type: type.PRODUCTS_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const productsUnshowByAction = (payload) => {
  return {
    type: type.PRODUCTS_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const productsDeleteByAction = (payload) => {
  return {
    type: type.PRODUCTS_DELETE_BY_ID_REQUESTING,
    payload
  };
};
export const currentMonthProductsVsPreviousMonthAction = (payload) => {
  return {
    type: type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_REQUESTING,
    payload
  };
};
