export const ALL_ORDER_REQUESTING = 'ALL_ORDER_REQUESTING';
export const ALL_ORDER_SUCCESS = 'ALL_ORDER_SUCCESS';
export const ALL_ORDER_FAILURE = 'ALL_ORDER_FAILURE';

export const LOGGED_IN_USER_ORDERS_ID_REQUESTING = 'LOGGED_IN_USER_ORDERS_ID_REQUESTING';
export const LOGGED_IN_USER_ORDERS_ID_SUCCESS = 'LOGGED_IN_USER_ORDERS_ID_SUCCESS';
export const LOGGED_IN_USER_ORDERS_ID_FAILURE = 'LOGGED_IN_USER_ORDERS_ID_FAILURE';

export const ORDER_CREATE_REQUESTING = 'ORDER_CREATE_REQUESTING';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';

export const ORDER_GET_BY_ID_REQUESTING = 'ORDER_GET_BY_ID_REQUESTING';
export const ORDER_GET_BY_ID_SUCCESS = 'ORDER_GET_BY_ID_SUCCESS';
export const ORDER_GET_BY_ID_FAILURE = 'ORDER_GET_BY_ID_FAILURE';

export const ORDER_UPDATE_BY_ID_REQUESTING = 'ORDER_UPDATE_BY_ID_REQUESTING';
export const ORDER_UPDATE_BY_ID_SUCCESS = 'ORDER_UPDATE_BY_ID_SUCCESS';
export const ORDER_UPDATE_BY_ID_FAILURE = 'ORDER_UPDATE_BY_ID_FAILURE';


export const ORDER_UNSHOW_BY_ID_REQUESTING = 'ORDER_UNSHOW_BY_ID_REQUESTING';
export const ORDER_UNSHOW_BY_ID_SUCCESS = 'ORDER_UNSHOW_BY_ID_SUCCESS';
export const ORDER_UNSHOW_BY_ID_FAILURE = 'ORDER_UNSHOW_BY_ID_FAILURE';

export const ORDER_DELETE_BY_ID_REQUESTING = 'ORDER_DELETE_BY_ID_REQUESTING';
export const ORDER_DELETE_BY_ID_SUCCESS = 'ORDER_DELETE_BY_ID_SUCCESS';
export const ORDER_DELETE_BY_ID_FAILURE = 'ORDER_DELETE_BY_ID_FAILURE';

export const ORDER_WITH_SALES_REQUESTING = 'ORDER_WITH_SALES_REQUESTING';
export const ORDER_WITH_SALES_SUCCESS = 'ORDER_WITH_SALES_SUCCESS';
export const ORDER_WITH_SALES_FAILURE = 'ORDER_WITH_SALES_FAILURE';

export const ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING = 'ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING';
export const ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS = 'ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS';
export const ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE = 'ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE';

export const ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING = 'ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING';
export const ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS = 'ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS';
export const ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE = 'ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE';

export const ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_REQUESTING = 'ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_REQUESTING';
export const ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_SUCCESS = 'ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_SUCCESS';
export const ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_FAILURE = 'ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_FAILURE';

export const ORDER_SALES_CHART_API_FOR_PAST_YEAR_REQUESTING = 'ORDER_SALES_CHART_API_FOR_PAST_YEAR_REQUESTING';
export const ORDER_SALES_CHART_API_FOR_PAST_YEAR_SUCCESS = 'ORDER_SALES_CHART_API_FOR_PAST_YEAR_SUCCESS';
export const ORDER_SALES_CHART_API_FOR_PAST_YEAR_FAILURE = 'ORDER_SALES_CHART_API_FOR_PAST_YEAR_FAILURE';

export const ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_REQUESTING = 'ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_REQUESTING';
export const ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_SUCCESS = 'ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_SUCCESS';
export const ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_FAILURE = 'ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_FAILURE';
