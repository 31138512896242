/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const BackPain = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Back Pain </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Back Pain?</strong>
        </Typography>
          <Typography variant="body1">
           <p>Back pain is one of the most common medical problems in India. It can range from a dull, constant ache to a sudden, sharp pain that may shoot down the leg. Sometimes it can come on suddenly - from an accident, a fall, or lifting something heavy, or it can develop slowly because of age-related degenerative changes in the spine. In some cases, inflammatory arthritis disorders or other medical conditions cause back pain.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Symptoms of Back Pain </strong>
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Back Pain include:
            <ul className='vw-ul'>
              <li>Pain when resting or sitting for long periods</li>
              <li>Pain when lifting something heavy or bending down</li>
              <li>Pain radiating from the glutes or hips</li>
              <li>Stiffness after a period of inactivity or when first waking up</li>
              <li>Numbness or weakness</li>
              <li>Pain in the legs or feet as well as in the back</li>
              <li>Unintentional weight loss</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Causes of Back Pain</strong>
          </Typography>
          <Typography variant="body1">
          <p>Back pain can be caused by many different factors, which may all be present at the same time and interact to result in chronic low back pain. These could include mechanical or structural problems with the spine, inflammatory conditions, and other medical conditions. It is also possible that no specific cause can be identified for the start of back pain.</p>
          <p>Back strains and sprains are the most common cause of back pain. You can injure muscles, tendons or ligaments by lifting something too heavy or not lifting safely. Some people strain their back by sneezing, coughing, twisting or bending over.</p>
          <p>The bones in the spine can break during an accident, like a car crash or a fall. Certain conditions (such as spondylolysis or osteoporosis) increase the risk of fractures.
</p>
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Risk Factors for Back Pain </strong>
          </Typography>
          <Typography variant="body1">
            Risk factors for Back Pain include:
            <ul className='vw-ul'>
            <li>Back pain is more common with age, starting around age 30 or 40.</li>
            <li>Lack of exercise. Weak, unused muscles in the back and abdomen might lead to back pain. </li>
            <li>Excess weight. Excess body weight puts extra stress on the back.</li>
            <li>Improper lifting </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Prevention and Management </strong>
          </Typography>
          <Typography variant="body1">
           <p>You can’t prevent lower back pain that results from disease or structural problems in the spine. But you can avoid injuries that cause back pain.</p>
           <p>To reduce your risk of a back injury, you should:</p>
           <ul className='vw-ul'>
           <li>Maintain a healthy weight</li>
           <li>Strengthen your abdominal muscles</li>
           <li>Lift the right way </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default BackPain;
