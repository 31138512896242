import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const FecalIncontinence = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Fecal Incontinence
          </Typography>
        </Grid>
        <Grid item xs={12}>
           <hr />
            <Typography variant="h4" gutterBottom>
              What is  Fecal Incontinence?
            </Typography>
            <Typography variant="body1">
            Fecal incontinence refers to the inability to control bowel movements,
resulting in involuntary leakage of feces. Symptoms can range from
occasional minor leakage to complete loss of bowel control. Causes of fecal
incontinence can include damage to the anal sphincter muscles or nerves due
to childbirth, pelvic surgery, or trauma. Other contributing factors may include
chronic constipation, diarrhea, rectal prolapse, nerve damage from conditions
such as diabetes or multiple sclerosis, and certain medications. Symptoms
may also be associated with age-related muscle weakening or neurological
disorders.             </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of  Fecal Incontinence
            </Typography>
            <Typography variant="body1">
              Some common symptoms of  Fecal Incontinence include:
              <ul>
                <li>Diarrhea.</li>
                <li>Constipation.</li>
                <li>Gas and bloating.</li>
               
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of  Fecal Incontinence
            </Typography>
            <Typography variant="body1">
               Fecal Incontinence can be caused by various factors, including:
              <ul>
                <li>Common causes of fecal incontinence include diarrhea, constipation, and muscle or nerve damage. The muscle or nerve damage may be associated with aging or with giving birth.</li>
                
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for  Fecal Incontinence
            </Typography>
            <Typography variant="body1">
            Anyone can experience fecal incontinence, but certain people are more likely to get it than others. You may be at risk if:
              <ul>
                <li>you’re over the age of 65</li>
                <li>you’re a woman</li>
                <li>you’re a woman who has given birth</li>
                <li>you have chronic constipation</li>
                <li>you have a disease or injury that caused nerve damage</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              While  Fecal Incontinence cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul>
                <li>avoid constipation, for example, by getting more exercise, eating foods that are high in fiber, and consuming plenty of liquids</li>
                <li>seek treatment for diarrhea, for example, by addressing an infection in the digestive system</li>
                <li>avoid straining when defecating, as this can weaken the anal sphincter muscles
</li>
                
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </Container>
  );
};

export default FecalIncontinence;
