/* eslint-disable react/prop-types */
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types'; // Import PropTypes

export default function BillingForm({ onSubmit }) {
    const [formData, setFormData] = React.useState({
      firstName: '',
      lastName: '',
      phone: '',
      emailAddress: '',
      address: '',
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
  
      if (form.checkValidity()) {
        // If the form is valid, call the onSubmit callback.
        onSubmit();
      } else {
        // Mark all form fields as touched to display validation messages.
        form.querySelectorAll('input, textarea').forEach((element) => {
          element.reportValidity();
        });
      }
    };
  
    // Add prop types validation
BillingForm.propTypes = {
    onSubmit: PropTypes.func.isRequired, // Make sure onSubmit is a function and is required
  };

  return (
    <FormControl>
      <React.Fragment>
        <Typography variant='h5' sx={{ textAlign: 'left', fontWeight: 700, marginBottom: '20px' }}>
          Billing Address
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                autoComplete="given-name"
                variant="outlined"
                autoFocus
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                autoComplete="family-name"
                variant="outlined"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="phone"
                name="phone"
                label="Phone No."
                fullWidth
                autoComplete="shipping address-level2"
                variant="outlined"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="emailAddress"
                name="emailAddress"
                label="Email Address"
                fullWidth
                autoComplete="shipping postal-code"
                variant="outlined"
                type="email"
                value={formData.emailAddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="address"
                name="address"
                label="Address"
                fullWidth
                autoComplete="shipping address-line1"
                variant="outlined"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={2}>
                <Typography variant='h5' sx={{ textAlign: 'left', fontWeight: 700 }}>Additional Information</Typography>
                <Typography sx={{ textAlign: 'left' }}>Order notes (Optional)</Typography>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Notes about your order e.g. special notes for delivery"
                  multiline
                  maxRows={4}
                />
              </Stack>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary">
            Place Order
          </Button>
        </Box>
      </React.Fragment>
    </FormControl>
  );
}


