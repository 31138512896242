/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Typography } from '@mui/material';

const Disclaimer = () => {

  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Disclaimer </h2></div>
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Typography variant="body1" style={{ marginTop: '16px' }}>
        <strong>1. General Information:</strong>
        <br /><br />
        Vedanta Wellness is an Ayurvedic clinic that provides traditional Ayurvedic therapies, consultations, and holistic wellness services. We are committed to promoting health and well-being through Ayurvedic principles and practices.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>
        <strong>2. Individual Results:</strong>

        <br /><br />

        Results from Ayurvedic treatments may vary from person to person. The effectiveness of Ayurvedic therapies and recommendations depends on various factors, including individual health conditions, lifestyle, and adherence to the prescribed regimen. Vedanta Wellness does not guarantee specific results or outcomes from our treatments. Our recommendations and therapies are based on Ayurvedic principles and holistic wellness practices.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>3. Consultations:</strong>

        <br /><br />

        Ayurvedic consultations at Vedanta Wellness are intended to provide guidance and personalised wellness recommendations based on Ayurvedic principles. These recommendations may include dietary advice, lifestyle modifications, and herbal remedies. However, it is your responsibility to follow these recommendations.

        <br /><br />

        It is also your responsibility to inform your healthcare provider (if any) about any holistic treatments or therapies you receive at Vedanta Wellness.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>4. Client Responsibilities:</strong>

        <br /><br />

        Clients have a responsibility to provide accurate and complete information about their health history and any ongoing medical treatments. It is essential to follow our practitioners' guidance and recommendations for the best possible results.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>5. Herbal Products:</strong>

        <br /><br />

        We may offer Ayurvedic herbal products as part of our services. Consultation with a qualified Ayurvedic practitioner is recommended before using herbal products, especially if you have known allergies or medical conditions.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>6. Liability:</strong>

        <br /><br />

        Vedanta Wellness, its practitioners, staff, and affiliates are not liable for any injury, loss, or damage resulting from the use of our services or products, including but not limited to consultations, treatments, or herbal remedies.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>7. Privacy:</strong>

        <br /><br />

        We respect our clients' privacy and adhere to all relevant data protection regulations. Personal and health information shared with Vedanta Wellness is kept confidential and used solely for the purpose of providing our services.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '20px' }}>

        <strong>8. Changes to Disclaimer:</strong>

        <br /><br />

        Vedanta Wellness reserves the right to modify or update this disclaimer as needed. Clients are encouraged to review this disclaimer periodically to stay informed of any updates.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        If you have any questions or concerns about this disclaimer or our services, please contact us at: cs@vedanta-wellness.com

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        By using the services of Vedanta Wellness, you acknowledge that you have read, understood, and agreed to this disclaimer.

      </Typography>

    </div>
</ >
  );

};

 

export default Disclaimer;