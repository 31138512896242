import React from 'react';
import '../css/Blogdetailpage.css';
//import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// import { Link } from 'react-router-dom';
import { Typography, Grid } from "@mui/material";

export default function Blogdetailpage() {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">The Ayurvedic Art of Anti - Ageing </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

      <img src="./images/ayurvedic.png" style={{ width: '100%' }} className="ayurvedic" alt="The Ayurvedic Art of Anti - Ageing" />
      
      <Grid container spacing={2}>
        <Grid item xs={12}>

      <div className="form-group">
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
        <br />
        <Typography variant="body1">
            “Head, shoulders, knees, and toes...” That’s how people learned to identify the various parts of the body at a young age.
            The childhood song definitely wouldn’t be as catchy if it named the seven body parts in Ayurveda: “Plasma, bones, muscles,
            fat, nervous system, and reproductive system...” Doesn’t have quite the same ring to it, does it?
            These seven tissues or Dhatus make up our bodies.
         </Typography> 
         <br />
          <Typography variant="h6" gutterBottom>
          <strong>Datu </strong>
          </Typography>
          <Typography variant="body1">
            The dhatus are the seven tissue groups that make up your body. They are plasma, blood, muscles, fat, bone, bone marrow,
            and reproductive system. Ayurveda identifies these areas to break down where disease manifests. You don’t just get sick;
            rather, one area of your body becomes imbalanced, which affects the rest of it. By breaking down
            these tissues, you can pinpoint the location of your illness and treat that specific spot.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
          <strong>Asthi Dhatu </strong>
          </Typography>

          <Typography variant="body1">
            The dhatus are the seven tissue groups that make up your body. They are plasma, blood, muscles, fat, bone, bone marrow,
            and reproductive system. Ayurveda identifies these areas to break down where disease manifests. You don’t just get sick;
            rather, one area of your body becomes imbalanced, which affects the rest of it. By breaking these tissues, you can pinpoint the location of your illness and
            treat that specific spot.
          </Typography>
          <br />
          <Typography variant="body1">
            The asthi dhatu refers to your bone tissue. It is the densest dhatu
            in your body, made up 80 percent earth, 15 percent air, and 5
            percent water. Ashti provides internal support, giving shape to your
            body and face. It protects your organs, including your brain, heart,
            and reproductive system.
          </Typography>
          <br />
          <Typography variant="body1">
            In Ayurveda, ashti is considered cold, dense, hard, stable, and
            rough, similar mostly to the Kapha Dosha with a bit of Vata.
            Psychologically, asthi is the ability to stand up for yourself and
            be firm in your beliefs.
          </Typography>
          <br />
          <Typography variant="body1">
            When asthi is healthy, you have a strong bone structure, teeth,
            nails, and hair. You are confident and assertive. On the other hand,
            when asthi is weak, you may have brittle nails, joint pain, hair
            loss, osteoporosis, arthritis, and scoliosis.
          </Typography>
          <br />
          <Typography variant="body1">
            Increased asthi causes a bone fusion, bony protuberances/spurs,
            calcifications, hunchback, extra teeth, or excess hair growth,
            similar to the Kapha Dosha. Decreased asthi causes spontaneous
            fractures, arthritis, osteoporosis, brittle nails, hair loss,
            shortened height, and scoliosis, which relates to the Vata Dosha.
          </Typography>
          <br />
          <Typography variant="body1">
            Excess asthi is caused by too much Kapha in the system and/or
            overconsumption of calcium. Diminished asthi is caused by excess
            Vata in the system, malnutrition, and/or lack of calcium and
            protein.
          </Typography>
          <br />
        <Typography variant="h6" gutterBottom>
        <strong>The Vata State of Life </strong>
        </Typography>

         <Typography variant="body1">
            The classical Ayurveda texts delineate three stages of life, broadly
            described as young age, middle age, and old age. These can be
            translated as the kapha stage of life (young), the pitta stage of
            life (middle), and the vata stage of life (old age).
            Vata is one of the three doshas, or biological-elemental compounds,
            which works in conjunction with pitta and kapha to maintain the
            functions of the body-mind complex. Vata is a substance-less
            substance composed of space and wind (also called air and ether),
            and governs all the movements of the body. Its qualities are dry,
            light, cold, subtle, and mobile; seasonally, vata rules the fall,
            winter, and cold climates.
          </Typography>
          <br />
          <Typography variant="body1">
            The classical Ayurvedic text known as the Charaka Samhita describes
            the vata phase of life as our final act, starting around age 50 or
            60. As the vata in our constitution increases, we experience a range
            of physical and mental changes, ranging from drier skin to declining
            cognitive abilities. Yet the vata stage can also bring softening and
            wisdom.
          </Typography>
          <br />
          <Typography variant="body1">
            With the increasing vata in your body, you experience symptoms in
            many areas. Examples in the body include poor blood circulation,
            feeling cold all the time, respiratory issues, heart palpitations,
            hearing problems, loss of senses, osteoporosis, and arthritis.
          </Typography>
          <br />
          <Typography variant="body1">
            At Vedanta Wellness, we try to ease your passage into the Vata
            Period of life with the help of our Ortho Care Package. In this
            Package we offer: various oil therapies like Janu Basti, Abhyangam,
            anti-inflammatory treatments like Elakizhi, Podikizhi and
            Manalkhizhi. At Vedanta Wellness, we try to ease your passage into the Vata
            Period of life with the help of our Ortho Care Package. In this
            Package we offer: various oil therapies like Janu Basti, Abhyangam,
            anti-inflammatory treatments like Elakizhi, Podikizhi and
            Manalkhizhi, cleansing treatments like Ksheera Dhara, as well as detoxifying
            therapies like Vasti and Ama Panchan.
          </Typography>
        </Box>

        <br />
        {/* <FontAwesomeIcon icon="fa-solid fa-forward" />        */}
        

        {/* <Link to="/Blogdetailpage1" className="link2n">{'Next >>'}</Link>  */}
        {/* <Box className="blg-from">
          <div className="box-bg">
         

            <textarea
              className="bd-form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="My Comment"
            ></textarea>
          </div>

          <div className="blg-detail-row">
            <input type="text" className="form-control bfrm-lft" placeholder="Name" />
            <input type="text" className="form-control" placeholder="Email" />
          </div>
          <button type="" className="post-btn">
            POST COMMENT
          </button>
        </Box> */}
      
        </div>
        </Grid>
        </Grid>
    </div>
    </>
  );
}