import React from 'react';
import { Typography, Grid} from '@mui/material';

const BiliaryColic = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Biliary Colic </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Biliary Colic ? </strong>
        </Typography>
            <Typography variant="body1">
            Biliary colic refers to the sudden and intense pain caused by the obstruction or
inflammation of the gallbladder or bile ducts. It is typically a result of gallstones, which
are hardened deposits in the gallbladder. When a gallstone obstructs the flow of bile, it
leads to spasms and pain in the upper abdomen, often radiating to the back or right
shoulder. Symptoms include severe abdominal pain, nausea, vomiting, and bloating.
The main cause of biliary colic is the presence of gallstones, although other factors
such as obesity, a high-fat diet, rapid weight loss, and certain medical conditions can
increase the risk. </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Biliary Colic </strong>
        </Typography>
            <Typography variant="body1">
            The key symptom of biliary colic is a sudden, intense stomach pain that lasts for 1 to 5 hours. Pain is caused by increased pressure in the gallbladder. This pain is usually felt in specific places, including:
            <ul className='vw-ul'>
                <li> The middle of the abdomen or belly</li>
                <li> Underneath the ribs on the right-hand side</li>
                <li> The side of the body or the shoulder blade </li>
               
              </ul>
              Unlike more common stomach pain, biliary colic pain does not go away when a person passes wind, is sick, or goes to the toilet.
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Biliary Colic </strong>
        </Typography>
            <Typography variant="body1">
            Biliary colic happens when the gallbladder contracts and causes a gallstone to temporarily block the duct that drains the gallbladder.
              Gallstones are hard masses made up of cholesterol and bilirubin that form in the gallbladder. They can be small and numerous, or large and few. Gallstones form due to chemical imbalances in bile or infrequent or incomplete emptying of the gallbladder.
              Increased pressure in the gallbladder causes the pain and discomfort associated with biliary colic.


            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Biliary Colic </strong>
        </Typography>
            <Typography variant="body1">
            Some people are more at risk of developing gallstones than others and are more likely to experience biliary colic. These groups include:
            <ul className='vw-ul'>
                <li>Women</li>
                <li>People over 40 years old</li>
                <li>People who are obese</li>
                <li>People with a family history of gallstones </li>
                <li>People who have lost weight very quickly </li>
                <li>People who have diabetes or insulin resistance</li>
              </ul>
            </Typography>
       
        </Grid>
      </Grid>
      </div>
      </>
  );
};

export default BiliaryColic;
