/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../css/Forgot.css';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      email: email,
      to: email,
      subject: 'Password reset link',
      text: `Click the link below to reset your password: ${'hhhhhhh'}`,
    };

    fetch('http://localhost:5000/api/users/forget_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Email sending failed.');
        }
        return response.json();
      })
      .then(() => {
        setMessage('Password reset link sent to your email.');
        setError('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setMessage('');
        setError('Please Enter Correct Email Address');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="us-container">
        <h1 className="us-header">Forgot Password</h1>
      </div>
      <Divider />
      <Grid className="form-box frm-txt-vw">
        <form onSubmit={handleSubmit}>
          <label className="form-label">Please Enter Your Email Address</label>
          <input
            type="email"
            className="form-reset"
            id="exampleFormControlInput1"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Grid className="btn-grp-vw">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                className="fg-btn"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Grid>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message-vw">{error}</p>}
        </form>
      </Grid>
    </div>
  );
}
