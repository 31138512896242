/* eslint-disable no-unused-vars */
import React from 'react';
import { Container, Typography, Button, Paper, Grid, Box } from '@mui/material';

const jobPositions = [
  {
    title: 'Frontend Developer',
    description: 'Are you a creative thinker with a strong command of frontend technologies? Join us to build beautiful and user-friendly web applications.',
  },
  {
    title: 'Backend Developer',
    description: 'If you\'re passionate about server-side development and enjoy solving complex problems, come work with us to create robust and scalable backend systems.',
  },
  {
    title: 'Ayurvedic Doctor',
    description: 'We are seeking a skilled Ayurvedic Doctor to provide holistic healthcare solutions to our patients, using the principles of Ayurveda.',
  },
  {
    title: 'Receptionist',
    description: 'We are looking for a friendly and organized receptionist to greet visitors, answer phones, and assist with administrative tasks.',
  },
  {
    title: 'Full Stack Developer',
    description: 'Join our team as a Full Stack Developer and work on end-to-end development projects, from frontend to backend, creating seamless user experiences.',
  },
  {
    title: 'Graphic Designer',
    description: 'Are you a creative graphic designer with a passion for visual storytelling? Help us create stunning visuals and branding materials.',
  },
];

const CareerPage = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Join Our Team
      </Typography>
      <Typography variant="body1" paragraph>
        At [Your Company Name], we are always searching for talented and passionate individuals to join our innovative team. If you have a passion for technology, healthcare, design, or administration, and a desire to make an impact, we want to hear from you!
      </Typography>

      <Grid container spacing={3}>
        {jobPositions.map((job, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Paper elevation={3} className="job-card">
              <Typography variant="h4" component="div">
                {job.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {job.description}
              </Typography>
              <Button variant="contained" color="primary">
                Learn More
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CareerPage;
