import React from 'react';
import { Typography, Grid } from '@mui/material';

const Oligomenorrhea = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Oligomenorrhea </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Oligomenorrhea? </strong>
        </Typography>
            <Typography variant="body1">
            Oligomenorrhea is a medical condition characterized by infrequent or irregular
menstrual periods. Women with oligomenorrhea may have menstrual cycles
longer than 35 days, or experience fewer than nine menstrual periods in a year.
The condition can be caused by various factors, including hormonal imbalances,
such as polycystic ovary syndrome (PCOS), thyroid disorders, excessive
exercise, extreme weight loss or gain, stress, certain medications, or
reproductive disorders. Symptoms can vary, but may include unpredictable
menstrual cycles, light or scanty periods, or missed periods. Oligomenorrhea can
affect fertility and may indicate an underlying health issue that requires medical
evaluation. </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Oligomenorrhea </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Oligomenorrhea include:
              <ul className='vw-ul'>
                <li>Acne.</li>
                <li>Headaches.</li>
                <li>Hot flashes.</li>
                <li>Abdominal pain.</li>
                <li>Vaginal discharge.</li>
                <li>Excess hair growth on your face and body.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Oligomenorrhea </strong>
        </Typography>
            <Typography variant="body1">
              Oligomenorrhea can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Most often, this condition is a side effect of hormonal birth control. Some women experience lighter and lighter periods for three to six months after they begin taking the birth control. Sometimes, their periods stop completely.</li>
                <li>Young women who participate in sports or engage in heavy exercise can develop this condition.
</li>
                <li>Eating disorders, such as anorexia nervosa and bulimia, can also cause this condition.</li>
                <li>Oligomenorrhea is common in adolescent girls and perimenopausal women due to fluctuating hormone levels.
                </li>
                <li>Oligomenorrhea can also occur in women who have diabetes or thyroid problems.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Oligomenorrhea </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Oligomenorrhea include:
              <ul className='vw-ul'>
                <li><b>Infertility:</b>oligomenorrhea can lead to infertility </li>
                <li><b>Endometrial Hyperplasia: </b>Oligomenorrhea that goes untreated for years can lead to endometrium proliferation and thus resulting in endometrial hyperplasia. </li>
                <li><b>Endometrial Cancer:</b> Endometrial hyperplasia is a precursor for endometrial cancer. Oral contraceptive pills that contain estrogen and progesterone are protective against endometrial cancer. </li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Oligomenorrhea cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
               <li><b>Lifestyle changes:</b> You may need to make adjustments to your diet and physical activity if nutritional deficiencies or strenuous activities are causing your issues. </li>
                
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Oligomenorrhea;
