/* eslint-disable no-undef */
// actions/contactActions.js
import * as type from "../types";

export const submitAppointmentRequest = function submitAppointmentRequest(payload) {
  console.log("Hello from actions.....");
  return{
   type:type.CREATE_APPOINTMENT_REQUESTING,
   payload,
};
};

export const submitAppointmentSuccess = () => ({
  type: type.CREATE_APPOINTMENT_SUCCESS,
});

export const submitAppointmentFailure = (error) => ({
  type: type.CREATE_APPOINTMENT_FAILURE,
  payload: error,
});
