import React from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useState } from 'react';
import AppointmentPopup from './AppointmentPopup';
import { Link } from 'react-router-dom';


export default function AboutHome() {
  const [isAppointmentPopupOpen, setAppointmentPopupOpen] = useState(false);

  const openAppointmentPopup = () => {
    setAppointmentPopupOpen(true);
  };

  const closeAppointmentPopup = () => {
    setAppointmentPopupOpen(false);
  };

  const handleAppointmentSubmit = (appointmentData) => {
    // Handle the submission of the appointment data here
    return appointmentData;
  };


  return (
    <Grid
      container
      spacing={2}
      item
      xs={12}
      md={12}
      sx={{
        /* backgroundImage:"url('../images/about-bg.jpg')", */
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <Grid className="home-about-img"
        container
        item
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        md={6}
      >
        <Box sx={{ padding: '10px 10px 50px 10px' }}>
          <Grid
            container
            sx={{ height: '500px', width: '664px', pl: '50px', pt: '30px' }}
          >
            <img alt="" src="../images/vedanta-wellness-2.png" />
          </Grid>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box sx={{ padding: '40px 50px 10px 50px', textAlign: 'left' }}>
          <Grid container>
            <Typography className='home-ab-hdg' variant='span'> About </Typography>
            <Typography className='home-abt-hdg' variant='span'
              sx={{
                color: '#0B8140',
                fontWeight: 300,
                ml: '10px',
              }}
            >
              {' '}
              Vedanta Wellness
            </Typography>
          </Grid>
          <Grid container item>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
              Welcome to Vedanta Wellness, our award winning Ayurvedic Clinic, where we blend traditional wisdom with modern healthcare practices to provide unparalleled holistic wellness solutions. Our Ayurvedic Healthcare Center has the best Ayurveda doctors in Delhi. We offer comprehensive treatments for various ailments, emphasizing personalized care and tailored solutions.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
              From Ayurvedic massages that rejuvenate the mind and body to specialized treatments for a wide array of diseases, we are committed to promoting overall health and well-being. Our Ayurveda Health Center also extends the same excellence in Ayurvedic healthcare, ensuring that our patients receive the highest standard of care. Experience the transformative power of Ayurveda with our state-of-the-art facilities and expert guidance, fostering a journey towards optimal health and vitality. 
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
              Want a new experience at an Ayurvedic Massage Centre in Delhi? Looking for an Ayurvedic treatment for diseases? Want to learn more about Ayurveda and Health? Book a free appointment with our experts today!
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box sx={{ mt: 5 }}>
              <Button
                variant="outlined"
                sx={{
                  '&:hover': {
                    border: '1px solid #F79320',
                    color: '#ffffff',
                    backgroundColor: '#F79320',
                  },
                  color: '#0b8140',
                  border: '1px solid #0b8140',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  textTransform: 'capitalize',
                }}
                onClick={openAppointmentPopup}
              >
                Book an Appointment
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid spacing={1} item xs={12} md={12}>
        <Grid className="about-vw abt-mui-btn">
          <div className="us-container">
            <h2>The Best Wellness Center in Delhi and NCR</h2>
            <p>
            Our unwavering commitment to the ancient science of Ayurveda, combined with a modern, client-centric approach, sets us apart. With a team of highly skilled Ayurvedic practitioners, a tranquil and rejuvenating environment, and a wide range of personalised therapies, we are dedicated to transforming lives. Experience profound healing, lasting vitality, and inner harmony as you embark on your wellness journey with us. Your well-being is our utmost priority.
            </p>
            <Button
              variant="outlined"
              onClick={openAppointmentPopup}// Use the common function
            >
              Book an Appointment
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid spacing={1} item xs={12} md={12}>
        <Grid className="ayurvedic-vw">
          <div className="us-container">
            <h2>Ayurvedic Therapies</h2>
            <p>
              There are many treatments under Ayurveda for managing the
              locomotive diseases. Major treatments are mentioned here.
            </p>

            <Grid spacing={3} item xs={12} md={12} sx={{ display: 'flex' }}>
              <Grid className="ayurvedic-bx">
                <h2>Abhyangam (Ayurvedic Massage)</h2>
                <p>
                Experience rejuvenation and relaxation through the ancient art of Abhyangam, an Ayurvedic massage that harmonizes your body and mind, promoting vitality and balance.
                </p>
                <Button variant="outlined" className="at-btn">
        <Link  to="/Abhyangam" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
      </Button>

              </Grid>

              <Grid className="ayurvedic-bx">
                <h2>Swedanam (Steaming)</h2>
                <p>
                Indulge in Swedanam, a therapeutic steaming treatment that purifies and detoxifies your body, relieving muscle tension and improving circulation for enhanced well-being.
                </p>
                <Button variant="outlined" className="at-btn">
        <Link  to="/Swedanam" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
      </Button>
              </Grid>

              <Grid className="ayurvedic-bx">
                <h2>Sirodhara / Kashaya Dhara</h2>
                <p>
                Discover tranquility with Sirodhara or Kashaya Dhara, where a continuous stream of herbal oils or decoctions gently flows over your forehead, soothing your senses and promoting mental clarity.
                </p>
                <Button variant="outlined" className="at-btn">
        <Link  to="/Sirodhara" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
      </Button>
              </Grid>
            </Grid>

            <Grid spacing={3} item xs={12} md={12} sx={{ display: 'flex' }}>
              <Grid className="ayurvedic-bx">
                <h2>Thakradhara</h2>
                <p>
                Achieve a state of serenity with Thakradhara, an Ayurvedic therapy involving a continuous stream of medicated buttermilk on the forehead, known to alleviate stress and promote relaxation.
                </p>
                <Button variant="outlined" className="at-btn">
                <Link to="/Thakradhara" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
                </Button>
              </Grid>

              <Grid className="ayurvedic-bx">
                <h2>Lepanam </h2>
                <p>
                Experience natural healing with Lepanam, a therapeutic herbal paste application that addresses specific ailments, promoting skin health and overall wellness.
                </p>
                <Button variant="outlined" className="at-btn">
        <Link  to="/Lepanam" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
      </Button>
              </Grid>

              <Grid className="ayurvedic-bx">
                <h2>Bundle Massages (Kizhi) </h2>
                <p>
                 Rejuvenate your body and revitalize your energy with Bundle Massages, also known as Kizhi, a traditional Ayurvedic therapy using heated herbal poultices to relieve muscle tension and improve circulation.
                </p>
                <Button variant="outlined" className="at-btn">
        <Link to="/BundleMassage" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>Know More</Link>
      </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid spacing={1} item xs={12} md={12}>
        <Grid className="about-vws abt-mui-btn">
          <div className="us-container">
            <h4 className="abt-mui-btn">How It Works</h4>
            <h2 className="abt-mui-btn">
             Easy step to consult with Ayurvedic Doctor.
            </h2>
            <p className="abt-just-txt abt-jtxt">
            1. Navigate to our official website, Vedanta Wellness, to access our online booking platform.</p>
            <p className="abt-just-txt">
            2. Look for the Book Appointment or Consult with an Ayurvedic Doctor option on our website&ldquo;s homepage or in the menu. </p>
            <p className="abt-just-txt">
            3. Pick a convenient date and time slot for your consultation from the available options provided in the booking system.</p>
            <p className="abt-just-txt">
            4. Fill in your personal information, including your name, contact details, and any relevant medical history, to complete the booking process. You may also leave specific comments or questions you&ldquo;d like to address during your consultation.
            </p>
            <p className="abt-just-txt abt-jtxtt">
            Alternatively, dial our clinic&ldquo;s phone number, which is listed on our website and social media.
            When connected, speak to our friendly receptionist, who will assist you in scheduling an appointment. Share your name, contact details, and any preferences regarding the doctor and appointment date/time. Make note of your appointment date and time, and ensure you have any relevant documents or questions ready for your consultation.
            </p>
            <Button
              variant="outlined"
              onClick={openAppointmentPopup} // Use the common function
            >
              Book an Appointment
            </Button>
          </div>
        </Grid>
      </Grid>
      <AppointmentPopup

        open={isAppointmentPopupOpen}

        onClose={() => setAppointmentPopupOpen(false)}

        closeAppointmentPopup={closeAppointmentPopup}

        onSubmit={handleAppointmentSubmit}

      />
    </Grid>
  );
}
