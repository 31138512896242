import * as type from '../actionTypes/mediaActionTypes';

export const mediaAction = (payload) => {
  return {
    type: type.ALL_MEDIA_REQUESTING,
    payload
  };
};
export const mediaLoggedInUserMediaAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_MEDIA_ID_REQUESTING,
    payload
  };
};
export const mediaCreateAction = (payload) => {
  return {
    type: type.MEDIA_CREATE_REQUESTING,
    payload
  };
};
export const mediaGetByAction = (payload) => {
  return {
    type: type.MEDIA_GET_BY_ID_REQUESTING,
    payload
  };
};
export const mediaUpdateByAction = (payload) => {
  return {
    type: type.MEDIA_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const mediaUnshowByAction = (payload) => {
  return {
    type: type.MEDIA_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const mediaDeleteByAction = (payload) => {
  return {
    type: type.MEDIA_DELETE_BY_ID_REQUESTING,
    payload
  };
};
