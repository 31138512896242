import React from 'react';
import { Typography, Grid } from '@mui/material';

const Scabies = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Scabies </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Scabies? </strong>
        </Typography>
            <Typography variant="body1">
              Scabies is a highly contagious skin infestation caused by the
              Sarcoptes scabiei mite. It is characterized by intense itching and
              a distinctive rash. The mites burrow into the skin, laying eggs
              and causing an inflammatory reaction. Scabies can be transmitted
              through close personal contact or by sharing contaminated items
              such as bedding or clothing. Symptoms of scabies include itching,
              especially at night, a rash consisting of small red bumps or
              blisters, and the presence of burrows (thin, gray, or brown lines)
              on the skin. The most commonly affected areas are the hands,
              wrists, elbows, armpits, genitals, and buttocks.{' '}
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Symptoms of Scabies </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Scabies include:
              <ul className='vw-ul'>
                <li> Intense itching, which is worse at night and could make it hard to sleep. </li>
                <li> Bumps that sometimes become infected as a result of scratching.</li>
                <li> A later rash that looks grayish or skin-colored lines on the skin. </li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Causes of Scabies </strong>
        </Typography>
            <Typography variant="body1">
              Scabies can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>
                  Intense itching, which is worse at night and could make it
                  hard to sleep.
                </li>
                <li>
                  Bumps that sometimes become infected as a result of
                  scratching.
                </li>
                <li>
                  A later rash that looks grayish or skin-colored lines on the
                  skin.
                </li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Risk Factors for Scabies </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Scabies include:
              <ul className='vw-ul'>
                <li>Scabies spreads through direct, prolonged skin-to-skin
                  contact with a person who has mites.
                </li>
                <li>
                  An infected person can easily pass scabies to his/her
                  household or sexual partners. Scabies in adults is usually
                  sexually acquired.
                </li>
                <li>
                  The likelihood of scabies increases easily under crowded
                  conditions, which involve close body and skin contact. Nursing
                  homes, prisons, and several types of care facilities are sites
                  of scabies outbreaks
                </li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Scabies cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li> Washing bed linens, towels and clothing in hot water and machine dry.</li>
                <li> Making sure family members and others in close contact with the infected person get checked for scabies. </li>
                <li> Limiting close contact with others if you know you’ve got scabies.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Scabies;
