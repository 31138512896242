/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { Box, Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import OrderSummary from '../Components/Common/OrderSummary';
import BillingForm from '../Components/Common/BillingForm';

export default function Checkout() {
  const [billingFormCompleted, setBillingFormCompleted] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleBillingFormSubmit = () => {
    // Add any additional logic here (e.g., sending data to the server).
    // For now, we'll just mark the form as completed.
    setBillingFormCompleted(true);
  };

  return (
    <>
      <div className="inner-container" style={{ marginBottom: '20px' }}>
        <h2 className="dnt-heading">Checkout</h2>
      </div>

      <Container className="chk-txt" sx={{ maxWidth: 'md', marginTop: '20px', padding: '10px' }}>
        <Box sx={{
          backgroundColor: '#f0f0f0',
        }}>
          <Box sx={{ padding: '20px', marginTop: '10px', borderRadius: '10px' }}>
            <Box sx={{ padding: '15px', backgroundColor: '#ffffff', marginTop: '15px', borderRadius: '10px' }}>
              <Grid container xs={12} sx={{ height: '50px' }}>
                <div className="inner-container">
                  <h2 className="dnt-heading dnt-m" xs={{ marginBottom: '20px', borderBottom: '1px solid #dddddd' }}>Checkout</h2>
                </div>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Item>
                    <Typography variant="span" sx={{ fontWeight: 'bold' }}>
                      Have a coupon?
                      <Link>
                        CLICK HERE TO ENTER CODE
                      </Link>
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
              <Grid container sx={{ margin: '20px 50px 50px 0px' }}>
                <Grid xs={12} md={8}>
                  <BillingForm onSubmit={handleBillingFormSubmit} />
                </Grid>
                <Grid md={4} xs={12}>
                  <Box sx={{ padding: '20px' }}>
                    <OrderSummary billingFormCompleted={billingFormCompleted} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
