/* eslint-disable react/prop-types */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
export default function MediaCard({ imageUrl, cardTitle, cardDesc, nextPageLink }) {
  const history = useNavigate();
  const handleClick = () => {
    history(nextPageLink);
  };
  return (
    <Card sx={{ maxWidth: 340, border: '2px solid #d6d0d0', boxShadow: 'none', textAlign: '-webkit-center', padding: '10px' }} key={cardTitle}>
      <CardMedia
        sx={{ height: '250px', width: '250px', borderRadius: '10%' }}
        image={imageUrl}
        title={cardTitle}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {cardTitle}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ justifyContent: 'center' }}
      >
        <Button variant="contained" sx={{ backgroundColor: '#0B8140', color: 'white', width: '250px', borderRadius: '25px', fontWeight: 700 }} size="small" onClick={handleClick}>View All Ayurvedic Therapy Packages</Button>
      </CardActions>
    </Card>
  );
}