/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Abhyangam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Abhyangam </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong> What is Abhyangam? </strong>
          </Typography>
          <Typography variant="body1">
            <p>
              Abhyangam, otherwise known as ayurvedic massage, is a broad term
              that means any kind of ayurvedic massage, of which there are a few
              different types including full-body massage, head massage (shiro
              abhyangam), or foot massage (pad abhyangam).
            </p>

            <p>
              Abhyanga is considered such a significantly beneficial therapy,
              that it's recommended daily - especially for the elderly, those
              with more air & ether in their constitution (vata dosha) and for
              those experiencing stress, anxiety & insomnia to name a few.
            </p>

            <p>
              Excellent for pain relief and healing, abhyanga is usually
              characterised by a specific sequence of strokes including long,
              sweeping movements using copious amounts of warm, herbal oils
              suitable for the individual. Different types of abhyanga can use
              herbal powders, herbal pastes or ghee.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Abhyangam </strong>
          </Typography>
          <Typography variant="body1">
            
              <p>The act of massaging the body melts away tension and stress from
              the muscles, as well as reduces aches & pains and balances what we
              call vata dosha in ayurveda, which primarily is responsible for
              balancing out nervous system and governs all movement in the body
              including elimination. Massaging the body with medicated or herbal
              oils specific for your natural constitution (dosha) or current
              condition (vikriti, or imbalance), further enhances the benefits
              of the massage. Further benefits of Ayurvedic massage include:</p>

              <ul className='vw-ul'>
              <li>Offers relief from stress, tension & low energy</li>
              <li>Supports musculoskeletal and nervous system health</li>
              <li>Tones the muscles & body's tissues </li>
              <li>Enhances circulation</li>
              <li>Improves sleep patterns</li>
              <li>Stimulates organ function</li>
              <li>Helps your body detox</li>
              <li>Promotes softer, stronger & more supple skin</li>
              <li>Increases radiance and lustre of the skin, for graceful aging & growing</li>
              <li>Prevents injury & muscle strain </li>
              <li>Excellent for nervous system conditions, anxiety & overwork</li>
              <li>Ideal to receive at the change of the seasons</li>
              <li>Prepares the body for panchakarma, by mobilising the doshas and moving ama (toxins) towards the digestive tract in order to be eliminated </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Treatments of Abhyangam </strong>
          </Typography>
          <Typography variant="body1">
           <p> In ayurveda, abhyanga comes under the type of therapy treatment called snehna, which can be translated to mean that which oils and also love. It might sound a little obscure at first - until maybe you’ve received an abhyanga or given one to yourself! </p>
            <p>There is an inherent connection between anointing the body with oil and enveloping it in love. The experience of receiving an abhyanga can offer a deep feeling of stability, warmth and comfort - love. Regular abhyanga is a way to keep yourself in optimal health and balance, via the skin, which ayurveda recognises as a major organ and vehicle to reach our internal organs. The skin is both a protective shield and a gateway to nourishing our bodies' more subtle channels through our pores.</p>
            <p>The ancient ayurvedic texts do not give specific details of the procedures involved in abhyanga, but only mention certain techniques such as gentle rubbing, pressing and squeezing, and utilising pressure. If you’ve received abhyanga before at different locations, this can explain why there are slight variations of traditional abhyanga techniques.</p>
            <p>The ancient ayurvedic texts do not give specific details of the procedures involved in abhyanga, but only mention certain techniques such as gentle rubbing, pressing and squeezing, and utilising pressure. If you’ve received abhyanga before at different locations, this can explain why there are slight variations of traditional abhyanga techniques.</p>
            <p>Beginning with a head and neck massage, then coming to lay down, your therapist will use a combination of strokes including cupping, kneading, rubbing and then massage with oil, each section of the body at a time until the entire back and front body is treated.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> How to Practise Abhyanga at Home </strong>
          </Typography>
          <Typography variant="body1">
          <p>Whilst we would all love to receive abhyanga massage from a practitioner or therapist all the time, the second-best thing is to give yourself an ayurvedic massage - often called ‘self-abhyanga’. </p>
          <p>As a daily practice, abhyanga is fantastic for general fatigue, stiffness in the joints and muscles, as preparation for the activities for the day, or in the evening it can be practised as post-workout recovery for the muscles and joints. </p>
          <p>Using an oil specifically catered to your dominant doshas or prakriti, can be a very good idea to bring you into balance — for general, all year, all seasons, all ages and dosha types, the best oil to use is organic, cold-pressed sesame oil.</p>
          <p>For Vata, you can also use warming oils such as almond oil. For Pitta, go for more cooling oils such as coconut or sunflower oil and for Kapha, use sesame oil in smaller quantities, or mix with mustard oil. If you don’t know your dosha (Vata, Pitta or Kapha) or are unsure - use sesame oil. </p>
          <p><b>Self-Abhyanga Instructions</b></p>
          <p>The most important thing is to warm your oil in order to offset the cold and dry properties of Vata, as well as help to relax the body and make the oil better able to penetrate the skin.</p>
          <p>Truly, there’s actually no right or wrong about how to practise self-massage, however there are a few key strokes that will help to stimulate circulation, digestion and to protect the joints.. The main thing is that the massage is done with presence and doesn’t become a superficial oil application - really feel each part of your body. </p>
          <p>You will need between 1⁄4 - 1⁄2 a cup and you can heat it in a small oil-burner with a candle lit underneath, or on the stove in a small pot. For practicing abhyanga whilst travelling or on-the-move, it also works well to use a small glass pump bottle containing the oil, warmed by submerging in a mug of boiling water.</p>
          <p>As a rule of thumb - massage the limbs with long strokes and the joints with gentler, round strokes. It’s ideal if you can relax in a warm place for 10 - 15 minutes after your massage before taking a warm shower, however it is not necessary and it is better to do the massage and hop straight in the shower or bath than to not do it! Once you are familiar with the sequence, your self-abhyanga can take anywhere from 5 - 15 minutes, depending on the time that you have. Start from your feet and work your way upwards, avoiding the neck and face (there are better oils for these areas).</p>
          </Typography>
        </Grid>
      
      </Grid>
    </div>
    </>
  );
};

export default Abhyangam;
