import React from 'react';
import { Typography, Grid } from '@mui/material';

const Influenza = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Influenza </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Influenza? </strong>
        </Typography>
            <Typography variant="body1">
            Influenza, commonly known as the flu, is a highly contagious respiratory
illness caused by influenza viruses. It typically occurs in seasonal outbreaks,
with symptoms ranging from mild to severe. Common symptoms of influenza
include sudden onset of fever, cough, sore throat, runny or stuffy nose,
muscle or body aches, fatigue, and headaches. In some cases, it can lead to
more severe complications, especially in high-risk individuals such as the
elderly, young children, pregnant women, and those with weakened immune
systems. Influenza is primarily transmitted through respiratory droplets and
can be prevented through annual vaccination, practicing good hygiene, and
avoiding close contact with infected individuals.             </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Influenza </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Influenza include:
              <ul className='vw-ul'>
                <li>Fever, Chills, Body aches</li>
                <li>Cough, Headache, Sore throat.</li>
                <li>Runny or stuffy nose</li>
                <li>Tiredness or feeling run down.</li>
                <li>Diarrhea or vomiting (usually only in kids). </li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Influenza </strong>
        </Typography>
            <Typography variant="body1">
            The influenza virus spreads from direct or indirect contact with someone else who’s infected. Common ways to get the flu include:
            <ul className='vw-ul'>
                <li>From someone nearby coughing, sneezing or talking. Droplets can either get onto your hands or move through the air to get into your nose or mouth. The flu then moves into your lungs.</li>
                <li>By touching a surface that’s contaminated by the flu virus, then touching your face, nose, mouth or eyes. This includes things like door knobs, desks, computers and phones.</li>
                <li>EBy touching the hands or face of someone who has the flu, then touching your face, nose, mouth or eyes. </li>
                
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Influenza </strong>
        </Typography>
            <Typography variant="body1">
            Influenza is a very serious illness for anyone at high risk. Certain diseases that place people at high risk include:
            <ul className='vw-ul'>
                <li>Chronic lung diseases such as asthma, COPD, bronchiectasis, or cystic fibrosis</li>
                <li>Heart disease</li>
                <li>Chronic kidney disease </li>
                <li>Diabetes or other chronic metabolic disorder </li>
                <li>Morbid obesity</li>
                <li>Severe anemia </li>
                <li>Liver disorders</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              The best way to prevent the flu is to get the flu vaccine every year. Vaccines train your immune system to recognize infections and fight them off before you get sick. The influenza virus can change (mutate) a little bit every year, which is why you need to get vaccinated every year.
              Other ways to reduce your risk of getting the flu include:
              <ul className='vw-ul'>
                <li>Wash your hands often with soap and water. If you aren’t able to use soap and water, use an alcohol-based hand sanitizer. </li>
                <li>Cover your nose and mouth when you sneeze or cough. Cough or sneeze into your elbow or a tissue rather than your bare hand. </li>
                <li>Avoid being around other people when you or they are sick with the flu or other infectious diseases.</li>
                <li>Consider wearing a mask if you’re sick and can’t avoid being around others.</li>
                <li>Don’t share food or eating utensils (forks, spoons, cups) with others.</li>
                <li>Avoid touching your face, eyes, nose and mouth.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Influenza;
