/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
// import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import '../css/Aboutuspage.css';

function Aboutuspage() {
  return (
    <>
    <div className="inner-container inner-margin"><h2 className="dnt-heading">About Us </h2></div>
    <div className="us-container">
      <Box className="aboutus-page">
        <img src="./images/vedanta-wellness.png" className="about-img" alt="" />
        <Grid className="para-1">
        <Typography className="abt-txtt">
        Welcome to Vedanta Wellness, our traditional Ayurveda Clinic in Noida, where ancient wisdom meets modern wellness. We are dedicated to providing you with a transformative healing experience that blends centuries old Ayurvedic traditions with the utmost in comfort and luxury.
        </Typography>
        <br />
        <Typography className="abt-txtt">
        At our Ayurveda Center in Delhi NCR, we are guided by a deep-rooted passion for Ayurveda, the world's oldest holistic healing system. Our journey began with a profound belief in the power of Ayurveda to harmonise the body, mind, and spirit. We've embarked on a mission to preserve and share the timeless knowledge of this ancient science with the world.
        </Typography>
        <br />
        <Typography className="abt-txtt">
        Vedanta Wellness was awarded the best Ayurvedic Center in Delhi. If you are looking for some wellness or relaxation, do consider our Ayurvedic Massage Centre in Noida. 
        </Typography>
        <br />
        <Typography className="abt-txtt">
        "स्वस्थस्य स्वास्थय रक्षण, आतरुस्य विकार प्रशमन" <br/>
        - Charaka Samhita, Sutra 30/26, <br/>
        which means that Ayurveda aims to maintain the health of a healthy person and manage or cure diseases that manifest in both the mind and body.
        </Typography>
        <br />
        <Typography className="abt-txtt">
        Our dedicated team of Ayurvedic experts and therapists are here to help you embark on your journey to optimal health and well-being. With decades of experience and a commitment to providing personalised care, they are ready to guide you towards balance and harmony.
        </Typography>
        <br />
          <Typography className="abt-txtt">
          We believe in the individuality of each person's wellness journey. Through a comprehensive assessment of your unique constitution (Prakriti) and current imbalances (Vikriti), we tailor our treatments and therapies to your specific needs. Our goal is to restore your natural state of health and vitality.
          </Typography>
          <br />
          <Typography className="abt-txtt">
          Nestled in a serene and tranquil environment, our clinic is designed to provide you with a haven of relaxation and rejuvenation. Every detail, from the soothing decor to the expertly designed treatment rooms, has been carefully crafted to enhance your healing experience.
          </Typography>

          <br />
          <Typography className="abt-txtt">
          We are committed to your well-being and the principles of Ayurveda. Our treatments use only the finest organic ingredients and are administered with the utmost care and attention to detail. We strive to create a sanctuary where you can escape the stresses of modern life and reconnect with your inner self.
          </Typography>
          <br />
          <Typography className="abt-txtt">
          Whether you seek relief from a specific ailment, desire a rejuvenating experience, or wish to explore the profound wisdom of Ayurveda, our clinic is here to serve you. Join us on a path to holistic health, balance, and vitality, as you embark on a journey that transcends time and transcends modern luxuries.
          </Typography>

          <br />
          <Typography className="abt-txtt">
          Hence, if you’re looking for an Ayurvedic Treatment in Noida for a health issue or on a journey of wellness, please consider heading to Vedanta Wellness. Feel free to reach out to us for any inquiries or to schedule your visit. We look forward to being a part of your wellness journey.
          </Typography>
          <br />
          <Typography className="abt-txtt">
          Welcome to the world of Ayurved at its finest.
          </Typography>

        </Grid>
      </Box>
    </div>
    </>
  );
}

export default Aboutuspage;
