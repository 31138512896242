import * as type from '../actionTypes/customerActionTypes';

export const customersAction = (payload) => {
  return {
    type: type.ALL_CUSTOMER_REQUESTING,
    payload
  };
};
export const customersLoggedInUserCustomersAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_CUSTOMERS_ID_REQUESTING,
    payload
  };
};
export const customersCreateAction = (payload) => {
  return {
    type: type.CUSTOMER_CREATE_REQUESTING,
    payload
  };
};
export const customersGetByAction = (payload) => {
  return {
    type: type.CUSTOMER_GET_BY_ID_REQUESTING,
    payload
  };
};
export const customersUpdateByAction = (payload) => {
  return {
    type: type.CUSTOMER_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const customersUnshowByAction = (payload) => {
  return {
    type: type.CUSTOMER_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const customersDeleteByAction = (payload) => {
  return {
    type: type.CUSTOMER_DELETE_BY_ID_REQUESTING,
    payload
  };
};
