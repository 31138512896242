/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import appointmentApi from "../../apis/appointmentApi";
import { connect } from 'react-redux';
import {
  submitAppointmentRequest,
  submitAppointmentSuccess,
  submitAppointmentFailure,
} from '../../redux/actions/appointmentActions';
import moment from "moment";



const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
    "& .MuiDialog-paper": {
      position: "relative",
    },
  },
  confirmationModal: {
    "& .MuiDialog-paper": {
      backgroundColor: "#f5f5f5",
      padding: "24px",
      borderRadius: "8px",
    },
  },
  confirmationTitle: {
    textAlign: "center",
  },
  confirmationButton: {
    margin: "0 auto",
    display: "block",
  },
}));

const AppointmentPopup = ({ open, onClose,
  submitAppointmentRequest,
  submitAppointmentSuccess,
  submitAppointmentFailure, }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contact);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [contactNo, setContactNo] = useState("");
  const [treatment, setTreatment] = useState("");
  const [message, setMessage] = useState("");
  const [bookingStatus, setBookingStatus] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [treatmentError, setTreatmentError] = useState("");
  const [messageError, setMessageError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false); // New state for form validity
  const appointmentData = {
    name,
    email,
    date,
    time,
    contactNo,
    treatment,
    message,
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleDateChange = (selectedDate) => {
    const currentDate = new Date(); // Get the current date
    if (selectedDate >= currentDate) {
      // Check if the selected date is today or in the future
      setDate(selectedDate);
    } else {
      // Handle invalid date selection
      // You can display an error message or perform some other action here
    }
  };


  const handleTimeChange = (newTime) => {
    setTime(newTime);
    validateTime(newTime);
  };

  const handleTreatmentChange = (event) => {
    setTreatment(event.target.value);
  };

  const handleContactNoChange = (event) => {
    setContactNo(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Name is required");
      setIsFormValid(false);
    } else {
      setNameError("");
      setIsFormValid(true);
    }
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Email is required");
      setIsFormValid(false);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  const validateDate = () => {
    if (!date) {
      setDateError("Date is required");
      setIsFormValid(false);
    } else {
      setDateError("");
      setIsFormValid(true);
    }
  };

  const validateTime = (selectedTime) => {
    if (!selectedTime) {
      setTimeError("Time is required");
      setIsFormValid(false);
    } else {
      setTimeError("");
      setIsFormValid(true);
    }
  };

  const validateContactNo = () => {
    if (contactNo.trim() === "") {
      setContactNoError("Contact number is required");
      setIsFormValid(false);
    } else if (!/^\d{10}$/.test(contactNo)) {
      setContactNoError("Invalid contact number format");
      setIsFormValid(false);
    } else {
      setContactNoError("");
      setIsFormValid(true);
    }
  };

  const validateTreatment = () => {
    if (treatment.trim() === "") {
      setTreatmentError("Treatment is required");
      setIsFormValid(false);
    } else {
      setTreatmentError("");
      setIsFormValid(true);
    }
  };

  const validateMessage = () => {
    if (message.trim() === "") {
      setMessageError("Message is required");
      setIsFormValid(false);
    } else {
      setMessageError("");
      setIsFormValid(true);
    }
  };

  const handleSubmit = () => {
    validateName();
    validateEmail();
    validateDate();
    validateTime(time);
    validateContactNo();
    validateTreatment();
    validateMessage();

    if (isFormValid) {
      dispatch(submitAppointmentRequest(appointmentData)); // Dispatch the request action
      console.log("you do not have any error............");
      setIsLoading(true);
  
      // Use your appointment API function to send the appointment data
      appointmentApi.submitAppointment(appointmentData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(submitAppointmentSuccess()); // Dispatch success action
            setShowConfirmationModal(true);
            setIsSuccess(true); // Set isSuccess to true
          } else {
            dispatch(submitAppointmentFailure('Failed to book the appointment')); // Dispatch failure action
          }
        })
        .catch((error) => {          
          console.error('Error:', error);
          dispatch(submitAppointmentFailure('Failed to book the appointment')); // Dispatch failure action
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderBookingMessage = () => {
    if (bookingStatus === "success") {
      return <p style={{ color: "green" }}>Your appointment is booked.</p>;
    } else if (bookingStatus === "failed") {
      return <p style={{ color: "red" }}>Failed to book the appointment.</p>;
    } else {
      return null;
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setIsLoading(false);
    setIsSuccess(false); // Reset success state
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.root}>
        <DialogTitle>
          <h3 style={{ textAlign: "center" }}>Book an Appointment</h3>
          <hr
            style={{
              borderTop: "2px solid #0b8140",
              margin: "10px auto",
              width: "50%",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Full Name"
            value={name}
            onChange={handleNameChange}
            onBlur={validateName}
            fullWidth
            margin="normal"
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <TextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            onBlur={validateEmail}
            fullWidth
            margin="normal"
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <TextField
            label="Contact Number"
            value={contactNo}
            onChange={handleContactNoChange}
            onBlur={validateContactNo}
            fullWidth
            margin="normal"
            error={Boolean(contactNoError)}
            helperText={contactNoError}
          />
          <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
            {isLoading && isFormValid && isSuccess !== true ? (
              <CircularProgress color="primary" size={24} />
            ) : null}
          </Stack>

          <FormControl fullWidth margin="normal">
            <InputLabel>Treatment</InputLabel>
            <Select
              value={treatment}
              onChange={handleTreatmentChange}
              onBlur={validateTreatment}
            >
             <MenuItem value="treatment1">Abhyangam (Ayurvedic Massage)</MenuItem>
              <MenuItem value="treatment2">Steaming (Swedanam)</MenuItem>
              <MenuItem value="treatment3">
                Sirodhara / Thakradhara / Kashaya dhara
              </MenuItem>
              <MenuItem value="treatment4">Bundle Massages (Kizhi)</MenuItem>
              <MenuItem value="treatment5">
                Lepanam (Application of Medicinal Paste)
              </MenuItem>
              <MenuItem value="treatment6">Sarvanga Dhara</MenuItem>
              <MenuItem value="treatment7">KatiVasti</MenuItem>
              <MenuItem value="treatment8">Januvasti</MenuItem>
              <MenuItem value="treatment9">Panchakarma Treatments</MenuItem>
              {/* Add more treatment options here */}
            </Select>
          </FormControl>
          <div style={{ marginBottom: "1rem" }}>
      <InputLabel>Date</InputLabel>
      <DatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
        minDate={new Date()} // Set the minimum date to today
        className="form-control"
      />
      {dateError && (
        <p style={{ color: "red", margin: "5px 0" }}>{dateError}</p>
      )}
    </div>
          <div style={{ marginBottom: "1rem" }}>
            <InputLabel>Time</InputLabel>
            <TimePicker
              label="Basic time picker"
              value={time}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            {timeError && (
              <p style={{ color: "red", margin: "5px 0" }}>{timeError}</p>
            )}
          </div>
          <TextField
            label="Message"
            value={message}
            onChange={handleMessageChange}
            onBlur={validateMessage}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            error={Boolean(messageError)}
            helperText={messageError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ color: "#0b8140" }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "#F79320",
              },
              backgroundColor: "#0b8140",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
            disabled={isLoading}
          >
            {isLoading ? "Booking..." : "Book Now"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        className={classes.confirmationModal}
      >
        <DialogTitle className={classes.confirmationTitle}>
          <h3>Confirmation</h3>
        </DialogTitle>
        <DialogContent>
          {isSuccess ? (
            <p style={{ color: "green", textAlign: "center" }}>
              Your appointment has been booked successfully.
            </p>
          ) : null}
          {renderBookingMessage()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmationModal}
            color="primary"
            className={classes.confirmationButton}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AppointmentPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  submitAppointmentRequest: PropTypes.func.isRequired,
  submitAppointmentSuccess: PropTypes.func.isRequired,
  submitAppointmentFailure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.appointment.isLoading,
  isSuccess: state.appointment.isSuccess,
});

const mapDispatchToProps = {
  submitAppointmentRequest,
  submitAppointmentSuccess,
  submitAppointmentFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPopup);
