import React from 'react';
import Grid from '@mui/material/Grid';
// import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Usersection from '../Components/Common/Usersection';
import '../css/Orderdetails.css';

export default function Orderdetails() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <Usersection />
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <Box sx={{ marginTop: '7%', padding: '2rem', marginRight: '2rem' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Order</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Total</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >#4431</th>
                  <td>Mark 02, 2023</td>
                  <td>Processing</td>
                  <td>200.00 for 1 item</td>
                  <td><button type="" className="view-btn">VIEW</button></td>
                </tr>
                </tbody>
            </table>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
