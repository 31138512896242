/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesAction, categoriesUnshowByAction, categoriesUpdateByAction } from '../../../redux/actions/categoryActions';
import { mediaCreateAction } from '../../../redux/actions/mediaActions';
import Layout from './Layout';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
  },
  searchInput: {
    marginRight: theme.spacing(2),
  },
  addCategoryContainer: {
    /* flexBasis: "50%", */
    flexDirection: 'column',
    marginRight: theme.spacing(4),
    /* marginLeft: "20%",
     marginTop: "10%", */
  },
  categoriesContainer: {
    flexBasis: '50%',
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  table: {
    // marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    marginTop: '10%',
  },
  imageCell: {
    width: '20%',
  },

}));

const ProductCategoriesPage = () => {
  const categorySelector = useSelector(state => state?.categories?.data);
  const mediaSelector = useSelector(state => state?.media?.data?.media);

  console.log('categorySelector', categorySelector);
  const classes = useStyles();
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [categoriesFromSelector, setCategoriesFromSelector] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]); // New state for original categoriesFromSelector
  // eslint-disable-next-line no-unused-vars
  const [mediaFromSelector, setMediaFromSelector] = useState({});
  const [returnedCreatedCategoryObject, setReturnedCreatedCategoryObject] = useState({});

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <>
          <img
            src={params?.formattedValue || params?.row?.linkedMedia?.server_image_url}
            style={{ width: '120px' }}
          />
        </>
      )
    },
    { field: 'name', headerName: 'Product Name', width: 150 },
    // { field: 'image', headerName: 'Image', width: 120, sortable: false, renderCell: (params) => <img className="customersListImg" src={params.row.avatar} alt="" /> },
    { field: 'slug', headerName: 'Slug', width: 100 },
    { field: 'description', headerName: 'Description', width: 120 },
    { field: 'createdAt', headerName: 'createdAt', width: 100 },
    { field: 'updatedAt', headerName: 'UpdatedAt', width: 120 },
    // { field: 'updatedAt', headerName: 'Count', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <>
          {/* <IconButton>
            <Edit onClick={() => handleEditAPage(params?.row)} />
          </IconButton> */}
          <IconButton onClick={() => handleDeleteCategory(params?.row?._id)}>
            <Delete />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    async function fetchAllCategories() {
      await dispatch(categoriesAction());
    }

    fetchAllCategories();
  }, [dispatch]);

  useEffect(() => {
    console.log('categorrr', categorySelector);
    if (categorySelector && !Array.isArray(categorySelector)) {
      if (categorySelector[0] && categorySelector[0]?.categoryResponse !== null && Object.keys(categorySelector[0]?.categoryResponse) !== 0) {

        const aCategory = categorySelector[0].categoryResponse;

        setCategoriesFromSelector([...categoriesFromSelector, {
          ...aCategory,
          id: aCategory._id,
          image: aCategory.linkedMedia !== null ? aCategory?.linkedMedia?.server_image_url : '',
          createdAt: aCategory?.createdAt?.split('T')[0],
          updatedAt: aCategory?.updatedAt?.split('T')[0],
        }]);
        setFilteredCategories([...categoriesFromSelector, {
          ...aCategory,
          id: aCategory._id,
          image: aCategory.linkedMedia !== null ? aCategory?.linkedMedia?.server_image_url : '',
          createdAt: aCategory?.createdAt?.split('T')[0],
          updatedAt: aCategory?.updatedAt?.split('T')[0],
        }]);
      }
    } else if (categorySelector && Array.isArray(categorySelector) && Object.keys(categorySelector) !== 0) {
      setCategoriesFromSelector(categorySelector.map(aCategory => ({
        ...aCategory,
        id: aCategory._id,
        image: aCategory.linkedMedia !== null ? aCategory?.linkedMedia?.server_image_url : '',
        createdAt: aCategory?.createdAt?.split('T')[0],
        updatedAt: aCategory?.updatedAt?.split('T')[0],
      })));
      setFilteredCategories(categorySelector.map(aCategory => ({
        ...aCategory,
        id: aCategory._id,
        image: aCategory.linkedMedia !== null ? aCategory?.linkedMedia?.server_image_url : '',
        createdAt: aCategory?.createdAt?.split('T')[0],
        updatedAt: aCategory?.updatedAt?.split('T')[0],
      })));
    }
  }, [categorySelector]);

  console.log('filetttttt', filteredCategories);

  useEffect(() => {
    const formData = new FormData();

    const uploadMedia = async () => {
      formData.append('image', image);
      formData.append('Role', secureLocalStorage.getItem('Role'));
      formData.append('userId', JSON.parse(secureLocalStorage.getItem('users'))?._id);
      formData.append('email', JSON.parse(secureLocalStorage.getItem('users')).email);

      formData.append('belongs_to_type', 'Category');

      formData.append('belongs_to_type_id', categorySelector?._id);

      formData.append('name', name);
      formData.append('slug', slug);
      formData.append('description', description);
      formData.append('isDeleted', false);
      formData.append('belongs_to', JSON.parse(secureLocalStorage.getItem('users'))?._id);
      formData.append('content_type', 'media');

      await (dispatch(mediaCreateAction(formData)));
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setImage(null);
    };
    if (categorySelector && !Array.isArray(categorySelector) && Object.keys(categorySelector) !== 0 && (image !== null)) {
      uploadMedia();
      // setImage(null)

      // setCategoriesFromSelector([...categoriesFromSelector, categorySelector]);
    }
    if (categorySelector && !Array.isArray(categorySelector) && Object.keys(categorySelector) !== 0) {
      setReturnedCreatedCategoryObject(categorySelector);
    }
  }, [categorySelector]);


  useEffect(() => {
    const updateCategoryLinkedMediaId = async () => {
      const updateCategoriesLinkedMediaPayload = {
        ...returnedCreatedCategoryObject,
        id: returnedCreatedCategoryObject?._id,
        Role: secureLocalStorage.getItem('Role'),
        userId: JSON.parse(secureLocalStorage.getItem('users'))?._id,
        email: JSON.parse(secureLocalStorage.getItem('users')).email,
        linkedMedia: mediaSelector?._id
      };

      await dispatch(categoriesUpdateByAction(updateCategoriesLinkedMediaPayload));
    };

    if (mediaSelector && Object.keys(mediaSelector).length !== 0) {
      updateCategoryLinkedMediaId();
      setMediaFromSelector(mediaSelector);
    }
  }, [mediaSelector]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setImage(selectedFile);
    } else {
      setImage(null); // Reset the image state if an invalid file is selected
      console.error('Invalid file format. Please select an image file.');
    }
  };

  const handleAddCategory = async () => {
    const categoryPayload = {
      name: name,
      slug: slug,
      description: description,
      route: name.split(' ').filter(aName => aName !== '').join('-'),
      image: image || '', // Provide a default value if image is null
      count: 0,
      isDeleted: false,
      content_type: 'category',
      userId: JSON.parse(secureLocalStorage.getItem('users'))?._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))?._id,
    };
    console.log('categoryPayload', categoryPayload);

    setCategoriesFromSelector([...categoriesFromSelector, {
      ...categoryPayload,
      id: categoryPayload._id || Math.random(),
      image: categoryPayload.linkedMedia !== null ? categoryPayload?.linkedMedia?.server_image_url : '',
    }]);
    await dispatch(categoriesCreateAction(categoryPayload));
    await dispatch(categoriesAction());

    // if (!!categoriesFromSelector === false) {
    // setCategoriesFromSelector([...categoriesFromSelector, categoryPayload]);
    // } else {
    //   setCategoriesFromSelector([categoryPayload]);
    // }
    setName('');
    setSlug('');
    setDescription('');
    // setImage(null);
  };


  const handleDeleteCategory = async (id) => {
    const deleteCategoryPayload = {
      id: id,
      isDeleted: false,
      content_type: 'category',
      userId: JSON.parse(secureLocalStorage.getItem('users'))?._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))?._id,
    };
    await dispatch(categoriesUnshowByAction(deleteCategoryPayload));
    setCategoriesFromSelector(categoriesFromSelector.filter(aCategory => aCategory?._id !== id));
    setFilteredCategories(categoriesFromSelector.filter(aCategory => aCategory?._id !== id)); // Update original categoriesFromSelector
  };

  const handleFilterChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = categoriesFromSelector.filter(
      (aCategory) =>
        aCategory.name.toLowerCase().includes(searchQuery) ||
        aCategory.slug.toLowerCase().includes(searchQuery)
    );
    setFilteredCategories(filteredResults);
  };

  return (
    <Layout>
      <div className="add-categ-container">
        <div className="add-page-contents add-page-content add-categ-lft">
          <h6>Add New Category </h6>

          <form style={{ border: '1px solid #dddddd', padding: '30px', marginTop: '20px', backgroundColor: '#efefef' }}>
            <TextField
              name="name"
              label="Name"
              value={name}
              onChange={handleNameChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Slug"
              value={slug}
              onChange={handleSlugChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              margin="normal"
            />
            <input type="file" onChange={handleImageChange} ref={fileInputRef} />
            <Button
              variant="contained"
              color="primary" className="vw-main-btn" style={{ marginTop: '15px' }}
              onClick={handleAddCategory}
              fullWidth
            > Add Category </Button>
          </form>
        </div>

        <div className="add-categ-rgt" style={{ marginRight: '30px', marginTop: '1px' }}>
          <div
            className={classes.categoriesContainer}
          >
            <div
              className={classes.filterContainer}
              style={{ marginRight: '0px', marginBottom: '14px', }}
            >
              <TextField
                label="Search"
                value={searchTerm}
                onChange={handleFilterChange}
                className={classes.searchInput}
                size="small"
              />
            </div>

            <div style={{ border: '1px solid #dddddd' }}>
              <DataGrid
                rows={filteredCategories}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
                checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductCategoriesPage;
