import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/feedbackActionTypes';
import feedbackApi from '../../apis/feedbackApi';

function* feedbacks(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.getAll, action);
    yield put({ type: type.ALL_FEEDBACK_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_FEEDBACK_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksLoggedInUserFeedbacks(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.loggedInUserFeedbacks, action);
    yield put({ type: type.LOGGED_IN_USER_FEEDBACKS_ID_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_FEEDBACKS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksCreate(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.create, action);
    yield put({ type: type.FEEDBACK_CREATE_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.FEEDBACK_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksGetBy(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.getBy, action);
    yield put({ type: type.FEEDBACK_GET_BY_ID_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.FEEDBACK_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksUpdateBy(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.updateBy, action);
    yield put({ type: type.FEEDBACK_UPDATE_BY_ID_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.FEEDBACK_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksUnshowBy(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.unshowBy, action);
    yield put({ type: type.FEEDBACK_UNSHOW_BY_ID_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.FEEDBACK_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* feedbacksDeleteBy(action) {
  try {
    const feedbacksResponse = yield call(feedbackApi.deleteBy, action);
    yield put({ type: type.FEEDBACK_DELETE_BY_ID_SUCCESS, feedbacksResponse: feedbacksResponse.data });
  } catch (e) {
    yield put({
      type: type.FEEDBACK_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myFeedbackSaga() {
  yield takeEvery(type.ALL_FEEDBACK_REQUESTING, feedbacks);
  yield takeEvery(type.LOGGED_IN_USER_FEEDBACKS_ID_REQUESTING, feedbacksLoggedInUserFeedbacks);
  yield takeEvery(type.FEEDBACK_CREATE_REQUESTING, feedbacksCreate);
  yield takeEvery(type.FEEDBACK_GET_BY_ID_REQUESTING, feedbacksGetBy);
  yield takeEvery(type.FEEDBACK_UPDATE_BY_ID_REQUESTING, feedbacksUpdateBy);
  yield takeEvery(type.FEEDBACK_UNSHOW_BY_ID_REQUESTING, feedbacksUnshowBy);
  yield takeEvery(type.FEEDBACK_DELETE_BY_ID_REQUESTING, feedbacksDeleteBy);
}

export default myFeedbackSaga;
