import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const mediaApi = {
  getAll: () => {
    const uri = HOST_ADDRESS + envVars.ALL_MEDIA;
    return axios.get(uri).then((response) => {
      return response;
    });
  },
  loggedInUserMedia: (params) => {
    const uri = HOST_ADDRESS + envVars.LOGGED_IN_USER_MEDIA_ID;
    return axios.post(`${uri}?userId=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.MEDIA_CREATE;
    return axios.post(uri, params.payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      return response;
    });
  },
  getBy: (params) => {
    const uri = HOST_ADDRESS + envVars.MEDIA_GET_BY_ID;
    return axios.get(`${uri}${params?.payload?.id}`).then((response) => {
      return response;
    });
  },
  updateBy: (params) => {
    const uri = HOST_ADDRESS + envVars.MEDIA_UPDATE_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.MEDIA_UNSHOW_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.MEDIA_DELETE_BY_ID;
    return axios.delete(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
};

export default mediaApi;
