/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-console */
// /* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
// import Breadcrumb from '../Components/Common/Breadcrumb';
import CartItems from '../Components/Common/CartItems';
import CartTotal from '../Components/Common/CartTotal';
import Coupon from '../Components/Common/Coupon';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import { cartGetCartAction } from '../redux/actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';

export default function Cart() {
  const dispatch = useDispatch();

  const cartSelector = useSelector(state => state?.cart?.data);

  // eslint-disable-next-line no-console
  console.log('userCartItems', JSON.parse(secureLocalStorage.getItem('isLoggedIn')), secureLocalStorage.getItem('isLoggedIn') === false,);
  // eslint-disable-next-line no-unused-vars
  const [cartCount, setCartCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loggedIn, setLoggedIn] = useState(JSON.parse(secureLocalStorage.getItem('isLoggedIn')));
  const [localStorageCart, setLocalStorageCart] = useState({ products: secureLocalStorage.getItem('guestCart') || null });

  const [cartFromSelector, setCartFromSelector] = useState(null);

  const updateCartCount = (count) => {
    setCartCount(count);
    setTotalAmount(cartFromSelector?.products[0].sale_price * (count || 1));
  };

  useEffect(() => {
    console.log('oooooooooo');
    const fetchUserCart = async () => {
      console.log('carrttttt JSON.parse(secureLocalStorage.getItem(\'users\'))', secureLocalStorage.getItem('guestCart'), !JSON.parse(secureLocalStorage.getItem('isLoggedIn')));

      if (!JSON.parse(secureLocalStorage.getItem('isLoggedIn'))) {
        console.log('carrtttttt', secureLocalStorage.getItem('guestCart'));
        secureLocalStorage.setItem('isLoggedIn', false);
      } else {
        secureLocalStorage.setItem('isLoggedIn', true);
        const fetchUserCartApiPayload = {
          Role: secureLocalStorage.getItem('Role'),
          userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
          email: JSON.parse(secureLocalStorage.getItem('users')).email,
        };
        await dispatch(cartGetCartAction(fetchUserCartApiPayload));
      }
    };

    fetchUserCart();
  }, []);

  useEffect(() => {
    if (cartSelector && Object.keys(cartSelector).length !== 0) {
      setCartFromSelector(cartSelector);
    }
  }, [cartSelector]);

  console.log('localStorageCart', localStorageCart, loggedIn);
  return (
    <>
      <div className="us-container">
        <h1 className="us-header">Cart </h1>
      </div>
      <Divider />

      <Container className="cart-txt" sx={{ maxWidth: 'md', marginTop: '30px', padding: '10px' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', borderRadius: '10px' }}>
          <Box sx={{ padding: '1px 1px 1px 2px', marginTop: '1px', borderRadius: '10px' }}>
            {/* <Breadcrumb pageName="cart" sx={{ marginLeft: '10px'}} /> */}
            <Box sx={{ padding: '15px', backgroundColor: '#ffffff', marginTop: '0px', borderRadius: '10px' }}>
              {loggedIn ?
                cartFromSelector?.products && cartFromSelector?.products.length === 0 ?
                  <>
                    <div style={{
                      fontSize: '25px',
                      textAlign: 'center'
                    }}>
                      No Items in your cart
                    </div>
                  </>
                  :
                  <>
                    <Box component="span">
                      <div className="inner-container" style={{ marginBottom: '20px' }}><h2 className="dnt-heading dnt-m">Cart </h2></div>
                    </Box>
                    <Grid container>
                      {cartFromSelector?.products && cartFromSelector?.products.map(aProduct => (
                        <Grid xs={8} md={8} key={aProduct._id}>
                          <CartItems
                            onCartCountChange={updateCartCount}
                            totalAmount={totalAmount}
                            currentItem={aProduct}
                            setTotalAmount={setTotalAmount}
                          />
                        </Grid>
                      ))}
                      <Grid md={4} xs={4}>
                        <Box sx={{ padding: '20px' }}>
                          <CartTotal totalAmount={totalAmount} />
                        </Box>
                      </Grid>
                      <Grid md={12} xs={4}>
                        <Coupon />
                      </Grid>
                    </Grid>
                  </>
                :
                localStorageCart.products !== null ? <>
                  <Grid container>
                    {localStorageCart?.products && localStorageCart?.products.map(aProduct => (
                      <Grid xs={8} md={8} key={aProduct._id}>
                        <CartItems onCartCountChange={updateCartCount} setTotalAmount={setTotalAmount} currentItem={aProduct} />
                      </Grid>
                    ))}
                    <Grid md={4} xs={4}>
                      <Box sx={{ padding: '20px' }}>
                        <CartTotal totalAmount={totalAmount} />
                      </Box>
                    </Grid>
                    <Grid md={12} xs={4}>
                      <Coupon />
                    </Grid>
                  </Grid>
                </> :
                  <div style={{
                    fontSize: '25px',
                    textAlign: 'center'
                  }}>
                    PLEASE LOGIN TO SEE YOUR CART
                    <br />
                    <br />
                    <h4 style={{ textAlign: 'center', marginRight: '30px' }}>
                      <bold>OR</bold>
                    </h4>
                    <br />
                    <Link to='/services-product'>
                      <Button variant="contained" size="medium"
                        sx={{ backgroundColor: '#0B8140', color: 'white', padding: '15px', width: '36%', borderRadius: '25px', fontWeight: 300 }}>
                        GO TO PRODUCT / SERVICES
                      </Button>
                    </Link>
                  </div>
              }
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}