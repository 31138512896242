/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css';
import './Slides.css';
import { Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import AboutUs from './AboutUs';
// import AyurvedicTherapies from './AyurvedicTherapies';
// import Packages from './Packages';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
// import Sevicesnproduct from '../../Pages/Sevicesnproduct';
import ProductSlideShow from './ProductSlideShow';
// import secureLocalStorage from 'react-secure-storage';
// import { v4 as uuidv4 } from 'uuid';
import AboutHome from './AboutHome';
import secureLocalStorage from 'react-secure-storage';
import {Helmet} from 'react-helmet';

const Slides = () => {
  useEffect(() => {
    console.log('secureLocalStorage.getItem(\'users\') === null', secureLocalStorage.getItem('users'), secureLocalStorage.getItem('users') === null);
    if (secureLocalStorage.getItem('users') === null) {
      // const initialGuestCart = {
      //     Role: secureLocalStorage.getItem('Role'),
      //     userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      //     email: JSON.parse(secureLocalStorage.getItem('users')).email,
      //     cart: []
      // };
      secureLocalStorage.setItem('isLoggedIn', 'false');
      // secureLocalStorage.setItem('guestCart', null);
      secureLocalStorage.removeItem('guestCart');
      // secureLocalStorage.setItem('guestCart', JSON.stringify(initialGuestCart));
    } else {
      console.log(':::::::::::::::::::::::');
      secureLocalStorage.setItem('isLoggedIn', 'true');
    }
  }, []);

  useEffect(() => {
    console.log('userCartItems ---- slides ', secureLocalStorage.getItem('isLoggedIn'));
  }, [localStorage]);


  return (
    <>
      <Grid container item>
      <Helmet>
      <meta charSet='utf-8' />
      <title>Ayurveda Doctor in Delhi-Noida | Health & Treatment | Massage Center</title>
      <meta name="description" content="Experience holistic healing at our Ayurveda center in Delhi-Noida. Expert doctors, treatments, massages & healthcare services for balance. Visit now." />
      </Helmet>

        <Grid item xs={12} md={12} sx={{ display: 'flex' }}>
          <Grid item xs={4} md={4} className="left-nav">
            <div className="hm-txt">Award Winning Wellness Center
              {/* The Best <br /> Wellness Center <br /> in Delhi and NCR */}
            </div>
            <Box className="left-nav-hm">
              <Link to="/ayurvedic-therapies" className="left-nav-link"> Ayurvedic Therapies </Link>
              <Link to="/diseases-treatments" className="left-nav-link"> Diseases & Treatments </Link>
              <Link to="/services-product" className="left-nav-link"> Services & Products </Link>
              <Link to="/membership" className="left-nav-link"> Membership </Link>
              <Link to="/contact-us" className="left-nav-link"> Contact Us </Link>
            </Box>
          </Grid>
          <Grid item xs={8} md={8} sm={12} sx={{ float: 'right' }}>
            <Carousel autoPlay >
              <div>
                <img alt="" src="./images/img1.jpg" />
                <p className="legend">Vedanta Wellness</p>
              </div>
              <div>
                <img alt="" src="./images/img2.jpg" />
                <p className="legend">Vedanta Wellness</p>
              </div>
              <div>
                <img alt="" src="./images/img3.jpg" />
                <p className="legend">Vedanta Wellness</p>
              </div>
            </Carousel>
          </Grid>
        </Grid>
        <AboutHome />
        {/* <AboutUs />
            <AyurvedicTherapies /> */}
        <ProductSlideShow />
        {/* <Packages />
            <ProductSlideShow /> */}
      </Grid>
    </>
  );
};
export default Slides;
