export const LOGIN_REQUESTING = "LOGIN_REQUESTING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_REQUESTING = "REGISTER_REQUESTING ";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const HIDE_MODAL = "HIDE_MODAL";

export const ADD_PRODUCT_REQUESTING = "ADD_PRODUCT_REQUESTING ";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
// export const SUBMIT_CONTACT_REQUEST = "SUBMIT_CONTACT_REQUEST";
// export const SUBMIT_CONTACT_SUCCESS = "SUBMIT_CONTACT_SUCCESS";
// export const SUBMIT_CONTACT_FAILURE = "SUBMIT_CONTACT_FAILURE";
export const CREATE_CONTACT_REQUESTING = "CREATE_CONTACT_REQUESTING";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";

// types.js
export const CREATE_APPOINTMENT_REQUESTING = "CREATE_APPOINTMENT_REQUESTING";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILURE = "CREATE_APPOINTMENT_FAILURE";
