import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid, Paper } from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Layout from './Layout';
import { ordersCreateAction } from '../../../redux/actions/orderActions';

function AddNewOrder() {
  const [orderNo, setOrderNo] = useState('');
  const [orderName, setOrderName] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [cost, setCost] = useState('');
  const [items, setItems] = useState([]);
  const [itemsGrandTotalCost, setItemsGrandTotalCost] = useState(0);
  const [itemsGrandTotalQuantity, setItemsGrandTotalQuantity] = useState(0);

  // const today = new Date().toDateString();

  const dispatch = useDispatch();

  const handleAddItem = () => {
    const newItem = {
      product_name: itemName,
      quantity,
      cost,
      total: (parseFloat(quantity) * parseFloat(cost)).toFixed(2),
    };
    setItems([...items, newItem]);

    // eslint-disable-next-line no-unused-vars
    const totalSum = items.reduce((sum, item) => {
      const totalValue = parseFloat(item.total);
      return sum + totalValue;
    }, 0);

    setItemName('');
    setQuantity('');
    setCost('');
  };

  useEffect(() => {
    const totalSum = items.reduce((sum, item) => {
      const totalValue = parseFloat(item.total);
      return sum + totalValue;
    }, 0);

    const totalQuantity = items.reduce((quantity, item) => {
      const totalQuantity = parseFloat(item.quantity);
      return quantity + totalQuantity;
    }, 0);

    setItemsGrandTotalCost(totalSum);
    setItemsGrandTotalQuantity(totalQuantity);
  }, [items]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const orderCreationPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,

      order_no: orderNo,
      order_name: orderName,
      date_created: dateCreated.split(' ').slice(1).join(' '),
      shipping_address: shippingAddress,
      billing_address: billingAddress,
      product_details: items,
      status: 'in_cart_only',
      isDeleted: false,
      itemsGrandTotalCost: itemsGrandTotalCost,
      itemsGrandTotalQuantity: itemsGrandTotalQuantity,
    };

    dispatch(ordersCreateAction(orderCreationPayload));

    // setOrderNo("");
    // setDateCreated("");
    // setShippingAddress("");
    // setBillingAddress("");
    // setItems([]);

  };

  return (
    <Layout>
      <div>
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginLeft: '200px', marginTop: '60px' }}
        >
          Add New Order
        </Typography>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '200px', marginTop: '30px' }}
        >
          <Grid item xs={10} md={5}>
            <Paper style={{ padding: 16 }}>
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Order No"
                      fullWidth
                      value={orderNo}
                      onChange={(e) => setOrderNo(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Order Name"
                      fullWidth
                      value={orderName}
                      onChange={(e) => setOrderName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      // defaultValue={() => today}
                      // defaultValue={dayjs('2022-04-17')}
                      value={dateCreated}
                      onChange={(date) => {
                        const newDate = date ? date.$d.toDateString() : '';
                        return setDateCreated(newDate);
                      }}
                      renderInput={(params) => <TextField
                        {...params}
                        label="Date Created"
                        fullWidth
                      />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Shipping Address"
                      fullWidth
                      value={shippingAddress}
                      onChange={(e) => setShippingAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Billing Address"
                      fullWidth
                      value={billingAddress}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={8} md={4}>
            <Paper style={{ padding: 16 }}>
              <Typography variant="h6" gutterBottom>
                Order Items
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Item Name"
                    fullWidth
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Quantity"
                    type="number"
                    fullWidth
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cost"
                    type="number"
                    fullWidth
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddItem}
                  >
                    Add Item
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <br />

            <Grid item xs={12} md={12}>
              <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                  Order Summary
                </Typography>
                {items.length > 0 ? (
                  <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {items.map((item, index) => (
                        <li key={index}>
                          <div>
                            <strong>Item Name:</strong> {item.product_name}
                          </div>
                          <div>
                            <strong>Quantity:</strong> {item.quantity}
                          </div>
                          <div>
                            <strong>Cost:</strong> {item.cost}
                          </div>
                          <div>
                            <strong>Total:</strong> {item.total}
                          </div>
                        </li>
                      ))}
                      <hr />
                    </ul>
                    Grand total: {itemsGrandTotalCost}
                    <br />
                    Total: Quantity: {itemsGrandTotalQuantity}
                  </div>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No items added yet.
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: '200px', marginTop: '350px', position: 'fixed' }}
          >
            Submit Order
          </Button>
        </Grid>
      </div>
    </Layout>
  );
}

export default AddNewOrder;
