/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const KatiVasti  = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">KatiVasti </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong> What is KatiVasti ? </strong>
          </Typography>
          <Typography variant="body1">
          <p>Ayurveda has a holistic approach towards all the health concerns that it can help with. Whether it's a common cold, high fever, a sore throat or a lower back pain, Ayurvedic treatments and therapy options will never focus on easing down the symptoms. Rather, they’ll have a comprehensive approach, helping build and maintain holistic well being.</p>
          <p>Kati vasti treatment in Ayurveda's take on helping individuals dealing with lower back pain and other related concerns. By definition, kati vasti is a lower back or lumbosacral therapy performed using heat and oil. The purpose is also to help bring vata dosha levels to optimum. </p> 
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of KatiVasti </strong>
          </Typography>
          <Typography variant="body1">
          <p>Kati vasti Ayurvedic treatment is said to be helpful for people facing lower back pain concerns. As the therapy process starts, the properties of herbal oils start healing, nourishing and strengthening the muscles and tissues in the covered area.</p>
          <p>As the heated herbal oils are absorbed, they may also lubricate the joints for smoother, and effortless movement, eventually resolving mobility and flexibility related concerns.</p>        
          <p>In the bigger picture, kati vasti Ayurveda treatment is said to help with lower back discomforts such as lumbar spondylitis, intervertebral disc prolapses, lumbago (lower back pain), and sciatica. </p>
          <p>Relaxation by supporting musculature of the affected intervertebral joint. This treatment also helps improve blood circulation in the back area. Additionally, it can also help strengthen the intervertebral joint compartments. Kati vasti is known as an effective preventive treatment for strengthening the spine and preventing back pain. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> What Are The  Procedure Of KatiVasti ? </strong>
          </Typography>
          <Typography variant="body1">
          <b>Kati vasti procedure followed by experts at the Ayurvedagram retreat goes as follows:</b>
          <p>A thick dough is prepared with black gram paste. The shape of this dough is either kept circular, oval or rectangular. The shape is determined by the back area that has to be treated (the area that is painful). For the procedure, the patient has to lie down on their stomach. Then the dough is placed on their lower back area having the back pain. The dough ring is placed in a manner that the edges of the ring of the dough are fixed to the back using water. This protects the ring from slipping down the back.</p>
          <p>This reservoir placed on the back is then filled with heated medicated herbal oil. For the procedure, the medicated oil is chosen based on the dosha that needs optimisation. The oil is supposed to remain within this dough mould. Through this process, the therapeutic properties of the oil absorb and reach the tissues. The warmth of this oil is maintained and helps nourish the tissues. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Kati Vasti Ayurveda treatment method </strong>
          </Typography>
          <Typography variant="body1">
          <p>The thick dough is prepared using the black gram paste. The shape of the dough mould could be either circular, oval or rectangular as per the back area where the patient is having pain. </p>
          <p>Once the patient lies down on the stomach, the dough is placed as a rim around the back.</p>
          <p>The edges of the ring of dough are fixed to the back using water that protects it from slipping off. This is like creating a reservoir on the back to store oil.</p>
          <p>The prescribed medicated oil (using herbs selected as per the prevailing dosha) is warmed and poured into the ring of dough.</p>
          <p>The oil remains within the confinements of the dough mould. Eventually, the therapeutic properties of the oil permeate and reach the tissues. </p>      
          <p>The warmth of the oil is maintained by appropriate replenishment.</p>        
          </Typography>
        </Grid>
      </Grid>
      </div>
      </>
  );
};

export default KatiVasti ;
