/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const Dysphagia = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Dysphagia
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is Dysphagia ?
            </Typography>
            <Typography variant="body1">
              Dysphagia is a condition characterized by difficulty or discomfort
              when swallowing. It can affect the passage of food or liquids from
              the mouth to the stomach. Symptoms of dysphagia include choking or
              coughing during eating or drinking, a sensation of food getting
              stuck in the throat or chest, regurgitation, and unintended weight
              loss. The causes of dysphagia can be varied, including muscle
              weakness or dysfunction in the throat or esophagus, structural
              abnormalities, neurological disorders (such as stroke or
              Parkinson's disease), and certain medical conditions (like
              gastroesophageal reflux disease or esophageal strictures).{' '}
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of Dysphagia
            </Typography>
            <Typography variant="body1">
              Some common symptoms of Dysphagia include:
              <ul>
                <li>Pain while swallowing</li>
                <li>Inability to swallow</li>
                <li>
                  A sensation of food getting stuck in the throat or chest or
                  behind the breastbone (sternum)
                </li>
                <li>Drooling</li>
                <li>Hoarseness</li>
                <li>Food coming back up (regurgitation)</li>
                <li>Frequent heartburn</li>
                <li>Food or stomach acid backing up into the throat</li>
                <li>Weight loss</li>
                <li>Coughing or gagging when swallowing</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Dysphagia
            </Typography>
            <Typography variant="body1">
              Dysphagia can be caused by various factors, including:
              <ul>
                <li>some medicines, such as antipsychotics</li>
                <li>having a learning disability</li>
                <li> cleft lip and palate in babies</li>
                <li>
                  heartburn and acid reflux, especially in children or people
                  who have gastro-oesophageal reflux disease
                </li>
                <li>
                  a condition that affects the nervous system or brain, such as
                  cerebral palsy, a stroke, dementia or multiple sclerosis
                </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for Dysphagia
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Dysphagia include:
              <ul>
                <li>
                  <b>Aging — </b>older adults are more at risk. This is due to
                  general wear and tear on the body over time. Also, certain
                  diseases of old age can cause dysphagia, such as Parkinson’s
                  disease.
                </li>
                <li>
                  <b>Neurological conditions — </b>certain nervous system
                  disorders make dysphagia more likely.
                </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              While Dysphagia cannot always be prevented, some strategies that
              may help manage the symptoms include:
              <ul>
                <li>
                  <b>Changing your eating habits:</b> Try eating smaller, more
                  frequent meals. Cut your food into smaller pieces, chew food
                  thoroughly and eat more slowly. If you have difficulty
                  swallowing liquids, there are products you can buy to thicken
                  liquids.
                </li>
                <li>
                  <b>
                    Trying foods with different textures to see if some cause
                    you more trouble:
                  </b>{' '}
                  Thin liquids, such as coffee and juice, are a problem for some
                  people, and sticky foods, such as peanut butter or caramel,
                  can make swallowing difficult. Avoid foods that cause you
                  trouble.
                </li>
                <li>
                  <b>Limiting alcohol and caffeine:</b> These can dry your mouth
                  and throat, making swallowing more difficult.
                </li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dysphagia;
