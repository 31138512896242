import { combineReducers } from 'redux';
import blogReducers from './reducers/blogReducers';
import cartReducers from './reducers/cartReducers';
import categoryReducers from './reducers/categoryReducers';
import couponReducers from './reducers/couponReducers';
import customerReducers from './reducers/customerReducers';
import feedbackReducers from './reducers/feedbackReducers';
import mediaReducers from './reducers/mediaReducers';
import notificationReducers from './reducers/notificationReducers';
import orderReducers from './reducers/orderReducers';
import pageReducers from './reducers/pageReducers';
import productReducers from './reducers/productReducers';
import tagReducers from './reducers/tagReducers';
import userReducers from './reducers/userReducers';
import contactReducer from './reducers/contactReducer';
import appointmentReducer from './reducers/appointmentReducers';

const rootReducer = combineReducers({
  blogs: blogReducers,
  cart: cartReducers,
  categories: categoryReducers,
  coupons: couponReducers,
  customers: customerReducers,
  feedbacks: feedbackReducers,
  media: mediaReducers,
  notifications: notificationReducers,
  orders: orderReducers,
  pages: pageReducers,
  products: productReducers,
  tags: tagReducers,
  users: userReducers,
  contact: contactReducer,
  appointment: appointmentReducer,
});

export default rootReducer;
