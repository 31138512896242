/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const WeightLoss = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Weight Loss </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

    <Grid container spacing={2}>
    <Grid item xs={12}>
    <Typography variant="h6" gutterBottom>
    <strong>What is Weight Loss? </strong>
    </Typography>
    
    <Typography variant="body1">
    <p> Healthy weight isn’t about following a diet or program. Instead, it involves a lifestyle with healthy eating patterns, regular physical activity, and stress management. People with gradual, steady weight loss (about 1 to 2 pounds per week) are more likely to keep the weight off than people who lose weight quickly.                      </p>
    <p> Sleep, age, genetics, diseases, medications, and environments may also contribute to weight management. If you are concerned about your weight or have questions about your medications, talk with your health care provider.                      </p>
     </Typography>
     
     </Grid>

     <Grid item xs={12}>
     <hr />

     <Typography variant="h6" gutterBottom>
     <strong>Symptoms of Weight Loss </strong>
     </Typography>

     <Typography variant="body1">
      Some common symptoms of Weight Loss include:

      <ul className='vw-ul'>
      <li> A strong urge to urinate that doesn't go away </li>
      <li> A burning feeling when urinating </li>
      <li> Urinating often, and passing small amounts of urine </li>
      <li> Urine that looks cloudy </li>
      <li> Urine that appears red, bright pink or cola-colored - signs of blood in the urine </li>
      <li> Strong-smelling urine </li>
      <li> Pelvic pain, in women - especially in the center of the pelvis and around the area of the pubic bone </li>  
      </ul>
      
      </Typography>
      </Grid>

      <Grid item xs={12}>
      <hr />
      <Typography variant="h6" gutterBottom>
      <strong>Why Weight Matters </strong>
      </Typography>
      
      <Typography variant="body1">
       <strong>Overweight Health Risks</strong> - Excess weight can lead to:

       <ul className='vw-ul'>
        <li>Heart disease</li>
        <li>High blood pressure</li>
        <li>Type 2 diabetes</li>
        <li>Joint problems</li>
      </ul>

      <strong>Underweight Health Risks</strong> - Being underweight can cause:

      <ul className='vw-ul'>
        <li>Weakened immune system</li>
        <li>Osteoporosis</li>
        <li>Fertility issues</li>
        <li>Muscle wasting</li>
      </ul>
      </Typography>

      </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Lose Weight with Ayurvedic Therapy </strong>
        </Typography>

         <Typography variant="body1">
         <p> Ayurveda offers natural ways to achieve and maintain a healthy weight. Some Ayurvedic practices for weight loss include: </p>

         <ul className='vw-ul'>
        <li>Dietary changes based on your dosha (body type)</li>
        <li>Herbal remedies and supplements</li>
        <li>Yoga and meditation for stress reduction</li>
        <li>Regular detoxification</li>
        </ul>
        </Typography>

        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default WeightLoss;
