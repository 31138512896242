/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const AnkleSprain = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Ankle Sprain </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Ankle Sprain? </strong>
        </Typography>
            <Typography variant="body1">
              An ankle sprain refers to the stretching or tearing of ligaments
              that connect the bones in the ankle joint. It commonly occurs when
              the ankle is twisted or turned beyond its normal range of motion,
              resulting in damage to the ligaments. Symptoms of an ankle sprain
              include pain, swelling, bruising, difficulty bearing weight or
              walking, and instability of the joint. Causes can vary from
              awkward steps, sports-related injuries, or accidental falls.
              Factors like weak muscles, previous ankle injuries, and uneven
              surfaces can increase the risk. Prompt medical attention, rest,
              ice, compression, and elevation (RICE) therapy, and rehabilitation
              exercises are generally recommended for recovery.
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Ankle Sprain</strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Ankle Sprain include:
              <ul className='vw-ul'>
                <li>
                  Sensation of discomfort at the ankle that may be present on
                  rest or at movement.{' '}
                </li>
                <li>Ankle redness or swelling </li>
                <li>
                  Instability of the Ankle, foot and leg especially during
                  walking{' '}
                </li>
                <li>
                  Burning pain with tingling or altered sensation like numbness.{' '}
                </li>
                <li>Feeling of stiffness, physical discomfort.</li>
                <li>
                  Inability to bear body weight on the affected ankle joint.{' '}
                </li>
                <li>Feeling of weakness in the foot and leg. </li>
                <li>
                  Finding it trouble to move, rotate or touch the ankle joint.{' '}
                </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Causes of Ankle Sprain </strong>
        </Typography>
            <Typography variant="body1">
              Ankle Sprain can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Walking or exercising on an uneven surface</li>
                <li>Falling down or tripping</li>
                <li>
                  Participating in sports that require cutting or jumping
                  actions, such as trail running, basketball, tennis, football,
                  and soccer
                </li>
                <li>Landing incorrectly after a jump.</li>
              </ul>
            </Typography>
      
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Ankle Sprain </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Ankle Sprain include:
              <ul className='vw-ul'>
                <li>Participating in high-risk sports.</li>
                <li>Loose joints.</li>
                <li>High-arched foot and weak muscles.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
          
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
        </Typography>
            <Typography variant="body1">
              While Ankle Sprain cannot always be prevented, some strategies
              that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>
                  <b>Protection:</b> Use crutches or apply a splint or brace to
                  limit use of your injured ankle.
                </li>
                <li>
                  <b>Rest:</b> Limit physical activities that may cause stress to
                  the sprain (no running, jumping, exercising).
                </li>
                <li>
                  <b>Ice:</b> Apply ice or a cold pack in a towel to your ankle
                  in 20-minute increments to reduce swelling.
                </li>
                <li>
                  <b>Compression:</b> Gently wrap your ankle in an elastic
                  bandage to help decrease swelling.
                </li>
                <li>
                  <b>Elevation:</b> Raise your ankle on pillows while you are
                  sitting or lying down so that it's higher than your heart.
                </li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default AnkleSprain;
