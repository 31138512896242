import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import {
  TextField,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { pagesAction, pagesUnshowByAction } from '../../../redux/actions/pageActions';

const PageList = () => {
  const navigate = useNavigate();
  const pagesSelector = useSelector(state => state.pages?.data);

  const [searchTerm, setSearchTerm] = useState('');

  const [pagesFromSelector, setPagesFromSelector] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);

  const dispatch = useDispatch();


  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    { field: 'author', headerName: 'Author', width: 250 },
    // { field: 'image', headerName: 'Image', width: 120, sortable: false, renderCell: (params) => <img className="customersListImg" src={params.row.avatar} alt="" /> },
    { field: 'createdAt', headerName: 'Added Date', width: 200 },
    { field: 'updatedAt', headerName: 'Last updated on', width: 220 },
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit onClick={() => handleEditAPage(params?.row)} />
          </IconButton>
          <IconButton onClick={() => handleDeleteAPage(params?.row?._id)}>
            <Delete />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    const pagesFromSelectorPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllPages() {
      await dispatch(pagesAction(pagesFromSelectorPayload));
    }

    fetchAllPages();
  }, [dispatch]);

  useEffect(() => {
    if (pagesSelector && Array.isArray(pagesSelector)) {
      setPagesFromSelector(pagesSelector.map((aPage) => ({
        ...aPage,
        id: aPage._id, // Add id property based on _id
        author: `${aPage?.belongs_to?.firstname} ${aPage?.belongs_to?.lastname}`,
        createdAt: aPage?.createdAt?.split('T')[0],
        updatedAt: aPage?.updatedAt?.split('T')[0],
      })));

      setFilteredPages(pagesSelector.map((aPage) => ({
        ...aPage,
        id: aPage._id, // Add id property based on _id
        author: `${aPage?.belongs_to?.firstname} ${aPage?.belongs_to?.lastname}`,
        createdAt: aPage?.createdAt?.split('T')[0],
        updatedAt: aPage?.updatedAt?.split('T')[0],
      })));
    }
  }, [pagesSelector]);


  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = pagesFromSelector.filter(
      (aPage) =>
        aPage.title.toLowerCase().includes(searchQuery) ||
        aPage.author.toLowerCase().includes(searchQuery)
    );
    setFilteredPages(filteredResults);
  };

  const pageCount = pagesFromSelector.length;

  const handleDeleteAPage = (id) => {
    const unshowABlogPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      id: id,
      content_type: 'page'
    };

    dispatch(pagesUnshowByAction(unshowABlogPayload));

    setPagesFromSelector(pagesFromSelector.filter((page) => page._id !== id));
  };

  const handleEditAPage = (aPage) => {
    navigate(
      '/AddPages',
      { state: aPage },
    );
    secureLocalStorage.setItem('sideBarSubHeadingId', 1);
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6>All Pages</h6>
          <Box>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={6}
              >
                <Typography variant="subtitle1" className="page-content-txt">
                  <strong>Pages Added:</strong> {pageCount}
                </Typography>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <TextField
                    label="Search Pages"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </Box>
              </Box>
              <DataGrid
                rows={filteredPages}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
                checkboxSelection
              />
            </Box>
          </Box>
        </div>
      </div>
    </Layout>
  );
};

export default PageList;

