/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';

const testimonials = [
  {
    content: "Vedanta Wellness helped in curing my knee and leg pain. Thank you Vedanta Wellness. Highly recommended!!",
    author: "Mrs. Reegena George"
  },
  {
    content: "I got relief after taking ayurvedic treatments offered by Vedanta Wellness. I think everybody should give it a try.",
    author: "Mr. Ranjit"
  },
  {
    content: "This center is really that place where you can go to relax and rejuvenate after a hectic schedule. Services are really good.",
    author: "Mrs. Punitha Jain"
  },
  {
    content: "One of my friends had suggested me to take a package for the treatment of my backache, and so I did. The treatment did wonders.",
    author: "Mr. V.P. Singh"
  },
  {
    content: "I was looking for a good Ayurveda doctor in Delhi for an Ayurvedic joint pain treatment. A friend of mine suggested that I should go to Vedanta Wellness located in Sector 56, Noida. Trust me, their Ayurvedic treatments are incredibly rejuvenating. I am feeling much better now. Thanks to Vedanta and the wonderful team there.",
    author: "Mr. Shivam chaudhary"
  }
];

const TestimonialPage = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Testimonials </h2></div>
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      {testimonials.map((testimonial, index) => (
        <Card key={index} elevation={3} className="testimonial-card-vw">
          <CardContent>
            <Typography variant="body1">
            "{testimonial.content}"
            </Typography>

            <Typography variant="subtitle2">
              - {testimonial.author}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
    </>
  );
};

export default TestimonialPage;