import * as type from '../actionTypes/blogActionTypes';

export const blogsAction = (payload) => {
  return {
    type: type.ALL_BLOGS_REQUESTING,
    payload
  };
};
export const blogsLoggedInUserBlogsAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_BLOGS_ID_REQUESTING,
    payload
  };
};
export const blogsCreateAction = (payload) => {
  return {
    type: type.BLOGS_CREATE_REQUESTING,
    payload
  };
};
export const blogsGetByAction = (payload) => {
  return {
    type: type.BLOGS_GET_BY_ID_REQUESTING,
    payload
  };
};
export const blogsUpdateByAction = (payload) => {
  return {
    type: type.BLOGS_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const blogsUnshowByAction = (payload) => {
  return {
    type: type.BLOGS_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const blogsDeleteByAction = (payload) => {
  return {
    type: type.BLOGS_DELETE_BY_ID_REQUESTING,
    payload
  };
};
