export const ALL_CUSTOMER_REQUESTING = 'ALL_CUSTOMER_REQUESTING';
export const ALL_CUSTOMER_SUCCESS = 'ALL_CUSTOMER_SUCCESS';
export const ALL_CUSTOMER_FAILURE = 'ALL_CUSTOMER_FAILURE';

export const LOGGED_IN_USER_CUSTOMERS_ID_REQUESTING = 'LOGGED_IN_USER_CUSTOMERS_ID_REQUESTING';
export const LOGGED_IN_USER_CUSTOMERS_ID_SUCCESS = 'LOGGED_IN_USER_CUSTOMERS_ID_SUCCESS';
export const LOGGED_IN_USER_CUSTOMERS_ID_FAILURE = 'LOGGED_IN_USER_CUSTOMERS_ID_FAILURE';

export const CUSTOMER_CREATE_REQUESTING = 'CUSTOMER_CREATE_REQUESTING';
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';
export const CUSTOMER_CREATE_FAILURE = 'CUSTOMER_CREATE_FAILURE';

export const CUSTOMER_GET_BY_ID_REQUESTING = 'CUSTOMER_GET_BY_ID_REQUESTING';
export const CUSTOMER_GET_BY_ID_SUCCESS = 'CUSTOMER_GET_BY_ID_SUCCESS';
export const CUSTOMER_GET_BY_ID_FAILURE = 'CUSTOMER_GET_BY_ID_FAILURE';

export const CUSTOMER_UPDATE_BY_ID_REQUESTING = 'CUSTOMER_UPDATE_BY_ID_REQUESTING';
export const CUSTOMER_UPDATE_BY_ID_SUCCESS = 'CUSTOMER_UPDATE_BY_ID_SUCCESS';
export const CUSTOMER_UPDATE_BY_ID_FAILURE = 'CUSTOMER_UPDATE_BY_ID_FAILURE';

export const CUSTOMER_UNSHOW_BY_ID_REQUESTING = 'CUSTOMER_UNSHOW_BY_ID_REQUESTING';
export const CUSTOMER_UNSHOW_BY_ID_SUCCESS = 'CUSTOMER_UNSHOW_BY_ID_SUCCESS';
export const CUSTOMER_UNSHOW_BY_ID_FAILURE = 'CUSTOMER_UNSHOW_BY_ID_FAILURE';

export const CUSTOMER_DELETE_BY_ID_REQUESTING = 'CUSTOMER_DELETE_BY_ID_REQUESTING';
export const CUSTOMER_DELETE_BY_ID_SUCCESS = 'CUSTOMER_DELETE_BY_ID_SUCCESS';
export const CUSTOMER_DELETE_BY_ID_FAILURE = 'CUSTOMER_DELETE_BY_ID_FAILURE';
