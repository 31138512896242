/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import './Topbar.css';
import { NotificationsNone, Language, Settings } from '@material-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
// import { useLogout } from './useLogout';
import secureLocalStorage from 'react-secure-storage';

function Topbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  // const isAuthenticated = secureLocalStorage.getItem('isAuthenticated');
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      secureLocalStorage.clear();
      navigate('/login');
    }
  };

  useEffect(() => {
    function handleClickOutsideDropdown(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideDropdown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
        <Link to="/Slides">
                <img
                  src="../images/logo.png"
                  alt="Vedanta Wellness"
                  className="vedanta-logo-admin"
                />
              </Link>
        </div>
        <div className="topRight">
          <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer" onClick={handleDropdown}>
            <Settings />
            {showDropdown && (
              <div className="dropdownContainer" ref={dropdownRef}>
                <ul className="dropdownMenu">
                  <li className="dropdownItem">
                    <Link to="/AdminProfilePage">Profile</Link>
                  </li>
                  <li className="dropdownItem" onClick={handleLogout}>
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
