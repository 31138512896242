/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Hypothyroidism = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Hypothyroidism </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Hypothyroidism? </strong>
        </Typography>
            <Typography variant="body1">
            Hypothyroidism is a condition characterized by an underactive thyroid gland, which produces
insufficient thyroid hormones. The thyroid hormones play a vital role in regulating metabolism,
growth, and development in the body. Common causes of hypothyroidism include autoimmune
thyroiditis (Hashimoto's disease), surgical removal of the thyroid gland, radiation therapy, certain
medications, and congenital factors. Symptoms of hypothyroidism can include fatigue, weight
gain, sensitivity to cold, dry skin, hair loss, constipation, depression, muscle weakness, and
cognitive difficulties. Diagnosis is made through blood tests that measure thyroid hormone levels.            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Hypothyroidism </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Hypothyroidism include:
              <ul className='vw-ul'>
                <li>Factorsatigue</li>
                <li>Washeight gain</li>
                <li>Trouble tolerating cold</li>
                <li>Joint and muscle pain</li>
                <li>Dry skin or dry, thinning hair</li>
                <li>Heavy or irregular menstrual periods or fertility problems</li>
                <li>Slowed heart rate,Depression.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Hypothyroidism </strong>
        </Typography>
            <Typography variant="body1">
              Hypothyroidism can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Hashimoto's disease, an autoimmune disorder where your immune system attacks your thyroid. This is the most common cause.</li>
                <li>Radioactive iodine treatment</li>
                <li>Thyroid surgery</li>
                <li>Radiation therapy</li>
                <li>Medications</li>
                <li>Iodine deficiency</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Hypothyroidism</strong>
        </Typography>
            <Typography variant="body1">
            Although anyone can develop hypothyroidism, you're at an increased risk if you:
            <ul className='vw-ul'>
                <li>Are a woman.</li>
                <li>Have a family history of thyroid disease.</li>
                <li>Have an autoimmune disease, such as type 1 diabetes or celiac disease.</li>
                <li>Have received treatment for hyperthyroidism.</li>
                <li>Received radiation to your neck or upper chest.</li>
                <li>Have had thyroid surgery.</li>
              </ul>
            </Typography>
      
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
            It is possible to avoid developing hypothyroidism, it can be prevented with a proper diet and lifestyle management. In addition these can help in prevention of hypothyroidism:
            <ul className='vw-ul'>
                <li>Diet rich in iodine, selenium, and zinc</li>
                <li>Giving up smoking </li>
                <li>Keep stress and anxiety under control by doing regular exercise and yoga </li>
                <li>Avoid self-medication </li>
                <li>Avoid gluten, processed foods, and fast food </li>
                <li>Low exposure to environmental radiation</li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default  Hypothyroidism;
