/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  filterLabel: {
    marginRight: theme.spacing(1),
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  customDropdown: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdownToggle: {
    display: 'block',
    padding: '8px 16px',
    backgroundColor: '#f2f2f2',
    border: '1px solid #ccc',
    cursor: 'pointer',
    width: '60px',
    height: '40px'
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    display: 'none',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    width: '80px',
    maxHeight: '150px',
    overflowY: 'auto',
  },
  dropdownItem: {
    padding: '8px 16px',
    cursor: 'pointer',
  },
}));

const FeedbackPage = () => {
  const classes = useStyles();
  const [feedbackData, setFeedbackData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [ratingFilter, setRatingFilter] = useState('');
  const [productSearch, setProductSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const feedbackPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchMyAPI() {
      // await dispatch(blogsAction()); your action here without `()`
    }

    fetchMyAPI();
  }, []);

  useEffect(() => {
    // Fetch feedback data from API
    fetchFeedbackData()
      .then((data) => {
        setFeedbackData(data);
        setFilteredData(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-undef
        console.error('Error fetching feedback data:', error);
      });
  }, []);

  const fetchFeedbackData = async () => {
    try {
      const response = await fetch('your_api_endpoint');
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch feedback data');
    }
  };

  const handleRatingFilterChange = (event) => {
    setRatingFilter(event.target.value);
    filterFeedbackData(event.target.value, productSearch);
  };

  const handleProductSearchChange = (event) => {
    setProductSearch(event.target.value);
  };

  const handleSearch = () => {
    filterFeedbackData(ratingFilter, productSearch);
  };

  const filterFeedbackData = (rating, product) => {
    const filtered = feedbackData.filter((feedback) => {
      if (rating !== '' && feedback.rating !== rating) {
        return false;
      }
      if (product !== '' && !feedback.productName.toLowerCase().includes(product.toLowerCase())) {
        return false;
      }
      return true;
    });
    setFilteredData(filtered);
  };

  const toggleDropdown = () => {
    const dropdownMenu = document.getElementById('dropdownMenu');
    dropdownMenu.style.display = dropdownMenu.style.display === 'none' ? 'block' : 'none';
  };

  const handleDropdownItemClick = (rating) => {
    setRatingFilter(rating);
    filterFeedbackData(rating, productSearch);
    toggleDropdown();
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6>Reviews</h6>

          <div className='rating-section-review'>

            <div style={{ float: 'left', marginRight: '670px' }} className={classes.filterContainer}>
              <Typography variant="body1" className={classes.filterLabel}>
                Filter by Rating:
              </Typography>
              <div className={classes.customDropdown}>
                <div style={{ width: '100px' }}
                  className={classes.dropdownToggle}
                  onClick={toggleDropdown}
                >
                  {ratingFilter || 'All'}
                </div>
                <ul id="dropdownMenu" className={classes.dropdownMenu}>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('')}
                  >
                    All
                  </li>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('1')}
                  >
                    1 Star
                  </li>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('2')}
                  >
                    2 Stars
                  </li>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('3')}
                  >
                    3 Stars
                  </li>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('4')}
                  >
                    4 Stars
                  </li>
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleDropdownItemClick('5')}
                  >
                    5 Stars
                  </li>
                </ul>
              </div>
            </div>

            <div className={classes.searchContainer}>
              <TextField
                className="input-tt"
                variant="filled"
                placeholder="Search..."
                value={productSearch}
                onChange={handleProductSearchChange}
              />

              <Button variant="contained" color="primary" className="btn-bg" onClick={handleSearch}>
                Search
              </Button>

            </div>

            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableHead className="tthead">
                  <TableRow>
                    <TableCell><Checkbox /></TableCell>
                    <TableCell><strong>Username</strong></TableCell>
                    <TableCell><strong>Rating</strong></TableCell>
                    <TableCell><strong>Review</strong></TableCell>
                    <TableCell><strong>Product Name</strong></TableCell>
                    <TableCell><strong>Date Of Review</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((feedback) => (
                    <TableRow key={feedback.id}>
                      <TableCell>
                        <input type="checkbox" />
                      </TableCell>
                      <TableCell>{feedback.username}</TableCell>
                      <TableCell>{feedback.rating}</TableCell>
                      <TableCell>{feedback.review}</TableCell>
                      <TableCell>{feedback.productName}</TableCell>
                      <TableCell>{feedback.date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default FeedbackPage;

