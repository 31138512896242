/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Sirodhara = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Sirodhara</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong> What is Sirodhara? </strong>
          </Typography>
          <Typography variant="body1">
          <p>Sirodhara is a more relaxed and therapeutic head massage experience that offers immense physical and mental benefits. So if you think a head massage is great, Sirodhara is even better. In this, a light massage is given at the end after dripping oil on the forehead. And yes, it is just as relaxing as it sounds.</p>
          <p>In simple terms, Sirodhara therapy can be described as pouring medicated oil over the forehead. Let's understand the procedure in detail.</p>
          <p>In this Ayurvedic therapy, the person receiving the treatment lies down on the back. Next, warm, medicated oil is poured over the forehead from a certain height in the next step.The oil then flows around the forehead and down into the scalp, which is then gently massaged into the skin by the practitioner.</p>
          <p>While a regular head massage may induce sleep and relaxation and strengthen the hair follicles, Sirodhara's benefits go above and beyond. It's a divine process that helps exfoliate dead skin, relieve stress, improve blood circulation, pacify elevated doshas and transport lymphatic fluids.</p>
          <p>When performed by an experienced practitioner, Sirodhara can help purge toxins, reduce mental fatigue and improve brain function.</p>
          <p>According to Ayurveda, Sirodhara treatment uses medicated oil, milk-based solution, herbal decoction, or aqueous formulation. It depends on the therapeutic benefits you seek.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Sirodhara: </strong>
          </Typography>
          <Typography variant="body1">
          
           <ul className='vw-ul'>
              <li><b> Fatigue</b><br />
              Shirodhara reduces fatigue and initiates a soothing effect on our brain cells. It is a purifying and rejuvenating therapy designed to eliminate toxins and mental exhaustion as well as relieve stress and any ill effects on the central nervous system.</li>
              <li><b> Beneficial In Sleep-Related Problems</b><br />
              The treatment has a soothing effect on the hypothalamus and helps regulate pituitary gland activity to induce sleep, thus treating conditions such as insomnia.</li>
              <li><b> Boosts Blood Circulation</b><br />
              The use of warm herbal oils in Shirodhara helps in vasodilation of the blood vessels, improving blood circulation to the brain.</li>
              <li><b> Helps Improve Headaches</b><br />
              In Ayurveda, due to stress or anxiety-related episodes, the Pitta dosha obstructs the flow of Vata dosha, consequently causing throbbing pain in the head. The soothing effect of these oils helps manage aggravated doshas and alleviates stress-induced headaches and migraines.</li>
              <li><b> Lowers Stress, Anxiety, And Depression</b><br />
              Shirodhara can help soothe the hypothalamus, which activates the regulation of the pituitary gland. It also works in reducing elevated serotonin levels and stress hormones such as noradrenaline and adrenaline, to effectively manage stress.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> What Are The Different Types Of Sirodhara? </strong>
          </Typography>
          <Typography variant="body1">
            
            <ul className='vw-ul'>
              <li><b>Tailadhara</b><br />
              In Tailadhara, Ayurvedic practitioners advise the use of herbal oils by mixing them with herbal extracts or different essential oils. This is beneficial for balancing the Vata dosha in the body.</li>
              <li><b>Takradhara</b><br />
              Takra Dhara uses buttermilk infused with special herbs. It is beneficial for balancing the Pitta dosha and helps maintain homeostasis, which is the body’s ability to maintain internal stability despite changes in external conditions.</li>
              <li><b>Ksheer Dhara</b> <br />
              Ksheer Dhara uses milk infused with herbs. The medicated milk is poured over the head and it is helpful in Vata and Pitta’s predominant headaches, stress, and anxiety, and helps nourish and relax the body.</li>
              <li><b>Kwathdhara</b> <br />
              Decoctions made with different herbs are used in Kwathdhara. It helps in balancing Vata and Kapha dosha and is considered therapeutic for various other conditions as well.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Why Is Sirodhara Practiced? </strong>
          </Typography>
          <Typography variant="body1">
            <strong>Some common risk factors for developing Sirodhara include:</strong>
            <p>Shirodhara relaxes the mind and helps to eliminate mental toxins (ama) by giving a purifying effect and balancing your doshas. It stimulates the vital points on your head, which help boost blood circulation. Massaging the head allows the oil to infuse deep into the scalp, imparting a comforting sensation, which further permeates into the internal peripheral nerves of the forehead.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fifth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> How Do You Perform Shirodhara?</strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>The Shirodhara treatment usually follows Abhyanga. The person lies down on the Shirodhara table with their eyes closed and covered.</li>
          <li>A stream of warm herbal decoction/oil is poured onto the person’s forehead. Vibrations are created on the forehead by the constant stream of oil. The oil penetrates deep down into the nervous system after saturating the forehead and scalp.  The oils used in this procedure improve blood circulation to the brain by the vasodilation of all the channels.</li>
          <li>The warm pressure and flow of oil help you to completely rejuvenate and relax. To maintain the rhythmic and continuous flow of oil over your entire forehead, the practitioner rotates the Dhara pot.
</li>
          <li>After finishing the Dhara, the patient is allowed to take rest for a few minutes, and then a hot water bath can be taken and the hair is cleaned out.</li>
          <li>The time and duration of Shirodhara treatment may extend from 30 to 60 minutes depending on the condition of the person. It is important to make sure that the oil does not enter your eyes or ears and is therefore recommended to be performed under the guidance of an experienced therapist.
</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Sirodhara;
