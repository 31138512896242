import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

export default function CartCount({ onStateChange, cartApiCount }) {
  const [state, setState] = useState(cartApiCount || 1);

  const handleIncrement = () => {
    const newState = state + 1;
    setState(newState);
    onStateChange(newState);
  };

  const handleDecrement = () => {
    if (state > 0) {
      const newState = state - 1;
      setState(newState);
      onStateChange(newState);
    }
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group" sx={{ borderRadius: '20px', border: '2px solid #eee' }}>
      <Button onClick={handleDecrement} sx={{ borderRadius: '20px', border: '2px solid #eee' }}>-</Button>
      <Button disabled>{state}</Button>
      <Button onClick={handleIncrement} sx={{ borderRadius: '20px', border: '2px solid #eee' }}>+</Button>
    </ButtonGroup>
  );
}

CartCount.propTypes = {
  onStateChange: PropTypes.func.isRequired,
  cartApiCount: PropTypes.number.isRequired
};