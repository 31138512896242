export const ALL_FEEDBACK_REQUESTING = 'ALL_FEEDBACK_REQUESTING';
export const ALL_FEEDBACK_SUCCESS = 'ALL_FEEDBACK_SUCCESS';
export const ALL_FEEDBACK_FAILURE = 'ALL_FEEDBACK_FAILURE';

export const LOGGED_IN_USER_FEEDBACKS_ID_REQUESTING = 'LOGGED_IN_USER_FEEDBACKS_ID_REQUESTING';
export const LOGGED_IN_USER_FEEDBACKS_ID_SUCCESS = 'LOGGED_IN_USER_FEEDBACKS_ID_SUCCESS';
export const LOGGED_IN_USER_FEEDBACKS_ID_FAILURE = 'LOGGED_IN_USER_FEEDBACKS_ID_FAILURE';

export const FEEDBACK_CREATE_REQUESTING = 'FEEDBACK_CREATE_REQUESTING';
export const FEEDBACK_CREATE_SUCCESS = 'FEEDBACK_CREATE_SUCCESS';
export const FEEDBACK_CREATE_FAILURE = 'FEEDBACK_CREATE_FAILURE';

export const FEEDBACK_GET_BY_ID_REQUESTING = 'FEEDBACK_GET_BY_ID_REQUESTING';
export const FEEDBACK_GET_BY_ID_SUCCESS = 'FEEDBACK_GET_BY_ID_SUCCESS';
export const FEEDBACK_GET_BY_ID_FAILURE = 'FEEDBACK_GET_BY_ID_FAILURE';

export const FEEDBACK_UPDATE_BY_ID_REQUESTING = 'FEEDBACK_UPDATE_BY_ID_REQUESTING';
export const FEEDBACK_UPDATE_BY_ID_SUCCESS = 'FEEDBACK_UPDATE_BY_ID_SUCCESS';
export const FEEDBACK_UPDATE_BY_ID_FAILURE = 'FEEDBACK_UPDATE_BY_ID_FAILURE';

export const FEEDBACK_UNSHOW_BY_ID_REQUESTING = 'FEEDBACK_UNSHOW_BY_ID_REQUESTING';
export const FEEDBACK_UNSHOW_BY_ID_SUCCESS = 'FEEDBACK_UNSHOW_BY_ID_SUCCESS';
export const FEEDBACK_UNSHOW_BY_ID_FAILURE = 'FEEDBACK_UNSHOW_BY_ID_FAILURE';

export const FEEDBACK_DELETE_BY_ID_REQUESTING = 'FEEDBACK_DELETE_BY_ID_REQUESTING';
export const FEEDBACK_DELETE_BY_ID_SUCCESS = 'FEEDBACK_DELETE_BY_ID_SUCCESS';
export const FEEDBACK_DELETE_BY_ID_FAILURE = 'FEEDBACK_DELETE_BY_ID_FAILURE';
