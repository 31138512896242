import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { Typography } from '@material-ui/core';

const data = [
  { name: 'Jan', Orders: 50 },
  { name: 'Feb', Orders: 30 },
  { name: 'Mar', Orders: 60 },
  { name: 'Apr', Orders: 20 },
  { name: 'May', Orders: 70 },
  { name: 'Jun', Orders: 40 },
  { name: 'Jul', Orders: 90 },
  { name: 'Aug', Orders: 80 },
  { name: 'Sep', Orders: 100 },
  { name: 'Oct', Orders: 120 },
  { name: 'Nov', Orders: 150 },
  { name: 'Dec', Orders: 180 },
];

const OrderChart = () => {
  return (
    <div>
      <Typography variant="h6"><strong>Order Chart</strong></Typography>
      <BarChart width={600} height={300} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Orders" fill="#0b8140" />
      </BarChart>
    </div>
  );
};

export default OrderChart;