import React from 'react';
import { Typography, Grid } from '@mui/material';

const BiliaryDisease = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Biliary Diseases </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Biliary Diseases? </strong>
        </Typography>
            <Typography variant="body1">
              Biliary disease refers to a group of disorders affecting the bile
              ducts, gallbladder, or liver. It encompasses conditions such as
              gallstones, cholangitis, biliary strictures, and primary biliary
              cirrhosis. Symptoms of biliary disease may include abdominal pain,
              jaundice (yellowing of the skin and eyes), fever, nausea,
              vomiting, pale stools, and dark urine. The causes of biliary
              disease vary depending on the specific condition. Gallstones are
              commonly caused by an imbalance in bile composition, while
              infections, autoimmune disorders, and certain genetic factors can
              contribute to other biliary diseases.
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Biliary Diseases </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Biliary Diseases include:
              <ul className='vw-ul'>
                <li> Jaundice (yellow tint to your skin and the whites of your eyes). </li>
                <li> Fatigue (extreme, constant weariness).</li>
                <li> Itchy skin (with biliary disease, substances in your blood, not on your skin, cause this). </li>
                <li> Fat deposits (small yellow or white bumps under your skin or around your eyes). </li>
                <li> Stomach pain (especially on the upper right side, where your liver is). </li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Biliary Diseases </strong>
        </Typography>
            <Typography variant="body1">
              Biliary Diseases can be caused by various factors including:
              <ul className='vw-ul'>
                <li>Heredity</li>
                <li>Increasing age</li>
                <li>Obesity</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Biliary Diseases </strong>
        </Typography>
            <Typography variant="body1">
              You are most likely to have gallbladder disease if you have
              gallstones. Risk factors include:
              <ul className='vw-ul'>
                <li>
                  <b>High cholesterol diet.</b> Cholesterol gallstones, made up
                  of extra cholesterol in the gallbladder, are the most common
                  type.
                </li>
                <li>
                  <b>Family history of gallbladder disease.</b> About 25% of
                  gallbladder diseases may be hereditary. Scientists have
                  identified as many as twelve different genes that may be
                  involved.
                </li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              Not all risk factors can be managed or modified, but you can
              optimize your Biliary Disease by maintaining a healthy diet and
              lifestyle. The modern Western diet is high in fats and cholesterol
              and low in fiber, which helps clear excess fats from the body.
              Make an effort to reduce fatty processed foods and eat more
              fiber-rich plants. Regular exercise can also help reduce your risk
              by encouraging your motility — the regular contractions of your
              digestive organs.
            </Typography>
      
        </Grid>
      </Grid>
      </div>
      </>
  );
};

export default BiliaryDisease;
