/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Endometriosis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Endometriosis </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Endometriosis? </strong>
        </Typography>
            <Typography variant="body1">
            Endometriosis is a chronic condition in which tissue similar to the lining of the uterus
(endometrium) grows outside the uterus, commonly in the pelvic area. Symptoms of
endometriosis include pelvic pain, painful periods, heavy menstrual bleeding, pain during
intercourse, and infertility. The exact cause of endometriosis is unknown, but theories
include retrograde menstruation (backflow of menstrual blood), hormonal imbalances,
genetic factors, and immune system disorders. The displaced endometrial tissue responds
to hormonal changes during the menstrual cycle, leading to inflammation, scarring, and
adhesions. </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Symptoms of Endometriosis </strong>
        </Typography>
            <Typography variant="body1">
            Endometriosis often causes severe pain in the pelvis, especially during menstrual periods. Some people also have pain during sex or when using the bathroom. Some people have trouble getting pregnant.
            Some people with endometriosis don’t have any symptoms. For those who do, a common symptom is pain in the lower part of the belly (pelvis). Pain may be most noticeable:  
            <ul className='vw-ul'>
                <li>Very painful menstrual cramps.</li>
                <li>Abdominal pain or back pain during your period or in between periods.</li>
                <li>Pain during sex.</li>
                <li>Heavy bleeding during periods or spotting (light bleeding) between periods.</li>
                <li>Infertility (difficulty becoming pregnant).</li>
                <li>Painful bowel movements.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Causes of Endometriosis </strong>
        </Typography>
            <Typography variant="body1">
              Endometriosis can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Retrograde menstruation is when menstrual blood containing endometrial cells flows back through the fallopian tubes and into the pelvic cavity at the time that blood is flowing out of the body through the cervix and vagina during periods. Retrograde menstruation can result in endometrial-like cells being deposited outside the uterus where they can implant and grow.</li>
                <li>Cellular metaplasia is when cells change from one form to another. Cells outside the uterus change into endometrial-like cells and start to grow.</li>
                <li>Exposure to irritants like cigarette smoke</li>
                <li>Stem cells can give rise to the disease, which then spreads through the body via blood and lymphatic vessels. </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Endometriosis</strong>
        </Typography>
            <Typography variant="body1">
            There are some factors that can place you at a higher risk of developing endometriosis. These factors can include:
            <ul className='vw-ul'>
                <li>Family history of endometriosis.</li>
                <li>The age you first start having periods. People who begin menstruating before age 11 may be at a higher risk.</li>
                <li>The length of your menstrual cycle (shorter time between periods) and the duration of flow (how many days of bleeding). </li>
                <li>Defects in your uterus or fallopian tubes.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
        </Typography>
            <Typography variant="body1">
              Endometriosis isn’t a condition you can necessarily prevent.There are certain factors that can reduce your risk of developing the condition, but in some cases, you may still have endometriosis.
              A few factors that can reduce your risk of endometriosis include:  
              <ul className='vw-ul'>
                <li>Pregnancy.</li>
                <li>Breastfeeding.</li>
                <li>Maintaining a weight that's healthy for you.</li>
                <li>Starting your menstrual period at a later age.</li>
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Endometriosis;
