/* eslint-disable no-undef */
// reducers/appointmentReducer.js
import * as type from "../types";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_APPOINTMENT_REQUESTING:
      console.log("reducer appointment");
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case type.CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case type.CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default appointmentReducer;
