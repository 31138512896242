export const ALL_CATEGORIES_REQUESTING = 'ALL_CATEGORIES_REQUESTING';
export const ALL_CATEGORIES_SUCCESS = 'ALL_CATEGORIES_SUCCESS';
export const ALL_CATEGORIES_FAILURE = 'ALL_CATEGORIES_FAILURE';

export const GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_REQUESTING = 'GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_REQUESTING';
export const GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_SUCCESS = 'GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_SUCCESS';
export const GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_FAILURE = 'GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_FAILURE';

export const LOGGED_IN_USER_CATEGORIES_ID_REQUESTING = 'LOGGED_IN_USER_CATEGORIES_ID_REQUESTING';
export const LOGGED_IN_USER_CATEGORIES_ID_SUCCESS = 'LOGGED_IN_USER_CATEGORIES_ID_SUCCESS';
export const LOGGED_IN_USER_CATEGORIES_ID_FAILURE = 'LOGGED_IN_USER_CATEGORIES_ID_FAILURE';

export const CATEGORIES_GET_BY_ID_REQUESTING = 'CATEGORIES_GET_BY_ID_REQUESTING';
export const CATEGORIES_GET_BY_ID_SUCCESS = 'CATEGORIES_GET_BY_ID_SUCCESS';
export const CATEGORIES_GET_BY_ID_FAILURE = 'CATEGORIES_GET_BY_ID_FAILURE';

export const CATEGORIES_CREATE_REQUESTING = 'CATEGORIES_CREATE_REQUESTING';
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS';
export const CATEGORIES_CREATE_FAILURE = 'CATEGORIES_CREATE_FAILURE';

export const CATEGORIES_UPDATE_BY_ID_REQUESTING = 'CATEGORIES_UPDATE_BY_ID_REQUESTING';
export const CATEGORIES_UPDATE_BY_ID_SUCCESS = 'CATEGORIES_UPDATE_BY_ID_SUCCESS';
export const CATEGORIES_UPDATE_BY_ID_FAILURE = 'CATEGORIES_UPDATE_BY_ID_FAILURE';

export const CATEGORIES_UNSHOW_BY_ID_REQUESTING = 'CATEGORIES_UNSHOW_BY_ID_REQUESTING';
export const CATEGORIES_UNSHOW_BY_ID_SUCCESS = 'CATEGORIES_UNSHOW_BY_ID_SUCCESS';
export const CATEGORIES_UNSHOW_BY_ID_FAILURE = 'CATEGORIES_UNSHOW_BY_ID_FAILURE';

export const CATEGORIES_DELETE_BY_ID_REQUESTING = 'CATEGORIES_DELETE_BY_ID_REQUESTING';
export const CATEGORIES_DELETE_BY_ID_SUCCESS = 'CATEGORIES_DELETE_BY_ID_SUCCESS';
export const CATEGORIES_DELETE_BY_ID_FAILURE = 'CATEGORIES_DELETE_BY_ID_FAILURE';

export const FIND_MOST_SOLD_CATEGORY_REQUESTING = 'FIND_MOST_SOLD_CATEGORY_REQUESTING';
export const FIND_MOST_SOLD_CATEGORY_SUCCESS = 'FIND_MOST_SOLD_CATEGORY_SUCCESS';
export const FIND_MOST_SOLD_CATEGORY_FAILURE = 'FIND_MOST_SOLD_CATEGORY_FAILURE';

export const FIND_TOP_CATEGORIES_SOLD_REQUESTING = 'FIND_TOP_CATEGORIES_SOLD_REQUESTING';
export const FIND_TOP_CATEGORIES_SOLD_SUCCESS = 'FIND_TOP_CATEGORIES_SOLD_SUCCESS';
export const FIND_TOP_CATEGORIES_SOLD_FAILURE = 'FIND_TOP_CATEGORIES_SOLD_FAILURE';
