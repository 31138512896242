import React from 'react';

import { Typography } from '@mui/material';

 

const PrivacyPolicy = () => {

  return (
      <>
      <div className="inner-container"><h2 className="dnt-heading">Privacy Policy</h2></div>
      <br />
      <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Typography variant="body1">
        Effective Date: 19/09/2023
      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>1. Introduction:</strong>

        <br /><br />

        Vedanta Wellness is committed to safeguarding the privacy of our patients and visitors. This Privacy Policy explains how we collect, use, disclose, and protect your personal and health information when you engage with our clinic.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>2. Information We Collect:</strong>

        <br /><br />

        <strong>2.1. Personal Information:</strong> We may collect personal information, such as your name, contact information, date of birth, and other details necessary for providing our services.

        <br /><br />

        <strong>2.2. Health Information:</strong> To provide you with Ayurvedic consultations and treatments, we may collect health-related information, including medical history, symptoms, and treatment plans.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>3. How We Use Your Information:</strong>

        <br /><br />

        3.1. We use the information collected to:
        <br /><br />
        <ul>

          <li>Provide Ayurvedic consultations, therapies, and treatments.</li>

          <li>Personalize treatment plans based on your health history.</li>

          <li>Communicate with you about appointments and treatment updates.</li>

          <li>Maintain patient records as required by law.</li>

          <li>Comply with legal and regulatory obligations.</li>

          <li>Use as reference for future Medical Cases.</li>

        </ul>

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>4. Disclosure of Your Information:</strong>

        <br /><br />

        4.1. We do not sell or rent your personal or health information to third parties.

        <br /><br />

        4.2. We may share your information with:
        <br /><br />
        <ul>

          <li>Our authorized staff members involved in your treatment.</li>

          <li>Healthcare professionals, as needed for your care.</li>

          <li>Legal authorities when required by law or to protect our legal rights.</li>

        </ul>

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>5. Data Security:</strong>

        <br /><br />

        We employ reasonable security measures to protect your information against unauthorized access, disclosure, alteration, or destruction.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>6. Retention of Information:</strong>

        <br /><br />

        We retain your personal and health information for the duration necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>7. Your Rights:</strong>

        <br /><br />

        You have the following rights regarding your personal information:
        <br /><br />
        <ul>

          <li>The right to access your data.</li>

          <li>The right to rectify inaccuracies in your data.</li>

          <li>The right to request deletion of your data, subject to legal obligations.</li>

          <li>The right to object to the processing of your data.</li>

          <li>The right to data portability, if applicable.</li>

        </ul>

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>8. Cookies and Tracking Technologies:</strong>

        <br /><br />

We may use cookies and similar technologies on our website to enhance your browsing experience. You can manage your cookie preferences through your browser settings.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

      <strong>9. Changes to this Privacy Policy:</strong>

<br /><br />

We may update this Privacy Policy from time to time. Any changes will be posted on our website with the updated &quot;Last Updated&quot; date.

 

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>10. Contact Information:</strong>

        <br /><br />

        If you have questions or concerns about our Privacy Policy or how we handle your information, please contact us at cs@vedanta-wellness.com.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        By engaging with Vedanta Wellness or our website, you consent to the practices outlined in this Privacy Policy.

      </Typography>

    </div>
</ >
  );

};

 

export default PrivacyPolicy;
