/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import '../css/Login.css';
import { loginRequest } from '../../src/redux/actions/userActions';
import { cartCreateOrUpdateCartAction } from '../redux/actions/cartActions';
import secureLocalStorage from 'react-secure-storage';

// eslint-disable-next-line react/prop-types
const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className="error-message">
      <p>{errorMessage}</p>
    </div>
  );
};

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('');
  // const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    await dispatch(loginRequest(userData));
  };
  console.log('inside ouside', users);

  useEffect(() => {
    console.log('inside USEEFFECT', users);
    // dispatch(hideModal({ data: "dfdjfhd" }));
    const willManageLoginAndCartFromLocalStorage = async () => {
      if (secureLocalStorage.getItem('token')) {
        if (secureLocalStorage.getItem('guestCart') && secureLocalStorage.getItem('guestCart') !== null) {

          const cartPayload = {
            Role: secureLocalStorage.getItem('Role'),
            userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
            email: JSON.parse(secureLocalStorage.getItem('users')).email,
            cart: JSON.parse(secureLocalStorage.getItem('guestCart'))
          };

          console.log('cartPayload', cartPayload);

          await dispatch(cartCreateOrUpdateCartAction(cartPayload));
          secureLocalStorage.removeItem('guestCart');
        }
        if (secureLocalStorage.getItem('Role') === 'Admin') {
          navigate('/Home');
        } else {
          navigate('/dashboard');
        }
      }
    };

    willManageLoginAndCartFromLocalStorage();

    // const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    //   if(secureLocalStorage.getItem('user').test(uuidPattern)) {

    //   }

    // if (users.error) {
    //   setErrorMessage("Invalid Email or Password");
    // }
    // if (users.data && users.data.token) {
    //   secureLocalStorage.setItem("token", users.data.token);
    //   console.log("User data:", users.data);
    // }
  }, [users]);

  return (
    <div>
      <div className="us-container">
        <h1 className="us-header">Login</h1>
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <Divider />
      <Grid className="form-box frm-txt frm-txt-vw">
        <form onSubmit={handleSubmit}>
          <div className="lg-txt">
            {/* <label className="form-label">User Name or Email Address</label> */}
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className="form-login form-login-vw"
              id="exampleFormControlInput1"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="lg-txt">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-login form-login-vw"
              id="exampleFormControlInput1"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {users.loader && <CircularProgress disableShrink />}
          <Grid className="label-signin">
            <div className="lg-txt">
              <label className="form-label"> </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              Keep me signed in
            </div>
          </Grid>

          <Grid className="btn-grp">
            <div className="lg-txt">
              <label></label>
              <button type="submit" className="lg-btn">
                Login
              </button>
              <button className="rsg-btn" onClick={() => navigate('/membership')}>
                Register
              </button>
            </div>
          </Grid>
        </form>
        <Grid display="block" sx={{ marginTop: '1%' }}>
          <div className="lg-txt">
            <label></label>
            <Link to="/Forgot">
              <p className="forgot-p" onClick={() => navigate('/Forgot')}>
                Forgot your Password
              </p>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
