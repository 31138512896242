import React from 'react';
import PropTypes from 'prop-types';
import '../../../css/AccountSuccess.css';

const CheckmarkIcon = () => (
  <svg width="600" height="100" viewBox="0 0 200 200">
    <circle
      fill="none"
      stroke="#ffffff"
      strokeWidth="5"
      strokeMiterlimit="10"
      cx="100"
      cy="100"
      r="60"
    />
    <text
      x="100"
      y="100"
      textAnchor="middle"
      dominantBaseline="central"
      fill="#ffffff"
      fontSize="70"
    >
      &#10003;
    </text>
  </svg>
);

const Modal = ({ show, redirectHandler }) => {
  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
          opacity: show ? '1' : '0',
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="btn-container">
              <div className="icon_message">
                <CheckmarkIcon />
                <p className="icon_msg">Success</p>
              </div>
            </div>
          </div>
          <div className="btn-container">
            Congratulation your account has been successfully created
          </div>
          <div className="btn-container">
            <div className="btn-signup" onClick={redirectHandler}>
              Continue Signing In
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  redirectHandler: PropTypes.func.isRequired,
};

export default Modal;
