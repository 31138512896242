/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const SarvangaDhara  = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">SarvangaDhara </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is SarvangaDhara ? </strong>
          </Typography>
          <Typography variant="body1">
          <p>In Sarvangadhara, warm medicated oil is systematically massaged on all parts of the body as the patient lies on a Droni (Ayurvedic therapy table). Ideally, five attendants are required for performing the therapy. Using kindis, or brass spouted pitchers, four practitioners simultaneously pour the oil, and massage the patient from head to toe. One attendant regularly replenishes the oil</p>
          <p>Ksheeradhara is a form of Sarvangadhara in which milk is poured along with the herbal oil. The treatment should be continued uninterrupted for a specific amount of time. During the post-therapy bath, green gram paste and herbal shampoo are used to wash away the oil. </p>
          <p>The major benefit of this therapy is that it soothes the frayed nerve ends, relieves stress, promotes circulation, relaxes muscle and induces sleep. It also activates the five energy centres of the spine and thereby raises the energy. It also improves physical, sexual, spiritual and mental abilities. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of SarvangaDhara </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>    
              <li>Our Procedure</li>
              <li>Completely rejuvenates the body</li>
              <li>Helpful for nervous disorders</li>
              <li>Prevents ageing symptoms & wrinkles</li>
              <li>Helps bring a lighter feeling to the body</li>
              <li>Clears all physical channels and ducts</li>
              <li>Retune the entire muscular system</li>
              <li>Calms the body down from stress and anxiety and relieves the tension it creates in the muscles </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>What Are The  Procedure Of SarvangaDhara ?</strong>
          </Typography>
          <Typography variant="body1">
          <p>Sarvanga dhara is one of the purvakarma treatments in Panchkarma. It actually rejuvenates and revitalises the therapy. In this process, the heated medicated oil is poured all over the body and massage is also done simultaneously. This treatment is ideal for both body and mind since it refreshes and gives relaxation. It is done for 45 to 60 minutes per day for a period varying from 7 days to 21 days. The people who undergo this process feel light, thought free and blissful during the treatment. </p>
          <p>The meaning of the word Pizhichil is squeezing and the procedure involves squeezing of the warm and heated oil from a cloth.</p>
          <p>The procedure includes head massage followed by body massage with medicated oil, to avoid complication like giddiness thalam should be applied on head. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Why Is SarvangaDhara Practiced ? </strong>
          </Typography>
          <Typography variant="body1">
            SarvangaDhara is Practiced, in following condition:
            <ul className='vw-ul'>
              <li>Nuro muscular diseases</li>
              <li>Paralysis </li>
              <li>Emaciation </li>
              <li>Skin diseases </li>
              <li>Relieves the body stiffness</li>
              <li>Muscle spasm</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fifth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>How Do You Perform SarvangaDhara ?</strong>
          </Typography>
          <Typography variant="body1">
            <ul className='vw-ul'>
              <li>Pizhichil is a form of seka and its one form of swedana, and this Sweda karma rectifies the function of medodhathvagni and bhoothagni and fastens the pakakarma, which causes srothomukha shodhana and causes profuse sweda.</li>
              <li>Hence the exudates will comes out of body leads to pain relieve and relaxes the muscular spasm etc. </li>
              <li>By swedana due to arterial dilatation the effective portion will get more circulation.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default SarvangaDhara ;
