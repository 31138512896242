import * as type from '../actionTypes/orderActionTypes';

export const ordersAction = (payload) => {
  return {
    type: type.ALL_ORDER_REQUESTING,
    payload
  };
};
export const ordersCreateAction = (payload) => {
  return {
    type: type.ORDER_CREATE_REQUESTING,
    payload
  };
};
export const ordersGetByAction = (payload) => {
  return {
    type: type.ORDER_GET_BY_ID_REQUESTING,
    payload
  };
};
export const ordersUpdateByAction = (payload) => {
  return {
    type: type.ORDER_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const ordersUnshowByAction = (payload) => {
  return {
    type: type.ORDER_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const ordersDeleteByAction = (payload) => {
  return {
    type: type.ORDER_DELETE_BY_ID_REQUESTING,
    payload
  };
};

export const ordersWithSalesAction = (payload) => {
  return {
    type: type.ORDER_WITH_SALES_REQUESTING,
    payload
  };
};


export const totalSalesComparedToPreviousMonthAction = (payload) => {
  return {
    type: type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING,
    payload
  };
};
export const netSalesComparedToPreviousMonthAction = (payload) => {
  return {
    type: type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING,
    payload
  };
};
export const currentMonthOrdersVsPreviousMonthAction = (payload) => {
  return {
    type: type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_REQUESTING,
    payload
  };
};
export const salesChartApiForPastYearAction = (payload) => {
  return {
    type: type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_REQUESTING,
    payload
  };
};
export const noOfOrdersChartApiForPastYearAction = (payload) => {
  return {
    type: type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_REQUESTING,
    payload
  };
};
