/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Januvasti = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Januvasti</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong> What is Januvasti ? </strong>
          </Typography>
          <Typography variant="body1">
          <p> Januvasti is one of the most effective and time-tested Ayurvedic treatment for chronic knee pain, stiffness, inflammation and injury. The treatment involves retaining pain relieving, warm ayurvedic oils over the affected area for a duration of time using gram flour, followed by gentle massage or Abhyanga. The ayurvedic oils and medicines for the treatment will be selected based on the patient and the condition. </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Januvasti </strong>
          </Typography>
          <Typography variant="body1">
            <ul className='vw-ul'>
              <li>
                The relaxing and healing effect of Janu Vasti reduces pain and
                stiffness by nourishing the structures of the knee joint.
              </li>
              <li>
                It soothes the nerves supplying the knee joint, and strengthens
                the muscles, tendon and ligaments of the region.
              </li>
              <li>This therapy also enhances local blood circulation.</li>

              <li>
                It improves flexibility and range of motion of the knee joint
                and reduces both, the intensity and frequency of knee pain.
              </li>
              <li>
                Janu Vasti is also advisable in people who have occupations
                involving standing for long hours, sports persons, athletes,
                etc.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> What Are The Medical Indications Of Janu Basti </strong>
          </Typography>
          <Typography variant="body1">
          <p>The remedial therapy of Janu Basti can be indicated in the following medical conditions:</p>
            <ul className='vw-ul'>
              <li>Osteoarthritis of the knee joint</li>
              <li>Knee bursitis</li>
              <li>Arthrosis of knee joints</li>
              <li>Osteoporosis,Arthritis changes,Ligament tear,Rheumatism</li>
              <li>Subluxation and Chronic pain of knee joint</li>
              <li>Ilio-tibial band syndrome</li>
              <li>Strains and sprains</li>
              <li>Post-trauma</li>
              <li>Degenerative disorder of knee joint</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> What Are The Procedure Of Januvasti ? </strong>
          </Typography>
          <Typography variant="body1">
          <p>Steps Of Janu Vasti:</p>
            <p>
              Start the therapy by making the patient lie down in a supine
              position
            </p>
            <p>
              Construct a reservoir or pocket-like structure on the knee (the
              compartment is made using a dough prepared from wet black gram
              flour. This reservoir mainly functions by holding the medicated
              herbal oil in place for a specific period)
            </p>
            <p>
              Select the required oil or a combination of various medicated oils
              to be poured into the reservoir
            </p>
            <p>
              After proper selection depending upon the condition and
              therapeutic effects of the oil, warm it up in a hot water bath
            </p>
            <p>
              Pour the medicated luke-warm oil into the Janu Sandhi (knee joint)
            </p>
            <p>
              Maintain the temperature of the poured oil using a dryer or
              periodically removing the oil and replacing it with warm oil
            </p>
            <p>
              Keep the oil in the reservoir depending upon the stringency of the
              disease and the bio-energies of the oils used
            </p>
            <p>
              After the required time, empty the oil from the compartment by
              piercing and draining it
            </p>
            <p>
              Perform an Abhyanga using herbal massage oils for some time for
              added overall relaxation and benefit
            </p>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Januvasti;
