/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../css/Reset.css';

export default function Reset() {
  const { resetToken } = useParams(); // extract the reset token from the URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      password: password,
      reset_token: resetToken, // pass the reset token to the API call
    };

    fetch('http://localhost:5000/api/users/reset_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          navigate('/success');
        }
      })
      .catch((error) => {
        console.error('Error resetting password:', error);
      });
  };

  return (
    <div>
      <h1 className="reset-header">Reset Password</h1>
      <Divider />
      <Grid className="form-box">
        <label className="form-label"> Please Enter Your New password</label>
        <input
          type="password"
          className="form-reset"
          id="exampleFormControlInput1"
          placeholder=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <label className="form-label"> Confirm Password</label>
        <input
          type="password"
          className="form-reset"
          id="exampleFormControlInput1"
          placeholder=""
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <Grid className="btn-grp">
          <button className="lg-btn" onClick={handleSubmit}>
            Submit
          </button>
        </Grid>
      </Grid>
    </div>
  );
}
