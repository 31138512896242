export const USERS_LOGIN_REQUESTING = "USERS_LOGIN_REQUESTING";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const USERS_LOGIN_FAILURE = "USERS_LOGIN_FAILURE";

export const USERS_CREATE_REQUESTING = "USERS_CREATE_REQUESTING ";
export const USERS_CREATE_SUCCESS = "USERS_CREATE_SUCCESS";
export const USERS_CREATE_FAILURE = "USERS_CREATE_FAILURE";

export const HIDE_MODAL = "HIDE_MODAL";

// new

export const ALL_USERS_REQUESTING = "ALL_USERS_REQUESTING";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAILURE = "ALL_USERS_FAILURE";

export const USERS_GET_BY_ID_REQUESTING = "USERS_GET_BY_ID_REQUESTING";
export const USERS_GET_BY_ID_SUCCESS = "USERS_GET_BY_ID_SUCCESS";
export const USERS_GET_BY_ID_FAILURE = "USERS_GET_BY_ID_FAILURE";

export const USERS_UPDATE_BY_ID_REQUESTING = "USERS_UPDATE_BY_ID_REQUESTING";
export const USERS_UPDATE_BY_ID_SUCCESS = "USERS_UPDATE_BY_ID_SUCCESS";
export const USERS_UPDATE_BY_ID_FAILURE = "USERS_UPDATE_BY_ID_FAILURE";

export const USERS_UNSHOW_BY_ID_REQUESTING = "USERS_UNSHOW_BY_ID_REQUESTING";
export const USERS_UNSHOW_BY_ID_SUCCESS = "USERS_UNSHOW_BY_ID_SUCCESS";
export const USERS_UNSHOW_BY_ID_FAILURE = "USERS_UNSHOW_BY_ID_FAILURE";

export const USERS_DELETE_USER_BY_ID_REQUESTING =
  "USERS_DELETE_USER_BY_ID_REQUESTING";
export const USERS_DELETE_USER_BY_ID_SUCCESS =
  "USERS_DELETE_USER_BY_ID_SUCCESS";
export const USERS_DELETE_USER_BY_ID_FAILURE =
  "USERS_DELETE_USER_BY_ID_FAILURE";


