import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const data = [
  { name: 'Jan', sales: 3000 },
  { name: 'Feb', sales: 4000 },
  { name: 'Mar', sales: 2000 },
  { name: 'Apr', sales: 5000 },
  { name: 'May', sales: 7000 },
  { name: 'Jun', sales: 6000 },
  { name: 'Jul', sales: 8000 },
  { name: 'Aug', sales: 9000 },
  { name: 'Sep', sales: 10000 },
  { name: 'Oct', sales: 12000 },
  { name: 'Nov', sales: 15000 },
  { name: 'Dec', sales: 18000 },
];

const useStyles = makeStyles((theme) => ({
  chart: {
    marginTop: theme.spacing(2),
  },
}));

const SalesChart = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6"><strong>Sales Chart</strong></Typography>
      <LineChart
        className={classes.chart}
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="sales"
          stroke="#0b8140"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
};

export default SalesChart;