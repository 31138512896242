// src/components/FAQPage.js
import React from 'react';
import { Typography, Divider } from '@mui/material';

const Faq = () => {
  const faqData = [
    {
      question: '1. Can I do yoga every day during treatment?',
      answer: 'Ayurvedic treatment advocates a softer version of yoga during Panchakarma. It is ideal for reducing physical and mental stress as well as to quiet the mind.',
    },
    {
      question: '2. Why are heavy workouts prohibited during the treatment period?',
      answer: 'Mental Stress and Exercise are avoided during treatment to prevent exhaustion.',
    },
    {
      question: 'Can I undergo all 5 Panchakarma?',
      answer: 'All adults (18 - 70 years) саn take Panchakarma treatment. During change of season, 2-3 complete panchakarma sessions in a year is recommended. 21 days are required for complete treatment as per patient and doctor consultation.',
    },
    {
      question: '4. Is there a delay in healing ayurvedic treatment?',
      answer: 'Ayurvedic medicines have positive results within 3 days, followed by proper pathya apathya.',
    },
    {
      question: '5. Are there any side effects of Ayurvedic medicine?',
      answer: 'Ayurvedic medicines use natural herbs, extracts and plants. The herbs and plants used have minimal side effects if made by an Ayurvedic physician',
    },
    {
      question: '6. Is there a cure for lifestyle ailments in Ayurveda?',
      answer: 'Yes. Ayurveda with a holistic approach provides the ultimate solution in the form of healthy lifestyle approaches, proper dietary management, detoxifications and rejuvenation to get rid of various lifestyle disorders.',
    },
    {
      question: '7. Why is Ayurveda Important?',
      answer: 'Ayurveda is one of the oldest systems which comprise thousands of medical concepts and hypotheses to treat many chronic diseases which are untreatable in modern medicines such as Cancer, Diabetic arthritis & Asthma.',
    },
    {
      question: '8. What is Panchakarma detox?',
      answer: '5-step of Panchakarma: Vaman, Virechan, Vasti, Nasya and Raktamokshan are followed to complete detoxification and encourage healthy body and mind to promote general wellbeing.',
    },
    {
      question: '9. Can one take Ayurveda medicine with modern medicines?',
      answer: 'Yes. You can take ayurvedic medicine with allopathic medicine. Maintain a 30 minute to 1 hour gap between taking medicines.',
    },
    {
      question: '10. How is Ayurveda treatment approach different from modern Medicare?',
      answer: 'Ayurvedic medicine is a holistic approach to treatment. Herbs and plants used in Ayurveda have minimal or no side effects if used by an Ayurvedic physician. Allopathic medicines are synthesised in Labs and have side effects ranging from mild to severe.',
    },
    {
      question: '11. Do we need to follow a diet when undergoing Ayurvedic treatment?',
      answer: 'In Ayurveda, diet forms one of the most important components of life and is included in tri-upastambha (three pillars of life). To Keep homeostasis of doshas ayurveda has described specific foods and diet.',
    },
  ];

  return (
<>
<div className="inner-container"><h2 className="dnt-heading">Frequently Asked Questions (FAQs)</h2></div>
<div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      {faqData.map((faqItem, index) => (

        <div key={index}>
          <Typography variant="h6" style={{ marginTop: '15px'}}>{faqItem.question}</Typography>
          <Typography variant="body1" style={{ marginTop: '8px', paddingBottom: '15px' }}>{faqItem.answer}</Typography>
          <Divider />
        </div>
      ))}
    </div>
    </>
  );
};

export default Faq;
