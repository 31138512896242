import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const ChronicConstipation = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Chronic Constipation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            What is Chronic Constipation ?
          </Typography>
          <Typography variant="body1">
            Chronic constipation is a persistent condition characterized by
            infrequent bowel movements, difficulty passing stools, and hard, dry
            stools. Symptoms may include straining during bowel movements, a
            feeling of incomplete evacuation, abdominal bloating, and
            discomfort. Causes of chronic constipation can be multifactorial,
            including lifestyle factors such as low fiber diet, inadequate fluid
            intake, lack of exercise, and certain medications. Other underlying
            conditions, such as irritable bowel syndrome (IBS), thyroid
            disorders, or neurological disorders, can contribute to chronic
            constipation.{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Symptoms of Chronic Constipation
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Chronic Constipation include:
            <ul>
              <li>You have fewer than three bowel movements a week.</li>
              <li>Your stools are dry, hard and/or lumpy.</li>
              <li>Your stools are difficult or painful to pass.</li>
              <li>You have a stomachache or cramps.</li>
              <li>You feel bloated and nauseated.</li>
              <li>
                You feel that you haven’t completely emptied your bowels after a
                movement.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Causes of Chronic Constipation
          </Typography>
          <Typography variant="body1">
            Chronic Constipation can be caused by various factors, including:
            <ul>
              <li>
                <b>Lifestyle factors:</b>Not eating enough fiber., Not drinking
                enough water (dehydration)., Not getting enough exercise.
              </li>
              <li>
                <b>Medications:</b>Many drugs can cause constipation.
              </li>
              <li>
                <b>Medical conditions:</b>Medical and health conditions that can
                cause constipation
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Risk Factors for Chronic Constipation
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Chronic Constipation
            include:
            <ul>
              <li>
                People older than 65 are often less active, have a slower
                metabolism and have less muscle contraction strength along their
                digestive tract than when they were younger.{' '}
              </li>
              <li>
                Being assigned female at birth, especially constipation during
                pregnancy and after childbirth. Changes in your hormones may
                make you more prone to constipation. The fetus inside your
                uterus may squish your intestines, slowing down the passage of
                stool.
              </li>
              <li>
                Not eating enough high-fiber foods. Fiber keeps food moving
                through your digestive system.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Prevention and Management
          </Typography>
          <Typography variant="body1">
            While Chronic Constipation cannot always be prevented, some
            strategies that may help manage the symptoms include:
            <ul>
              <li>
                Drink two to four extra glasses of water a day. Avoid
                caffeine-containing drinks and alcohol, which can cause
                dehydration. Also, avoid juice and high-sweetened beverages.
              </li>
              <li>
                Avoid processed meats, fried foods and refined carbs like white
                bread, pasta and potatoes. It’s OK to eat lean meats like
                poultry and low-fat dairy products.
              </li>
              <li>
                Add fruits, vegetables, whole grains and other high-fiber foods
                to your daily diet. Eat fewer high-fat foods, like meat, eggs
                and cheese.
              </li>
              <li>Get moving — exercise.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChronicConstipation;
