/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import '../css/Blogs.css';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActions, Grid } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Helmet } from 'react-helmet';

export default function Blogs() {
  const [blogConstant, setBlogConstant] = useState([]);
  useEffect(() => {
    setBlogConstant([
      {
        imageUrl: '/images/ayurvedic.png', // Absolute path
        blogTitle: 'The Ayurvedic Art of Anti - Ageing',
        cardDesc: 'Decs',
        blog: '"Head, shoulders, knees, and toes." That’s how people learned to identify the various parts of the body at a young age...',
        nextPageLink: '/the-ayurvedic-art-of-anti-ageing',
      },

      {
        imageUrl: '/images/kapadosha.png', // Absolute path
        blogTitle: 'Kapha Dosha and Type 2 Diabetes',
        cardDesc: 'Decs',
        blog: 'Ayurveda, which originated in ancient India, is a standard healing practice with origins dating back to 1000 BC...',
        nextPageLink: '/kapha-dosha-and-type-2-diabetes',
      },

      {
        imageUrl: '/images/breathingout.png', // Absolute path
        blogTitle: 'Breathing out Pollution with Ayurveda',
        cardDesc: 'Decs',
        blog: 'Neti and nasya are the Ayurvedic practices of cleansing the nasal passages. Neti is rinsing out the nostrils with salt water...',
        nextPageLink: '/breathing-out-pollution-with-ayurveda',
      },
       
      {
        imageUrl: '/images/blog-img-4.png', // Absolute path
        blogTitle: 'Ayurvedas Role in Modern Healthcare',
        cardDesc: 'Decs',
        blog: 'In todays fast-paced world, marked by hectic lifestyles and unhealthy habits, the global population faces a growing burden of lifestyle-related diseases.',
        nextPageLink: '/ayurveda-role-in-modern-healthcare',
      },
      {
        imageUrl: '/images/blog-img-5.png', // Absolute path
        blogTitle: 'Hypertension Solutions with Ayurveda',
        cardDesc: 'Decs',
        blog: 'In todays fast-paced world, with its unhealthy lifestyles and dietary habits, lifestyle diseases like hypertension have become increasingly common.',
        nextPageLink: '/hypertension-solutions-with-ayurveda-at-vedanta-wellness',
      },
      {
        imageUrl: '/images/blog-img-6.png', // Absolute path
        blogTitle: 'Joint Pain Treatment with Ayurveda',
        cardDesc: 'Decs',
        blog: 'Life can be challenging when joint pain becomes a constant companion. Whether due to age, lifestyle factors, or underlying health conditions.',
        nextPageLink: '/rediscover-comfort-with-ayurvedic-joint-pain-treatment-at-vedanta-wellness',
      },
      {
        imageUrl: '/images/blog-img-7.png', // Absolute path
        blogTitle: 'Ayurvedic Treatment for Low Blood Pressure',
        cardDesc: 'Decs',
        blog: 'In todays fast-paced world, the timeless wisdom of Ayurveda is gaining recognition for its holistic approach to health.',
        nextPageLink: '/ayurvedic-treatment-for-low-blood-pressure-at-vedanta-wellness',
      },

    ]);
  }, []);

  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Blogs - Ayurvedic Healthcare Center | Vedanta-wellness.com </title>
    <meta name="description" content="Explore Ayurvedic wellness at Vedanta Healthcare Center through insightful blogs on natural health tips and holistic guidance." />
    </Helmet>

      <div className="inner-container">
        <h2 className="dnt-heading">Blogs</h2>
      </div>

      <div className="blog-txt">
        <Grid container spacing={0} align="center"
          sx={{ marginTop: '3%', maxWidth: '90%', marginLeft: '4%', marginRight: 'auto', }} >

          {blogConstant.map((item, index) => (
            <Grid className="blog-tb-vw" item xs={12} sm={6} md={4} sx={{ width: '80%' }} key={index}>
              <Card
                sx={{ maxWidth: 640, maxLength: 700,
                  margin: '0px 0px 27px 0px', padding: '2%',
                  cursor: 'pointer', }}
                onClick={() => (window.location.href = item.nextPageLink)}
              >

                <CardMedia component="img" height="240" image={item.imageUrl} alt="" />
                <CardContent>
                  <Box align="left" sx={{ textColor: 'grey', display: 'flex' }}>
                    <CalendarMonthIcon sx={{ color: 'green', height: '22px', textAlign: 'left' }}
                    />

                    <Typography className="blog-card-dt-vw">
                      September 12, 2023
                    </Typography>
                  </Box>

                  <Typography className="blog-card-heading">
                   <strong> {item.blogTitle}</strong>
                  </Typography>

                  <Typography variant="body1" className="blog-card-txt-vw">
                    {item.blog}
                  </Typography>
                </CardContent>
                <CardActions>
<button className="card-btn" onClick={() => (window.location.href = item.nextPageLink)} > Read More </button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}