import React from 'react';
import { Container, Grid, Link, } from '@mui/material';
// import { Container, Typography, Grid, Link, } from '@mui/material';

import '../css/Diseasesntreatments.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import AllergyRhinitis from "../Disesases&Treatments/AllergyRhinitis";
// import AnkleSprain from "../Disesases&Treatments/AnkleSprain";
import { Helmet } from 'react-helmet';

const treatments = [
  {
    image: '../images/back-pain.png',
    heading: 'Musculo Skeletal Diseases',

    illnesses: [
      { name: 'Ankle Sprain', link: '/Diseases&Treatments/AnkleSprain' },
      { name: 'Arthritis', link: '/Diseases&Treatments/Arthritis' },
     // { name: 'Brachial Plexus Injury', link: ' ' },
      { name: 'Cervical', link: '/Diseases&Treatments/Cervical' },
      { name: 'Degenerative Disc Disease', link: '/Diseases&Treatments/DegenerativeDiscDisease' },
      { name: 'Disc Prolapse', link: '/Diseases&Treatments/DiscProlapse' },
      { name: 'Disc Bulging', link: '/Diseases&Treatments/DiscBulging' },
      { name: 'Elbow Arthritis', link: '/Diseases&Treatments/ElbowArthritis' },
      { name: 'Frozen Shoulder', link: '/Diseases&Treatments/FrozenShoulder' },
      { name: 'Heel Pain', link: '/Diseases&Treatments/HeelPain' },
      { name: 'Knee Fractures & Injuries', link: '/Diseases&Treatments/KneeFractures' },
      { name: 'Osteoarthritis', link: '/Diseases&Treatments/Osteoarthritis' },
      { name: 'Osteoporosis', link: '/Diseases&Treatments/Osteoporosis' },
      { name: 'Rheumatoid Arthritis', link: '/Diseases&Treatments/RheumatoidArthritis' },
      { name: 'Sciatica', link: '/Diseases&Treatments/Sciatica' },
      { name: 'Spondyloarthritis', link: '/Diseases&Treatments/Spondyloarthritis' },
      { name: 'Back Pain', link: '/Diseases&Treatments/BackPain' },
    ],
  },
  {
    image: '../images/bundle-massages.png',
    heading: 'Bundle Massages (Kizhi)',
    illnesses: [
      { name: 'Choornapinda Swedam', link: '/ChoornaPindaSwedam' },
      { name: 'Patrapinda Swedam', link: '/PatraPindaSwedam' },
      { name: 'Shashtikasalipinda Swedam', link: '/ShashtikasaliPindaSwedam' },
      { name: 'Kizhi', link: '/ChoornaPindaSwedam' },
    ],
  },
  {
    image: '../images/nasyam.png',
    heading: 'Panchakarma Treatments',
    illnesses: [
      { name: 'Vamana', link: '/Vamana' },
      { name: 'Virechana', link: '/Virechana' },
      { name: 'Nasyam', link: '/Nasyam' },
      { name: 'Vasti', link: '/Vasti' },
    ],
  },
  {
    image: '../images/women-care.png',
    heading: 'Women Care',
    illnesses: [
      { name: 'Polycystic Ovary Syndrome', link: '/Diseases&Treatments/PolycysticOvarySyndrome' },
      { name: 'Urinary Tract Infection (UTI)', link: '/Diseases&Treatments/UrinaryTractInfection' },
      { name: 'Weight Loss', link: '/Diseases&Treatments/WeightLoss' },
      { name: 'Endometriosis', link: '/Diseases&Treatments/Endometriosis' },
      { name: 'Menorrhagia', link: '/Diseases&Treatments/Menorrhagia' },
      { name: 'Oligomenorrhea', link: '/Diseases&Treatments/Oligomenorrhea' },
    ],
  },
  {
    image: '../images/digestive-system.png',
    heading: 'Digestive System',
    illnesses: [
      { name: 'Biliary Colic', link: '/Diseases&Treatments/BiliaryColic' },
      { name: 'Biliary Disease', link: '/Diseases&Treatments/BiliaryDisease' },
      { name: 'Dyspepsia', link: '/Diseases&Treatments/Dyspepsia' },
      { name: 'Gastroesophageal Reflux', link: '/Diseases&Treatments/GastroesophagealRefluxDisease' },
      { name: 'Irritable Bowel Syndrome', link: '/Diseases&Treatments/IrritableBowelSyndrome' },
      { name: 'Jaundice', link: '/Diseases&Treatments/Jaundice' },
    ],
  },
  {
    image: '../images/general-health-1.png',
    heading: 'General Health',
    illnesses: [
      { name: 'Allergic Rhinitis', link: '/Diseases&Treatments/AllergyRhinitis' },
      { name: 'Bladder Pain Syndrome', link: '/Diseases&Treatments/BladderPainSyndrome' },
      { name: 'Diabetes', link: '/Diseases&Treatments/Diabetes' },
      { name: 'Diabetic Kidney Disease', link: '/Diseases&Treatments/DiabeticKidneyDisease' },
      { name: 'Diabetic Retinopathy', link: '/Diseases&Treatments/DiabeticRetinopathy' },
      { name: 'Hypoglycemia', link: '/Diseases&Treatments/Hypoglycemia' },
      { name: 'Hypothyroidism', link: '/Diseases&Treatments/Hypothyroidism' },
      { name: 'Immunodeficiency Disorder', link: '/Diseases&Treatments/ImmunodeficiencyDisorder' },
      { name: 'Influenza', link: '/Diseases&Treatments/Influenza' },
      { name: 'Hypertension', link: '/Diseases&Treatments/Hypertension' },
      { name: 'Male Infertility', link: '/Diseases&Treatments/MaleInfertility' },
      { name: 'Lipid Disorders', link: '/Diseases&Treatments/LipidDisorders' },
    ],
  },
  {
    image: '../images/general-health.png',
    heading: 'Skin Care',
    illnesses: [
      { name: 'Skin Allergies', link: '/Diseases&Treatments/SkinAllergies' },
      { name: 'Scabies', link: '/Diseases&Treatments/Scabies' },
    ],
  },
  {
    image: '../images/anxiety.png',
    heading: 'Neurology',
    illnesses: [
      { name: 'Anxiety', link: '/Diseases&Treatments/Anxiety' },
      { name: 'Nerve Injury', link: '/Diseases&Treatments/NerveInjury' },
   //   { name: 'Migraine', link: ' ' },
    ],
  },
  // ...rest of the treatments array
];

function Diseasesntreatments() {
  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Ayurveda for Diabetes & Hypertension | Back Pain & Joint Treatment </title>
    <meta name="description" content="Ayurvedic approaches for diabetes, hypertension, back pain, and joint issues. Effective treatments for holistic well-being. Visit now." />
    </Helmet>

      <div className="inner-container"><h2 className="dnt-heading">Diseases & Treatments</h2></div>
      <Container>

        {treatments.map((category, index) => (
          <div key={index} className="category">
            <div className="inner-container vw-margin-tp"><h2 className="dnt-treatment">{category.heading}</h2></div>

            <div className="">
              <Grid container spacing={10} alignItems="center">
                {index % 2 === 0 ? (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <img
                        src={category.image}
                        alt={category.heading}
                       // className="category-image"
                        style={{ margin: '38px 0 15px 0', width: '100%' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} className="dnt-top-margin-m">
                      <ul className="illness-list">
                        {category.illnesses.map((illness, index) => (
                          <li key={index} className="illness-item">
                            <Link href={illness.link} className="illness-link">
                              <ArrowForwardIosIcon className="icon" />&nbsp;{illness.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <ul className="illness-list">
                        {category.illnesses.map((illness, index) => (
                          <li key={index} className="illness-item">
                            <Link href={illness.link} className="illness-link">
                              <ArrowForwardIosIcon className="icon" />&nbsp;{illness.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} className="dnt-top-margin-m">
                      <img
                      style={{margin: '38px 0 22px', width: '100%' }}
                        src={category.image}
                        alt={category.heading}
                       // className="category-image"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
            <div className="category-divider"></div>
          </div>
        ))}
      </Container>
    </>
  );
}

export default Diseasesntreatments;
