/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';

const jobPositions = [
  {
    title: 'Business Development Executive',
    description: 'Engaging in calling, Hosting in person and web based meetings Participation in professional tradeshows and presentations etc.',
    careerPageLink: '/career-details',
  },
  {
    title: 'Business Development Manager',
    description: 'The business development manager\'s main task is to facilitate the growth of the business for which he or she works. Working primarily with the sales and marketing departments.',
    careerPageLink: '/career-details',
  },
  {
    title: 'Customer Care Executive',
    description: 'Client Relationship Management, New Business Development, Client Calling, Client Development Feedback Management. At least 2 Years experience in Wellness Sector.',
    careerPageLink: '/career-details',
  },
  {
    title: 'Front Office Executive',
    description: 'Your responsibilities include ensuring the front desk welcomes guests positively and executing all administrative tasks to the highest quality standards.',
    careerPageLink: '/career-details',
  },
];

const CareersPage = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Careers </h2></div>
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

    <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '20px' }}>
      At Vedanta Wellness, we are always searching for talented and passionate individuals to join our innovative team. If you have a passion for technology, healthcare, design, or administration, and a desire to make an impact, we want to hear from you!
    </Typography>

    <Grid container spacing={3}>
      {jobPositions.map((job, index) => (
        <Grid item xs={12} sm={6} lg={6} key={index}>
          <Paper elevation={3}
           style={{ padding: '20px', minHeight: '100%', display: 'flex', flexDirection: 'column',
           justifyContent: 'space-between', border: '1px solid #ccc', 
           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >

          <Typography variant="h6" component="div" style={{ color: '#0B8140', marginBottom: '7px' }}>
           {job.title}
           </Typography>

            <Typography variant="body1" style={{ marginBottom: '15px', }}>
            {job.description}
            </Typography>

            <button className="card-btn" 
            onClick={() => (window.location.href = job.careerPageLink)} >Read More</button>
          </Paper>
        </Grid>
      ))}

    </Grid>

    </div>
    </>
  );
};

export default CareersPage;