/* eslint-disable no-undef */
// actions/contactActions.js

import * as type from "../types";
// export const submitContactRequest = (data) => ({
//   type: SUBMIT_CONTACT_REQUEST,
//   payload: data,

// });

export const submitContactRequest = function submitContactRequest(payload) {
  console.log("Hello from actions.....");
  return {
    type: type.CREATE_CONTACT_REQUESTING,
    payload,
  };
};

export const submitContactSuccess = () => ({
  type: type.CREATE_CONTACT_SUCCESS,
});

export const submitContactFailure = (error) => ({
  type: type.CREATE_CONTACT_FAILURE,
  payload: error,
});
