/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const BundleMassage = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            BundleMassage
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the first heading */}
          <Typography variant="h6" gutterBottom>
            What is BundleMassage?
          </Typography>
          <Typography variant="body1">
          Bundle Massage is an ancient Ayurvedic sudation therapy using numerous medicinal leaves (herbs). It induces perspiration within the affected area, which prompts the skin to eliminate wastes. Fresh leaves (ela) having vata pacifying properties make the herbal kizhis (bundles). These kizhis, dipped into warm Ayurvedic oils, will be used for massaging the body.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
            Benefits of BundleMassage:
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>Stimulates brain cells by reverse stimulation method</li>
              <li>Gives strength to the muscles</li>
              <li>Activate peripheral nervous system</li>
              <li>Improves skin texture and circulation</li>
              <li>Induces good sleep and reduces the stress.</li>
              <li>It promotes the peripheral vascular circulation</li>
              <li>Stimulates the nerve endings in the periphery</li>
              <li>Restores vitality and enthusiasm</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
            Conditions for which Bundle Massage can be beneficial:
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>Hemiplegia/Paraplegia</li>
              <li>Pseudo muscular hypertrophy</li>
              <li>Sciatica</li>
              <li>Multiple sclerosis</li>
              <li>Cervical and lumbar spondylitis</li>
              <li>Arthritis</li>
              <li>Under Weight</li>
              <li>Muscular disorders</li>
              <li>Neurological disorders</li>
              <li>Diabetic neuropathy</li>
              <li>Osteoporosis</li>
              <li>Ankylosing spondylitis</li>
              <li>Motor neuron disease </li>
              <li>Parkinsonism</li>
              <li>High Blood pressure</li>
              <li>High cholesterol</li>
            </ul>
          </Typography>
        </Grid>
        
      </Grid>
    </Container>
  );
};

export default BundleMassage;
