/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const DryEyes = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Dry Eyes
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is  Dry Eyes ?
            </Typography>
            <Typography variant="body1">
            Dry eyes, also known as dry eye syndrome or keratoconjunctivitis
sicca, is a condition characterized by insufficient lubrication and
moisture on the surface of the eyes. Symptoms may include a
stinging or burning sensation, redness, blurred vision, sensitivity to
light, eye fatigue, and a feeling of grittiness or foreign body sensation.
Causes of dry eyes can be multifactorial, including reduced tear
production, increased tear evaporation, or an imbalance in the
composition of tears. Factors such as aging, hormonal changes,
certain medications, environmental factors (e.g., dry or windy
climate), prolonged screen time, and certain medical conditions (e.g.,
Sjögren's syndrome) can contribute to dry eyes.            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of  Dry Eyes 
            </Typography>
            <Typography variant="body1">
              When you have dry eye, you might feel like there’s something in your eye that just won’t come out. This can feel like a scratchy, gritty or sandy sensation.
              Symptoms may also include:
              <ul>
                <li>Burning or stinging.</li>
                <li>Feeling sensitive to light.</li>
                <li>Blurred or changed vision.
</li>
                <li>Mucus that comes out of your eye.
</li>
                <li>Watery eyes, with excess tears running down your cheeks.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of  Dry Eyes 
            </Typography>
            <Typography variant="body1">
               Dry Eyes  can be caused by various factors, including:
              <ul>
                <li>Your eyes don’t produce enough tears.</li>
                <li>Your tears evaporate too quickly.
</li>
                <li>Both of these happen together.
</li>
              
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for  Dry Eyes 
            </Typography>
            <Typography variant="body1">
            Some risks you can’t control. For example, the older you get, the more likely you are to develop dry eye. But other risks you may be able to manage through lifestyle changes or adjustments to your medications.
              Some common risk factors for developing  Dry Eyes  include:
              <ul>
                <li><b>Environment and lifestyle factors:</b> Things like the weather, how much time you spend looking at screens, contact lens use, smoking or allergies can increase your risk of dry eye. </li>
                <li><b>Medications:</b> Taking certain medications can increase your risk of dry eye, including some that treat depression, allergies, blood pressure, glaucoma, menopause and pain. Anticholinergics, oral contraceptives and systemic retinoids can increase your risk as well.</li>
                <li><b>Surgeries:</b> Some eye surgeries increase your risk of dry eye, including LASIK, cataract surgery and surgery on your cornea.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              While  Dry Eyes  cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul>
                <li>Avoid air blowing in your eyes</li>
                <li>Add moisture to the air.</li>
                <li>Consider wearing wraparound sunglasses or other protective eyewear</li>
                <li>Take eye breaks during long tasks.</li>
                <li>Position your computer screen below eye level.</li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </Container>
  );
};

export default DryEyes;
