import React from 'react';
import { Grid, Typography } from '@mui/material';
import MediaCard from '../Components/Common/Card';
import { Box, Container } from '@mui/system';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Category = () => {

  const [serviceInfo, setServiceInfo] = useState([]);

  useEffect(() => {
    setServiceInfo([
      {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Ayurvedic Therapy',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/ayurvedic-therapy',
      },
      {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Bundle Massages',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/bundle-massages',
      }, {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Panchakarma Treatments',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/panchakarma-treatments',
      }, {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Ayurvedic Therapy',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/ayurvedic-therapy',
      }, {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Bundle Massages',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/bundle-massages',
      }, {
        imageUrl: 'https://vedanta-wellness.com/wp-content/uploads/2022/07/Mask-Group-9.png',
        cardTitle: 'Panchakarma Treatments',
        cardDesc: 'Decs',
        nextPageLink: '/category-list/panchakarma-treatments',
      },
    ]);
  }, []);

  // useEffect(() => {
  //     function fetchCategory() {
  //     fetch('https://dummyjson.com/products/categories')
  //         .then(res => res.json())
  //         .then(res => setServiceInfo(res));    
  //     }
  //     fetchCategory();
  // }, []);

  return (
    <>
      <Container sx={{ maxWidth: 'md', marginTop: '50px' }}>
        <Box component="span">
          <Typography variant="h3" component="h2" sx={{ fontWeight: 700, textAlign: 'start' }}>
            Service/Product Category Page
          </Typography>;
        </Box>
        <Box component="p" sx={{ backgroundColor: '#0B8140', height: '10px', width: '200px' }}></Box>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3, border: '3px solid #eee' }}>
          {serviceInfo.map((item) => (
            <Grid item xs={6} md={4} sm={6} key={item.imageUrl}>
              <Item>
                <MediaCard imageUrl={item.imageUrl}
                  cardTitle={item.cardTitle}
                  cardDesc={item.cardDesc}
                  nextPageLink={item.nextPageLink} />
              </Item>
            </Grid>
          ))
          }

        </Grid>
      </Container>
    </>
  );
};

export default Category;