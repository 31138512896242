/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const ShashtikasaliPindaSwedam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Shashtikasali Pinda Swedam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
           <strong> What is Shashtikasali Pinda Swedam ? </strong>
          </Typography>
          <Typography variant="body1">
          <p>Shashtika Pinda Sweda or Shastika Shali Pinda Sveda which is also called as Navarakizhi is one of the Ayurvedic treatments. </p>
          <p>In this treatment, sweat-inducing massage is given to the patient. It consists of a preparation where milk and rice are cooked along with some medicinal herbs which provide relief from pain and stiffness. This therapy also helps in the muscle strength. It rejuvenates and re-energises the body. Through this treatment the body tissue like muscles, bones and soft tissues get nutrition and strength. </p>
          <p>Navarakizhi also provides relief from pain, swelling and stiffness that are caused by the joint disorders such as arthritis.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Shashtikasali Pinda Swedam </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>Degeneration of the joint tissues and wasting of muscles is prevented.</li>
              <li>In the joints, soft tissues and muscles, it induces sweating and brings about a feeling of lightness.</li>
              <li>Morbidity associated with the Vata and Pitta dosha are corrected.</li>
              <li>Enhances flexibility and movements of the joints.</li>
              <li>Improves blood circulation in the skin, and improves its colour and complexion.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Procedure of Shashtikasali Pinda Swedam </strong>
          </Typography>
          <Typography variant="body1">
          <p>Firstly kwatha or decoction of balamoola is prepared by boiling around 750 grams of it in 12 litres of water. After preparing the kwatha, Shashtik rice should be cooked in about 1.5 litres of balamoola kwatha and 1.5 litres of milk. The rice should be cooked till they become thick and semisolid. After the rice has been cooked, they should be put in a cotton cloth and a potali or bolus should be made of it. </p>
          <p>After the potalis are prepared, the patient is asked to lie down on the massage table and gentle massage using warm potalis is done. The temperature of the potalis should be maintained during the procedure by dipping them heated milk kwatha mixture. The procedure lasts for about an hour or till the patient starts sweating.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> When It Should Be Avoided ? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>This treatment is not recommended for patients with diabetes unless the benefits outweigh the risks.</li>
          <li>High blood pressure - treatment can be done for local problems in joints and soft tissues but only under the supervision of a trained expert. </li>
          <li>Severe varicosity of veins.</li>
          <li>Deep vein thrombosis.</li>
          <li>Fractures.</li>
          <li>Fever, Severe inflammation.</li>
          </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default ShashtikasaliPindaSwedam;
