export const ALL_PAGES_REQUESTING = 'ALL_PAGES_REQUESTING';
export const ALL_PAGES_SUCCESS = 'ALL_PAGES_SUCCESS';
export const ALL_PAGES_FAILURE = 'ALL_PAGES_FAILURE';

export const LOGGED_IN_USER_PAGES_ID_REQUESTING = 'LOGGED_IN_USER_PAGES_ID_REQUESTING';
export const LOGGED_IN_USER_PAGES_ID_SUCCESS = 'LOGGED_IN_USER_PAGES_ID_SUCCESS';
export const LOGGED_IN_USER_PAGES_ID_FAILURE = 'LOGGED_IN_USER_PAGES_ID_FAILURE';

export const PAGES_CREATE_REQUESTING = 'PAGES_CREATE_REQUESTING';
export const PAGES_CREATE_SUCCESS = 'PAGES_CREATE_SUCCESS';
export const PAGES_CREATE_FAILURE = 'PAGES_CREATE_FAILURE';

export const PAGES_GET_BY_ID_REQUESTING = 'PAGES_GET_BY_ID_REQUESTING';
export const PAGES_GET_BY_ID_SUCCESS = 'PAGES_GET_BY_ID_SUCCESS';
export const PAGES_GET_BY_ID_FAILURE = 'PAGES_GET_BY_ID_FAILURE';

export const PAGES_UPDATE_BY_ID_REQUESTING = 'PAGES_UPDATE_BY_ID_REQUESTING';
export const PAGES_UPDATE_BY_ID_SUCCESS = 'PAGES_UPDATE_BY_ID_SUCCESS';
export const PAGES_UPDATE_BY_ID_FAILURE = 'PAGES_UPDATE_BY_ID_FAILURE';


export const PAGES_UNSHOW_BY_ID_REQUESTING = 'PAGES_UNSHOW_BY_ID_REQUESTING';
export const PAGES_UNSHOW_BY_ID_SUCCESS = 'PAGES_UNSHOW_BY_ID_SUCCESS';
export const PAGES_UNSHOW_BY_ID_FAILURE = 'PAGES_UNSHOW_BY_ID_FAILURE';

export const PAGES_DELETE_BY_ID_REQUESTING = 'PAGES_DELETE_BY_ID_REQUESTING';
export const PAGES_DELETE_BY_ID_SUCCESS = 'PAGES_DELETE_BY_ID_SUCCESS';
export const PAGES_DELETE_BY_ID_FAILURE = 'PAGES_DELETE_BY_ID_FAILURE';
