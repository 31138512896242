import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/blogActionTypes';
import blogApi from '../../apis/blogApi';

function* blogs(action) {
  try {
    const blogsResponse = yield call(blogApi.getAll, action);
    yield put({ type: type.ALL_BLOGS_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_BLOGS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsLoggedInUserBlogs(action) {
  try {
    const blogsResponse = yield call(blogApi.loggedInUserBlogs, action);
    yield put({ type: type.LOGGED_IN_USER_BLOGS_ID_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_BLOGS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsCreate(action) {
  try {
    const blogsResponse = yield call(blogApi.create, action);
    yield put({ type: type.BLOGS_CREATE_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.BLOGS_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsGetBy(action) {
  try {
    const blogsResponse = yield call(blogApi.getBy, action);
    yield put({ type: type.BLOGS_GET_BY_ID_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.BLOGS_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsUpdateBy(action) {
  try {
    const blogsResponse = yield call(blogApi.updateBy, action);
    yield put({ type: type.BLOGS_UPDATE_BY_ID_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.BLOGS_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsUnshowBy(action) {
  try {
    const blogsResponse = yield call(blogApi.unshowBy, action);
    yield put({ type: type.BLOGS_UNSHOW_BY_ID_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.BLOGS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* blogsDeleteBy(action) {
  try {
    const blogsResponse = yield call(blogApi.deleteBy, action);
    yield put({ type: type.BLOGS_DELETE_BY_ID_SUCCESS, blogsResponse: blogsResponse.data });
  } catch (e) {
    yield put({
      type: type.BLOGS_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myBlogSaga() {
  yield takeEvery(type.ALL_BLOGS_REQUESTING, blogs);
  yield takeEvery(type.LOGGED_IN_USER_BLOGS_ID_REQUESTING, blogsLoggedInUserBlogs);
  yield takeEvery(type.BLOGS_CREATE_REQUESTING, blogsCreate);
  yield takeEvery(type.BLOGS_GET_BY_ID_REQUESTING, blogsGetBy);
  yield takeEvery(type.BLOGS_UPDATE_BY_ID_REQUESTING, blogsUpdateBy);
  yield takeEvery(type.BLOGS_UNSHOW_BY_ID_REQUESTING, blogsUnshowBy);
  yield takeEvery(type.BLOGS_DELETE_BY_ID_REQUESTING, blogsDeleteBy);
}

export default myBlogSaga;
