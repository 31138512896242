/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CartCount from "./CartCount";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { cartUpdateCartItemQuantityAction } from "../../redux/actions/cartActions";

export default function CartItems({
  setTotalAmount,
  onCartCountChange,
  currentItem,
}) {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const [count, setCount] = useState(currentItem?.quantity || 1);
  // eslint-disable-next-line no-unused-vars
  const [localStorageCart, setLocalStorageCart] = useState(
    secureLocalStorage.getItem("guestCart"),
  );

  const handleCountChange = async (newCount) => {
    console.log("YOU JUST ADDED THE CART ITEM", newCount);
    setCount(newCount);
    onCartCountChange(newCount); // Call the update function passed from Cart.js
    if (JSON.parse(secureLocalStorage.getItem("isLoggedIn"))) {
      const cartUpdateItemQuantityPayload = {
        Role: secureLocalStorage.getItem("Role"),
        userId: JSON.parse(secureLocalStorage.getItem("users"))._id,
        email: JSON.parse(secureLocalStorage.getItem("users")).email,
        productId: currentItem?.productId?._id,
        quantity: newCount,
      };
      console.log("cartupdateitem", cartUpdateItemQuantityPayload);
      await dispatch(
        cartUpdateCartItemQuantityAction(cartUpdateItemQuantityPayload),
      );
    }

    if (localStorageCart && localStorageCart?.length !== 0) {
      localStorageCart.map((i) => {
        if (i.productId === currentItem.productId) {
          i.quantity = newCount;
        }
      });
      secureLocalStorage.setItem("guestCart", localStorageCart);
    }
  };

  useEffect(() => {
    if (setTotalAmount) {
      setTotalAmount(
        (prevTotalAmount) =>
          prevTotalAmount +
          currentItem?.productId?.sale_price * currentItem?.quantity,
      );
    }
  }, []);
  // useEffect(() => {
  //   if (currentItem && currentItem.quantity !== null && currentItem.quantity !== undefined)
  //     setCount(currentItem.quantity);
  //   // eslint-disable-next-line no-undef
  //   console.log('current Item', currentItem);
  // });

  // eslint-disable-next-line no-console, no-undef
  console.log("currentItem", currentItem, count);

  return (
    <List sx={{ width: "100%", maxWidth: "auto", bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <ClearOutlinedIcon sx={{ margin: "auto" }} />
        <ListItemAvatar
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "0px",
            margin: "20px",
          }}
        >
          <Avatar
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "0px",
              marginRight: "20px",
            }}
            alt=""
            src={currentItem?.productId?.linkedMedia?.server_image_url}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{ margin: "auto" }}
          primary={currentItem?.productId?.product_name}
          secondary={
            <React.Fragment>
              {/* <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                Ali Connors
              </Typography> */}
              {/* {" — I'll be in your neighborhood doing errands this…"} */}
            </React.Fragment>
          }
        />
        <ListItemText sx={{ margin: "auto" }} />
        <ListItemText
          sx={{ margin: "auto" }}
          primary={
            <CartCount onStateChange={handleCountChange} cartApiCount={count} />
          }
        />
        <ListItemText
          sx={{ margin: "auto" }}
          primary={currentItem?.productId?.sale_price * currentItem?.quantity}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}
