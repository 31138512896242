import React from 'react';
import '../css/Addresses.css';
import Usersection from '../Components/Common/Usersection';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Addresses() {
  return (
    <div className="us-container">
    <h1 className="us-header">Addresses </h1>
       <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <Usersection />
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <Box
            sx={{
              marginTop: '6px',
              padding: '1px 1px 1px 40px',
              marginRight: '2rem',
              
            }}
          >
            <Typography className="adr-header">The following addressess will be used on the checkout
             page by default
            </Typography>

          <Box sx={{width:'90%'}}>
          <Grid container sx={{marginTop:'20px'}} spacing={5}>

          <Grid md={6} sx={{height:'3 rem'}}>
        
          <Box className="bil-section addr-txt" >
          <Item>
          <Typography sx={{fontWeight:'bold', textAlign:'left', marginLeft:'10px',height:'50px', fontSize:'24px', paddingTop:'4px' }}>
            Billing address
          </Typography>
          <Divider />
          <Typography className="adr-para">
            You have not set up this type of address yet.
          </Typography>
          </Item>
          </Box>
          
          </Grid>

          <Grid md={6}>
          
          <Box className="bil-section addr-txt">
          <Item>
          <Typography sx={{fontWeight:'bold',textAlign:'left', marginLeft:'10px',height:'50px', fontSize:'24px', paddingTop:'4px' }}>
            Shiping address
          </Typography>

          <Divider/>

          <Typography className="adr-para">
            You have not set up this type of address yet.
          </Typography>
          </Item>
          </Box>

          </Grid>
          
          </Grid>
          </Box> 
          </Box>

        </Grid>
        
      </Grid>
    </div>
  );
}
