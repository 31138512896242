/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const NerveInjury= () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Nerve Injury </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Nerve Injury? </strong>
        </Typography>
            <Typography variant="body1">
            Nerve injury refers to damage or trauma to the nerves, which can result in various neurological
symptoms and impairments. Nerves are responsible for transmitting signals between the brain, spinal
cord, and the rest of the body, allowing for movement, sensation, and coordination. Nerve injuries can
occur due to various causes, including trauma, compression, repetitive motion, infections,
autoimmune disorders, or medical conditions such as diabetes. Symptoms of nerve injury can vary
depending on the location and severity of the damage but may include pain, numbness or tingling,
weakness or paralysis, loss of coordination, and changes in sensation.
</Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Nerve Injury </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Nerve Injury include:
              <ul className='vw-ul'>
                <li><b>Numbness:</b> Some nerves only transmit sensation, so a nerve injury to these nerves would cause some amount of numbness.</li>
                <li><b>Weakness:</b> In addition to sensory nerves, some nerves give you the ability to move, and other nerves do both of these things. Injury to nerves that carry motor signals causes some amount of weakness.
</li>
                <li><b>Pain:</b> This is frequently a symptom after nerve injury. The pain present after a nerve injury can be anywhere along the course of the nerve, but it is typically at the injury site.
</li>
                <li>Itchy or watery eyes</li>
                <li>Postnasal drip</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Nerve Injury </strong>
        </Typography>
            <Typography variant="body1">
              Nerve Injury can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Injury from an accident, a fall or sports, which can stretch, compress, crush or cut nerves</li>
                <li>Medical conditions, such as diabetes, Guillain-Barre syndrome and carpal tunnel syndrome</li>
                <li>Autoimmune diseases including lupus, rheumatoid arthritis and Sjogren's syndrome
</li>
                <li>Other causes include narrowing of the arteries, hormonal imbalances and tumors.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Nerve Injury </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Nerve Injury include:
              <ul className='vw-ul'>
                <li>Diabetes, especially if your sugar levels are not controlled well.</li>
                <li>Alcohol misuse.</li>
                <li>Low levels of vitamins in the body, especially vitamin B-12.</li>
                <li>Infections, such as Lyme disease, shingles, hepatitis B and C, and HIV.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Nerve Injury cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li><b>Maintain Good Posture:</b> Poor posture can put pressure on nerves, especially in the neck, back, and wrist areas. Be conscious of your posture when sitting, standing, and working, and make adjustments as needed to maintain a neutral spine and wrist position</li>
                <li><b>Exercise Regularly:</b> Regular physical activity can help improve circulation and keep your muscles and nerves healthy. Incorporate exercises that promote flexibility, strength, and balance into your routine.</li>
                <li><b>Protect Yourself During Sports and Activities:</b> When participating in sports or activities with a risk of injury, wear appropriate protective gear and follow safety guidelines to reduce the chances of nerve damage.</li>
                <li><b>Avoid Repetitive Movements:</b> Repetitive movements can lead to overuse injuries and compression of nerves. Take regular breaks if you perform repetitive tasks, and try to vary your movements to avoid putting prolonged stress on specific nerves.</li>
                
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default NerveInjury;
