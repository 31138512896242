import React, { useState, useEffect } from 'react';
import './CustomersList.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import { userRows } from './DummyData';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { customersAction } from '../../../redux/actions/customerActions';
import Layout from './Layout';

function CustomersList() {
  const customersSelector = useSelector(state => state?.customers?.data);
  const [data, setData] = useState(userRows);
  const [customersFromSelector, setCustomersFromSelector] = useState([]);

  // eslint-disable-next-line no-console, no-undef
  console.log('customersFromSelector', customersFromSelector);


  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAllCustomersPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllCustomers() {
      await dispatch(customersAction(fetchAllCustomersPayload));
    }
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    if (customersSelector && Array.isArray(customersSelector) && customersSelector.length !== 0) {
      // customersSelector.map((aCustomer) => ({
      //   ...aCustomer,
      //   id: aCustomer._id // Add id property based on _id
      // }));

      setCustomersFromSelector(customersSelector.map((aCustomer) => ({
        ...aCustomer,
        id: aCustomer._id, // Add id property based on _id
        status: aCustomer.isDeleted ? 'Inactive' : 'active'
      })));
    }
  }, [customersSelector]);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    { field: 'username', headerName: 'Username', width: 150 },
    { field: 'full_name', headerName: 'Name', width: 150 },
    { field: 'image', headerName: 'Image', width: 120, sortable: false, renderCell: (params) => <img className="customersListImg" src={params.row.avatar} alt="" /> },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone_number', headerName: 'Phone No', width: 120 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'transaction', headerName: 'Transaction Volume', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <>
          <Link to={`/user/${params.row.id}`}>
            <button className="customersListEdit">Edit</button>
          </Link>
          <DeleteOutline
            className="customersListDelete"
            onClick={() => handleDelete(params.row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content usr-txt">
          <h6 style={{ marginBottom: '20px' }}>Customers </h6>
          <DataGrid
            rows={customersFromSelector}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            checkboxSelection
          />
        </div>
      </div>
    </Layout>

  );
}

export default CustomersList;
