import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const Thakradhara = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
             Thakradhara
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the first heading */}
          <Typography variant="h4" gutterBottom>
            What is Thakradhara?
          </Typography>
          <Typography variant="body1">
          Abhyanga, otherwise known as ayurvedic massage, is a broad term that means any kind of ayurvedic massage, of which there are a few different types including full-body massage, head massage (shiro abhyanga), or foot massage (pad abhyanga).

Abhyanga is considered such a significantly beneficial therapy, that it’s recommended daily - especially for the elderly, those with more air & ether in their constitution (vata dosha) and for those experiencing stress, anxiety & insomnia to name a few.

Excellent for pain relief and healing, abhyanga is usually characterised by a specific sequence of strokes including long, sweeping movements using copious amounts of warm, herbal oils suitable for the individual. Different types of abhyanga can use herbal powders, herbal pastes or ghee.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h4" gutterBottom>
          Benefits of Thakradhara
          </Typography>
          <Typography variant="body1">
          Do you experience anxiety and stress? (hello, don’t we all!)

Do you experience pain or stiffness in your joints or muscle fatigue after exercise?

Do you find you get dry skin, especially in the cooler months?

Do you suffer from insomnia or digestive complaints?
                      <ul>
                      The act of massaging the body melts away tension and stress from the muscles, as well as reduces aches & pains and balances what we call vata dosha in ayurveda, which primarily is responsible for balancing out nervous system and governs all movement in the body including elimination. Massaging the body with medicated or herbal oils specific for your natural constitution (dosha) or current condition (vikriti, or imbalance), further enhances the benefits of the massage. Further benefits of Ayurvedic massage include;
              <li>Nasal congestion</li>
              <li>Runny nose</li>
              <li>Sneezing</li>
              <li>Itchy or watery eyes</li>
              <li>Postnasal drip</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h4" gutterBottom>
            Causes of Thakradhara
          </Typography>
          <Typography variant="body1">
            Thakradhara can be caused by various factors, including:
            <ul>
              <li>Allergens such as pollen, dust mites, or pet dander</li>
              <li>Infections, such as the common cold</li>
              <li>Exposure to irritants like cigarette smoke</li>
              <li>Changes in weather or temperature</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h4" gutterBottom>
            Risk Factors for Thakradhara
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Thakradhara include:
            <ul>
              <li>A family history of allergies or Thakradhara</li>
              <li>Being exposed to environmental triggers</li>
              <li>Having other allergic conditions, such as asthma or eczema</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fifth heading */}
          <Typography variant="h4" gutterBottom>
            Prevention and Management
          </Typography>
          <Typography variant="body1">
            While Thakradhara cannot always be prevented, some strategies that may help manage the symptoms include:
            <ul>
              <li>Avoiding triggers, such as allergens or irritants</li>
              <li>Using nasal saline rinses to keep the nasal passages clear</li>
              <li>Taking antihistamine medications as prescribed by a healthcare professional</li>
              <li>Keeping the indoor environment clean and free of dust and mold</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Thakradhara;
