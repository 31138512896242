import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/notificationActionTypes';
import notificationApi from '../../apis/notificationApi';

function* notifications(action) {
  try {
    const notificationsResponse = yield call(notificationApi.getAll, action);
    yield put({ type: type.ALL_NOTIFICATIONS_SUCCESS, notificationsResponse: notificationsResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_NOTIFICATIONS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* notificationsCreate(action) {
  try {
    const notificationsResponse = yield call(notificationApi.create, action);
    yield put({ type: type.NOTIFICATIONS_CREATE_SUCCESS, notificationsResponse: notificationsResponse.data });
  } catch (e) {
    yield put({
      type: type.NOTIFICATIONS_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* notificationsUnshowBy(action) {
  try {
    const notificationsResponse = yield call(notificationApi.unshowBy, action);
    yield put({ type: type.NOTIFICATIONS_UNSHOW_BY_ID_SUCCESS, notificationsResponse: notificationsResponse.data });
  } catch (e) {
    yield put({
      type: type.NOTIFICATIONS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* notificationsDeleteBy(action) {
  try {
    const notificationsResponse = yield call(notificationApi.deleteBy, action);
    yield put({ type: type.NOTIFICATIONS_DELETE_BY_ID_SUCCESS, notificationsResponse: notificationsResponse.data });
  } catch (e) {
    yield put({
      type: type.NOTIFICATIONS_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myNotificationSaga() {
  yield takeEvery(type.ALL_NOTIFICATIONS_REQUESTING, notifications);
  yield takeEvery(type.NOTIFICATIONS_CREATE_REQUESTING, notificationsCreate);
  yield takeEvery(type.NOTIFICATIONS_UNSHOW_BY_ID_REQUESTING, notificationsUnshowBy);
  yield takeEvery(type.NOTIFICATIONS_DELETE_BY_ID_REQUESTING, notificationsDeleteBy);
}

export default myNotificationSaga;
