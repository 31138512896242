import React from 'react';
import { Typography, Grid } from '@mui/material';

const BladderPainSyndrome = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Bladder Pain Syndrome </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Bladder Pain Syndrome? </strong>
        </Typography>
            <Typography variant="body1">
            Bladder Pain Syndrome (BPS), also known as interstitial cystitis, is a chronic condition
characterized by recurring pain in the bladder and surrounding pelvic region.
Symptoms of BPS include bladder pain, urgency to urinate, frequency of urination
(often small amounts), and nocturia (frequent urination at night). Some individuals may
also experience pelvic pain, discomfort during sexual intercourse, and an increased
sensitivity to certain foods and drinks. The exact cause of BPS is not fully understood,
but it is believed to involve a combination of factors including bladder lining
abnormalities, nerve dysfunction, inflammation, and immune system dysfunction.            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Bladder Pain Syndrome: </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Bladder Pain Syndrome include:
              <ul className='vw-ul'>
                <li>Pain in your bladder region.</li>
                <li>Pressure or discomfort when your bladder is filling. </li>
                <li>Urinary frequency.</li>
                <li>Only peeing a small amount.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Bladder Pain Syndrome </strong>
        </Typography>
            <Typography variant="body1">
            Healthcare providers and medical researchers don’t completely understand what causes BPS. They suspect that it may be related to certain medical conditions, including:
            <ul className='vw-ul'>
                <li>Autoimmune diseases.</li>
                <li>Allergies.</li>
                <li>Defects in your bladder lining.</li>
                <li>Abnormalities in your mast cell (cells that cause allergic symptoms).</li>
                <li>Unidentified infections.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Bladder Pain Syndrome</strong>
        </Typography>
            <Typography variant="body1">
            You are more at risk for painful bladder syndrome if you:
             <ul className='vw-ul'>
                <li>Are female</li>
                <li>Are age 30 or older</li>
                <li>Have fair skin and red hair</li>
                <li>Have a chronic pain disorder, like fibromyalgia or irritable bowel syndrome</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
        </Typography>
            <Typography variant="body1">
              While Bladder Pain Syndrome cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Diet Changes</li>
                <li>Physical Activity</li>
                <li>Reduce Stress </li>
                <li>Physical Therapy</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default BladderPainSyndrome;
