/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Delete, Edit, Close, Done } from '@material-ui/icons';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './Layout';
import { couponsAction, couponsCreateAction, couponsUpdateByAction, couponsUnshowByAction } from '../../../redux/actions/couponsActions';

function CouponCard({ coupon, onDelete, onEdit }) {
  const [newCode, setNewCode] = useState(coupon.coupon_code);
  const [newDescription, setNewDescription] = useState(coupon.description);

  const [editing, setEditing] = useState(false);
  // const [updatedCoupon, setUpdatedCoupon] = useState(coupon);

  // const dispatch = useDispatch();

  function handleEdit() {
    setEditing(true);
  }

  function handleCancel() {
    setEditing(false);
    // setUpdatedCoupon(coupon);
  }

  function handleSave() {
    const newCoupon = {
      ...coupon,
      coupon_code: newCode?.toUpperCase(),
      description: newDescription
    };
    onEdit(newCoupon);
    setEditing(false);
  }

  const handleNewCodeChange = (event) => {
    setNewCode(event.target.value);
  };

  const handleNewDescriptionChange = (event) => {
    setNewDescription(event.target.value);
  };



  return (
    <Card className="cc-container" style={{ marginBottom: '20px' }}>
      <CardHeader
        title={
          editing ? (
            <TextField
              name="code"
              value={newCode.toUpperCase()}
              onChange={handleNewCodeChange}
              fullWidth
            />
          ) : (
            coupon.coupon_code
          )
        }
        action={
          editing ? (
            <>
              <IconButton onClick={() => handleSave(coupon._id)}>
                <Done />
              </IconButton>
              <IconButton onClick={handleCancel}>
                <Close />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => onDelete(coupon._id)}>
                <Delete />
              </IconButton>
            </>
          )
        }
      />
      <CardContent>
        {editing ? (
          <TextField
            name="description"
            value={newDescription}
            onChange={handleNewDescriptionChange}
            fullWidth
            multiline
          />
        ) : (
          <Typography variant="body2">{coupon.description}</Typography>
        )}
        <Typography variant="caption">{`Expires on: ${coupon?.expiration_date?.split('T')[0]}`}</Typography>
      </CardContent>
      {!editing ? (
        <CardActions>
          <Button color="primary" className="btn-bg" style={{ color: '#ffffff', marginBottom: '10px' }} >Redeem</Button>
        </CardActions>) :
        <></>}
    </Card>
  );
}

function CouponPage() {
  const couponsSelector = useSelector(state => state?.coupons?.data);
  const dispatch = useDispatch();

  // const [coupons, setCoupons] = useState();
  const [couponsFromSelector, setCouponsFromSelector] = useState();

  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [expiresOn, setExpiresOn] = useState('');

  useEffect(() => {
    const fetchAllCouponsPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllCoupons() {
      await dispatch(couponsAction(fetchAllCouponsPayload));
    }

    fetchAllCoupons();
  }, [dispatch]);

  useEffect(() => {
    if (couponsSelector && Array.isArray(couponsSelector)) {
      setCouponsFromSelector(couponsSelector);
    }
    if (couponsSelector && couponsSelector?.coupon !== undefined && Object.keys(couponsSelector?.coupon).length !== 0) {
      const alreadyContains = couponsFromSelector.some(aCoupon => aCoupon._id === couponsSelector?.coupon?._id);
      if (!alreadyContains)
        setCouponsFromSelector([...couponsFromSelector, couponsSelector.coupon]);
    }

  }, [couponsSelector]);

  async function handleAddCoupon(event) {
    event.preventDefault();
    // setCoupons((prevState) => [...prevState, { ...newCoupon }]);

    const addCouponPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,

      coupon_code: code.toUpperCase(),
      description: description,
      expiration_date: expiresOn,
      isDeleted: false,
      content_type: 'coupon',
    };

    await dispatch(couponsCreateAction(addCouponPayload));
    // setNewCoupon({ code: "", description: "", expiresOn: "" });
  }

  async function handleEditCoupon(updatedCoupon) {

    // const updatedCouponFromSelector = couponsFromSelector.map(aCoupon => )

    setCouponsFromSelector((prevState) => {
      const newResponse = prevState.map((aCoupon) => {
        if (aCoupon._id === updatedCoupon._id) {
          return updatedCoupon;
        } else {
          return aCoupon;
        }
      });
      return newResponse;
    }
    );

    const updateCouponPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,

      id: updatedCoupon._id,
      coupon_code: updatedCoupon.coupon_code.toUpperCase(),
      description: updatedCoupon.description,
      isDeleted: false,
      content_type: 'coupon',
    };

    await dispatch(couponsUpdateByAction(updateCouponPayload));

  }

  async function handleDeleteCoupon(id) {
    const deleteCouponPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      id: id,
      content_type: 'coupon',
    };

    await dispatch(couponsUnshowByAction(deleteCouponPayload));
    setCouponsFromSelector((prevState) => prevState.filter((coupon) => coupon._id !== id));
  }

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleExpiresOnChange = (event) => {
    setExpiresOn(event.target.value);
  };


  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6 style={{ marginBottom: '20px' }}>Coupon Manager </h6>
          <>
            <Grid container spacing={2}>
              <Grid item xs={10} md={8}>
                <form onSubmit={handleAddCoupon}>
                  <TextField
                    name="code"
                    label="Coupon Code"
                    value={code.toUpperCase()}
                    onChange={handleCodeChange}
                    fullWidth
                    required
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                  />
                  <TextField
                    name="description"
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    fullWidth
                    multiline
                    required
                    variant="outlined"
                    rows={6}
                    style={{ marginBottom: '20px' }}
                  />
                  <TextField
                    name="expiresOn"
                    label="Expiration Date"
                    type="date"
                    value={expiresOn}
                    onChange={handleExpiresOnChange}
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                  />
                  <Button type="submit" color="primary" className="btn-bg" variant="contained">
                    Add Coupon
                  </Button>
                </form>
              </Grid>

              <Grid item xs={8} md={4}>
                {couponsFromSelector && couponsFromSelector.map((coupon) => (
                  <CouponCard
                    key={coupon._id}
                    coupon={coupon}
                    onDelete={() => handleDeleteCoupon(coupon._id)}
                    onEdit={(updatedCoupon) => handleEditCoupon(updatedCoupon)}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        </div>
      </div>
    </Layout>
  );
}

export default CouponPage;
