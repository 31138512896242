import React from 'react';
import { Typography, Grid } from '@mui/material';

const DiabeticRetinopathy = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Diabetic Retinopathy </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Diabetic Retinopathy ?</strong>
        </Typography>
            <Typography variant="body1">
            Diabetic retinopathy is a complication of diabetes that affects the blood vessels in the retina, the lightsensitive tissue at the back of the eye. High blood sugar levels can damage these blood vessels,
leading to vision problems. Symptoms may not be present in the early stages, but as the disease
progresses, symptoms can include blurred vision, floaters (spots or dark strings in the field of vision),
difficulty seeing at night, and eventually, vision loss. The primary cause of diabetic retinopathy is longterm poorly controlled diabetes. Other factors such as high blood pressure, high cholesterol levels,
and smoking can exacerbate the condition. Regular eye examinations and maintaining good blood
sugar control are essential for managing diabetic retinopathy.
            </Typography>
     
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Diabetic Retinopathy</strong>
        </Typography>
            <Typography variant="body1">
              In the early stages, most people experience no signs of diabetes-related retinopathy. You may not experience vision changes until the condition is severe. For some people, symptoms come and go.
              Symptoms of diabetes-related retinopathy include:
              <ul className='vw-ul'>
                <li>Blurred or distorted vision.</li>
                <li>New color blindness or seeing colors as faded.</li>
                <li>Poor night vision (night blindness).</li>
                <li>Small dark spots (eye floaters) or streaks in your vision.</li>
                <li>Trouble reading or seeing faraway objects.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Diabetic Retinopathy </strong>
        </Typography>
            <Typography variant="body1">
              Diabetic Retinopathy can be caused by various factors, including:
             <ul className='vw-ul'>
             <li>Diabetic retinopathy is caused by having high blood glucose levels for long periods of time. Prolonged bouts of high sugar glucose levels can weaken and damage the small blood vessels within the retina. This may cause haemorrhages, exudates and even swelling of the retina. Over time, the retina eventually becomes starved of oxygen and abnormal blood vessels start to grow.</li>
             </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Diabetic Retinopathy </strong>
        </Typography>
            <Typography variant="body1">
            Anyone who has diabetes can develop diabetic retinopathy. The risk of developing the eye condition can increase as a result of:
            <ul className='vw-ul'>
                <li>Having diabetes for a long time </li>
                <li>Poor control of your blood sugar level </li>
                <li>High blood pressure</li>
                <li>High cholesterol</li>
                <li>Pregnancy</li>
                <li>Tobacco use</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
            </Typography>
            <Typography variant="body1">
            If you have diabetes, you can lower your risk of developing diabetes-related retinopathy by:
            <ul className='vw-ul'>
                <li>Avoiding smoking.</li>
                <li>Controlling your blood sugar.</li>
                <li>Exercising regularly.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default DiabeticRetinopathy;
