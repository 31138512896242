/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Vasti = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Vasti</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Vasti ?</strong>
          </Typography>
          <Typography variant="body1">
            <p>
              Vasti Karma is a specific therapeutic procedure, in which
              medicines are administered into body through lower pathways, most
              often through anus. It cleanses the accumulated toxins from all
              over the body.
            </p>
            <p>
              Some times medicines are also administered by other routes, like
              Urethra or vagina, then this procedure is termed as Uttara vasti.
            </p>
            <p>
              Vasti is primarily administered to treat vata dosha but can be
              tridoshahara in nature and can produce both samsodhana or shamana
              effects. It is also highly beneficial in rejuvenating treatment,
              especially in convalescence.
            </p>
            <p>
              Vasti karma is considered as the most important procedure in five
              fold Panchakarma therapy. The word Vasti, stands for a special
              instrument used for enemata therapy.
            </p>
            <p>
              It is effective in majority of vata conditions like Parkinson's
              disease, MND/ALS, multiple sclerosis, paralysis, fibromyalgia,
              back pain, neck pain, slip disc, sciatica, atrophy, arthritis,
              rheumatic diseases, neurological diseases, gout, diseases of GIT,
              splenomegaly, intestinal worms, fistula in ano, fever, psychiatric
              problems, gynaec problems, infertility etc.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Vasti </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>It helps in the lubrication and nourishment of the lower gastrointestinal tract.</li>
              <li>The process also helps in curing constipation.</li>
              <li>Very beneficial in curing dryness of the body.</li>
              <li>Abdominal distention.</li>
              <li>Useful in alleviating sciatic and back pains.</li>
              <li>It helps in treating metabolic and digestive disorders.</li>
              <li>It also removes the obstruction of flatus and urine.</li>
              <li>Effective for Various Neurological Disorders like Paralysis, Parkinson's Disease, etc</li>
              <li>Effective for Skin Disorders like eczema, psoriasis, acne, etc.</li>
              <li>Effective for Autoimmune Disorders like Rheumatoid arthritis, inflammatory bowel disease, etc</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>How is the Vasti treatment Procedure? </strong>
          </Typography>
          <Typography variant="body1">
          <p>The patient is allowed to eat according to the basti type which will be administered.</p>
          <p>The patient made to get on to the dhroni ,given Abhyangam with suitable oil and mild sudation is done on the waist region. </p>
          <p>The patient is made to lie comfortably on left lateral position with his left leg extended or stretched out and right leg folded in front and the left hand kept beneath the head .</p>
          <p>Anal region is well lubricated.</p>
          <p>A very soft simple rubber catheter is inserted in the anus.</p>
          <p>Oil/kashayam/basti fluid is put into the rectum by syringe or enema pot.</p>
          <p>Patient is advised the dietary habits acc to the basti type given.</p>
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Who Should Be Avoided? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>Fever</li>
              <li>Indigestion </li>
              <li>Obesity </li>
              <li>Pregnancy.</li>
              <li>Bleeding piles etc.</li>
              <li>Menstrual cycle.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Vasti;
