/* eslint-disable no-undef */
import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const userApi = {
  login: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_LOGIN;
    return axios.post(uri, params).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_CREATE;
    console.log('API Request Data:', params);
    return axios.post(uri, params).then((response) => {
      console.log('API Response:', response);
      return response;
    });
  },

  getAll: () => {
    const uri = HOST_ADDRESS + envVars.ALL_USERS;
    return axios.get(uri).then((response) => {
      return response;
    });
  },
  getBy: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_GET_BY_ID;
    return axios.get(`${uri}${params?.payload?.id}`).then((response) => {
      return response;
    });
  },
  updateBy: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_UPDATE_BY_ID;
    return axios.put(`${uri}?id=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_UNSHOW_BY_ID;
    return axios.put(`${uri}?id=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.USERS_DELETE_USER_BY_ID;
    return axios.delete(`${uri}?id=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
};

export default userApi;
