import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types'; // Import PropTypes

function createData(name, amount) {
  return { name, amount };
}

const rows = [
  createData('Subtotal:', 'INR 15.00'),
  createData('Total:', 'INR 15.00'),
];

export default function OrderSummary({ billingFormCompleted }) {
  const navigate = useNavigate();

  const handlePlaceOrderClick = () => {
    if (billingFormCompleted) {
      navigate('/orderdetails1');
    }
  };

  // Add prop types validation
OrderSummary.propTypes = {
  billingFormCompleted: PropTypes.bool.isRequired, // Make sure billingFormCompleted is a boolean and is required
};

  return (
    <TableContainer component={Paper} sx={{ padding: '20px' }}>
      <Table sx={{ minWidth: 'auto', padding: '20px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell variant="head" size="medium" sx={{ fontWeight: 'bold' }}>Your order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <hr></hr>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
       <FormControlLabel value="female" control={<Radio />} label="Direct bank transfer" />
        <FormControlLabel value="male" control={<Radio />} label="Cash on delivery" />
      </RadioGroup>
      <br />
      {/* Remove the "disabled" attribute to enable the button */}
      <Button
        variant="contained"
        size="medium"
        sx={{ backgroundColor: '#0B8140', color: 'white', padding: '15px', width: '100%', borderRadius: '25px', fontWeight: 300 }}
        onClick={handlePlaceOrderClick}
      >
        PLACE ORDER
      </Button>
      {billingFormCompleted || (
        <Typography variant="body2" sx={{ color: 'red', marginTop: '10px' }}>
          Please complete the billing form before proceeding.
        </Typography>
      )}
    </TableContainer>
  );
}
