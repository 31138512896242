/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const RheumatoidArthritis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Rheumatoid Arthritis</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Rheumatoid Arthritis?</strong>
        </Typography>
        <Typography variant="body1">
            Rheumatoid arthritis (RA) is a chronic autoimmune disease that primarily
affects the joints, causing inflammation, pain, and stiffness. It occurs when the
immune system mistakenly attacks the body's own tissues, particularly the
lining of the joints, leading to joint damage and deformity over time. The exact
cause of RA is unknown, but it is believed to involve a combination of genetic
and environmental factors. Common symptoms of rheumatoid arthritis include
joint pain, swelling, warmth, stiffness, and reduced range of motion. It can also
affect other organs and systems in the body. Early diagnosis and treatment are
crucial to manage symptoms and prevent joint damage. </Typography>   
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Rheumatoid Arthritis </strong>
            </Typography>
            <Typography variant="body1">
             <p> Some common symptoms of Rheumatoid Arthritis include:</p>
              <ul className='vw-ul'>
                <li>Pain, swelling, stiffness and tenderness in more than one joint.</li>
                <li>Stiffness, especially in the morning or after sitting for long periods.</li>
                <li>Pain and stiffness in the same joints on both sides of your body.</li>
                <li>Fatigue (extreme tiredness), tiredness.</li>
                <li>Fever</li>
              </ul>
            </Typography>
      
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Rheumatoid Arthritis</strong>
        </Typography>
            <Typography variant="body1">
            <p>Rheumatoid Arthritis can be caused by various factors, including:</p>
            <ul className='vw-ul'>
                <li><b>Age:</b> It affects adults of any age (most people are diagnosed between ages of 40 & 60) </li>
                <li><b>Sex: </b>Most common among women than men</li>
                <li><b>Genetics:</b> It develops because of Combination of Genetic and environmental factors such as smoking & diet </li>
                <li><b>Weight:</b> if you are overweight</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Rheumatoid Arthritis</strong>
            </Typography>
            <Typography variant="body1">
              <p>Some common risk factors for developing Rheumatoid Arthritis include:</p>
              <ul className='vw-ul'>
                <li><b>Family history: </b> You’re more likely to develop RA if you have a close relative who also has it.</li>
                <li><b>Sex:</b> Women and people designated female at birth are two to three times more likely to develop rheumatoid arthritis.</li>
                <li><b>Smoking: </b> Smoking increases a person’s risk of rheumatoid arthritis and makes the disease worse.</li>
                <li><b>Obesity: </b> Your chances of developing RA are higher if you have obesity.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
            </Typography>
            <Typography variant="body1">
              <p>While Rheumatoid Arthritis cannot always be prevented, some strategies that may help manage the symptoms include:</p>
              <ul className='vw-ul'>
              <li>There is no way to prevent Rheumatoid arthritis but you may be able to lower your chances If you quit smoking, maintain your weight, adapt healthy lifestyle.</li>
               </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default RheumatoidArthritis;
