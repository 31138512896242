import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const Migraine = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Migraine
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is  Migraine?
            </Typography>
            <Typography variant="body1">
            Migraine is a neurological disorder characterized by recurrent and
intense headaches, often accompanied by other symptoms. Migraine
headaches are usually pulsating or throbbing and typically affect one
side of the head. Common symptoms include severe pain, sensitivity
to light (photophobia), noise (phonophobia), and sometimes smells or
certain foods. Migraine attacks can also cause nausea, vomiting, and
dizziness. The exact cause of migraines is not fully understood, but
they are believed to involve a combination of genetic, environmental,
and neurological factors. Triggers such as stress, hormonal changes,
certain foods, sleep disturbances, and sensory stimuli can provoke
migraine episodes.            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of  Migraine
            </Typography>
            <Typography variant="body1">
            The main symptom of Migraine is the very severe Headache sensation usually on  either side of Head. In some cases it may also happen on both sides or in the whole head area. Other than the main symptomatology the whole episode of Migraine takes progress through four stages namely- Prodromal, Aura, Attack and Postdromal stage. 
              <ul>
                <li><b>Prodromal stage-</b> In this stage, patient experiences noticeable changes that alarm the upcoming Migraine attack.This includes neck stiffness, increased urine frequency and  mood changes.

</li>
                <li><b>Aura stage-</b> This stage is much closer to the Migrainous attack. Aura is the  intolerable hypersensitivity to light, bright light and sound which may last for  several minutes to few hours. </li>
                <li><b>Attack stage-</b> The main Migraine attack starts gradually and becomes  severe with throbbing and pulsatile with nausea and vomiting. </li>
                <li><b>Postdromal stage-</b> In this stage of Migraine attack the patient is in state of tiredness, confusion and exhaustion. 

</li>
                
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of  Migraine
            </Typography>
            <Typography variant="body1">
               Migraine can be caused by various factors, including:
              <ul>
                <li>Doctors don’t know the exact cause of migraine headaches, although they seem to be related to changes in your brain and to your genes. Your parents can even pass down migraine triggers like fatigue, bright lights, or weather changes.</li>
                <li>For many years, scientists thought migraine happened because of changes in blood flow in the brain. Most now think this can contribute to the pain but is not what starts it.

</li>
                <li>Current thinking is that a migraine likely starts when overactive nerve cells send out signals that trigger your trigeminal nerve, which gives sensation to your head and face.</li>
                <li>This cues your body to release chemicals like serotonin and calcitonin gene-related peptide (CGRP). CGRP makes blood vessels in the lining of your brain swell. Then, neurotransmitters cause inflammation and pain.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for  Migraine
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing  Migraine include:
              <ul>
                <li>Women have migraines three times more often than men.</li>
                <li>Most people start having migraine headaches between ages 10 and 40. But many women find that their migraines get better or go away after age 50.s</li>
                <li>Four out of five people with migraine have other family members who get them. If one parent has a history of these types of headaches, their child has a 50% chance of getting them. If both parents have them, the risk jumps to 75%.
                </li>
                <li>Depression, anxiety, bipolar disorder, sleep disorders, and epilepsy can raise your odds.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              While  Migraine cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul>
                <li>Lifestyle changes and dietary modifications are some of the many focuses to  prevent Migraine.</li>
                <li>Stress-reduction approaches such as exercise, relaxation techniques, and  biofeedback may help to lessen the frequency and intensity of migraines.  Biofeedback is a technique that uses electrical instruments to teach you how  to control your heartbeat, blood pressure and muscle tension. </li>
                <li>Keep track of what seems to set off your migraines. You can find out what  foods and drugs you should stay away from. It also assists you in determining  what you should do, such as maintaining a regular sleep schedule and eating regular meals. </li>
                <li>Resting in a quiet, darkish environment with your eyes closed. • Applying a cool towel or an ice pack to your brow. </li>
                <li>Drinking plenty of water can also help a lot.
</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </Container>
  );
};

export default Migraine;
