/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Vamana = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Vamana</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Vamana?</strong>
          </Typography>
          <Typography variant="body1">
            <p>Vamana is one of the panchakarma procedures. </p>
            <p>
              Vamana is the treatment where the patient is made to vomit all the
              vitiated contents from the stomach, in order to cleanse the body.
            </p>
            <p>
              Vamana procedure is usually done in cases of kapha vitiation.
              Therefore, medicines that further aggravate kapha are
              administered. We know that the aggravated kapha causes vomiting.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Vamana</strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>The Vitiated and aggressive Kapha Dosha is thrown out of the body, which cures the disease.</li>
              <li>Increases digestion and metabolic power.</li>
              <li>General health is restored.</li>
              <li>The sense organs, mind, intellect, and complexity become apparent and gain strength. </li>
              <li>One gets strength, nutrition, and immunity.</li>
              <li>It enhances the possibility of fertility, virility, and sexual power. </li>
              <li>It helps to slow down the aging process.</li>
              <li>A person can stay healthy for a long time.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Procedure of Vamana </strong>
          </Typography>
          <Typography variant="body1">
            <p><b>Preoperative procedure</b></p>
            <p>
              We begin with the snehapana for moisturizing the body. We give the
              patient any formulations of ghee for the purpose of the detachment
              of adhered doshas from the channels.
            </p>
            <p>
              We then induce perspiration which then takes the loosened doshas
              to the alimentary canal for further expulsion from the body.
            </p>
            <p>
              On the previous day, we advise the patient to have foods that
              increase kapha like heavy food, sweets, and oily substances.
            </p>
            <p><b>Procedure</b></p>
            <p>
              On the day of vamana, the body is massaged with oil, and
              perspiration is induced.
            </p>
            <p>
              We then ask the patient to sit comfortably in a chair facing a
              basin kept on a stool.
            </p>
            <p>
              At first, the patient is given six glasses of milk and is made to
              vomit. After that, medicine for vamana, like madanaphala ( Randia
              dumetorum) is given, which further induces vomiting.
            </p>
            <p>
              We advise the patient to vomit the entire contents out, our
              physicians keep a close check on any kind of complications.
            </p>
            <p><b>Post-operative procedure</b></p>
            <p>
              After the physicians have made sure that the patient has vomited
              the entire contents, we advise the patient to wash their face with
              lukewarm water and then administer medicinal fumes to be inhaled.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> When It Should Be Avoided ? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>Pregnant women</li>
              <li>In children</li>
              <li>For elderly people</li>
              <li>Person under stress.</li>
              <li>In case of cardiac problems.</li>
              <li>Patient of high blood pressure.</li>
              <li>In wounded condition.</li>
              <li>Patient who is vomiting already.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Vamana;
