/* eslint-disable no-console */
import React from 'react';
import { Typography } from '@mui/material';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ScrollToTop from "react-scroll-to-top";


function Footer() {
  const location = useLocation();

  // const [isHover, setIsHover] = useState(false);

  // eslint-disable-next-line no-undef
  console.log('location', location,);
  const excludedRoutes = [
    '/home',
    '/about-us',
    '/Home', // Add the private routes here that should exclude the footer
    '/OrderChart',
    '/SalesChart',
    '/ProductTagsPage',
    '/Sidebar',
    '/Topbar',
    '/Layout',
    '/LeatherBoard',
    '/AddPages',
    '/Blog',
    '/PageList',
    '/BlogList',
    '/AddProductPage',
    '/ProductListPage',
    '/CustomersList',
    '/AddNewOrder',
    '/AllOrders',
    '/Allorders',
    '/OrderInfo',
    '/FeedbackPage',
    '/UsersList',
    '/FeaturedInfo',
    '/ProductCategoriesPage',
    '/CouponCard',
    '/PatientProfilepage',
    '/OrderMgmt',
    '/AdminProfilePage',
    '/MediaLibrary'
  ];

  //   const handleMouseEnter = () => {
  //     setIsHover(true);
  //  };

  //  const handleMouseLeave = () => {
  //     setIsHover(false);
  //  };


  // Check if the current location matches any of the excluded routes
  const shouldRenderFooter = !excludedRoutes.includes(location.pathname);

  if (!shouldRenderFooter) {
    return null; // Do not render the footer if the current location is an excluded route
  }

  return (
    <>
    <div className="footer-container">
      <footer className="footer-upper">
        <div className="us-container">
          <Grid container className="row new-class" spacing={2}>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Ayurvedic Therapies</h4>
              <ul className="list-1">
                <li><Link className="link" to="/Abhyangam">Abhyangam</Link></li>
                <li><Link className="link" to="/Swedanam">Steaming (Swedanam) </Link></li>
                <li><Link className="link" to="/Sirodhara">Sirodhara </Link></li>
                <li><Link className="link" to="/Lepanam">Lepanam </Link></li>
                <li><Link className="link" to="/SarvangaDhara">Sarvanga Dhara </Link></li>
                <li><Link className="link" to="/KatiVasti">KatiVasti </Link></li>
                <li><Link className="link" to="/Januvasti">Januvasti </Link></li>
              </ul>
            </Grid>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Bundle Massages (Kizhi)</h4>
              <ul className="list-1">
              <li><Link className="link" to="/ChoornaPindaSwedam">Choorna Pinda Swedam </Link></li>
              <li><Link className="link" to="/PatraPindaSwedam">Patra Pinda Swedam </Link></li>
              <li><Link className="link" to="/ShashtikasaliPindaSwedam">Shashtikasali Pinda Swedam </Link></li>
              </ul>
            </Grid>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Panchakarma Treatments</h4>
              <ul className="list-1">
              <li><Link className="link" to="/Vamana">Vamana</Link></li>
              <li><Link className="link" to="/Virechana">Virechana </Link></li>
              <li><Link className="link" to="/Nasyam">Nasyam </Link></li>
              <li><Link className="link" to="/Vasti">Vasti </Link></li>
              <li><Link className="link" to="/Raktamoksham">Raktamoksham </Link></li>
              </ul>
            </Grid>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Diseases & Treatments</h4>
              <ul className="list-1">
                <li><Link className="link" to="/Diseases&Treatments/Arthritis">
                Arthritis
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/Osteoarthritis">
                Osteoarthritis
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/Gout">
                Gout
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/RheumatoidArthritis">
                Rheumatoid Arthritis
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/Spondyloarthritis">
                Spondyloarthritis 
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/Osteoporosis">
                Osteoporosis
                  </Link></li>
                <li><Link className="link" to="/Diseases&Treatments/BackPain">
                Back Pain

                  </Link></li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </footer>

      {/* ------------------------------------second navbar-------------------------------------------------- */}

      <footer className="footer">
        <div className="us-container">
          <Grid container className="row new-class" spacing={2}>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Quick Links</h4>
              <ul className="list-1">
                <li>
                  <Link className="link" to="/slides">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/ayurvedic-therapies">
                    Ayurvedic Therapies
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/diseases-treatments">
                    Diseases & Treatments
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/membership">
                    Membership
                  </Link>
                </li>
                <li><Link className="link" to="/blogs">Blogs</Link></li>
                <li><Link className="link" to="/testimonials">Testimonials </Link></li>
              </ul>
            </Grid> 
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Policies</h4>
              <ul className="list-1">
              <li><Link className="link" to="/careers"> Careers </Link></li>
              <li><Link className="link" to="/term-conditions">Terms & Conditions</Link></li>
              <li><Link className="link" to="/privacy-policy">Privacy Policies</Link></li>
              <li><Link className="link" to="/faq">FAQ</Link></li>
              <li><Link className="link" to="/SatisfactionGuarantee">Satisfaction Guarantee</Link></li>
              <li><Link className="link" to="/disclaimer">Disclaimer</Link></li>
              <li><Link className="link" to="/sitemap">Sitemap</Link></li>
              </ul>
            </Grid>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Contact Us</h4>
              <ul className="list-1">
                <li>
                  <strong>Call Now:</strong> +91-9811669606 /
                </li>
                <li>+91-9810781909</li>
                <li>
                  <strong>Email:</strong> info@vedanta-wellness.com
                </li>
                <li>
                  <strong>Address:</strong> Vedanta Wellness, C-11,
                </li>
                <li>Sector-56, Noida, India</li>
              </ul>
            </Grid>
            <Grid className="" md={3} sm={6} xs={12}>
              <h4 className="col-first">Reach Us</h4>
              <ul className="list-1">
              <li>
                <iframe
                  title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.961586548549!2d77.34346151508187!3d28.60092918243029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59a734ba435%3A0x7983ff1747fae637!2sVedanta%20Wellness!5e0!3m2!1sen!2sin!4v1688449329067!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border:0 , pointerEvents: 'none' }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className="us-container">
          <Typography className="copyright-footer"><span className="cr-txt">
            Copyright &copy; Vedanta Wellness - 2023. All rights reserved.
          </span>
          </Typography>
        </div>
      </footer>
    </div>
    <ScrollToTop smooth />
    </>
  );
}

export default Footer;
