import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/couponActionTypes';
import couponApi from '../../apis/couponApi';

function* coupons(action) {
  try {
    const couponsResponse = yield call(couponApi.getAll, action);
    yield put({ type: type.ALL_COUPONS_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_COUPONS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsLoggedInUserCoupons(action) {
  try {
    const couponsResponse = yield call(couponApi.loggedInUserCoupons, action);
    yield put({ type: type.LOGGED_IN_USER_COUPONS_ID_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_COUPONS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsCreate(action) {
  try {
    const couponsResponse = yield call(couponApi.create, action);
    yield put({ type: type.COUPONS_CREATE_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.COUPONS_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsGetBy(action) {
  try {
    const couponsResponse = yield call(couponApi.getBy, action);
    yield put({ type: type.COUPONS_GET_BY_ID_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.COUPONS_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsUpdateBy(action) {
  try {
    const couponsResponse = yield call(couponApi.updateBy, action);
    yield put({ type: type.COUPONS_UPDATE_BY_ID_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.COUPONS_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsUnshowBy(action) {
  try {
    const couponsResponse = yield call(couponApi.unshowBy, action);
    yield put({ type: type.COUPONS_UNSHOW_BY_ID_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.COUPONS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* couponsDeleteBy(action) {
  try {
    const couponsResponse = yield call(couponApi.deleteBy, action);
    yield put({ type: type.COUPONS_DELETE_BY_ID_SUCCESS, couponsResponse: couponsResponse.data });
  } catch (e) {
    yield put({
      type: type.COUPONS_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myCouponSaga() {
  yield takeEvery(type.ALL_COUPONS_REQUESTING, coupons);
  yield takeEvery(type.LOGGED_IN_USER_COUPONS_ID_REQUESTING, couponsLoggedInUserCoupons);
  yield takeEvery(type.COUPONS_CREATE_REQUESTING, couponsCreate);
  yield takeEvery(type.COUPONS_GET_BY_ID_REQUESTING, couponsGetBy);
  yield takeEvery(type.COUPONS_UPDATE_BY_ID_REQUESTING, couponsUpdateBy);
  yield takeEvery(type.COUPONS_UNSHOW_BY_ID_REQUESTING, couponsUnshowBy);
  yield takeEvery(type.COUPONS_DELETE_BY_ID_REQUESTING, couponsDeleteBy);
}

export default myCouponSaga;
