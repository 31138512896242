export const ALL_TAGS_REQUESTING = 'ALL_TAGS_REQUESTING';
export const ALL_TAGS_SUCCESS = 'ALL_TAGS_SUCCESS';
export const ALL_TAGS_FAILURE = 'ALL_TAGS_FAILURE';

export const LOGGED_IN_USER_TAGS_ID_REQUESTING = 'LOGGED_IN_USER_TAGS_ID_REQUESTING';
export const LOGGED_IN_USER_TAGS_ID_SUCCESS = 'LOGGED_IN_USER_TAGS_ID_SUCCESS';
export const LOGGED_IN_USER_TAGS_ID_FAILURE = 'LOGGED_IN_USER_TAGS_ID_FAILURE';

export const TAGS_CREATE_REQUESTING = 'TAGS_CREATE_REQUESTING';
export const TAGS_CREATE_SUCCESS = 'TAGS_CREATE_SUCCESS';
export const TAGS_CREATE_FAILURE = 'TAGS_CREATE_FAILURE';

export const TAGS_GET_BY_ID_REQUESTING = 'TAGS_GET_BY_ID_REQUESTING';
export const TAGS_GET_BY_ID_SUCCESS = 'TAGS_GET_BY_ID_SUCCESS';
export const TAGS_GET_BY_ID_FAILURE = 'TAGS_GET_BY_ID_FAILURE';

export const TAGS_UPDATE_BY_ID_REQUESTING = 'TAGS_UPDATE_BY_ID_REQUESTING';
export const TAGS_UPDATE_BY_ID_SUCCESS = 'TAGS_UPDATE_BY_ID_SUCCESS';
export const TAGS_UPDATE_BY_ID_FAILURE = 'TAGS_UPDATE_BY_ID_FAILURE';

export const TAGS_UNSHOW_BY_ID_REQUESTING = 'TAGS_UNSHOW_BY_ID_REQUESTING';
export const TAGS_UNSHOW_BY_ID_SUCCESS = 'TAGS_UNSHOW_BY_ID_SUCCESS';
export const TAGS_UNSHOW_BY_ID_FAILURE = 'TAGS_UNSHOW_BY_ID_FAILURE';

export const TAGS_DELETE_BY_ID_REQUESTING = 'TAGS_DELETE_BY_ID_REQUESTING';
export const TAGS_DELETE_BY_ID_SUCCESS = 'TAGS_DELETE_BY_ID_SUCCESS';
export const TAGS_DELETE_BY_ID_FAILURE = 'TAGS_DELETE_BY_ID_FAILURE';
