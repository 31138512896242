/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import { cartCreateOrUpdateCartAction } from '../../redux/actions/cartActions';

const AddToCart = (props) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(secureLocalStorage.getItem('isLoggedIn') === true);

  console.log('secureLocalStorage.getItem(\'isLoggedIn\')', secureLocalStorage.getItem('isLoggedIn'), JSON.parse(secureLocalStorage.getItem('isLoggedIn')), secureLocalStorage.getItem('isLoggedIn') === true);

  const addToCartButtonHandler = async () => {
    if (isLoggedIn) {
      if (secureLocalStorage.getItem('guestCart') !== null) {
        const cartPayload = {
          Role: secureLocalStorage.getItem('Role'),
          userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
          email: JSON.parse(secureLocalStorage.getItem('users')).email,
          cart: JSON.parse(secureLocalStorage.getItem('guestCart'))
        };

        console.log('cartPayload', cartPayload);

        await dispatch(cartCreateOrUpdateCartAction(cartPayload));
        secureLocalStorage.removeItem('guestCart');
      } else {
        const cartPayload = {
          Role: secureLocalStorage.getItem('Role'),
          userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
          email: JSON.parse(secureLocalStorage.getItem('users')).email,
          productId: props?.product?._id,
          quantity: 1
        };

        console.log('cartPayload');

        await dispatch(cartCreateOrUpdateCartAction(cartPayload));
      }
    } else {
      const guestCart = {
        productId: props?.product,
        quantity: props?.quantity || 1
      };

      let localStorageCart = secureLocalStorage.getItem('guestCart');

      if (localStorageCart === null) {
        localStorageCart = [guestCart];
      } else {
        let isPresentInCart = false;
        console.log('!!!!!!!! localStorageCart', localStorageCart);
        localStorageCart.map(i => {
          console.log('*******', i.productId === guestCart.productId, i.productId, guestCart.productId);
          if (i.productId === guestCart.productId) {
            i.quantity += 1;
            isPresentInCart = true;
          }
        });
        if (!isPresentInCart) {
          localStorageCart.push(guestCart);
        }

        console.log('localStorageCart', localStorageCart);
      }

      secureLocalStorage.setItem('guestCart', localStorageCart);
    }
  };

  console.log('KJJJJJJJ ', secureLocalStorage.getItem('guestCart'));

  return (
    <Button {...props} onClick={addToCartButtonHandler} >
      Add to Cart
    </Button>
  );
};

AddToCart.propTypes = {
  quantity: PropTypes.string,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  })
};

export default AddToCart;
