import React from 'react';
import List from '@mui/material/List';
import './Usersection.css';
import Dashboard from '../../Pages/Dashboard';
import Box from '@mui/material/Box';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes,
} from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';

// const style = {
//   width: '100%',
//   maxWidth: 360,
//   bgcolor: 'background.paper',
// };

export default function Usersection() {
  const navigate = useNavigate();

  return (
    <div className="left-sidemenu">
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Box>
        <List component="nav" aria-label="mailbox folders">
          <ListItem button>
            <ListItemText
              primary="Dashboard"
              onClick={() => navigate('/dashboard')}
            />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Orders" onClick={() => navigate('/order')} />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Downloads"
              onClick={() => navigate('/downloads')}
            />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText
              primary="Addresses"
              onClick={() => navigate('/address')}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Account details"
              onClick={() => navigate('/Accountdetailspage')}
            />
          </ListItem>
          {/* <Divider light />
          <ListItem button>
            <ListItemText primary="Logout" />
          </ListItem>
          <Divider light /> */}
        </List>
      </Box>
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Usersection />}>
      <Route path="dashboard" element={<Dashboard />} />
      {/* ... etc. */}
    </Route>,
  ),
);

export { router };
