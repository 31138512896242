/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../css/Sevicesnproduct.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getAllCategoriesWithTheirAssociatedProductsAction } from "../redux/actions/categoryActions";

export default function Sevicesnproduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categorySelector = useSelector((state) => state?.categories?.data);

  const [selectedView, setSelectedView] = useState("web");
  const [categoriesFromSelector, setCategoriesFromSelector] = useState([]);

  console.log("categorySelector", categorySelector);

  useEffect(() => {
    const fetchCategoriesAccordingly = async () => {
      await dispatch(getAllCategoriesWithTheirAssociatedProductsAction());
    };

    fetchCategoriesAccordingly();
  }, []);

  useEffect(() => {
    if (
      categorySelector &&
      Object.keys(categorySelector).length !== 0 &&
      Array.isArray(categorySelector).length !== 0
    ) {
      setCategoriesFromSelector(categorySelector);
    }
  }, [categorySelector]);

  console.log(">>>> categorySelector", categorySelector);
  console.log(">>>> categoriesFromSelector", categoriesFromSelector);

  const handleChange = (event, newAlignment) => {
    setSelectedView(newAlignment);
  };

  const services = [
    {
      title: "Abhyangam",
      image: "/images/abhyangam-img.jpg",
      route: "/services/Abhyangam",
    },

    {
      title: "Swedanam",
      image: "/images/Mask-Group-6-2.png",
      route: "Swedanam",
    },

    {
      title: "Sirodhara",
      image: "/images/Mask-Group-6.png",
      route: "Sirodhara",
    },

    {
      title: "Lepanam",
      image: "/images/Mask-Group-6-3.png",
      route: "Lepanam",
    },

    {
      title: "Detoxification Therapy",
      image: "/images/detoxification-img.jpg",
      route: "Detoxification Therapy",
    },
    {
      title: "Women Care Therapy",
      image: "/images/img-51.jpg",
      route: "Women Care Therapy",
    },

    {
      title: "Elderly Care Therapy",
      image: "/images/img-52.jpg",
      route: "Elderly Care Therapy",
    },

    {
      title: "Weight Loss Packages ",
      image: "/images/package-3.png",
      route: "Weight Loss Packages ",
    },

    // Add more services as needed
  ];

  const handleCategoryClick = (category) => {
    const view = selectedView === "web" ? "category-list" : "services";

    navigate(`/${view}/${encodeURIComponent(category.route)}`, {
      state: category,
    });
  };

  return (
    <>
      <div className="inner-container">
        <h2 className="dnt-services-heading">Product & Service Category </h2>
      </div>
      <div className="us-container">
        <ToggleButtonGroup
          color="secondary"
          value={selectedView}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="web">
            <span
              style={{
                textTransform: "capitalize",
                backgroundColor: "#0B8140 !important",
                color: "#ffffff !important",
              }}
            >
              Product Category
            </span>
          </ToggleButton>
          <ToggleButton value="android">
            <span
              style={{
                textTransform: "capitalize",
                backgroundColor: "#0B8140 !important",
                color: "#ffffff !important",
              }}
            >
              Service Category
            </span>
          </ToggleButton>
        </ToggleButtonGroup>

        {/* <Divider /> */}

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "2%" }}
        >
          {selectedView === "web"
            ? // Render categories

              categoriesFromSelector.map((category, index) => (
                <Grid
                  item
                  md={3}
                  key={index}
                  sx={{ borderWidth: "2px", marginBottom: "20px" }}
                >
                  <Card sx={{ maxWidth: 345 }}>
                    <Box sx={{ padding: "15px", borderRadius: "15px" }}>
                      <CardActionArea
                        onClick={() => handleCategoryClick(category)}
                      >
                        <CardMedia
                          component="img"
                          height="270"
                          padding="20px"
                          borderRadius="5%"
                          image={category.linkedMedia.server_image_url}
                          alt="green iguana"
                          sx={{ borderRadius: "15px" }}
                        />

                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="product-c-name"
                            sx={{ fontWeight: "400", fontSize: "17px" }}
                          >
                            {category.name}
                          </Typography>

                          <Typography variant="body2" color="text.secondary">
                            <button
                              className="snp-btn"
                              onClick={() =>
                                handleCategoryClick(category.route)
                              }
                            >
                              View Products
                            </button>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                  </Card>
                </Grid>
              ))
            : // Render services

              services.map((service, index) => (
                <Grid
                  item
                  md={3}
                  key={index}
                  sx={{ borderWidth: "2px", marginBottom: "20px" }}
                >
                  <Card sx={{ maxWidth: 345 }}>
                    <Box sx={{ padding: "15px", borderRadius: "15px" }}>
                      <CardActionArea
                        onClick={() => handleCategoryClick(service.route)}
                      >
                        <CardMedia
                          component="img"
                          height="270"
                          padding="20px"
                          borderRadius="0px"
                          image={service.image}
                          alt=""
                          sx={{ borderRadius: "0px" }}
                        />

                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="product-c-name"
                            sx={{ fontWeight: "400", fontSize: "17px" }}
                          >
                            {service.title}
                          </Typography>

                          <Typography variant="body2" color="text.secondary">
                            <button
                              className="snp-btn"
                              onClick={() => handleCategoryClick(service.route)}
                            >
                              View Services
                            </button>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </div>
    </>
  );
}
