/* eslint-disable no-console */
/* eslint-disable no-undef */
import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/userActionTypes';
import userApi from '../../apis/userApi';
// eslint-disable-next-line no-unused-vars
import secureLocalStorage from 'react-secure-storage';

function* usersCreate(action) {
  try {
    const response = yield call(userApi.create, action.payload);
    console.log("hello from saga");
    if (response.status === 200) {
      console.log('User registration successful. Response:', response.data);
      yield put({ type: type.USERS_CREATE_SUCCESS, usersResponse: response.data });
    } else {
      console.error('User registration failed. Response:', response);
      yield put({
        type: type.USERS_CREATE_FAILURE,
        Error: { msg: 'Invalid Password', status: response.status },
      });
    }
    
  } catch (e) {
    yield put({
      type: type.USERS_CREATE_FAILURE,
      Error: { msg: e.message, status: e.response?.status || 500 },
    });
  }
}

function* usersLogin(action) {
  try {
    const response = yield call(userApi.login, action.payload);
    console.log('login Response..',response);
    if (response.status == '200') {
      secureLocalStorage.setItem('token', response.data.user.token);
      secureLocalStorage.setItem('Role', response.data.user.Role);
      secureLocalStorage.setItem('users', JSON.stringify(response.data.user));
      secureLocalStorage.setItem('Response', JSON.stringify(response.data));
      yield put({ type: type.USERS_LOGIN_SUCCESS, usersResponse: response.data });
    } else {
      yield put({
        type: type.USERS_LOGIN_FAILURE,
        Error: { msg: 'Invalid Password', status: response.status },
      });
    }

  } catch (e) {
    yield put({
      type: type.USERS_LOGIN_FAILURE,
      Error: { msg: e.message, status: e.response?.status || 500 },
    });
  }
}

// new START

function* usersAll(action) {
  try {
    const usersResponse = yield call(userApi.getAll, action);
    // eslint-disable-next-line no-console, no-undef
    console.log('>>>>>>>>>>>>>', usersResponse);
    yield put({ type: type.ALL_USERS_SUCCESS, usersResponse: usersResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_USERS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* usersGetBy(action) {
  try {
    const usersResponse = yield call(userApi.getBy, action);
    yield put({ type: type.USERS_GET_BY_ID_SUCCESS, usersResponse: usersResponse.data });
  } catch (e) {
    yield put({
      type: type.USERS_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* usersUpdateBy(action) {
  try {
    const usersResponse = yield call(userApi.updateBy, action);
    yield put({ type: type.USERS_UPDATE_BY_ID_SUCCESS, usersResponse: usersResponse.data });
  } catch (e) {
    yield put({
      type: type.USERS_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* usersUnshowBy(action) {
  try {
    const usersResponse = yield call(userApi.unshowBy, action);
    yield put({ type: type.USERS_UNSHOW_BY_ID_SUCCESS, usersResponse: usersResponse.data });
  } catch (e) {
    yield put({
      type: type.USERS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* usersDeleteBy(action) {
  try {
    const usersResponse = yield call(userApi.deleteBy, action);
    yield put({ type: type.USERS_DELETE_USER_BY_ID_SUCCESS, usersResponse: usersResponse.data });
  } catch (e) {
    yield put({
      type: type.USERS_DELETE_USER_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

// new TILL HERE

function* userSaga() {
  yield takeEvery(type.USERS_LOGIN_REQUESTING, usersLogin);
  yield takeEvery(type.USERS_CREATE_REQUESTING, usersCreate);
  // yield takeLatest(type.HIDE_MODAL, hideRegisterModal);

  // new

  yield takeEvery(type.ALL_USERS_REQUESTING, usersAll);
  yield takeEvery(type.USERS_GET_BY_ID_REQUESTING, usersGetBy);
  yield takeEvery(type.USERS_UPDATE_BY_ID_REQUESTING, usersUpdateBy);
  yield takeEvery(type.USERS_UNSHOW_BY_ID_REQUESTING, usersUnshowBy);
  yield takeEvery(type.USERS_DELETE_USER_BY_ID_REQUESTING, usersDeleteBy);

}

export default userSaga;
