import React from 'react';
import '../css/Downloads.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Usersection from '../Components/Common/Usersection';
import { Typography } from '@mui/material';


export default function Downloads() {
  return (
    <div className="us-container">
    <h1 className="us-header">Downloads </h1>
      <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <Usersection />
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <Box
            sx={{
              marginTop: '6px',
              padding: '2rem',
              backgroundColor: '#dcdcdc',
              marginRight: '2rem',
            }}
          >
            <Typography align="left">No downloads available yet</Typography>
            <button className="order-btn">BROWSE PRODUCTS</button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
