import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Usersection from '../Components/Common/Usersection';
import '../css/Orders.css';
import { useNavigate } from 'react-router-dom';


function Orders() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  return (
    <div className="us-container">
      <h1 className="us-header">Orders </h1>
      <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}><Usersection /></Grid>


        <Grid item md={9} sm={12} xs={12}>
          <Box sx={{ marginTop: '6px', padding: '2rem', backgroundColor: '#dcdcdc', marginRight: '2rem' }}>
            <Typography align="left">No order has been made yet</Typography>
            <button className="order-btn">BROWSE PRODUCTS</button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Orders;