import * as type from '../actionTypes/couponActionTypes';

export const couponsAction = (payload) => {
  return {
    type: type.ALL_COUPONS_REQUESTING,
    payload
  };
};
export const couponsLoggedInUserCouponsAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_COUPONS_ID_REQUESTING,
    payload
  };
};
export const couponsCreateAction = (payload) => {
  return {
    type: type.COUPONS_CREATE_REQUESTING,
    payload
  };
};
export const couponsUpdateByAction = (payload) => {
  return {
    type: type.COUPONS_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const couponsUnshowByAction = (payload) => {
  return {
    type: type.COUPONS_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const couponsDeleteByAction = (payload) => {
  return {
    type: type.COUPONS_DELETE_BY_ID_REQUESTING,
    payload
  };
};
