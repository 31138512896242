import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;
  
const pageApi = {
  getAll: (params) => {
    const uri = HOST_ADDRESS + envVars.ALL_PAGES;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  loggedInUserPage: (params) => {
    const uri = HOST_ADDRESS + envVars.LOGGED_IN_USER_PAGES_ID;
    return axios.post(`${uri}?userId=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.PAGES_CREATE;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  getBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PAGES_GET_BY_ID;
    return axios.get(`${uri}${params?.payload?._id}`).then((response) => {
      return response;
    });
  },
  updateBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PAGES_UPDATE_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PAGES_UNSHOW_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.PAGES_DELETE_BY_ID;
    return axios.delete(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
};

export default pageApi;
