/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  useParams, Link, useLocation
} from 'react-router-dom';
// import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AddToCart from '../Components/Common/AddToCart';
import { productsGetByAction } from '../redux/actions/productsActions';
import { useDispatch, useSelector } from 'react-redux';

const ProductDetails = () => {
  const productSelector = useSelector(state => state?.products?.data);
  const dispatch = useDispatch();
  const { pid } = useParams();
  const [product, setProduct] = useState(useLocation()?.state);
  // const product = useLocation()?.state;
  // eslint-disable-next-line no-unused-vars
  const [productObject, setProductObject] = useState(null);

  // eslint-disable-next-line no-console, no-undef
  console.log('loc3333ation', product);

  useEffect(() => {
    const fetchProductById = async () => {
      console.log('product === null');
      const productsGetByPayload = {
        productId: pid,
      };
      await dispatch(productsGetByAction(productsGetByPayload));
    };
    // const { orderId } = useParams();
    console.log('produccc', product, product === null);
    if (product === null) {
      // dispatching will be executed here
      fetchProductById();
    } else {
      setProductObject({
        productId: product,
        quantity: 1
      });
    }
  }, [product]);

  useEffect(() => {
    if (productSelector && Object.keys(productSelector).length !== 0) {
      setProduct(productSelector);
      setProductObject({
        productId: productSelector,
        quantity: 1,
      });
    }
  }, [productSelector]);


  return (
    <>
      <div className="inner-container" style={{ marginBottom: '20px' }}><h2 className="dnt-heading">{product?.name} </h2></div>
      <Container>
        <div className="product-img-sec">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={product?.linkedMedia.server_image_url}
              alt={product?.product_name}
              style={{ width: '450px', height: 'auto' }}
            />
          </div>
        </div>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong>{product?.product_name}</strong>
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong>Price:</strong> {product?.sale_price}/-
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Size:</strong> {product?.bottle_size}
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Description:</strong> {product?.description}
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Reference:</strong> {product?.reference}
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Ingredients:</strong> {product?.ingredients}
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Indications:</strong> {product?.indications}
        </Typography>
        <Typography variant="body1" style={{ margin: '10px 0' }}>
          <strong> Dosage:</strong> {product?.dosage}
        </Typography>

        <div style={{ display: 'flex', margin: '20px 0' }}>
          {/* <Link to="/cart" style={{ textDecoration: 'none' }}> */}

          <AddToCart variant="contained" color="primary" className="vw-main-bttn" product={productObject} route='/cart' />

          {/* </Link> */}
          {/* </div>

        <div style={{ display: 'flex', margin: '20px 0' }}> */}
          <Link to="/cart" style={{ textDecoration: 'none', marginLeft: '5em' }}>
            {/* <div style={{ textDecoration: 'none', marginLeft: '5em' }}> */}
            <Button variant="outlined" color="primary" className="vw-main-bttn" style={{ padding: '6px' }}>
              View Cart
            </Button>

            {/* </div> */}
          </Link>
        </div>

        <Typography variant="h5" style={{ marginTop: '70px' }}>
          <div className="inner-container product-dtl-pg" style={{ marginBottom: '20px' }}><h2 className="dnt-heading dnt-m">Related Products </h2></div>
        </Typography>

        {/* <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {product?.relatedProducts.map((relatedProduct) => (
            <Grid item key={relatedProduct?.id} xs={12} sm={6} md={4}>
              <ProductListCard product={relatedProduct} />
            </Grid>
          ))}
        </Grid> */}
      </Container>
    </>
  );
};

export default ProductDetails;

