import React from 'react';
import { Outlet } from 'react-router-dom';
// import secureLocalStorage from "react-secure-storage";

// const useAuth = () => {
//   const user = secureLocalStorage.getItem("token");
//   if (user) {
//     return true;
//   } else {
//     return false;
//   }
// };

const PublicRoutes = () => {
  // const auth = useAuth();

  // return auth ? <Navigate to="/dashboard" /> : <Outlet />;
  return <Outlet />;
};

export default PublicRoutes;
