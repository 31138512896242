import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import {
  TextField,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { Delete, Edit } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { blogsAction, blogsUnshowByAction } from '../../../redux/actions/blogActions';

const BlogList = () => {
  const navigate = useNavigate();
  const blogsSelector = useSelector(state => state.blogs?.data);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [blogsFromSelector, setBlogsFromSelector] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    { field: 'author', headerName: 'Author', width: 250 },
    // { field: 'image', headerName: 'Image', width: 120, sortable: false, renderCell: (params) => <img className="customersListImg" src={params.row.avatar} alt="" /> },
    { field: 'createdAt', headerName: 'Added Date', width: 200 },
    { field: 'updatedAt', headerName: 'Last updated on', width: 220 },
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit onClick={() => handleEditABlog(params?.row)} />
          </IconButton>
          <IconButton onClick={() => handleDeleteABlog(params?.row?._id)}>
            <Delete />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    const blogsFromSelectorPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllBlogs() {
      await dispatch(blogsAction(blogsFromSelectorPayload));
    }

    fetchAllBlogs();
  }, [dispatch]);

  useEffect(() => {
    if (blogsSelector && Array.isArray(blogsSelector)) {
      setBlogsFromSelector(blogsSelector.map((aBlog) => ({
        ...aBlog,
        id: aBlog._id, // Add id property based on _id
        author: `${aBlog?.belongs_to?.firstname} ${aBlog?.belongs_to?.lastname}`,
        createdAt: aBlog?.createdAt?.split('T')[0],
        updatedAt: aBlog?.updatedAt?.split('T')[0],
      })));

      setFilteredBlogs(blogsSelector.map((aBlog) => ({
        ...aBlog,
        id: aBlog._id, // Add id property based on _id
        author: `${aBlog?.belongs_to?.firstname} ${aBlog?.belongs_to?.lastname}`,
        createdAt: aBlog?.createdAt?.split('T')[0],
        updatedAt: aBlog?.updatedAt?.split('T')[0],
      })));
    };
  }, [blogsSelector]);

  const handleSearch = (event) => {
    const searchQuery = event.target?.value?.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = blogsFromSelector.filter(
      (aBlog) => aBlog?.title?.toLowerCase().includes(searchQuery) ||
        aBlog?.author?.toLowerCase().includes(searchQuery));
    setFilteredBlogs(filteredResults);
  };
  const pageCount = blogsFromSelector.length;

  const handleDeleteABlog = (id) => {
    const unshowABlogPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      id: id,
      content_type: 'blog'
    };
    dispatch(blogsUnshowByAction(unshowABlogPayload));
    setBlogsFromSelector(blogsFromSelector.filter(aBlog => aBlog._id !== id));
    setFilteredBlogs(blogsFromSelector.filter(aBlog => aBlog._id !== id));
  };

  const handleEditABlog = (aBlog) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('//{}{}{}{', aBlog);
    navigate(
      '/Blog',
      { state: aBlog },
    );
    secureLocalStorage.setItem('sideBarSubHeadingId', 1);
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6>All Blogs</h6>
          <Box>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={6}
              >
                <Typography variant="subtitle1" className="page-content-txt">
                  Blogs Added: {pageCount}
                </Typography>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <TextField
                    label="Search Blog"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </Box>
              </Box>
              <DataGrid
                rows={filteredBlogs}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
                checkboxSelection
              />

              <Box marginTop={2}>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Layout >
  );
};

export default BlogList;

