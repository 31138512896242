/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';

export default function ProductImages() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { state } = useLocation();
  const { imageUrl } = state;

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <CardMedia
        component="img"
        sx={{ height: '250px', width: '60%', borderRadius: '10%' }}
        image={'hfjdfhdjfd'}
        // title="hiii"

        // onClick={()=>navigate("/product-details/:pid")}
      />
    </div>
  );
}
