export const ALL_MEDIA_REQUESTING = 'ALL_MEDIA_REQUESTING';
export const ALL_MEDIA_SUCCESS = 'ALL_MEDIA_SUCCESS';
export const ALL_MEDIA_FAILURE = 'ALL_MEDIA_FAILURE';

export const LOGGED_IN_USER_MEDIA_ID_REQUESTING = 'LOGGED_IN_USER_MEDIA_ID_REQUESTING';
export const LOGGED_IN_USER_MEDIA_ID_SUCCESS = 'LOGGED_IN_USER_MEDIA_ID_SUCCESS';
export const LOGGED_IN_USER_MEDIA_ID_FAILURE = 'LOGGED_IN_USER_MEDIA_ID_FAILURE';

export const MEDIA_CREATE_REQUESTING = 'MEDIA_CREATE_REQUESTING';
export const MEDIA_CREATE_SUCCESS = 'MEDIA_CREATE_SUCCESS';
export const MEDIA_CREATE_FAILURE = 'MEDIA_CREATE_FAILURE';

export const MEDIA_GET_BY_ID_REQUESTING = 'MEDIA_GET_BY_ID_REQUESTING';
export const MEDIA_GET_BY_ID_SUCCESS = 'MEDIA_GET_BY_ID_SUCCESS';
export const MEDIA_GET_BY_ID_FAILURE = 'MEDIA_GET_BY_ID_FAILURE';


export const MEDIA_UPDATE_BY_ID_REQUESTING = 'MEDIA_UPDATE_BY_ID_REQUESTING';
export const MEDIA_UPDATE_BY_ID_SUCCESS = 'MEDIA_UPDATE_BY_ID_SUCCESS';
export const MEDIA_UPDATE_BY_ID_FAILURE = 'MEDIA_UPDATE_BY_ID_FAILURE';


export const MEDIA_UNSHOW_BY_ID_REQUESTING = 'MEDIA_UNSHOW_BY_ID_REQUESTING';
export const MEDIA_UNSHOW_BY_ID_SUCCESS = 'MEDIA_UNSHOW_BY_ID_SUCCESS';
export const MEDIA_UNSHOW_BY_ID_FAILURE = 'MEDIA_UNSHOW_BY_ID_FAILURE';


export const MEDIA_DELETE_BY_ID_REQUESTING = 'MEDIA_DELETE_BY_ID_REQUESTING';
export const MEDIA_DELETE_BY_ID_SUCCESS = 'MEDIA_DELETE_BY_ID_SUCCESS';
export const MEDIA_DELETE_BY_ID_FAILURE = 'MEDIA_DELETE_BY_ID_FAILURE';
