/* eslint-disable no-undef */
/* eslint-disable no-console */
import * as type from '../actionTypes/cartActionTypes';

export const cartCreateOrUpdateCartAction = (payload) => {
  return {
    type: type.CART_CREATE_OR_UPDATE_CART_REQUESTING,
    payload
  };
};
export const cartGetCartAction = (payload) => {
  return {
    type: type.CART_GET_CART_REQUESTING,
    payload
  };
};
export const cartClearByAction = (payload) => {
  return {
    type: type.CLEAR_CART_BY_ID_REQUESTING,
    payload
  };
};
export const cartDeleteItemFromCartByAction = (payload) => {
  return {
    type: type.DELETE_ITEM_FROM_CART_BY_REQUESTING,
    payload
  };
};
export const cartUpdateCartItemQuantityAction = (payload) => {
  return {
    type: type.UPDATE_CART_ITEM_QUANTITY_REQUESTING,
    payload
  };
};