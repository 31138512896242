import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const BronchialAsthma = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Bronchial Asthma
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            What is Bronchial Asthma ?
          </Typography>
          <Typography variant="body1">
            Bronchial asthma is a chronic respiratory condition characterized by
            inflammation and narrowing of the airways, causing recurring
            episodes of wheezing, coughing, shortness of breath, and chest
            tightness. It is typically triggered by various factors, including
            allergens (such as pollen, dust mites, or pet dander), irritants
            (such as smoke or strong odors), respiratory infections, exercise,
            and emotional stress. The underlying causes of asthma are not fully
            understood, but a combination of genetic and environmental factors
            play a role. These factors contribute to an exaggerated immune
            response, leading to airway inflammation, increased mucus
            production, and bronchial muscle constriction{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Symptoms of Bronchial Asthma
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Bronchial Asthma include:
            <ul>
              <li>Difficulty breathing</li>
              <li>Tightness in chest</li>
              <li>Wheezing while exhaling</li>
              <li>Coughing</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Causes of Bronchial Asthma
          </Typography>
          <Typography variant="body1">
            Bronchial Asthma can be caused by various factors, including:
            <ul>
              <li>
                Allergy to substances like dust, animal dander, pollens etc.
              </li>
              <li>Respiratory infections like cold, sinusitis etc.</li>
              <li>Smoking,Air pollution, Stress</li>
              <li>Preservatives added to food</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Risk Factors for Bronchial Asthma
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Bronchial Asthma include:
            <ul>
              <li>Family history of Asthma</li>
              <li>
                Having any other allergic health condition like atopic
                dermatitis
              </li>
              <li>Obesity</li>
              <li>Chronic smoker</li>
              <li>
                Occupational allergens like pesticides, chemicals used in
                hairdressing etc
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Prevention and Management
          </Typography>
          <Typography variant="body1">
            To prevent oneself from any kind of all allergic Asthma or Bronchial
            Asthma, one should follow the following preventive measures to stay
            away from any lung related problems.{' '}
            <ul>
              <li>
                Wear mask to avoid pollution or any other allergen like dust,
                pollens etc.
              </li>
              <li>
                Avoid using heavy curtains, carpets at home that catches dust
                easily.
              </li>
              <li>Quit smoking</li>
              <li>Keep pets out of your room</li>
              <li>Open windows and doors while cleaning.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BronchialAsthma;
