/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  FormControl,
  Input,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { usersGetByAction } from '../../../redux/actions/userActions';
import Layout from './Layout';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: 'auto',
    marginLeft: '20%',
    marginTop: '40px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3)
    },
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {
      margin: theme.spacing(2, 0),
    },
  },
  columnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '0 0 calc(50% - 10px)',
    },
  },
}));

const AdminProfilePage = () => {
  const classes = useStyles();

  const userSelector = useSelector(state => state?.users?.data?.User);

  const dispatch = useDispatch();
  // const [profileData, setProfileData] = useState({
  //   username: '',
  //   firstname: '',
  //   lastname: '',
  //   email: '',
  //   website: '',
  //   bio: '',
  //   avatar: null,
  //   password: '',
  //   sessions: '',
  //   addressLine1: '',
  //   addressLine2: '',
  //   city: '',
  //   pincode: '',
  //   state: '',
  //   country: '',
  //   phone: '',
  // });
  const [userFromSelector, setUserFromSelector] = useState({});

  useEffect(() => {
    const fetchAdminInfo = async () => {
      const fetchAdminInfoPayload = {
        id: JSON.parse(secureLocalStorage.getItem('users'))._id,
      };
      await dispatch(usersGetByAction(fetchAdminInfoPayload));
    };

    fetchAdminInfo();
  }, []);

  useEffect(() => {
    if (userSelector && Object.keys(userSelector).length !== 0) {
      setUserFromSelector({
        username: userSelector?.username || '',
        firstname: userSelector?.firstname || '',
        lastname: userSelector?.lastname || '',
        email: userSelector?.email || '',
        website: userSelector?.website || '',
        bio: userSelector?.biographical_information || '',
        avatar: userSelector?.linkedMedia?.server_image_url || '',
        addressLine1: userSelector?.address_line_1 || userSelector?.address || '',
        addressLine2: userSelector?.address_line_2 || '',
        city: userSelector?.city || '',
        pincode: userSelector?.pincode || '',
        state: userSelector?.state || '',
        country: userSelector?.country || '',
        phone: userSelector?.phone || '',
      });
    };
    console.log('userSelector', userSelector);
  }, userSelector);

  const handleProfileDataChange = (event) => {
    const { name, value } = event.target;
    setUserFromSelector((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUserFromSelector((prevData) => ({
        ...prevData,
        avatar: reader.result,
      }));
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content r-txt">
          <h6 style={{ marginBottom: '20px', borderBottom: 'none', fontSize: '25px' }}>Profile </h6>
          <Paper style={{ padding: '20px' }}>
            <Avatar alt="Profile Picture" src={userFromSelector?.avatar} className={classes.avatar} />

            <FormControl>
              {/* <InputLabel htmlFor="avatar-upload">Profile Picture</InputLabel> */}
              <Input style={{ width: '230px' }}
                type="file"
                id="avatar-upload"
                name="avatar"
                onChange={handleAvatarChange}
              />
              {/* {profileData.avatar && <Avatar alt="Profile Picture" src={profileData.avatar} className={classes.avatar} />} */}
              <FormHelperText style={{ textAlign: 'center' }}>Select an image file for your profile picture</FormHelperText>
            </FormControl>

            <form className={classes.form} onSubmit={handleSubmit}>
              <Typography variant="h6" className={classes.heading}>
                Profile Information
              </Typography>
              <div className={classes.columnContainer}>
                <TextField
                  label="Username"
                  name="username"
                  value={userFromSelector?.username || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="First Name"
                  name="firstname"
                  value={userFromSelector?.firstname || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <div className={classes.columnContainer}>
                <TextField
                  label="Last Name"
                  name="lastname"
                  value={userFromSelector?.lastname || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="Email"
                  name="email"
                  value={userFromSelector?.email || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <div className={classes.columnContainer}>
                <TextField
                  label="Website"
                  name="website"
                  value={userFromSelector?.website || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="Biographical Information"
                  name="bio"
                  value={userFromSelector?.bio || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <Typography variant="h6" className={classes.heading}>
                Contact Details
              </Typography>
              <TextField
                label="Phone Number"
                name="phone"
                value={userFromSelector?.phone || ''}
                onChange={handleProfileDataChange}
                fullWidth
              />
              <div className={classes.columnContainer}>
                <TextField
                  label="Address Line 1"
                  name="addressLine1"
                  value={userFromSelector?.addressLine1 || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="Address Line 2"
                  name="addressLine2"
                  value={userFromSelector?.addressLine2 || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <div className={classes.columnContainer}>
                <TextField
                  label="City"
                  name="city"
                  value={userFromSelector?.city || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="Pincode"
                  name="pincode"
                  value={userFromSelector?.pincode || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <div className={classes.columnContainer}>
                <TextField
                  label="State"
                  name="state"
                  value={userFromSelector?.state || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
                <TextField
                  label="Country"
                  name="country"
                  value={userFromSelector?.country || ''}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </div>
              <Typography variant="h6" className={classes.heading}>
                Account Management
              </Typography>
              <TextField
                label="New Password"
                name="password"
                value={userFromSelector?.password || ''}
                onChange={handleProfileDataChange}
                fullWidth
                type="password"
              />
              <TextField
                label="Sessions"
                name="sessions"
                value={userFromSelector?.sessions || ''}
                onChange={handleProfileDataChange}
                fullWidth
              />

              <Button variant="contained" color="primary" type="submit" className="btn-bg">
                Update Profile
              </Button>
            </form>
          </Paper>
        </div>
      </div>
    </Layout>
  );
};

export default AdminProfilePage;

