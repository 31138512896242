/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Terms & Conditions</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Typography variant="body1">
        Effective Date: 19/09/2023
      </Typography>
      <Typography variant="body1" style={{ marginTop: '16px' }}>
        <strong>1. Introduction</strong>
        <br /><br />
        These Terms and Conditions ("T&C") govern your use of our Ayurvedic wellness services and website. By accessing our clinic's services or website, you agree to comply with and be bound by these T&C. If you do not agree with any part of these terms, please do not use our services or website.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>2. Clinic Services</strong>

        <br /><br />

        <span style={{ fontWeight: 'medium',m: 1 }}>a.Holistic Wellness Services: </span> Vedanta Wellness offers Ayurvedic consultations, treatments, therapies, and wellness programs. These services are provided by qualified and certified Ayurvedic practitioners and are intended to promote holistic health and well-being.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>b.</span> Medical Disclaimer: We strongly recommend informing your healthcare provider (if any) about any Ayurvedic treatments you receive at our clinic.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>c.</span> Individualized Treatment: Our Ayurvedic treatments are personalized based on your health history, constitution (Prakriti), and current imbalances (Vikriti). The effectiveness of treatments may vary based on individual factors, adherence to recommended therapies, and lifestyle choices.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>3. Consultations and Appointments</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Scheduling: Appointments can be scheduled online or by contacting our clinic directly. We recommend scheduling appointments in advance to secure your preferred time slot.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>b.</span> Cancellation and Rescheduling: We understand that plans may change. Please provide at least 24 hours' notice for appointment cancellations or rescheduling. Failure to do so may result in a fee.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>4. Fees and Payments</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Payment: Fees for services are outlined on our website or provided during consultation. Payment is due before the time of service.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>b.</span> Accepted Payment Methods: We accept cash, credit cards, and other payment methods as specified on our website.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>c.</span> No Refunds: Fees for services are non-refundable.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>5. Privacy and Confidentiality</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Patient Information: We respect your privacy and protect the confidentiality of your personal and health information in accordance with applicable data protection laws. For more details, please refer to our Privacy Policy.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>6. Code of Conduct</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Respect and Professionalism: We expect all clients to treat our staff and other clients with respect and professionalism. We reserve the right to refuse or terminate services to anyone engaging in inappropriate behavior.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>b.</span> Clinic Practice and Privacy: We are committed to ensuring your privacy during your visit to our clinic. We adhere to strict professional and legal standards to safeguard your confidentiality, and you can trust that your privacy will be fully respected throughout your time at our facility.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>7. Liability and Disclaimers</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Limitation of Liability: Vedanta Wellness and its staff are not liable for any direct, indirect, consequential, or special damages arising from the use or misuse of our services or website.

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>b.</span> Accuracy of Information: While we strive to provide accurate information, we do not warrant the accuracy, completeness, or reliability of information on our website.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>8. Amendments to Terms & Conditions</strong>

        <br /><br />

        <span style={{ fontWeight: 'bold' }}>a.</span> Updates: We may update these T&C from time to time. It is your responsibility to review them periodically. Continued use of our services or website after changes constitutes acceptance of the updated terms.

      </Typography>

      <Typography variant="body1" style={{ marginTop: '16px' }}>

        <strong>9. Contact Information</strong>

        <br /><br />

        If you have any questions or concerns about these T&C or our services, please contact us at cs@vedanta-wellness.com.

      </Typography>

    </div>
</>

  );

};
export default TermsAndConditions;
