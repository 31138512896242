import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { tagsCreateAction, tagsAction, tagsUnshowByAction } from '../../../redux/actions/tagActions';

import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginRight: theme.spacing(2),
  },
}));

function ProductTagsPage() {
  const tagSelector = useSelector(state => state?.tags?.data);

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');

  const [tagsFromSelector, setTagsFromSelector] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'slug', headerName: 'Slug', width: 100 },
    { field: 'description', headerName: 'Description', width: 120 },
    { field: 'createdAt', headerName: 'createdAt', width: 100 },
    { field: 'updatedAt', headerName: 'UpdatedAt', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <>
          {/* <IconButton>
            <Edit onClick={() => handleEditAPage(params?.row)} />
          </IconButton> */}
          <IconButton onClick={() => handleDeleteTag(params?.row?._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    async function fetchAllCategories() {
      await dispatch(tagsAction());
    }

    fetchAllCategories();
  }, [dispatch]);

  useEffect(() => {
    if (tagSelector && !Array.isArray(tagSelector)) {
      if (tagSelector[0] && tagSelector[0]?.tagResponse !== null && Object.keys(tagSelector[0]?.categoryResponse) !== 0) {

        const aTag = tagSelector[0].tagResponse;

        setTagsFromSelector([...tagsFromSelector, {
          ...aTag,
          id: aTag._id,
          image: aTag.linkedMedia !== null ? aTag?.linkedMedia?.server_image_url : '',
          createdAt: aTag?.createdAt?.split('T')[0],
          updatedAt: aTag?.updatedAt?.split('T')[0],
        }]);
        setFilteredTags([...tagsFromSelector, {
          ...aTag,
          id: aTag._id,
          image: aTag.linkedMedia !== null ? aTag?.linkedMedia?.server_image_url : '',
          createdAt: aTag?.createdAt?.split('T')[0],
          updatedAt: aTag?.updatedAt?.split('T')[0],
        }]);
      }
    } else if (tagSelector && Array.isArray(tagSelector) && Object.keys(tagSelector) !== 0) {
      setTagsFromSelector(tagSelector.map(aTag => ({
        ...aTag,
        id: aTag._id,
        image: aTag.linkedMedia !== null ? aTag?.linkedMedia?.server_image_url : '',
        createdAt: aTag?.createdAt?.split('T')[0],
        updatedAt: aTag?.updatedAt?.split('T')[0],
      })));
      setFilteredTags(tagSelector.map(aTag => ({
        ...aTag,
        id: aTag._id,
        image: aTag.linkedMedia !== null ? aTag?.linkedMedia?.server_image_url : '',
        createdAt: aTag?.createdAt?.split('T')[0],
        updatedAt: aTag?.updatedAt?.split('T')[0],
      })));
    }
  }, [tagSelector]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleAddTag = async () => {
    const tagPayload = {
      name: name,
      slug: slug,
      description: description,

      isDeleted: false,
      content_type: 'tag',
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    await dispatch(tagsCreateAction(tagPayload));

    // setTagsFromSelector
    setTagsFromSelector([...tagsFromSelector, tagPayload]);


    setName('');
    setSlug('');
    setDescription('');
  };

  const handleDeleteTag = async (id) => {
    const deleteTagPayload = {
      id: id,
      isDeleted: true,
      content_type: 'tag',
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
    };
    await dispatch(tagsUnshowByAction(deleteTagPayload));
    setTagsFromSelector(tagsFromSelector.filter(aTag => aTag._id !== id));
    // setOriginalCategories(tagsFromSelector.filter(aTag => aTag._id !== id)); // Update original tagsFromSelector
  };

  const handleFilterChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = tagsFromSelector.filter(
      (aTag) =>
        aTag.name.toLowerCase().includes(searchQuery) ||
        aTag.slug.toLowerCase().includes(searchQuery)
    );
    setFilteredTags(filteredResults);
  };

  return (
    <Layout>
      <div className="add-categ-container">
        <div className="add-page-contents add-page-content add-categ-lft">
          <h6>Add New Tag </h6>

          <form style={{ border: '1px solid #dddddd', padding: '30px', marginTop: '20px', backgroundColor: '#efefef' }}>
            <TextField
              name="name"
              label="Name"
              value={name}
              onChange={handleNameChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              name="slug"
              label="Slug"
              value={slug}
              onChange={handleSlugChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              name="description"
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              multiline
              rows={4}
              sx={{ marginBottom: 2 }}
            />
            <Button className="btn-bg" variant="contained" onClick={handleAddTag}>
              Add Tag
            </Button>
          </form>
        </div>

        <div className="add-categ-rgt" style={{ marginRight: '30px', marginTop: '90px' }}>
          <TextField
            label="Search"
            value={searchTerm}
            onChange={handleFilterChange}
            className={classes.searchInput}
            size="small"
          />

          <DataGrid
            rows={filteredTags}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            checkboxSelection
          />
        </div>
      </div>
    </Layout>
  );
}

export default ProductTagsPage;
