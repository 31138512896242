import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const Hyperthyroidism = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Hyperthyroidism 
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is Hyperthyroidism ?
            </Typography>
            <Typography variant="body1">
            Hyperthyroidism, also called overactive thyroid, is a condition where your thyroid makes and releases high levels of thyroid hormone. This condition can make your metabolism speed up. Symptoms of hyperthyroidism include a rapid heartbeat, weight loss, increased appetite and anxiety. Hyperthyroidism can be treated with antithyroid drugs, radioactive iodine, beta blockers and surgery.            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of Hyperthyroidism 
            </Typography>
            <Typography variant="body1">
              Some common symptoms of Hyperthyroidism  include:
              <ul>
                <li>Rapid heartbeat</li>
                <li>Feeling shaky and/or nervous.</li>
                <li>Weight loss.</li>
                <li>Increased appetite.
</li>
                <li>Diarrhea and more frequent bowel movements.
                </li>
                <li>Vision changes.</li>
                <li>Thin, warm and moist skin.</li>
                <li>Menstrual changes.
</li>
                <li>Intolerance to heat and excessive sweating.</li>
                <li>Sleep issues.
                </li>
                <li>Swelling and enlargement of the neck from an enlarged thyroid gland (goiter).</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Hyperthyroidism 
            </Typography>
            <Typography variant="body1">
              Hyperthyroidism  can be caused by various factors, including:
              <ul>
                <li>Graves’ disease</li>
                <li>overactive thyroid nodules
</li>
                <li>inflammation of the thyroid gland, called thyroiditis</li>
                <li>too much iodine </li>
                <li>too much thyroid hormone medicine</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for Hyperthyroidism 
            </Typography>
            <Typography variant="body1">
            You are at higher risk for hyperthyroidism if you:
              <ul>
                <li>Are a woman </li>
                <li>Are older than age 60</li>
                <li>Have been pregnant or had a baby within the past 6 months</li>
                <li>Have had thyroid surgery or a thyroid problem, such as goiter</li>
                <li>Have a family history of thyroid disease</li>
                <li>Have type 1 diabetes or primary adrenal insufficiency, a hormonal disorder</li>
                <li>Get too much iodine, from eating large amounts of foods containing iodine or using iodine-containing medicines or supplements</li>
              
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              While Hyperthyroidism  cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul>
                <li>Get your hormone levels checked all through pregnancy and 6 months after giving birth.</li>
                <li>Control your diabetes.</li>
                <li>Check your Vitamin B12 levels.People with Pernicious anemia are at higher risk</li>
                <li>Check your thyroid Level Regularly, if you have a family history.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </Container>
  );
};

export default Hyperthyroidism ;
