/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Typography, Grid } from "@mui/material";

const DiscBulging = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Disc Bulging </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Disc Bulging?</strong>
        </Typography>

        <Typography variant="body1">
         A bulging, or herniated, disk occurs when the spongy center of a disk in the spine pushes out through a tear in the outer, rubbery portion of the disk. It can press on the spinal cord and nerve roots, leading to pain and problems with mobility. Bulging disks are usually due to age-related degeneration, while symptoms tend to progress gradually. People also call them herniated, ruptured, or protruding disks.
        </Typography>
        </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Disc Bulging </strong>
        </Typography>

        <Typography variant="body1">
         Some common symptoms of Disc Bulging include:
         <ul className='vw-ul'>
         <li> Back pain that worsens with movement, such as when sneezing{" "} </li>
         <li> <b>Tingling.</b> This is similar to the feeling you have when a leg falls asleep because you sat cross-legged.{" "} </li>
         <li> Spasms in the back muscles</li>
         <li> Weakness and numbness in the legs and feet</li>
         <li> Reduced mobility in the legs, knees, and ankles</li>
         <li> Decreased bladder and bowel control</li>
         <li> Difficulty walking</li>
         </ul>
         </Typography>

        </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Disc Bulging </strong>
        </Typography>

        <Typography variant="body1">
         Common causes of Disc Bulging include:
         <ul className='vw-ul'>
         <li> People who have led a sedentary lifestyle or those who smoke increase the chances for bulging disc. </li>
         <li> Continuous strain on the disc from injury or heavy lifting and strain can wear them down throughout the years. </li>
         <li> Weakened back muscles can accelerate the process and may lead to a sudden herniation of the weakened disc. </li>
         <li> Although bulging discs occur over time, herniated discs may occur quickly by trauma. </li>
         <li> Bad posture including improper body positioning during sleep, sitting, standing or exercise are all risk factors which may contribute to the development of a bulging disc. </li>
         <li> High contact sports or activities are also risk factors.</li>
         <li> Runners who fail to use shoes that provide orthopedic support may also develop bulging discs. </li>
         <li> Activities that place stress and strain on the spine can lead to the weakening of the discs. </li>
         </ul>

          </Typography>
        </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Disc Bulging </strong>
        </Typography>

         <Typography variant="body1">
          Some common risk factors for developing Disc Bulging include:
          <ul className='vw-ul'>
          <li> Excess body weight causes extra stress on the disks in the lower back. Occupation. </li>
          <li> People with physically demanding jobs have a greater risk of back problems. </li>
          <li> Repetitive lifting, pulling, pushing, bending sideways and twisting also can increase the risk of a herniated disk. </li>
          <li> Driving frequently.</li>
          <li> Having a sedentary lifestyle with limited physical activity. </li>
          <li> Smoking, as it may accelerate degeneration by reducing the oxygen supply to the disk. </li>
          </ul>

          </Typography>
        </Grid>

        <Grid item xs={12}>

        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>

        <Typography variant="body1">
         Preventing a bulging disk is not always possible, as disk gel naturally degrades over time. However, people can take the following steps to prevent a bulging disk from becoming severe:
       
         <ul className='vw-ul'>
         <li> Reaching or maintaining a moderate body weight to reduce pressure on the vertebrae </li>
         <li> Keeping physically active to strengthen the muscles surrounding the spine </li>
         <li> Staying flexible and taking breaks to stand and stretch when sitting for long periods </li>
         <li> Practicing proper posture to reduce stress on the spine</li>
         </ul>

          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default DiscBulging;
