/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ProductListCard from '../Components/Common/ProductListCard';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategoriesWithTheirAssociatedProductsAction } from '../redux/actions/categoryActions';

const CategoryList = () => {
  const categoriesSelector = useSelector(state => state?.categories?.data);
  const location = useLocation();
  const dispatch = useDispatch();

  const [categoriesFromSelectorOrLocation, setCategoriesFromSelectorOrLocation] = useState([]);

  // console.log('location', location);

  const { id } = useParams();

  useEffect(() => {
    const fetchCategoriesAccordingly = async () => {
      if (location.state === null) {
        await dispatch(getAllCategoriesWithTheirAssociatedProductsAction());
      }
    };

    fetchCategoriesAccordingly();
  }, []);

  useEffect(() => {
    console.log('loocation', location, location.state, location?.state !== null);
    if (location?.state !== null) {
      console.log('looooooooooooooo', location?.state);
      setCategoriesFromSelectorOrLocation(location?.state);
    } else if (categoriesSelector && Object.keys(categoriesSelector).length !== 0) {
      setCategoriesFromSelectorOrLocation(categoriesSelector.filter(aCategory => aCategory.route === id)[0]);
    }

  }, [location, categoriesSelector]);

  console.log('categoriesFromSelectorOrLocation', categoriesFromSelectorOrLocation);

  // const handleGoBack = () => {
  //   // Handle the back button action here
  // };

  // eslint-disable-next-line no-console, no-undef
  // console.log('categoryInfo', categoryInfo);
  // const category = categoryInfo.find((category) => category.id === id);

  // if (!category) {
  //   return (
  //     <Container>
  //       <Typography variant="h4">Category not found</Typography>
  //     </Container>
  //   );
  // }

  return (
    <>
      <div className="inner-container">
        <h2 className="dnt-heading">{categoriesFromSelectorOrLocation.name}
          <Button
            variant="contained"
            color="primary" className="vw-main-btn vw-back-btn"
            component={Link}
            to="/services-product"
          >
            Back
          </Button>
        </h2>
      </div>
      <Container>


        <Grid container className="product-containers" alignItems="center" justifyContent="space-between" spacing={2} sx={{ marginTop: '5px' }}>
          {/* <div item>
          <Typography variant="h6">
            <IconButton component={Link} to="/Slides">
              <HomeIcon />
            </IconButton>{" "}
            {" "}

          </Typography>
          <div className="item-1">
          {" "}
            <Button
              variant="contained"
              color="primary" className="vw-main-btn vw-back-btn"
              component={Link}
              to="/services-product"
            >
              Back
            </Button>{" "}
          </div>
        </div> */}
          {/* <Divider /> */}

          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            {categoriesFromSelectorOrLocation.length !== 0 && categoriesFromSelectorOrLocation.products.map((product) => (
              <Grid item key={product.id} xs={12} sm={6} md={3}>
                <ProductListCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CategoryList;
