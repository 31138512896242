import React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';

export default function AyurvedicTherapies() {
  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Ayurvedic Abhyangam | Steaming & Lepanam Therapies | Bundle Massages </title>
    <meta name="description" content="Visit for Ayurvedic Abhyangam, steaming, lepanam therapies & bundle massages. Experience rejuvenation through ancient healing methods." />
    </Helmet>

      <div className="inner-container"><h2 className="dnt-heading">Ayurvedic Therapies</h2></div>
      <Grid spacing={2} item xs={12} md={12}>
        <Grid p={5}>
          <Box item sx={{ width: '100%', textAlign: 'center' }}>
            <Box item spacing={2} sx={{ paddingTop: '20px', paddingBottom: '20px', width: '100%', textAlign: 'center' }}>
              <Typography variant="p" className='typo-p'>
                <p>There are many treatments under Ayurveda for managing the locomotive diseases. Major treatments are mentioned here.</p>
              </Typography>
            </Box>
          </Box>
          <Grid container>
            <Grid md={3} sm={6} xs={12}>
              <Card className='therapiescard' sx={{ borderBottom: '3px solid #0b8140' }}>
                <CardMedia
                  component="img"
                  // height="180"
                  image="../images/Mask-Group-6-1.png"
                  alt=""
                />
                <CardContent>
                  <strong><span style={{ color: '#0b8140' }}>Abhyangam</span> (Ayurvedic Massage)</strong>
                  <Typography variant="body2" color="text.secondary">
                    In this treatment medicated oil will be applied all over the body or part of the body as per the suggestion of the physician seeing the disease condition.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={3} sm={6} xs={12}>
              <Card className='therapiescard' sx={{ borderTop: '3px solid #0b8140' }}>
                <CardContent>
                  <strong><span style={{ color: '#0b8140' }}>Swedanam</span> (Steaming)</strong>
                  <Typography variant="body2" color="text.secondary">
                    The patient is asked to sit in a steam box fully covered except the head. Medicines are put in boluses and kept inside the steam generator or cooker along with water.
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  // height="180"
                  image="../images/Mask-Group-6-2.png"
                  alt=""
                />
              </Card>
            </Grid>
            <Grid md={3} sm={6} xs={12}>
              <Card className='therapiescard' sx={{ borderBottom: '3px solid #0b8140' }}>
                <CardMedia
                  component="img"
                  // height="180"
                  image="../images/Mask-Group-6.png"
                  alt=""
                />
                <CardContent>
                  <strong style={{ color: '#0b8140' }}>Sirodhara / Thakradhara / Kashaya Dhara</strong>
                  <Typography variant="body2" color="text.secondary">
                    In this treatment, medicated warm oil, medicated buttermilk, decoctions, etc. are poured continuously for stipulated timing with the suggestion of a doctor.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={3} sm={6} xs={12}>
              <Card className='therapiescard' sx={{ borderTop: '3px solid #0b8140' }}>
                <CardContent>
                  <strong><span style={{ color: '#0b8140' }}>Lepanam </span>(Application Of Medicinal Paste)</strong>
                  <Typography variant="body2" color="text.secondary">
                    In this treatment a paste is made of <br /> different medicines. The paste is applied over <br /> the affected area with bearable heat for <br /> stipulated time.
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  // height="180"
                  image="../images/Mask-Group-6-3.png"
                  alt=""
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
