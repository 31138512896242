export const userData = [
  {
    name: 'Jan',
    'Active User': 4000,
  },
  {
    name: 'Feb',
    'Active User': 3000,
  },
];
export const productData = [
  {
    name: 'Jan',
    'Sales': 4000,
  },
  {
    name: 'Feb',
    'Sales': 3000,
  },
];
export const userRows = [
  {
    id: 1,
    name: 'Test User 1',
    username: 'testuser-1',
    phone: '987654321',
    avatar:
      'https://images.gr-assets.com/authors/1561336084p8/4123863.jpg',
    email: 'testuser-1@gmail.com',
    status: 'active',
    transaction: '950.00',
  },
  {
    id: 2,
    name: 'Test User 2',
    username: 'testuser-2',
    phone: '987654123',
    avatar:
      'https://images.gr-assets.com/authors/1561336084p8/4123863.jpg',
    email: 'testuser-2@gmail.com',
    status: 'active',
    transaction: '320.00',
  },
];
export const productRows = [
  {
    id: 1,
    name: 'Apple Airpods',
    img:
      'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 2,
    name: 'Apple Watch Series 6',
    img:
      'https://www-konga-com-res.cloudinary.com/w_auto,f_auto,fl_lossy,dpr_auto,q_auto/media/catalog/product/G/I/174379_1610801608.jpg',
    stock: 123,
    status: 'active',
    price: '$270.00',
  },
];