import React from 'react';
import { Typography, Grid } from '@mui/material';

const Osteoarthritis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Osteoarthritis</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Osteoarthritis?</strong>
        </Typography>
            <Typography variant="body1">
            Osteoarthritis (OA) is a degenerative joint disease that primarily affects the cartilage, the
protective tissue covering the ends of bones in a joint. It is the most common form of arthritis and
often develops gradually over time. Osteoarthritis is characterized by the breakdown and loss of
cartilage, which leads to joint pain, stiffness, and reduced mobility. The exact cause of
osteoarthritis is not fully understood, but it is believed to involve a combination of factors including
age, genetics, joint overuse or injury, obesity, and underlying joint abnormalities. Common
symptoms of osteoarthritis include joint pain, stiffness (especially after periods of inactivity),
swelling, and a decreased range of motion.
</Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Osteoarthritis</strong>
        </Typography>
        <Typography variant="body1">
        <p>Osteoarthritis symptoms often develop slowly and worsen over time. Signs and symptoms of osteoarthritis include:</p>
        <ul className='vw-ul'>
        <li><b>Pain:</b> Affected joints might hurt during or after movement.</li>
        <li><b>Stiffness: </b>Joint stiffness might be most noticeable upon awakening or after being inactive.</li>
        <li><b>Tenderness:</b> Your joint might feel tender when you apply light pressure to or near it.</li>
        <li><b>Loss of flexibility:</b> You might not be able to move your joint through its full range of motion.</li>
        <li><b>Swelling:</b> This might be caused by soft tissue inflammation around the joint.</li>
        </ul>
        </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Osteoarthritis </strong>
        </Typography>
            <Typography variant="body1">
              Osteoarthritis can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Old age</li>
                <li>Women’s are more likely to develop osteoarthritis</li>
                <li>Obesity</li>
                <li>Joint injuries</li>
                <li>Repeated stress on the joint</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Osteoarthritis</strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Osteoarthritis include:
              <ul className='vw-ul'>
                <li>Previous joint injury</li>
                <li>Obesity</li>
                <li>Poor posture</li>
                <li>Weather changes (Cold, damp weather can worsen the symptoms)</li>
                <li>Over use of joints</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
            </Typography>
            <Typography variant="body1">
              While Osteoarthritis cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
              <li>Keep a healthy body weight</li>
              <li>control your Blood sugar</li>
              <li>Be active</li>
              <li>Prevent injuries to your joint</li>
              </ul>
            </Typography>
       
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Osteoarthritis;
