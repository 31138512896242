/* eslint-disable no-unused-vars */
import React from 'react';
import FeaturedInfo from '../FeaturedInfo';
import LeatherBoard from '../LeatherBoard';
import OrderChart from '../OrderChart';
import SalesChart from '../SalesChart';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
// import { }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(7),
    paddingLeft: theme.spacing(35), // Add left padding to content area
  },
  orderChartItem: {
    marginTop: theme.spacing(2), // Add bottom margin to OrderChart item
  },
  LeatherBoardItem: {},
}));

function Home() {
  const classes = useStyles();
  secureLocalStorage.setItem('sideBarSubHeadingId', JSON.stringify(0));
  // const blogsResponseFromHome = useSelector(state => state);
  // const dateRange = {
  //   startDate: new Date('2022-01-01'),
  //   endDate: new Date('2022-12-31'),
  // }; // Define the date range object

  return (
    <div className="home">
      <Sidebar />
      <div className={classes.root}>
        <Topbar />
        <div className="ad-home">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FeaturedInfo />
            </Grid>
            <Grid item xs={6}>
              <SalesChart /> {/* Pass the dateRange object to SalesChart */}
            </Grid>
            <Grid item xs={6} className={classes.orderChartItem}>
              <OrderChart />
            </Grid>
            <Grid item xs={12} className={classes.LeatherBoardItem}>
              <LeatherBoard />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Home;
