/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const HeelPain = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Heel Pain </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Heel Pain?</strong>
            </Typography>
            <Typography variant="body1">
            Heel pain refers to discomfort or pain experienced in the heel area of the foot. It can
be caused by various conditions, including plantar fasciitis (inflammation of the
plantar fascia), Achilles tendinitis (inflammation of the Achilles tendon), heel spurs
(bony growths on the heel bone), and stress fractures. Symptoms of heel pain may
include sharp or stabbing pain in the heel, especially when walking or standing,
tenderness, swelling, and difficulty bearing weight on the affected foot. Contributing
factors can include overuse or repetitive strain, improper footwear, sudden impact or
injury, obesity, and certain medical conditions like arthritis.             </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Heel Pain</strong>
        </Typography>
            <Typography variant="body1">
            Heel pain symptoms vary depending on the cause. In addition to pain, you may experience:
            <ul className='vw-ul'>
                <li>Bony growth on the heel.</li>
                <li>Discoloration (bruising or redness).</li>
                <li>Stiffness.</li>
                <li>Swelling.</li>
                <li>Tenderness.</li>
                <li>Pain after standing from a resting/sitting position.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Heel Pain </strong>
            </Typography>
            <Typography variant="body1">
              Anything that puts a lot of pressure and strain on your foot can cause heel pain. The way you walk (foot mechanics) and your foot's shape (foot structure) are also factors.
              You may be more likely to develop heel pain if you:
              <ul className='vw-ul'>
                <li>Are overweight (have obesity).</li>
                <li>Have foot and ankle arthritis, flat feet or high foot arches.</li>
                <li>Run or jump a lot in sports or for exercise</li>
                <li>Spend a lot of time standing, especially on concrete floors.</li>
                <li>Wear improperly fitted shoes without arch support and/or cushion.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Risk Factors for Heel Pain </strong>
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Heel Pain include:
              <ul className='vw-ul'>
                <li>Having tight calf muscles</li>
                <li>Having high arches</li>
                <li>Running excessively </li>
                <li>Being an older adult</li>
                <li>Carrying extra weight</li>
                <li>Having a job that requires a lot of standing or walking</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Heel Pain cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>wearing shoes when on hard ground, and not going barefoot</li>
                <li>reaching or maintaining a moderate body weight to reduce stress on the heels</li>
                <li>choosing footwear with heels made of material that can absorb some stress or using inserted heel pads </li>
                <li>ensuring that shoes fit properly and do not have worn down heels or soles </li>
                <li>resting the feet rather than standing, when possible </li>
                <li>warming up properly before engaging in sports and activities that may place a lot of stress on the heels</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default HeelPain;
