/* eslint-disable react/prop-types */
import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import './RegisterForm.css';
import InputField from '../../Components/Common/Input/InputField';
import AccountSuccess from '../../Components/Common/Modal/AccountSuccess';
import { Helmet } from 'react-helmet';

export default function RegisterForm(props) {
  // eslint-disable-next-line react/prop-types
  const { handleInput, handleSubmit, registerPage, registerPageErrors, redirectHandler } = props;
  const users = useSelector((state) => state.users);
  const showModal = useSelector((state) => state.users.showModal);

  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Membership - Ayurveda For Stress | Vedanta-wellness.com </title>
    <meta name="description" content="Explore our membership for Ayurveda stress relief at Vedanta-wellness.com. Embrace holistic well-being with ancient techniques and personalized care." />
    </Helmet>

      <div className="m-container">
        <h1 className="membership">Membership</h1>
        <Divider />
        <Grid container spacing={2} className="form-box">
          <Grid item xs={6}>
            <InputField
              type="text"
              membership={true}
              htmlFor="exampleFormControlInput1"
              labelClassName="form-label"
              labelText="Username"
              className="form-control"
              name="username"
              id="exampleFormControlInput1"
              placeholder=""
              value={registerPage.username}
              onChangeFn={handleInput}
              errCss={registerPageErrors.usernameError ? 'error' : ''}
            />
            {registerPageErrors.usernameError && (
              <p className="error-message error-vw">{registerPageErrors.usernameError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              membership={true}
              htmlFor="exampleFormControlInput2"
              labelClassName="form-label"
              labelText="First Name"
              className="form-control"
              name="firstname"
              id="exampleFormControlInput2"
              placeholder=""
              value={registerPage.firstname}
              onChangeFn={handleInput}
              errCss={registerPageErrors.firstnameError ? 'error' : ''}
            />
            {registerPageErrors.firstnameError && (
              <p className="error-message error-vw">{registerPageErrors.firstnameError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputField
              membership={true}
              type="text"
              htmlFor="exampleFormControlInput3"
              labelClassName="form-label"
              labelText="Last Name"
              className="form-control"
              name="lastname"
              id="exampleFormControlInput3"
              placeholder=""
              value={registerPage.lastname}
              onChangeFn={handleInput}
              errCss={registerPageErrors.lastnameError ? 'error' : ''}
            />
            {registerPageErrors.lastnameError && (
              <p className="error-message error-vw">{registerPageErrors.lastnameError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            {users.loader && (
              <CircularProgress disableShrink className="loader" />
            )}
            <InputField
              type="email"
              membership={true}
              htmlFor="exampleFormControlInput4"
              labelClassName="form-label"
              labelText="Email Address"
              className="form-control"
              name="email"
              id="exampleFormControlInput4"
              placeholder=""
              value={registerPage.email}
              onChangeFn={handleInput}
              errCss={registerPageErrors.emailError ? 'error' : ''}
            />
            {showModal && (
              <AccountSuccess
                redirectHandler={redirectHandler}
                isShowing={showModal}
              />
            )}
            {registerPageErrors.emailError && (
              <p className="error-message error-vw">{registerPageErrors.emailError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
          <InputField
  type="text"
  membership={true}
  htmlFor="exampleFormControlInput5"
  labelClassName="form-label"
  labelText="Phone Number"
  className="form-control"
  name="phonenumber"
  id="exampleFormControlInput5"
  placeholder=""
  value={registerPage.phonenumber}
  onChangeFn={handleInput}
  errCss={registerPageErrors.phonenumberError ? 'error' : ''}
  pattern="[0-9]*" // Only allows numeric characters
/>

            {registerPageErrors.phonenumberError && (
              <p className="error-message error-vw">{registerPageErrors.phonenumberError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              membership={true}
              htmlFor="exampleFormControlInput6"
              labelClassName="form-label"
              labelText="Address"
              className="form-control"
              name="address"
              id="exampleFormControlInput6"
              placeholder=""
              value={registerPage.address}
              onChangeFn={handleInput}
              errCss={registerPageErrors.addressError ? 'error' : ''}
            />
            {registerPageErrors.addressError && (
              <p className="error-message error-vw">{registerPageErrors.addressError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="password"
              membership={true}
              htmlFor="exampleFormControlInput7"
              labelClassName="form-label"
              labelText="Password"
              className="form-control"
              name="password"
              id="exampleFormControlInput7"
              placeholder=""
              value={registerPage.password}
              onChangeFn={handleInput}
              errCss={registerPageErrors.passwordError ? 'error' : ''}
            />
            {registerPageErrors.passwordError && (
              <p className="error-message error-vw">{registerPageErrors.passwordError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="password"
              membership={true}
              htmlFor="exampleFormControlInput8"
              labelClassName="form-label"
              labelText="Confirm Password"
              className="form-control"
              name="confirmpassword"
              id="exampleFormControlInput8"
              placeholder=""
              value={registerPage.confirmpassword}
              onChangeFn={handleInput}
              errCss={registerPageErrors.confirmpasswordError ? 'error' : ''}
            />
            {registerPageErrors.confirmpasswordError && (
              <p className="error-message error-vw">{registerPageErrors.confirmpasswordError}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <button className="register-btn" onClick={handleSubmit}>
              Register
            </button>
          </Grid>
          <Grid item xs={6}>
          <a href="/login"> 
              <button className="login-btn"> Login </button>
              </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
