import React from 'react';
import Grid from '@mui/material/Grid';
import UserSection from '../Components/Common/Usersection';
import { useNavigate } from 'react-router-dom';


function Dashboard() {
  const navigate = useNavigate();
  return (
    <div className="us-container">
      <h1 className="us-header">User Dashboard</h1>
      <Grid container spacing={0}>
        <Grid item md={3} sm={12} xs={12}>
          <UserSection />
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <div className="container-right">
            <p>
              Hello <strong>User Name</strong>,
            </p>
            <p>From your account dashboard you can view your recent orders, manage your shipping and billing addresses, <br />
              and edit your password and account details.</p>
            <button type="" className="dashboard-btn" onClick={() => navigate('/order')}>Orders</button>
            <button className="dashboard-btn" onClick={() => navigate('/downloads')}>Downloads</button><br />
            <button type="" className="dashboard-btn" onClick={() => navigate('/address')}>Addresses</button>
            <button className="dashboard-btn" onClick={() => navigate('/Accountdetailspage')}>Account details</button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;