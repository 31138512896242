import React from 'react';
import '../css/Successful.css';


function Successful() {
  return (
    <div>
      <h1 className="successful-header">User have been successfully login</h1>
    </div>
  );
}

export default Successful;
