import React from 'react';
import { Typography, Grid } from '@mui/material';

const Hypoglycemia = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Hypoglycemia </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Hypoglycemia? </strong>
        </Typography>
            <Typography variant="body1">
            Hypoglycemia refers to a condition characterized by low blood sugar levels, typically below 70
mg/dL. It most commonly occurs in individuals with diabetes who take insulin or certain medications
to manage their blood sugar levels. However, it can also happen in individuals without diabetes due
to various reasons such as prolonged fasting, excessive alcohol consumption, certain medical
conditions, or medication side effects. Symptoms of hypoglycemia include shakiness, sweating,
dizziness, confusion, irritability, weakness, headache, and in severe cases, loss of consciousness or
seizures. </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Hypoglycemia </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Hypoglycemia include:
              <ul className='vw-ul'>
                <li>Shaking or trembling.</li>
                <li>Weakness.</li>
                <li>Sweating and chills</li>
                <li>Extreme hunger </li>
                <li>Faster heart rate.</li>
                <li>Dizziness or lightheadedness.</li>
                <li>Confusion or trouble concentrating.</li>
                <li>Anxiety or irritability.</li>
                <li>Color draining from your skin (pallor).</li>
                <li>Tingling or numbness in your lips, tongue or cheeks.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Hypoglycemia </strong>
        </Typography>
            <Typography variant="body1">
              Hypoglycemia can be caused by various factors including:
              <ul className='vw-ul'>
                <li>Taking too much insulin.</li>
                <li>Not eating enough carbs for how much insulin you take.</li>
                <li>Timing of when you take your insulin.</li>
                <li>The amount and timing of physical activity.</li>
                <li>Drinking alcohol</li>
                <li>How much fat, protein, and fiber are in your meal.</li>
                <li>Hot and humid weather.</li>
                <li>Going through puberty, menstruation.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Hypoglycemia </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Hypoglycemia include:
              <ul className='vw-ul'>
                <li>Seizures</li>
                <li>Nervous system damage</li>
                <li>Loss of consciousness</li>
                <li>Death</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Hypoglycemia cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Follow your healthcare provider’s instructions about food and exercise</li>
                <li>Use a continuous glucose monitor (CGM) or check your blood sugar regularly, including before and after meals, before and after exercise and before bed</li>
                <li>After you’ve treated a low blood sugar episode, write down the details of the episode. Include details such as the time, what you ate recently, whether you exercised, the symptoms and your glucose level. This can help you and your provider adjust your management plan to try and prevent future lows. </li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Hypoglycemia ;
