import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/categoryActionTypes';
import categoryApi from '../../apis/categoryApi';

function* categories(action) {
  try {
    const categoriesResponse = yield call(categoryApi.getAll, action);
    yield put({ type: type.ALL_CATEGORIES_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_CATEGORIES_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* categoriesGetAllCategoriesWithTheirAssociatedProducts(action) {
  try {
    const categoriesResponse = yield call(categoryApi.getAllCategoriesWithTheirAssociatedProducts, action);
    yield put({ type: type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* categoriesLoggedInUserCategories(action) {
  try {
    const categoriesResponse = yield call(categoryApi.loggedInUserCategories, action);
    yield put({ type: type.LOGGED_IN_USER_CATEGORIES_ID_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_CATEGORIES_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* categoriesGetBy(action) {
  try {
    const categoriesResponse = yield call(categoryApi.getBy, action);
    yield put({ type: type.CATEGORIES_GET_BY_ID_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.CATEGORIES_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* categoriesCreate(action) {
  try {
    const categoriesResponse = yield call(categoryApi.create, action);
    yield put({ type: type.CATEGORIES_CREATE_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.CATEGORIES_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* categoriesUpdateBy(action) {
  try {
    const categoriesResponse = yield call(categoryApi.updateBy, action);
    yield put({ type: type.CATEGORIES_UPDATE_BY_ID_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.CATEGORIES_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* categoriesUnshowBy(action) {
  try {
    const categoriesResponse = yield call(categoryApi.unshowBy, action);
    yield put({ type: type.CATEGORIES_UNSHOW_BY_ID_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.CATEGORIES_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* categoriesDeleteBy(action) {
  try {
    const categoriesResponse = yield call(categoryApi.deleteBy, action);
    yield put({ type: type.CATEGORIES_DELETE_BY_ID_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.CATEGORIES_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* findMostSoldCategory(action) {
  try {
    const categoriesResponse = yield call(categoryApi.findMostSoldCategory, action);
    yield put({ type: type.FIND_MOST_SOLD_CATEGORY_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.FIND_MOST_SOLD_CATEGORY_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* findTopCategoriesSold(action) {
  try {
    const categoriesResponse = yield call(categoryApi.findTopCategoriesSold, action);
    yield put({ type: type.FIND_TOP_CATEGORIES_SOLD_SUCCESS, categoriesResponse: categoriesResponse.data });
  } catch (e) {
    yield put({
      type: type.FIND_TOP_CATEGORIES_SOLD_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myCategorySaga() {
  yield takeEvery(type.ALL_CATEGORIES_REQUESTING, categories);
  yield takeEvery(type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_REQUESTING, categoriesGetAllCategoriesWithTheirAssociatedProducts);
  yield takeEvery(type.LOGGED_IN_USER_CATEGORIES_ID_REQUESTING, categoriesLoggedInUserCategories);
  yield takeEvery(type.CATEGORIES_GET_BY_ID_REQUESTING, categoriesGetBy);
  yield takeEvery(type.CATEGORIES_CREATE_REQUESTING, categoriesCreate);
  yield takeEvery(type.CATEGORIES_UPDATE_BY_ID_REQUESTING, categoriesUpdateBy);
  yield takeEvery(type.CATEGORIES_UNSHOW_BY_ID_REQUESTING, categoriesUnshowBy);
  yield takeEvery(type.CATEGORIES_DELETE_BY_ID_REQUESTING, categoriesDeleteBy);
  yield takeEvery(type.FIND_MOST_SOLD_CATEGORY_REQUESTING, findMostSoldCategory);
  yield takeEvery(type.FIND_TOP_CATEGORIES_SOLD_REQUESTING, findTopCategoriesSold);
}

export default myCategorySaga;
