/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Sciatica = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Sciatica </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Sciatica?</strong>
        </Typography>

        <Typography variant="body1">
        Sciatica refers to pain that radiates along the path of the sciatic nerve, which branches from your lower back through your hips and buttocks and down each leg. 
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Sciatica</strong>
        </Typography>

        <Typography variant="body1">
        Some common symptoms of Sciatica include:
        <ul className='vw-ul'>
        <li><b>Pain:</b> Sciatica pain happens because of pressure on the affected nerve(s). Most people describe sciatica pain as burning or like an electric shock. This pain also often shoots or radiates down the leg on the affected side. Pain commonly happens with coughing, sneezing, bending or lifting your legs upward when lying on your back. </li>
        <li><b>Tingling:</b> This is similar to the feeling you have when a leg falls asleep because you sat cross-legged. </li>
        <li><b>Numbness:</b> This is when you can’t feel sensations on the skin in the affected areas of your back or leg. It happens because signals from your back or leg are having trouble reaching your brain. </li>
        <li><b>Muscle weakness:</b> This is a more severe symptom. It means that muscle command signals are having trouble reaching their destinations in your back or legs. </li>
        <li><b>Urinary incontinence:</b> This is a very severe symptom. It means signals that control your bladder and bowels aren’t reaching their destinations.</li>
        </ul>
        </Typography>
        </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Sciatica </strong>
        </Typography>

        <Typography variant="body1">
         Common causes of sciatica include:
         <ul className='vw-ul'>
         <li> Slipped or herniated disk</li>
         <li> Spinal stenosis</li>
         <li> Piriformis syndrome (a pain disorder involving the narrow muscle in the buttocks) </li>
         <li> Pelvic injury or fracture </li>
         <li> Tumors</li>
         <li> Spondylolisthesis</li>
         </ul>
         </Typography>
         </Grid>

        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Sciatica </strong>
        </Typography>

        <Typography variant="body1">
        Some common risk factors for developing Sciatica include:
        <ul className='vw-ul'>
        <li><b>Having a current or previous injury.</b> An injury to your spine or lower back increases your risk of developing sciatica</li>
        <li><b>Normal wear-and-tear.</b> As you get older, normal wear-and-tear on your spine can lead to pinched nerves, herniated disks and other conditions that can cause sciatica. Age-related conditions like osteoarthritis can also play a role.</li>
        <li>Having excess weight or obesity.</li>
        <li>Having insufficient core strength.</li>
        <li>Not using good posture and form when lifting. </li>
        <li>Having diabetes.</li>
        <li><b>Physical inactivity.</b> Sitting for long periods and a lack of physical activity can contribute to an increased risk of sciatica.</li>
        <li><b>Using tobacco.</b> Nicotine use can affect circulation and increase your risk of chronic pain. That includes conditions like sciatica.</li>
        </ul>

        </Typography>
        </Grid>

        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>

        <Typography variant="body1">
         It's not always possible to prevent sciatica, and the condition can come back. To protect your back:
         <ul className='vw-ul'>
         <li> Exercise regularly. </li>
         <li> Keep good posture when sitting. </li>
         <li> Use your body correctly. </li>
         </ul>
         </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Sciatica;
