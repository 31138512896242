/* eslint-disable no-undef */
// sagas/appointmentSaga.js
import { put, takeEvery, call } from "redux-saga/effects";
import * as type from "../types";
import appointmentApi from "../../apis/appointmentApi";

function* appointmentForm(action) {
  console.log("Saga invoked with payload:", action.payload);
  try {
    const response = yield call(appointmentApi.submitAppointment, action.payload);
    console.log("API Response:", response);
    if (response && response.appointmentData) {
      console.log("hello from saga");
      yield put({ type: type.CREATE_APPOINTMENT_SUCCESS, response: response.appointmentData });
    } else {
      yield put({
        type: type.CREATE_APPOINTMENT_FAILURE,
        payload: "Failed to book the appointment",
      });
    }
  } catch (error) {
    console.error("API Error:", error);
    yield put({ type: type.CREATE_APPOINTMENT_FAILURE, payload: error.message });
  }
}

function* appointmentFormSaga() {
  yield takeEvery(type.CREATE_APPOINTMENT_REQUESTING, appointmentForm);
}

export default appointmentFormSaga;
