/* eslint-disable no-undef */
// contactApi.js
import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const contactApi = {
  submitContact: (data) => {
    const uri = HOST_ADDRESS + '/api/contact/submit-form';
    console.log('API Request Data:', data); // Debugging
    return axios.post(uri, data)
      .then((response) => {
        console.log('API Response:', response); // Debugging
        return response;
      })
      .catch((error) => {
        console.error('API Error:', error); // Debugging
        throw error; // Re-throw the error for further investigation
      });
  },
};



export default contactApi;
