import React from 'react';
import PropTypes from 'prop-types';
import '../../../css/Membership.css';

function InputField(props) {
  const {
    type,
    className,
    name,
    value,
    placeholder,
    onChangeFn,
    id,
    htmlFor,
    labelClassName,
    labelText,
    errCss,
    membership,
  } = props;
  return (
    <>
      {membership ? (
        <>
          {' '}
          <label htmlFor={htmlFor} className={labelClassName}>
            {labelText}
          </label>
          <input
            type={type}
            className={className}
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChangeFn}
          />
          <p className={errCss}>{ }</p>
        </>
      ) : (
        <>
          {' '}
          <input
            type={type}
            className={className}
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChangeFn}
          />
        </>
      )}
    </>
  );
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  labelClassName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  errCss: PropTypes.string.isRequired,
  membership: PropTypes.bool.isRequired,
};

export default InputField;
