import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';



const useStyles = makeStyles((theme) => ({
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', // Use space-between to separate label and input
        marginBottom: theme.spacing(2),
    //   marginLeft:'550px'
    },
    filterDropdown: {
        width: '200px',
    }
  
  }));
  
  // ... rest of your code
  

const PatientListPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const profileData = location.state && location.state.profileData ? location.state.profileData : [];



  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  const filteredprofileData = profileData
  ? profileData.filter(
      (patient) =>
        `${patient.firstname} ${patient.lastname}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
    )
  : [];


  const filteredAndFilteredprofileData = selectedFilter
    ? filteredprofileData.filter(
        (patient) => patient.patientListTitle === selectedFilter
      )
    : filteredprofileData;

  const patientListTitles = [
    // eslint-disable-next-line no-undef
    ...new Set(profileData.map((patient) => patient.patientListTitle)),
  ];

  return (
    <div className="add-pages-container">
      <div className="add-page-contents add-page-content r-txt">
        <h6 style={{ marginBottom: '20px', borderBottom: 'none', fontSize: '25px' }}>
          Patient List
        </h6>
        <div className={classes.filterContainer}>
          {/* <InputLabel className={classes.searchInputLabel}>Search Patient</InputLabel> */}
          <div className={classes.searchContainer}>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
            }}
          >
            <TextField
              fullWidth
              label="Search Patient"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
                          />
                           </Box>
          </div>
          <FormControl variant="outlined" className={classes.filterDropdown}>
            <InputLabel>Filter</InputLabel>
            <Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
                          label="Filter"
                          style={{ width: '200px' }} // Adjust this width as needed
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Filter By Name">Filter By Name</MenuItem>
              <MenuItem value="Last One Week">Last One Week</MenuItem>
              <MenuItem value="From Last To First">From Last To First</MenuItem>
              <MenuItem value="Filter By Date">Filter By Date</MenuItem>
              {patientListTitles.map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Sl No</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone No</b>
                  </TableCell>
                  <TableCell>
                    <b>Date of Treatment</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Treatment Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Filled By</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndFilteredprofileData.map((patient, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{`${patient.firstname || ''} ${
                      patient.lastname || ''
                    }`}</TableCell>
                    <TableCell>{patient.phoneNumber || ''}</TableCell>
                    <TableCell>{patient.date || ''}</TableCell>
                    <TableCell>{patient.address || ''}</TableCell>
                    <TableCell>{patient.treatmentName || ''}</TableCell>
                    <TableCell>{patient.filledByName || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default PatientListPage;
