import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/productActionTypes';
import productApi from '../../apis/productApi';

function* products(action) {
  try {
    const productsResponse = yield call(productApi.getAll, action);
    yield put({ type: type.ALL_PRODUCTS_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_PRODUCTS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsLoggedInUserProducts(action) {
  try {
    const productsResponse = yield call(productApi.loggedInUserProducts, action);
    yield put({ type: type.LOGGED_IN_USER_PRODUCTS_ID_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_PRODUCTS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsCreate(action) {
  try {
    const productsResponse = yield call(productApi.create, action);
    yield put({ type: type.PRODUCTS_CREATE_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsGetBy(action) {
  try {
    // eslint-disable-next-line no-console, no-undef
    console.log('!!!!!!1', action);
    const productsResponse = yield call(productApi.getBy, action);
    yield put({ type: type.PRODUCTS_GET_BY_ID_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsUpdateBy(action) {
  try {
    const productsResponse = yield call(productApi.updateBy, action);
    yield put({ type: type.PRODUCTS_UPDATE_BY_ID_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsUnshowBy(action) {
  try {
    const productsResponse = yield call(productApi.unshowBy, action);
    yield put({ type: type.PRODUCTS_UNSHOW_BY_ID_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* productsDeleteBy(action) {
  try {
    const productsResponse = yield call(productApi.deleteBy, action);
    yield put({ type: type.PRODUCTS_DELETE_BY_ID_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* currentMonthProductsVsPreviousMonth(action) {
  try {
    const productsResponse = yield call(productApi.currentMonthProductsVsPreviousMonth, action);
    yield put({ type: type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_SUCCESS, productsResponse: productsResponse.data });
  } catch (e) {
    yield put({
      type: type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myProductSaga() {
  yield takeEvery(type.ALL_PRODUCTS_REQUESTING, products);
  yield takeEvery(type.LOGGED_IN_USER_PRODUCTS_ID_REQUESTING, productsLoggedInUserProducts);
  yield takeEvery(type.PRODUCTS_CREATE_REQUESTING, productsCreate);
  yield takeEvery(type.PRODUCTS_GET_BY_ID_REQUESTING, productsGetBy);
  yield takeEvery(type.PRODUCTS_UPDATE_BY_ID_REQUESTING, productsUpdateBy);
  yield takeEvery(type.PRODUCTS_UNSHOW_BY_ID_REQUESTING, productsUnshowBy);
  yield takeEvery(type.PRODUCTS_DELETE_BY_ID_REQUESTING, productsDeleteBy);
  yield takeEvery(type.PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_REQUESTING, currentMonthProductsVsPreviousMonth);
}

export default myProductSaga;
