import { all } from "redux-saga/effects";
import blogSaga from "../saga/blogSaga";
import cartSaga from "../saga/cartSaga";
import categorySaga from "../saga/categorySaga";
import couponSaga from "../saga/couponSaga";
import customerSaga from "../saga/customerSaga";
import feedbackSaga from "../saga/feedbackSaga";
import mediaSaga from "../saga/mediaSaga";
import notificationSaga from "../saga/notificationSaga";
import orderSaga from "../saga/orderSaga";
import pageSaga from "../saga/pageSaga";
import productSaga from "../saga/productSaga";
import tagSaga from "../saga/tagSaga";
import userSaga from "../saga/userSaga";
import contactFormSaga from "../saga/contactFormSaga";
import appointmentFormSaga from "../saga/appointmentSaga";

export default function* rootSaga() {
  yield all([
    userSaga(),
    blogSaga(),
    cartSaga(),
    categorySaga(),
    couponSaga(),
    customerSaga(),
    feedbackSaga(),
    mediaSaga(),
    notificationSaga(),
    orderSaga(),
    pageSaga(),
    productSaga(),
    tagSaga(),
    contactFormSaga(),
    appointmentFormSaga()
  ]);
}
