/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const cartApi = {
  createOrUpdateCart: (params) => {
    console.log('APIIIIIparams', params);
    const uri = HOST_ADDRESS + envVars.CART_CREATE_OR_UPDATE_CART;
    return axios.post(uri, params.payload).then((response) => {
      console.log('APIIIIIresponse', response);
      return response;
    });
  },
  getCart: (params) => {
    const uri = HOST_ADDRESS + envVars.CART_GET_CART;
    return axios.post(`${uri}${params?.payload?.userId}`, params.payload).then((response) => {
      return response;
    });
  },
  clearBy: (params) => {
    const uri = HOST_ADDRESS + envVars.CLEAR_CART_BY_ID;
    return axios.post(`${uri}${params?.payload?.id}`, params).then((response) => {
      return response;
    });
  },
  deleteItemBy: (params) => {
    const uri = HOST_ADDRESS + envVars.DELETE_ITEM_FROM_CART_BY;
    return axios.post(`${uri}${params?.payload?.id}`, params).then((response) => {
      return response;
    });
  },
  updateCartItemQuantity: (params) => {
    const uri = HOST_ADDRESS + envVars.UPDATE_CART_ITEM_QUANTITY;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  }
};

export default cartApi;
