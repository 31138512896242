/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Button, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, fade } from '@material-ui/core/styles';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { mediaAction, mediaCreateAction } from '../../../redux/actions/mediaActions';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    minHeight: '80vh',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  mediaItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const MediaLibrary = () => {
  const mediaSelector = useSelector(state => state?.media?.data?.media);

  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [mediaItems, setMediaItems] = useState([]);
  const [selectedMediaItem, setSelectedMediaItem] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAllMedia() {
      await dispatch(mediaAction());
    }

    if (mediaItems.length === 0) {
      fetchAllMedia();
    }
  }, []);

  useEffect(() => {
    if (mediaSelector && Array.isArray(mediaSelector) && mediaSelector.length !== 0) {
      setMediaItems(mediaSelector);
    }
  }, [mediaSelector]);

  const handleFileInputChange = async (event) => {
    console.log('event', event);
    const file = event.target.files[0];
    const formData = new FormData();
    const reader = new FileReader();
    reader?.readAsDataURL(file);
    reader.onload = () => {
      const newMediaItem = {
        src: reader.result,
        title: file.name,
        type: file.type,
        size: file.size,
        date: new Date(),
      };
      setMediaItems([...mediaItems, newMediaItem]);
    };

    formData.append('image', file);
    formData.append('Role', secureLocalStorage.getItem('Role'));
    formData.append('userId', JSON.parse(secureLocalStorage.getItem('users'))._id);
    formData.append('email', JSON.parse(secureLocalStorage.getItem('users')).email);
    formData.append('belongs_to', JSON.parse(secureLocalStorage.getItem('users'))._id);
    formData.append('belongs_to_type', 'Page');
    formData.append('isDeleted', false);
    formData.append('content_type', 'media');

    // await (dispatch(mediaCreateAction(formData)))
    await (dispatch(mediaCreateAction(formData)));
  };

  const handleMediaItemClick = (mediaItem) => {
    setSelectedMediaItem(mediaItem);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    // make an API call to fetch media items based on the search query and selected category
    // update mediaItems state with the fetched media items
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content md-page-contents">
          <h6>Media Library</h6>
          <div className="m-pg-container">
            <label htmlFor="media-file-upload">
              <Button variant="contained" component="span" className="bttn-bg-green">
                Add New Media
              </Button>
            </label>
            {/* <img src={imageUrl} alt="Image" /> */}
          </div>
        </div>
      </div>

      <div className="add-pages-container">
        <div className="m-page-contents">
          <div className="m-filter">
            {/* <h2>Media Items</h2> */}
            <select>
              <option>All Media items</option>
              <option>Images</option>
              <option>Videos</option>
            </select>
            <select>
              <option>All Dates</option>
              <option>Today</option>
              <option>This Week</option>
              <option>This Month</option>
              <option>This Year</option>
            </select>
            <button>Bulk Select</button>
          </div>

          <div className="m-filter-search">
            <div className="m-search-icon">
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </div>
        </div>

        <div className={classes.mediaItemContainer}>
          {mediaItems.map((mediaItem, index) => {
            return (
              <div key={index} onClick={() => handleMediaItemClick(mediaItem)}>
                {(mediaItem?.type?.startsWith('image/') || mediaItem?.mimetype?.startsWith('image/')) ? (
                  <img
                    src={mediaItem?.src || mediaItem?.server_image_url}
                    alt={mediaItem?.title}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <video
                    src={mediaItem?.src}
                    alt={mediaItem?.title}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                )}
                <div>{mediaItem?.title}</div>
              </div>
            );
          })}
        </div>

        {selectedMediaItem && (
          <div>
            {(selectedMediaItem?.type?.startsWith('image/') || selectedMediaItem?.mimetype?.startsWith('image/')) ? (
              <img src={selectedMediaItem?.src || selectedMediaItem?.server_image_url} alt={selectedMediaItem?.title || selectedMediaItem?.filename} />
            ) : (
              <video src={selectedMediaItem?.src || selectedMediaItem?.server_video_url} alt={selectedMediaItem?.title || selectedMediaItem?.filename} />
            )}
            <div>{selectedMediaItem?.title || selectedMediaItem?.filename}</div>
            <div>{selectedMediaItem?.type || selectedMediaItem?.mimetype}</div>
            <div>{selectedMediaItem?.size || selectedMediaItem?.file_size}</div>
            <div>{selectedMediaItem?.date?.toLocaleString() || selectedMediaItem?.createdAt.split('T')[0]}</div>
          </div>
        )}

        <input
          type="file"
          id="media-file-upload"
          accept="image/*, video/*"
          style={{ display: 'none' }}
          onChange={(event) => {
            console.log('event !!!!!!!!@@2');
            return handleFileInputChange(event);
          }}
        />
      </div>
    </Layout>
  );
};

export default MediaLibrary;
