import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/customerActionTypes';
import customerApi from '../../apis/customerApi';

function* customers(action) {
  try {
    const customersResponse = yield call(customerApi.getAll, action);
    yield put({ type: type.ALL_CUSTOMER_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_CUSTOMER_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersLoggedInUserCustomers(action) {
  try {
    const customersResponse = yield call(customerApi.loggedInUserCustomers, action);
    yield put({ type: type.LOGGED_IN_USER_CUSTOMERS_ID_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_CUSTOMERS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersCreate(action) {
  try {
    const customersResponse = yield call(customerApi.create, action);
    yield put({ type: type.CUSTOMER_CREATE_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.CUSTOMER_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersGetBy(action) {
  try {
    const customersResponse = yield call(customerApi.getBy, action);
    yield put({ type: type.CUSTOMER_GET_BY_ID_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.CUSTOMER_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersUpdateBy(action) {
  try {
    const customersResponse = yield call(customerApi.updateBy, action);
    yield put({ type: type.CUSTOMER_UPDATE_BY_ID_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.CUSTOMER_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersUnshowBy(action) {
  try {
    const customersResponse = yield call(customerApi.unshowBy, action);
    yield put({ type: type.CUSTOMER_UNSHOW_BY_ID_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.CUSTOMER_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* customersDeleteBy(action) {
  try {
    const customersResponse = yield call(customerApi.deleteBy, action);
    yield put({ type: type.CUSTOMER_DELETE_BY_ID_SUCCESS, customersResponse: customersResponse.data });
  } catch (e) {
    yield put({
      type: type.CUSTOMER_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myCustomerSaga() {
  yield takeEvery(type.ALL_CUSTOMER_REQUESTING, customers);
  yield takeEvery(type.LOGGED_IN_USER_CUSTOMERS_ID_REQUESTING, customersLoggedInUserCustomers);
  yield takeEvery(type.CUSTOMER_CREATE_REQUESTING, customersCreate);
  yield takeEvery(type.CUSTOMER_GET_BY_ID_REQUESTING, customersGetBy);
  yield takeEvery(type.CUSTOMER_UPDATE_BY_ID_REQUESTING, customersUpdateBy);
  yield takeEvery(type.CUSTOMER_UNSHOW_BY_ID_REQUESTING, customersUnshowBy);
  yield takeEvery(type.CUSTOMER_DELETE_BY_ID_REQUESTING, customersDeleteBy);
}

export default myCustomerSaga;
