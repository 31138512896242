import * as type from '../actionTypes/categoryActionTypes';

export const categoriesAction = (payload) => {
  return {
    type: type.ALL_CATEGORIES_REQUESTING,
    payload
  };
};

export const getAllCategoriesWithTheirAssociatedProductsAction = (payload) => {
  return {
    type: type.GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS_REQUESTING,
    payload
  };
};

export const categoriesLoggedInUserCategoriesAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_CATEGORIES_ID_REQUESTING,
    payload
  };
};
export const categoriesGetByAction = (payload) => {
  return {
    type: type.CATEGORIES_GET_BY_ID_REQUESTING,
    payload
  };
};
export const categoriesCreateAction = (payload) => {
  return {
    type: type.CATEGORIES_CREATE_REQUESTING,
    payload
  };
};
export const categoriesUpdateByAction = (payload) => {
  return {
    type: type.CATEGORIES_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const categoriesUnshowByAction = (payload) => {
  return {
    type: type.CATEGORIES_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const categoriesDeleteByAction = (payload) => {
  return {
    type: type.CATEGORIES_DELETE_BY_ID_REQUESTING,
    payload
  };
};

export const findMostSoldCategoryAction = (payload) => {
  return {
    type: type.FIND_MOST_SOLD_CATEGORY_REQUESTING,
    payload
  };
};

export const findTopCategoriesSoldAction = (payload) => {
  return {
    type: type.FIND_TOP_CATEGORIES_SOLD_REQUESTING,
    payload
  };
};
