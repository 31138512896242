import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
  },
  leatherboard: {
    width: '45%',
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2)
  ,
  },
}));

const TopCategoriesSold = () => {
  const classes = useStyles();

  return (
    <div className={classes.leatherboard}>
      <Typography variant="h6" className={classes.title}>
        <strong>Top Categories - Sold</strong>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Category</strong></TableCell>
              <TableCell align="right"><strong>Item Solds</strong></TableCell>
              <TableCell align="right"><strong>Net Sales</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>AyurvedicTherapies</TableCell>
              <TableCell align="right">10</TableCell>
              <TableCell align="right">$100.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bundle Massages</TableCell>
              <TableCell align="right">8</TableCell>
              <TableCell align="right">$80.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Diseases & treatments</TableCell>
              <TableCell align="right">6</TableCell>
              <TableCell align="right">$60.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const TopProductsSold = () => {
  const classes = useStyles();

  return (
    <div className={classes.leatherboard}>
      <Typography variant="h6" className={classes.title}>
        <strong>Top Products - Sold</strong>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Product</strong></TableCell>
              <TableCell align="right"><strong>Item Solds</strong></TableCell>
              <TableCell align="right"><strong>Net Sales</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Abhyangam </TableCell>
              <TableCell align="right">10</TableCell>
              <TableCell align="right">$100.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>swedanam</TableCell>
              <TableCell align="right">8</TableCell>
              <TableCell align="right">$80.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lepanam</TableCell>
              <TableCell align="right">6</TableCell>
              <TableCell align="right">$60.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const LeatherBoard = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <TopCategoriesSold />
              <TopProductsSold />
                  {/* Add more components as needed */}
  </div>
</div>
);
};

export default LeatherBoard;
