import React from 'react';
import { Typography, Grid } from '@mui/material';

const Hypertension = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Hypertension </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Hypertension? </strong>
        </Typography>
            <Typography variant="body1">
              Hypertension, or high blood pressure, is a chronic medical
              condition characterized by elevated blood pressure levels in the
              arteries. It occurs when the force of blood against the artery
              walls is consistently too high. The causes of hypertension can be
              multifactorial, including genetic factors, unhealthy lifestyle
              choices (such as poor diet, lack of physical activity, and
              smoking), obesity, stress, age, and certain medical conditions.
              Symptoms of hypertension are often silent, but in severe cases,
              they can include headaches, dizziness, chest pain, shortness of
              breath, and vision problems.{' '}
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Hypertension </strong>
        </Typography>
            <Typography variant="body1">
              A person with hypertension may not notice any symptoms. Without
              detection, hypertension can damage the heart, blood vessels, and
              other organs, such as the kidneys.
              <ul className='vw-ul'>
                <li>Sweating</li>
                <li>Anxiety</li>
                <li>Sleeping problems</li>
                <li>Blushing</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Hypertension </strong>
        </Typography>
            <Typography variant="body1">
              Primary hypertension doesn’t have a single, clear cause. Usually,
              many factors come together to cause it. Common causes include:
              <ul className='vw-ul'>
                <li>Unhealthy eating patterns (including a diet high in sodium). </li>
                <li>Lack of physical activity.</li>
                <li>Exposure to irritants like cigarette smoke</li>
                <li>High consumption of beverages containing alcohol.</li>
                <li>Kidney disease.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Hypertension </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Hypertension include:
              <ul className='vw-ul'>
                <li>
                  Having biological family members with high blood pressure,
                  cardiovascular disease or diabetes.
                </li>
                <li>Being over age 55.</li>
                <li>Having overweight or obesity.</li>
                <li>Not getting enough exercise.</li>
                <li>Eating foods high in sodium.</li>
                <li>Smoking or using tobacco products.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Hypertension cannot always be prevented, some strategies
              that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Limit How Much Alcohol You Drink</li>
                <li>Keep Yourself at a Healthy Weight</li>
                <li>Be Physically Active</li>
                <li>Do Not Smoke</li>
                <li>Get Enough Sleep</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Hypertension;
