/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Dyspepsia = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Dyspepsia </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Dyspepsia ? </strong>
        </Typography>
            <Typography variant="body1">
            Dyspepsia, also known as indigestion, is a common digestive disorder
characterized by persistent or recurrent pain or discomfort in the upper
abdomen. Symptoms may include a feeling of fullness, bloating, belching,
nausea, and sometimes heartburn. Causes of dyspepsia can vary and may
include overeating, fatty or spicy foods, excessive alcohol consumption,
smoking, stress, certain medications, and underlying conditions such as
gastroesophageal reflux disease (GERD), peptic ulcers, or gastritis. In
some cases, no specific cause can be identified, and dyspepsia is
categorized as functional dyspepsia. </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Dyspepsia </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Dyspepsia include:
              <ul className='vw-ul'>
                <li>Pain relating to the digestive system</li>
                <li>A burning sensation in the digestive tract</li>
                <li>Feeling too full after eating</li>
                <li>Feeling full too quickly during eating</li>
               
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Dyspepsia </strong>
        </Typography>
            <Typography variant="body1">
              Dyspepsia  can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Dietary factors</li>
                <li>Smoking</li>
                <li>Obesity</li>
                <li>Stress</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Dyspepsia </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Dyspepsia  include:
              <ul className='vw-ul'>
                <li>Have a history of anxiety or depression </li>
                <li>Have a history of abuse.</li>
                <li>Have a history of H. pylori infection.</li>
                <li>Smoke or use tobacco products.</li>
                <li>Use NSAIDs.</li>
                <li>Were assigned "female" at birth.</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
            The best way to prevent indigestion is to avoid the foods and situations that seem to cause it. Keeping a food diary is helpful in identifying foods that cause indigestion. Here are some other suggestions:
            <ul className='vw-ul'>
                <li>Eat small meals so the stomach does not have to work as hard or as long.</li>
                <li>Try not to chew with your mouth open, talk while you chew, or eat too fast. This makes you swallow too much air, which can add to indigestion.</li>
                <li>Avoid foods that contain high amounts of acids, such as citrus fruits and tomatoes.</li>
                <li>Avoid spicy foods.</li>
                <li>Eat slowly.</li>
                <li>Reduce or avoid foods and beverages that contain caffeine.</li>
                <li>If stress is a trigger for your indigestion, learn new methods for managing stress, such as relaxation and biofeedback techniques.</li>
                <li>Avoid wearing tight-fitting garments because they tend to compress the stomach, which can cause its contents to enter the esophagus.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
      </div>
      </>
  );
};

export default Dyspepsia;
