import * as type from '../actionTypes/blogActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function blogReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_BLOGS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_BLOGS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_BLOGS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_BLOGS_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.LOGGED_IN_USER_BLOGS_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_BLOGS_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.BLOGS_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.BLOGS_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.BLOGS_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.BLOGS_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.BLOGS_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.BLOGS_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.BLOGS_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.BLOGS_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.BLOGS_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.BLOGS_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.BLOGS_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.BLOGS_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.BLOGS_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.BLOGS_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.BLOGS_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.blogsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    default:
      return newState;
  }
}