/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Typography, Grid } from "@mui/material";

const SkinAllergies = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Skin Allergy </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>

        <Typography variant="h6" gutterBottom>
        <strong>What is Skin Allergy? </strong>
        </Typography>

          <Typography variant="body1">
          A skin allergy is an allergic reaction to a typically harmless substance, such as wool, pollen, soap, or plants. The immune system reacts to this trigger and attacks to remove it from the body. 
          The result is an allergic skin rash, which tends to be red and itchy. Skin allergies encompass a number of allergic conditions, such as atopic dermatitis, contact dermatitis and hives.
          </Typography>          
          </Grid>

        <Grid item xs={12}>

          <hr />

          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of SkinAllergies </strong>
          </Typography>

          <Typography variant="body1">

            Some common symptoms of Skin Allergies include:

            <ul className='vw-ul'>
              <li>Rash </li>
              <li>Itching </li>
              <li>Redness</li> 
              <li>Swelling </li>
              <li>Raised bumps</li>
              <li>Scaling or flaking of skin</li>
              <li>Cracked skin</li>
            </ul>

          </Typography>

        </Grid>

        <Grid item xs={12}>

          <hr />

          <Typography variant="h6" gutterBottom>
          <strong>Causes of SkinAllergies </strong>
          </Typography>

          <Typography variant="body1">

            Common causes of Skin Allergy include:

            <ul className='vw-ul'>
              <li>Latex</li>
              <li>Pet dander </li>
              <li>Poison ivy or poison oak </li>
              <li>Cold or hot temperatures </li>
              <li>Laundry detergent</li>
              <li>Soap</li>
              <li>Chemical</li>
              <li>Drugs</li>
              <li>Sunlight</li>
              <li>Water</li>
              <li>Food</li>
            </ul>
          </Typography>

        </Grid>

        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Skin Allergies </strong>
          </Typography>

          <Typography variant="body1">
            Some common risk factors for developing SkinAllergies include:
            <ul className='vw-ul'>
              <li>Mothers who smoke during pregnancy are more likely to increase the child's risk of allergies. Secondhand smoke also raises the allergy risk for children and infants. </li>
              <li><b>Genetics:</b> If you have a family history of skin allergies or other allergic conditions like asthma or hay fever, you may be at a higher risk. </li>
              <li><b>Exposure to Allergens:</b> Exposure to allergens like pollen, dust mites, pet dander, or certain foods can trigger skin allergies. Identifying and minimizing contact with these allergens is essential.</li>
              <li><b>Personal History:</b> If you have a history of skin allergies or other allergic conditions, you may be more prone to developing new allergies. </li>
              <li><b>Occupational Exposure:</b> Some occupations require exposure to chemicals or substances that can trigger skin allergies. Proper protective gear and hygiene practices can reduce the risk. </li>
              <li><b>Skin Sensitivity:</b> Individuals with naturally sensitive skin may be more susceptible to skin allergies.</li>
            </ul>
          </Typography>
        </Grid>

        <Grid item xs={12}>

        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>

          <Typography variant="body1">
            It's not always possible to prevent SkinAllergies, and the condition can come back. To protect your back:
          <ul className='vw-ul'>
          <li><b>Identify Triggers:</b> Work with an allergist or dermatologist to identify the specific triggers causing your skin allergies. This may involve allergy testing.</li>
          <li><b>Avoid Allergens:</b> Once you've identified your triggers, take steps to avoid them. This may include using allergen-proof covers for bedding, changing your diet, or avoiding certain skincare products.</li>
          <li><b>Skin Care:</b> Use gentle, fragrance-free soaps and moisturizers. Avoid hot water and harsh scrubbing when washing your skin, as this can exacerbate skin allergies.</li>
          <li><b>Hydration:</b> Keep your skin well-hydrated by drinking plenty of water and using a good moisturizer. Moisturized skin is less prone to irritation. </li>
          </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default SkinAllergies;
