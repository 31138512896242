import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const DiabeticFootInfection = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
          Diabetic Foot Infection
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is  Diabetic Foot Infection
?
            </Typography>
            <Typography variant="body1">
            Diabetic foot infection refers to a bacterial infection that develops in the foot of
individuals with diabetes. It commonly occurs due to a combination of factors such
as poor blood circulation, nerve damage (neuropathy), and compromised immune
function. Symptoms include foot pain, redness, swelling, warmth, foul odor, open
sores (ulcers), and difficulty walking. The causes can be attributed to foot injuries,
poor foot hygiene, inadequate blood sugar control, peripheral arterial disease, and
foot deformities. Diabetic foot infections can lead to serious complications if not
treated promptly, such as cellulitis, abscess formation, and even amputation.            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of  Diabetic Foot Infection

            </Typography>
            <Typography variant="body1">
              Some common symptoms of Diabetic Foot Infection
 include:
              <ul>
                <li>Darkened skin on the affected area.</li>
                <li>Diminished ability to sense hot or cold.</li>
                <li>Diminished ability to sense hot or cold.</li>
                <li>Loss of hair in the area.</li>
                <li>Numbness, Pain, Tingling</li>
                <li>Any changes to the skin or toenails, including cuts, blisters, calluses or sores.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Diabetic Foot Infection

            </Typography>
            <Typography variant="body1">
Diabetic Foot Infection
 can be caused by various factors, including:
              <ul>
                <li>poor circulation</li>
                <li>high blood sugar (hyperglycemia)
</li>
                <li>nerve damage</li>
                <li>irritated or wounded feet</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for Diabetic Foot Infection

            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Diabetic Foot Infection
 include:
              <ul>
                <li>A family history of allergies or Diabetic Foot Infection
</li>
                <li>Peripheral vascular disease</li>
                <li>Poor glycemic controla</li>
                <li>Diabetic nephropathy</li>
                <li>Previous foot ulcerations/amputations
</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
            You can also reduce the risk of diabetes-related foot problems by:
              <ul>
                <li>Checking your feet every day for any changes. Don’t forget to look between your toes. Ask someone for help or use a mirror if needed.</li>
                <li>Getting your feet checked at every visit with a healthcare provider.</li>
                <li>Keeping your toenails trimmed and filed. Cut them straight across to prevent ingrown toenails.
</li>
                <li>Maintaining blood flow to the feet. For example, keep your feet elevated when you sit, and wiggle your toes often. Stay active, but choose activities that are gentler on the feet, such as walking or swimming</li>
                <li>Wearing socks and shoes (or slippers) all day. Make sure your socks aren’t too tight and that your shoes fit properly. Some people with diabetes wear therapeutic shoes or shoe inserts to help prevent wounds or sores.</li>
                <li>Washing your feet every day in warm water, not hot. Dry them thoroughly afterward, including between the toes. Then apply lotion to the tops and bottoms of the feet, but not between the toes.</li>

              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </Container>
  );
};

export default DiabeticFootInfection;
