/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Spondyloarthritis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Spondyloarthritis </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Spondyloarthritis? </strong>
          </Typography>
          <Typography variant="body1">
            <p>
              Spondyloarthritis is a group of inflammatory diseases that cause
              arthritis. You also may hear it called spondyloarthropathy or, for
              short, SpA. It's different from other kinds of arthritis because
              it also causes inflammation in areas called entheses where
              ligaments and tendons attach to bones. Ligaments are tissues that
              connect your bones to one another, and tendons are tissues that
              connect your bones to your muscles.
            </p>
            <p>
              Spondyloarthritis is a major cause of lower back pain, arthritis
              in the peripheral joints such as those in arms and legs, eye
              problems, and even inflammatory bowel disease (IBD).
            </p>
            <p>
              It tends to affect people in their teens and 20s, especially young
              men. If you have a relative with any form of spondyloarthritis,
              you may be at a higher risk of developing it.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Symptoms of Spondyloarthritis</strong>
          </Typography>
          <Typography variant="body1">
            The different types of spondyloarthritis may have :
            <ul className='vw-ul'>
              <li>Back pain</li>
              <li>Digestive issues</li>
              <li>Tiredness</li>
              <li>Inflammation of the aortic heart valve</li>
              <li>Osteoporosis</li>
              <li>Pain or swelling in other joints, including your hips, knees, ankles, feet, hands, wrists, elbows, and shoulders</li>
              <li>Psoriasis skin rash</li>
              <li>Skin and nail changes of psoriasis</li>
              <li>Swelling along the tendons of your fingers or toes ("sausage fingers")</li>
              <li>Swelling, pain, or redness in part of an eye</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Causes of Spondyloarthritis </strong>
          </Typography>
          <Typography variant="body1">
          People often develop spondyloarthritis in their teenage years or 20s. Those with the following characteristics may be more likely to experience spondyloarthritis:
          <ul className='vw-ul'>
              <li> Being male </li>
              <li> Having a family history of spondyloarthritis </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Risk Factors for Spondyloarthritis </strong>
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developingSpondyloarthritis include:
            <ul className='vw-ul'>
              <li> Certain bacterial infections, such as salmonella or sexually transmitted bacteria </li>
              <li> Family history </li>
              <li> Male gender </li>
              <li> Psoriasis, for psoriatic arthritis </li>
              <li> Inflammatory bowel disease, for enteropathic arthritis </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Prevention and Management </strong>
          </Typography>
          <Typography variant="body1">
          Although spondyloarthritis currently has no cure, treatment helps relieve symptoms and prevent progression. At UT Southwestern, our rheumatologists offer several treatment options:
          <ul className='vw-ul'>
          <li> Biologic medications that block specific pathways of inflammation, such as Humira and Enbrel, or the newer Cosentyx and Taltz</li>
          <li> Physical therapy to improve strength and flexibility. </li>
          </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Spondyloarthritis;
