/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Gout = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Gout</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Gout?</strong>
        </Typography>
        <Typography variant="body1">
        Gout is a painful form of arthritis. When your body has extra uric acid, sharp crystals can form in your joints (usually your big toe). Flare-ups of symptoms like pain and swelling come and go in periods called gout attacks. Treatment is usually a combination of symptom management and changing your diet.
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of Gout</strong>
          </Typography>
          <Typography variant="body1">
          Gout attacks are very painful and can happen suddenly, often overnight. During a gout attack, symptoms in your affected joints may include:
          <ul className='vw-ul'>
          <li>Intense pain.</li>
          <li>Discoloration or redness.</li>
          <li>Stiffness.</li>
          <li>Swelling.</li>
          <li>Tenderness, even to a light touch (like a bedsheet covering your affected joint).</li>
          <li>Warmth, or a feeling like the joint is "on fire."</li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Causes of Gout</strong>
          </Typography>
          <Typography variant="body1">
            Gout can be caused by various factors, including:
            <ul className='vw-ul'>
            <li>Gout occurs when urate crystals accumulate in your joint, causing the inflammation and intense pain of a gout attack. Urate crystals can form when you have high levels of uric acid in your blood. Your body produces uric acid when it breaks down purines — substances that are found naturally in your body.</li>
            <li>Purines are also found in certain foods, including red meat and organ meats, such as liver. Purine-rich seafood includes anchovies, sardines, mussels, scallops, trout and tuna. Alcoholic beverages, especially beer, and drinks sweetened with fruit sugar (fructose) promote higher levels of uric acid.</li>
            <li>Normally, uric acid dissolves in your blood and passes through your kidneys into your urine. But sometimes either your body produces too much uric acid or your kidneys excrete too little uric acid. When this happens, uric acid can build up, forming sharp, needlelike urate crystals in a joint or surrounding tissue that cause pain, inflammation and swelling.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Gout</strong>
          </Typography>
          <Typography variant="body1">
          You're more likely to develop gout if you have high levels of uric acid in your body. Factors that increase the uric acid level in your body include:
          <ul className='vw-ul'>
          <li><b>Diet:</b> Eating a diet rich in red meat and shellfish and drinking beverages sweetened with fruit sugar (fructose) increase levels of uric acid, which increase your risk of gout. Alcohol consumption, especially of beer, also increases the risk of gout.</li>
          <li><b>Weight:</b> If you're overweight, your body produces more uric acid and your kidneys have a more difficult time eliminating uric acid.</li>
          <li><b>Medical conditions:</b> Certain diseases and conditions increase your risk of gout. These include untreated high blood pressure and chronic conditions such as diabetes, obesity, metabolic syndrome, and heart and kidney diseases. </li>
          <li><b>Certain medications: </b> Low - dose aspirin and some medications used to control hypertension - including thiazide diuretics, angiotensin-converting enzyme (ACE) inhibitors and beta blockers - also can increase uric acid levels. So can the use of anti-rejection drugs prescribed for people who have undergone an organ transplant.</li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fifth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Prevention and Management</strong>
          </Typography>
          <Typography variant="body1">
            While Gout cannot always be prevented, some strategies that may help manage the symptoms include:
            <ul className='vw-ul'>
            <li>The best way to prevent gout is to limit how often you consume high-purine foods and drinks. Make sure you drink plenty of water to help your kidneys function better and avoid dehydration.</li>
            <li>Getting regular exercise can help reduce stress on your joints and reduce your risk for obesity and other health conditions that make you more likely to develop gout.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Gout;
