import React from 'react';
import { Typography } from '@mui/material';

const SatisfactionGuarantee = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Customer Satisfaction Guarantee </h2></div>
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
    <br />
      <Typography variant="body1">

        At Vedanta Wellness, we are dedicated to your health and well-being. We proudly offer our Customer Satisfaction Guarantee to every patient we serve. Our commitment to you includes:
        <br /><br />
        <strong>Personalized Care:</strong> We will create a personalized Ayurvedic treatment plan tailored to your unique needs and goals.
        <br /><br />
        <strong>Qualified Practitioners:</strong> You will receive care from experienced and certified Ayurvedic practitioners who are dedicated to your health.
        <br /><br />
        <strong>Transparency:</strong> We will explain your treatment options, expected outcomes, and any potential risks, ensuring you have the information you need to make informed decisions.
        <br /><br />
        <strong>Progress Monitoring:</strong> Throughout your journey with us, we will regularly monitor your progress and make necessary adjustments to your treatment plan.
        <br /><br />
        <strong>Accessible Support:</strong> Our team is always here to address your questions, concerns, and provide guidance during your treatment.
        <br /><br />
        <strong>Satisfaction Assurance:</strong> If, for any reason, you are not completely satisfied with your Ayurvedic experience at our clinic, please let us know. We will work with you to make it right, whether that involves adjusting or extending your treatment plan or providing additional support.
        <br /><br />
        Your satisfaction and well-being are our top priorities. Trust Vedanta Wellness to provide the highest level of care and support on your path to holistic health.
      </Typography>
    </div>
  </>
  );

};

export default SatisfactionGuarantee;
