import * as type from '../actionTypes/notificationActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function notificationReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_NOTIFICATIONS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_NOTIFICATIONS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_NOTIFICATIONS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.NOTIFICATIONS_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.NOTIFICATIONS_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.NOTIFICATIONS_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.NOTIFICATIONS_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.NOTIFICATIONS_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.NOTIFICATIONS_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.NOTIFICATIONS_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.NOTIFICATIONS_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.NOTIFICATIONS_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.notificationsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    default:
      return newState;
  }
}