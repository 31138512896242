import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Usersection from '../Components/Common/Usersection';
import Divider from '@mui/material/Divider';

function Orderdetail1() {
  return (
    <>
      <div className="us-container">
        <h1 className="us-header">Order Details </h1>
      </div>
      <Divider />
      <div className="us-container">
        <Grid container spacing={2} sx={{
          marginTop: '10px',
        }}>
          <Grid item md={3} sm={12} xs={12}>
            <Usersection />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <Box
              sx={{
                marginTop: '1px',
                padding: '1px 1px 1px 10px',
                backgroundColor: '',
                marginRight: '2rem',
              }}
            >
              <Typography align="left">
                Order #4431 was placed on March 02, 2023 and is currently Processing.
              </Typography>
              <Typography variant="h5" align="left" sx={{ padding: '2% 0', fontWeight: 'bold' }}>
                Order Details
              </Typography>
              <Box sx={{ textAlign: 'left' }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Abhyangam(Ayurvedix Message)x1</td>
                      <td>Rs 200.00</td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>Rs 200.00</td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td>Cash on delivery</td>
                    </tr>
                    <tr>
                      <td>Total :</td>
                      <td>Rs 200.00</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Billing address</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>Test Test</p>
                        <p>Test</p>
                        <p>Test</p>
                        <p>Test</p>
                        <p>Test 123456</p>
                        <p>123456789</p>
                        <p>test@test@gmail.com</p>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Orderdetail1;