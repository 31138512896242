import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../css/Sitemap.css';

function Sitemap() {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Sitemap</h2></div>
    <div className="sitemap-footer">
      <div className="sitemap-container">
        <Grid container className="sitemap-row" spacing={2}>
          <Grid className="sitemap-section" md={3} sm={6} xs={12}>
            {/* <h4 className="sitemap-heading">Home</h4> */}
            <ul className="sitemap-list">
            <li><Link className="sitemap-link" to="/"> Home </Link></li>
            <li><Link className="sitemap-link" to="/aboutus"> About Us </Link></li>
            <li><Link className="sitemap-link" to="/contact-us"> Contact Us </Link></li>
            <li><Link className="link" to="/ayurvedic-therapies"> Ayurvedic Therapies </Link></li>
            <li><Link className="link" to="/diseases-treatments"> Diseases & Treatments</Link></li>
            <li><Link className="link" to="/membership"> Membership </Link></li>
            <li><Link className="link" to="/testimonials"> Testimonials </Link></li>
            <li><Link className="link" to="/blogs"> Blogs </Link></li>
            <li><Link className="link" to="/careers"> Careers </Link></li>
            <li><Link className="link" to="/term-conditions"> Terms & Conditions</Link></li>
            <li><Link className="link" to="/privacy-policy"> Privacy Policies</Link></li>
            <li><Link className="link" to="/faq"> FAQ</Link></li>
            <li><Link className="link" to="/SatisfactionGuarantee"> Satisfaction Guarantee</Link></li>
            <li><Link className="link" to="/disclaimer"> Disclaimer</Link></li>
            <li><Link className="link" to="/sitemap"> Sitemap</Link></li>
            </ul>
          </Grid>

          <Grid className="sitemap-section" md={3} sm={6} xs={12}>
            {/* <h4 className="sitemap-heading">Services & Products</h4> */}
            <ul className="sitemap-list">
                <li><Link className="link" to="/Abhyangam">Abhyangam</Link></li>
                <li><Link className="link" to="/Swedanam">Steaming (Swedanam) </Link></li>
                <li><Link className="link" to="/Sirodhara">Sirodhara </Link></li>
                <li><Link className="link" to="/Lepanam">Lepanam </Link></li>
                <li><Link className="link" to="/SarvangaDhara">Sarvanga Dhara </Link></li>
                <li><Link className="link" to="/KatiVasti">KatiVasti </Link></li>
                <li><Link className="link" to="/Januvasti">Januvasti </Link></li>
                <li><Link className="link" to="/ChoornaPindaSwedam">Choorna Pinda Swedam </Link></li>
                <li><Link className="link" to="/PatraPindaSwedam">Patra Pinda Swedam </Link></li>
                <li><Link className="link" to="/ShashtikasaliPindaSwedam">Shashtikasali Pinda Swedam </Link></li>
                <li><Link className="link" to="/Vamana">Vamana</Link></li>
                <li><Link className="link" to="/Virechana">Virechana </Link></li>
                <li><Link className="link" to="/Nasyam">Nasyam </Link></li>
                <li><Link className="link" to="/Vasti">Vasti </Link></li>
                <li><Link className="link" to="/Raktamoksham">Raktamoksham </Link></li>
            </ul>
          </Grid>

          <Grid className="sitemap-section" md={3} sm={6} xs={12}>
            {/* <h4 className="sitemap-heading">Blogs</h4> */}
            <ul className="sitemap-list">
            <li><Link className="sitemap-link" to="/Diseases&Treatments/AnkleSprain"> Ankle Sprain </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Arthritis"> Arthritis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Cervical"> Cervical </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/DegenerativeDiscDisease"> Degenerative Disc Disease </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/DiscProlapse"> Disc Prolapse </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/DiscBulging"> Disc Bulging </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/ElbowArthritis"> Elbow Arthritis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/FrozenShoulder"> Frozen Shoulder </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/HeelPain"> Heel Pain </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/KneeFractures"> Knee Fractures & Injuries </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Osteoarthritis"> Osteoarthritis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Osteoporosis"> Osteoporosis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/RheumatoidArthritis"> Rheumatoid Arthritis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Sciatica"> Sciatica </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Spondyloarthritis"> Spondyloarthritis </Link></li>
            </ul>
          </Grid>

          <Grid className="sitemap-section" md={3} sm={6} xs={12}>
            {/* <h4 className="sitemap-heading">Diseases & Treatments</h4> */}
            <ul className="sitemap-list">
            <li><Link className="sitemap-link" to="/Diseases&Treatments/PolycysticOvarySyndrome"> Polycystic Ovary Syndrome (PCOS) </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/UrinaryTractInfection"> Urinary Tract Infection (UTI) </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/WeightLoss"> Weight Loss </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Endometriosis"> Endometriosis </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Menorrhagia"> Menorrhagia </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Oligomenorrhea"> Oligomenorrhea </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/BiliaryColic"> Biliary Colic </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/BiliaryDisease"> Biliary Disease </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Dyspepsia"> Dyspepsia </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/KneeFractures"> Knee Fractures & Injuries </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/GastroesophagealRefluxDisease"> Gastroesophageal Reflux </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/IrritableBowelSyndrome"> Irritable Bowel Syndrome </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/Jaundice"> Jaundice </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/SkinAllergies"> Skin Allergies </Link></li>
            <li><Link className="sitemap-link" to="/Diseases&Treatments/LipidDisorders"> Lipid Disorders </Link></li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
    </>
  );
}

export default Sitemap;