/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Divider } from '@material-ui/core';
import { useEffect } from 'react';
import Layout from './Layout';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { pagesCreateAction, pagesUpdateByAction } from '../../../redux/actions/pageActions';
import { mediaCreateAction } from '../../../redux/actions/mediaActions';
import './AddPages.css';

const AddPages = () => {
  const location = useLocation();
  const pagesSelector = useSelector(state => state?.pages?.data?.page);
  const mediaSelector = useSelector(state => state?.media?.data?.media);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [publishingError, setPublishingError] = useState(<></>);
  const [pagesFromSelector, setPagesFromSelector] = useState([]);
  const [mediaFromSelector, setMediaFromSelector] = useState({});
  const editPayloadFromRoute = location.state || {};


  useEffect(() => {
    if (editPayloadFromRoute && Object.keys(editPayloadFromRoute).length !== 0) {
      setTitle(editPayloadFromRoute.title);
      setContent(editPayloadFromRoute.content);
      setSelectedMedia(editPayloadFromRoute?.linkedMedia?.server_image_url);
    }

  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    setWordCount(content.split(/\s+/).filter((word) => word !== '').length);
  }, [content]);

  useEffect(() => {
    const formData = new FormData();

    const uploadMedia = async () => {
      formData.append('image', selectedMedia);
      formData.append('Role', secureLocalStorage.getItem('Role'));
      formData.append('userId', JSON.parse(secureLocalStorage.getItem('users'))._id);
      formData.append('email', JSON.parse(secureLocalStorage.getItem('users')).email);

      formData.append('belongs_to', JSON.parse(secureLocalStorage.getItem('users'))._id);
      formData.append('belongs_to_type', 'Page');

      formData.append('belongs_to_type_id', pagesSelector?._id);
      formData.append('isDeleted', false);
      formData.append('content_type', 'media');

      await (dispatch(mediaCreateAction(formData)));
      setSelectedMedia(null);
    };

    if (selectedMedia !== null && pagesSelector && pagesSelector?._id !== undefined) {
      uploadMedia();
      setPagesFromSelector(pagesSelector);
    }
  }, [pagesSelector]);

  useEffect(() => {
    if (mediaSelector && Object.keys(mediaSelector).length !== 0) {
      setMediaFromSelector(mediaSelector);
    }
  }, [mediaSelector]);

  useEffect(() => {
    const updatePageLinkedMediaId = async () => {
      const updatePagePayload = {
        ...pagesFromSelector,
        id: pagesFromSelector._id,
        Role: secureLocalStorage.getItem('Role'),
        userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
        email: JSON.parse(secureLocalStorage.getItem('users')).email,
        linkedMedia: mediaSelector?._id
      };

      await dispatch(pagesUpdateByAction(updatePagePayload));
    };

    if ((pagesFromSelector?._id !== null || pagesFromSelector?._id !== undefined) && mediaSelector && Object.keys(mediaSelector).length !== 0) {
      updatePageLinkedMediaId();
    }
  }, [mediaSelector]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const mediaInputRef = useRef(null);

  const handleMediaSelect = (event) => {
    const file = event.target.files[0];
    setSelectedMedia(file);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
  ];

  const handleSaveDraft = () => {
    // Perform the logic to save the draft
  };

  const handlePreview = () => {
    // Perform the logic to preview the page
  };

  const handlePublish = async () => {
    if (title.trim() === '') {
      setPublishingError(
        <h4 style={{ color: '#99231d' }}>
        Please fill up the title !
        </h4>
      );
    } else if (content.replace('<p>', '').replace('</p>', '').trim() === '') {
      setPublishingError(
        <h4 style={{ color: '#99231d' }}>
          Please add the content!
        </h4>
      );
    }
    if (title.trim() !== '' && content.trim() !== '') {
      const addPagePayload = {
        Role: secureLocalStorage.getItem('Role'),
        userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
        email: JSON.parse(secureLocalStorage.getItem('users')).email,
        title: title,
        content: content,
        isDeleted: false,
        belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
        content_type: 'page'
      };

      await dispatch(pagesCreateAction(addPagePayload));

      setPublishingError(
        <h4 style={{ color: '#2E6930' }}>
          Your Page has been published.
        </h4>
      );
      // Perform the logic to publish the page

      setTitle('');
      setContent('');

      setTimeout(() => {
        setPublishingError('');
      }, 3000);
    }
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-pages-form add-page-content">
          <h6>Add New Page</h6>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="Add a title"
            className="add-pages-title"
          />
          <div className="add-pages-media-container">
            <button
              onClick={() => mediaInputRef.current.click()}
              className="add-pages-media-button"
            >
              Add Media
            </button>
            <input
              type="file"
              ref={mediaInputRef}
              style={{ display: 'none' }}
              onChange={handleMediaSelect}
            />
            {selectedMedia && (
              <div className="add-pages-media-preview-container">
                <img
                  src={selectedMedia instanceof File ? URL.createObjectURL(selectedMedia) : selectedMedia}
                  alt={selectedMedia.name}
                  className="add-pages-media-preview"
                />
              </div>
            )}
          </div>
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            className="add-pages-content"
          />
          <div className="add-pages-footer">
            <p>{wordCount} words</p>
            <div>
              {publishingError}
            </div>
            <div>
              {/* <button
                onClick={handleSaveDraft}
                className="add-pages-button add-pages-save-draft"
              >
                Save Draft
              </button>
              <button
                onClick={handlePreview}
                className="add-pages-button add-pages-preview"
              >
                Preview
              </button> */}
              <button
                onClick={handlePublish}
                className="add-pages-button add-pages-publish"
              >
                Publish
              </button>
            </div>
          </div>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="add-pages-sidebar">
          {/* <h2>Add Pages</h2> */}
        </div>
      </div>
    </Layout>
  );
};

export default AddPages;

