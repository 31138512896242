/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const PatraPindaSwedam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Patra Pinda Swedam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong> What is Patra Pinda Swedam ? </strong>
          </Typography>
          <Typography variant="body1">
            <p>
              In Ayurveda, Swedana or fomentation is a treatment procedure that
              involves inducing sweat in an individual by heat application in
              the form of steam or other heated medicaments. As the body starts
              sweating in the Swedana treatment, stiffness, heaviness, and
              coldness are eliminated.
            </p>

            <p>
              Pinda Sweda is a common form of Swedana in which the medicaments
              are tied up in a cloth and used for the therapy. In Patra Pinda
              Sweda, leaves of medicinal plants and other drugs are tied up in
              the cloth and applied to the body while frequently heating it.
              Also called Ela Kizhi, the treatment is usually done after a light
              oil massage or Abhyanga; it offers relief in diseases related to
              bones, muscles, and nerves.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Patra Pinda Swedam </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
              <li>Delays the age-related degeneration of joints.</li>
              <li>Improves the range of movements of the joints.</li>
              <li>Prevents water retention in the joints</li>
              <li>Improves blood circulation in the body</li>
              <li>Soothes the nerves and offers relief from neuralgic pain caused by nerve disorders. </li>
              <li>Helps to eliminate toxins from the body through sweatin.</li>
              <li>Improves the sensory and motor functions in people with paralysis </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Procedure of Patra Pinda Swedam </strong>
          </Typography>
          <Typography variant="body1">
            <p><b>Preoperative procedure</b></p>
            <p> For Patra Pinda Sweda Ayurvedic treatment, the following medicinal leaves are generally used. </p>
            <ul className='vw-ul'>
              <li>Eranda (Ricinus communis)</li>
              <li>Nirgundi (Vitex negundo)</li>
              <li>Arka (Calotropis gigantean)</li>
              <li>Chincha (tamarind)</li>
              <li>Dhatura (Dhatura metal)</li>
              <li>Shigru (moringa leaves)</li>
            </ul>
            <p>
              Other ingredients that may also be used are lemon, coconut
              grating, Saindhava Lavana, Methika, Rasnadi churna, etc. For the
              procedure, fresh leaves are collected and cut down into small
              pieces. They are then heated in a non-stick pan in an herbal oil,
              along with other ingredients. After the mixture is fried, it is
              tied in a clean cloth to form a bolus of Patra Pinda.
            </p>
            <p><b>Procedure</b></p>
            <p>
              The heating apparatus is maintained at a constant temperature, and
              the bolus is frequently dipped in the warm oil while rubbing the
              body with mild pressure. The fomentation given in Patra Pinda
              Sweda therapy is carried out in seven postures like that of
              Abhyanga massage so that all the parts of the body are equally
              fomented.
            </p>

            <p>
              The fomentation lasts for 5-10 minutes in each posture. A uniform
              temperature of the bolus is maintained throughout the procedure by
              dipping it in the heated mixture of medicated decoction and milk.
            </p>
            <p><b>Post-procedure</b></p>
            <p>
              The procedure completes after sweating is induced. After that,
              Rasnadi Churna is applied to the individual and they are advised
              to take rest for an hour and bathe with warm water. A light diet
              after the procedure is recommended.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> When It Should Be Avoided ? </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
           <li>People with skin allergies are not recommended to undergo Patra Pinda Sweda therapy.</li>
           <li>It is not recommended for people with sensitive skin as well because symptoms like redness and itching may develop due to the application of heat. </li>
           <li>Fomentation should be avoided on the parts that have an acute inflammation.</li>
           <li>Patra Pinda Sweda is contraindicated for pregnant women and people with diabetes.</li>
           <li>Other conditions where it is contraindicated are certain cardiac problems, hemorrhagic disorders, indigestion, fractures, acute illness, and extreme fatigue.</li>
           </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default PatraPindaSwedam;
