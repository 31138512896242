/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import PropTypes from 'prop-types';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import {
  totalSalesComparedToPreviousMonthAction,
  netSalesComparedToPreviousMonthAction,
  currentMonthOrdersVsPreviousMonthAction,
} from '../../../redux/actions/orderActions';
import { currentMonthProductsVsPreviousMonthAction } from '../../../redux/actions/productsActions';
import './FeaturedInfo.css';

/**
 * Formula for %age change in current month products sold
 * = ((current - previous) / previous) * 100
 * if (%age < 0) {
 *  -> it's loss <ArrowDownward />
 * } else if (%age > 0) {
 *  -> it's profit <ArrowUpward />
 * } else {
 *  -> it's neutral
 * }
 */

function FeaturedInfo() {
  const orderSelectorForTotalSales = useSelector(state => state.orders?.data?.total_sales);
  const orderSelectorForNetSales = useSelector(state => state.orders?.data?.net_sales);
  const orderSelectorForOrderQuantity = useSelector(state => state.orders?.data?.order_quantity);
  const productSelectorForStats = useSelector(state => state.products?.data?.products_stats);
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [featuredInfo, setFeaturedInfo] = useState([{
    id: 0,
    featuredTitle: 'Total Sales',
    featuredMoney: '0 trial',
    featuredMoneyRate: 0,
  }, {
    id: 1,
    featuredTitle: 'Net Sales',
    featuredMoney: '0 trial',
    featuredMoneyRate: 0,
  }, {
    id: 2,
    featuredTitle: 'Order',
    featuredMoney: '0 trial',
    featuredMoneyRate: 0,
  }, {
    id: 3,
    featuredTitle: 'Product/Services Sold',
    featuredMoney: '0 trial',
    featuredMoneyRate: 0,
    // }, {
    //   id: 4,
    //   featuredTitle: 'Variations Sold',
    //   featuredMoney: 0,
    // featuredMoneyRate: 0,
  }
  ]);

  useEffect(() => {
    const fetchAllHomePageBlockDataFromOrdersApi = async () => {
      const fetchAllHomePageBlockDataFromOrdersApiPayload = {
        Role: secureLocalStorage.getItem('Role'),
        userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
        email: JSON.parse(secureLocalStorage.getItem('users')).email,
      };

      await dispatch(totalSalesComparedToPreviousMonthAction(fetchAllHomePageBlockDataFromOrdersApiPayload));
      await dispatch(netSalesComparedToPreviousMonthAction(fetchAllHomePageBlockDataFromOrdersApiPayload));
      await dispatch(currentMonthOrdersVsPreviousMonthAction(fetchAllHomePageBlockDataFromOrdersApiPayload));
      await dispatch(currentMonthProductsVsPreviousMonthAction(fetchAllHomePageBlockDataFromOrdersApiPayload));
    };

    fetchAllHomePageBlockDataFromOrdersApi();
  }, []);

  useEffect(() => {
    setFeaturedInfo(prevFeaturedInfo => {
      const updatedInfo = [...prevFeaturedInfo];

      // Update Total Sales
      if (orderSelectorForTotalSales) {
        updatedInfo[0].featuredMoney = orderSelectorForTotalSales.lastMonth;
        updatedInfo[0].featuredMoneyRate = orderSelectorForTotalSales;
      }

      // Update Net Sales
      if (orderSelectorForNetSales) {
        updatedInfo[1].featuredMoney = orderSelectorForNetSales.lastMonth;
        updatedInfo[1].featuredMoneyRate = orderSelectorForNetSales;
      }

      // Update Order Quantity
      if (orderSelectorForOrderQuantity) {
        updatedInfo[2].featuredMoney = orderSelectorForOrderQuantity.lastMonth;
        updatedInfo[2].featuredMoneyRate = orderSelectorForOrderQuantity;
      }

      return updatedInfo;
    });

  }, [orderSelectorForTotalSales, orderSelectorForNetSales, orderSelectorForOrderQuantity]);

  useEffect(() => {
    setFeaturedInfo(prevFeaturedInfo => {
      const updatedInfo = [...prevFeaturedInfo];

      if (productSelectorForStats) {
        updatedInfo[3].featuredMoney = productSelectorForStats.lastMonth;
        updatedInfo[3].featuredMoneyRate = productSelectorForStats;
      }
      return updatedInfo;
    });
  }, [productSelectorForStats]);


  return (
    // <Layout>
    <div className="featured">
      {featuredInfo.map(info => (
        <Fragment key={info.div}>
          <FeaturedInfoCardBlock
            key={info.id}
            featuredTitle={info.featuredTitle}
            featuredMoney={info.featuredMoney}
            featuredMoneyRate={info.featuredMoneyRate}
          />
        </Fragment>
      ))}
    </div>
    // {/* </Layout> */}
  );
};

function FeaturedInfoCardBlock(props) {
  const { key, featuredTitle, featuredMoney, featuredMoneyRate } = props;

  const featuredMoneyRatePercentage = (((featuredMoneyRate?.currentMonth - featuredMoneyRate?.lastMonth) / featuredMoneyRate?.lastMonth) * 100) || 0;

  return (
    <div className="featuredItem" key={key}>
      <span className="featuredTitle">{featuredTitle}</span>
      <div className="featuredMoneyContainer">
        <span className="featuredMoney"> ₹ {featuredMoney}</span>
        <span className="featuredMoneyRate">
          {featuredMoneyRate?.currentMonth} {featuredMoneyRatePercentage > 0 ? <ArrowUpward className="featuredIcon" /> : featuredMoneyRatePercentage  === 0 ? <></> : <ArrowDownward className="featuredIcon negative" />}
        </span>
      </div>
      <span className="featuredSub"> Compared to last month</span>
    </div>
  );
}

FeaturedInfoCardBlock.propTypes = {
  key: PropTypes.number.isRequired,
  featuredTitle: PropTypes.string.isRequired,
  featuredMoney: PropTypes.number.isRequired || PropTypes.string.isRequired,
  featuredMoneyRate: PropTypes.object.isRequired,
};

export default FeaturedInfo;
