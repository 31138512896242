/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// import { useRoutes } from "react-router-dom";
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import './Layout.css';
// import LeatherBoard from "./LeatherBoard";
// import AddPages from "./AddPages";
// import MediaLibrary from "./MediaLibrary";
// import AdminProfilePage from "./AdminProfilePage";
// import OrderMgmt from "./OrderMgmt";
// import PatientProfilePage from "./PatientProfilepage";
// import CouponCard from "./CouponCard";
// import ProductCategoriesPage from "./ProductCategoriesPage";
// import FeaturedInfo from "./FeaturedInfo";
// import Home from "./Home/Home";
// import SalesChart from "./SalesChart";
// import OrderChart from "./OrderChart";
// import ProductTagsPage from "./ProductTag";
// import Blog from "./Blog";
// import PageList from "./Pagelist";
// import BlogList from "./BlogList";
// import AddProductPage from "./AddproductPage";
// import ProductListPage from "./ProductListPage";
// import PaymentPage from "./PaymentPage";
// import CustomersList from "./CustomersList";
// import AddNewOrder from "./AddNewOrder";
// import AllOrders from "./AllOrder";
// import OrderInfo from "./OrderInfo";
// import FeedbackPage from "./FeedbackPage";
// import UsersList from "./UsersList";
// import GeneralSettingsPage from "./GeneralSettingsPage";
// import MailPage from "./MailPage";

const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  // const routes = [
  //   {
  //     path: "Sidebar",
  //     element: <Sidebar />,
  //   },
  //   {
  //     path: "Topbar",
  //     element: <Topbar />,
  //   },
  //   {
  //     path: "LeatherBoard",
  //     element: <LeatherBoard />,
  //   },
  //   {
  //     path: "AddPages",
  //     element: <AddPages />,
  //   },
  //   {
  //     path: "MediaLibrary",
  //     element: <MediaLibrary />,
  //   },
  //   {
  //     path: "AdminProfilePage",
  //     element: <AdminProfilePage />,
  //   },
  //   {
  //     path: "OrderMgmt",
  //     element: <OrderMgmt />,
  //   },
  //   {
  //     path: "PatientProfilePage",
  //     element: <PatientProfilePage />,
  //   },
  //   {
  //     path: "PatientProfilePage",
  //     element: <PatientProfilePage />,
  //   },
  //   {
  //     path: "CouponCard",
  //     element: <CouponCard />,
  //   },
  //   {
  //     path: "ProductCategoriesPage",
  //     element: <ProductCategoriesPage />,
  //   },
  //   {
  //     path: "FeaturedInfo",
  //     element: <FeaturedInfo />,
  //   },
  //   {
  //     path: "Home",
  //     element: <Home />,
  //   },
  //   {
  //     path: "SalesChart",
  //     element: <SalesChart />,
  //   },
  //   {
  //     path: "OrderChart",
  //     element: <OrderChart />,
  //   },
  //   {
  //     path: "ProductTagsPage",
  //     element: <ProductTagsPage />,
  //   },
  //   {
  //     path: "Blog",
  //     element: <Blog />,
  //   },
  //   {
  //     path: "Layout",
  //     element: <Layout />,
  //   },
  //   {
  //     path: "PageList",
  //     element: <PageList />,
  //   },
  //   {
  //     path: "BlogList",
  //     element: <BlogList />,
  //   },
  //   {
  //     path: "AddProductPage",
  //     element: <AddProductPage />,
  //   },
  //   {
  //     path: "ProductListPage",
  //     element: <ProductListPage />,
  //   },
  //   {
  //     path: "PaymentPage",
  //     element: <PaymentPage />,
  //   },

  //   {
  //     path: "CustomersList",
  //     element: <CustomersList />,
  //   },
  //   {
  //     path: "AddNewOrder",
  //     element: <AddNewOrder />,
  //   },
  //   {
  //     path: "AllOrders",
  //     element: <AllOrders />,
  //   },
  //   {
  //     path: "/OrderInfo/:orderId",
  //     element: <OrderInfo />,
  //   },
  //   {
  //     path: "FeedbackPage",
  //     element: <FeedbackPage />,
  //   },
  //   {
  //     path: "UsersList",
  //     element: <UsersList />,
  //   },
  //   {
  //     path: "GeneralSettingsPage",
  //     element: <GeneralSettingsPage />,
  //   },
  //   {
  //     path: "MailPage",
  //     element: <MailPage />,
  //   },


  // ]
  // const routing = useRoutes(routes);
  return (
    // <React.Fragment>
    <div className="layout-container">
          <div className="topbar">
              <Topbar/>
        {/* Top bar content */}
          </div>
          
          <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
          <Sidebar/>
        {/* Sidebar menu content */}
      </div>
      <div className="content">{children}</div>
      {/* <button onClick={handleToggleSidebar} className="sidebar-toggle">
        Toggle Sidebar
      </button> */}
      </div>
      // {/* </React.Fragment> */}
  );
};

export default Layout;
