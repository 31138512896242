import React from 'react';
import { Typography, Grid } from '@mui/material';

const Diabetes = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Diabetes </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Diabetes ? </strong>
        </Typography>
          <Typography variant="body1">
            Diabetes, also known as Diabetes Mellitus, is a lifestyle disorder
            that occurs when glucose, also called as sugar, levels are too high
            in blood. Glucose is the main source of energy for all body
            functions, which we gain from the food we eat. Insulin hormone,
            secreted from the Pancreas, is responsible for the uptake and use of
            glucose by virtue of its sensitivity. Any deviance as either lesser
            Insulin secretion or increased dietary Glucose intake or both is
            responsible for the condition to occur. The decreased production of
            insulin or inability of the cells to not make use of insulin
            properly. Sometimes it is also defined as the deranged metabolism of
            carbohydrate, protein and fat metabolism. Diabetes is classified in
            many types as follows - <br />
            <b>Type 1 Diabetes -</b> Also known as autoimmune Diabetes in which
            the body is not able to form Insulin.<br />
            <b>Type 2 Diabetes -</b> the body is not able to make or use insulin
            well. Gestational Diabetes- diabetes develops during pregnancy and
            usually goes away after parturition.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of Diabetes</strong>
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Diabetes include:
            <ul className='vw-ul'>
              <li>Frequent urination</li>
              <li>Frequent skin and/or vaginal yeast infections.</li>
              <li>Increased thirst (polydipsia) and dry mouth.</li>
              <li>Numbness or tingling in your hands or feet.</li>
              <li>Fatigue</li>
              <li>Slow-healing sores or cuts.</li>
              <li>Blurred vision</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Causes of Diabetes</strong>
          </Typography>
          <Typography variant="body1">
            Diabetes can be caused by various factors, including:
            <ul className='vw-ul'>
              <li>
                Unhealthy lifestyle- comprising irregular meal timings, junk
                food and fast food consumption, oily and fried meals with lots
                or spice and salt.
              </li>
              <li>
                Female being overweight and having higher calorie intake during
                pregnancy- this reason is responsible for the incident of
                Gestational Diabetes.
              </li>
              <li>
                Sleeping for longer hours just after having heavy meals, both in
                daytime and/or at night time.
              </li>
              <li>
                Stressful occupation and nerve racking endless competitive jobs.
              </li>
              <li>Higher Dietary Sugar and carbohydrates. </li>
              <li>High blood pressure </li>
              <li>High Cholesterol levels</li>
              <li>
                Genetics- having a family history of either parents or closer
                blood relatives
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Diabetes</strong>
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Diabetes include:
            <ul className='vw-ul'>
              <li>Weight. Being overweight or obese is a main risk. </li>
              <li>
                Fat distribution. Storing fat mainly in the abdomen — rather
                than the hips and thighs — indicates a greater risk.
              </li>
              <li>Inactivity.</li>
              <li>Family history.</li>
              <li>Race and ethnicity</li>
              <li>Blood lipid levels.</li>
              <li>Age</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Prevention and Management</strong>
          </Typography>
          <Typography variant="body1">
            While Diabetes cannot always be prevented, some strategies that may
            help manage the symptoms include:
            <ul className='vw-ul'>
              <li>
                Maintaining a healthy body weight special care must be taken
                when age is above 40 years.
              </li>
              <li>
                Adoption of active physical lifestyle on regular basis that may
                include walking, running, Yoga, stretching, use of lift in place
                of stair climbing.
              </li>
              <li>
                Correcting and maintaining a normal Blood pressure and
                Cholesterol levels.
              </li>
              <li>
                Inclusion of more fibre and roughage food items in diet. •
                Keeping the body well hydrated with fruit juices and natural
                sugars containing drinks.
              </li>
              <li>
                Avoid consumption of Alcohol, Caffeine, Tobacco, carbonated
                drinks and sodas.
              </li>
              <li>
                A keen watch must be kept on observing the amount of Calorie
                intake and calorie intake must be customised according to the
                weight, height and demands of daily need as per the level of
                activity, by a certified and well trained Dietician.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Diabetes;
