import React from 'react';
import { Typography, Grid } from '@mui/material';

const DiscProlapse = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Disc Prolapse </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Disc Prolapse ? </strong>
        </Typography>
            <Typography variant="body1">
            Disc prolapse, also known as a herniated disc or slipped disc, refers to the displacement of the
soft gel-like material (nucleus pulposus) within an intervertebral disc, which then protrudes through
the outer fibrous ring (annulus fibrosus). This can cause compression or irritation of nearby
nerves, resulting in symptoms such as localized or radiating pain, numbness, tingling, and muscle
weakness. Common causes of disc prolapse include age-related degeneration, wear and tear,
poor posture, lifting heavy objects incorrectly, and repetitive spinal movements. Factors such as
obesity, smoking, and genetic predisposition can also contribute.            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Disc Prolapse </strong>
        </Typography>
            <Typography variant="body1">
              Herniated disk symptoms vary depending on where the problem is in your spine. Symptoms worsen with movement and get better with rest.
              It’s common for a herniated disk in the lower back to cause “sciatic nerve” pain. This sharp pain usually shoots down one side of your buttocks into your leg and sometimes the foot. Other symptoms of a herniated disk in your lower back include:
              <ul className='vw-ul'>
                <li>Back pain.</li>
                <li>Tingling or numbness in the legs and/or feet.</li>
                <li>Muscle weakness.</li>
              </ul>
              Symptoms of a herniated disk in your neck include:
              <ul className='vw-ul'>
                <li>Pain near or between your shoulder blades</li>
                <li>Pain that travels to your shoulder, arm and sometimes your hand and fingers.</li>
                <li>Neck pain, especially in the back and on the sides of your neck.
                </li>
                <li>Pain that increases when bending or turning your neck.</li>
                <li>Numbness or tingling in your arms.</li>
              </ul>
          </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Disc Prolapse </strong>
        </Typography>
            <Typography variant="body1">
            Disks have soft, gel-like centers and a firmer outer layer, like a jelly doughnut. With time, the outer layer weakens and can crack. A herniated disk happens when the inner “jelly” substance pushes through the crack. The leaked material may press on nearby spinal nerves.
            Several factors can contribute to a disk rupture, including:
            <ul className='vw-ul'>
                <li>Aging.</li>
                <li>Excessive weight.</li>
                <li>Repetitive motions.</li>
                <li>Sudden strain from improper lifting or twisting.</li>
              </ul>
            </Typography>
      
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Disc Prolapse </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Disc Prolapse  include:
              <ul className='vw-ul'>
                <li> Excess body weight causes extra stress on the disks in the lower back.</li>
                <li> Excess body weight causes extra stress on the disks in the lower back.</li>
                <li>Some people inherit a predisposition to developing a herniated disk.</li>
                <li>Frequent driving.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management</strong>
        </Typography>
        <Typography variant="body1">
        In most cases, pain from a herniated disk can go away in time. To ease pain while your disk heals, you can:
        <ul className='vw-ul'>
        <li>Rest for one to three days, if the pain is severe, but it important to avoid long periods of bed rest to prevent stiffness. </li>  
        <li>Apply heat or ice to the affected area.</li>
        </ul>
        </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default DiscProlapse;
