import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/tagActionTypes';
import tagApi from '../../apis/tagApi';

function* tags(action) {
  try {
    const tagsResponse = yield call(tagApi.getAll, action);
    yield put({ type: type.ALL_TAGS_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_TAGS_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsLoggedInUserTags(action) {
  try {
    const tagsResponse = yield call(tagApi.loggedInUserTags, action);
    yield put({ type: type.LOGGED_IN_USER_TAGS_ID_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_TAGS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsCreate(action) {
  try {
    const tagsResponse = yield call(tagApi.create, action);
    yield put({ type: type.TAGS_CREATE_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.TAGS_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsGetBy(action) {
  try {
    const tagsResponse = yield call(tagApi.getBy, action);
    yield put({ type: type.TAGS_GET_BY_ID_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.TAGS_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsUpdateBy(action) {
  try {
    const tagsResponse = yield call(tagApi.updateBy, action);
    yield put({ type: type.TAGS_UPDATE_BY_ID_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.TAGS_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsUnshowBy(action) {
  try {
    const tagsResponse = yield call(tagApi.unshowBy, action);
    yield put({ type: type.TAGS_UNSHOW_BY_ID_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.TAGS_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* tagsDeleteBy(action) {
  try {
    const tagsResponse = yield call(tagApi.deleteBy, action);
    yield put({ type: type.TAGS_DELETE_BY_ID_SUCCESS, tagsResponse: tagsResponse.data });
  } catch (e) {
    yield put({
      type: type.TAGS_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myTagSaga() {
  yield takeEvery(type.ALL_TAGS_REQUESTING, tags);
  yield takeEvery(type.LOGGED_IN_USER_TAGS_ID_REQUESTING, tagsLoggedInUserTags);
  yield takeEvery(type.TAGS_CREATE_REQUESTING, tagsCreate);
  yield takeEvery(type.TAGS_GET_BY_ID_REQUESTING, tagsGetBy);
  yield takeEvery(type.TAGS_UPDATE_BY_ID_REQUESTING, tagsUpdateBy);
  yield takeEvery(type.TAGS_UNSHOW_BY_ID_REQUESTING, tagsUnshowBy);
  yield takeEvery(type.TAGS_DELETE_BY_ID_REQUESTING, tagsDeleteBy);
}

export default myTagSaga;
