import React from 'react';
import Modal from '../Modal/Modal';
import PropTypes from 'prop-types';
import '../../../css/AccountSuccess.css';

function AccountSuccess({ isShowing, closeModalHandler, redirectHandler }) {
  //   openModalHandler = () => {
  //     this.setState({
  //       isShowing: true,
  //     });
  //   };

  //   closeModalHandler = () => {
  //     this.setState({
  //       isShowing: false,
  //     });
  //   };

  return (
    <div>
      <Modal
        className="modal"
        show={isShowing}
        close={closeModalHandler}
        redirectHandler={redirectHandler}
      ></Modal>
    </div>
  );
}

AccountSuccess.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  redirectHandler: PropTypes.func.isRequired,
};


export default AccountSuccess;
