import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Usersection from '../Components/Common/Usersection';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

function Orderpage() {
  return (
    <div>
        <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <Usersection />
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Box
            sx={{
              marginTop: '7%',
              padding: '2rem',
              backgroundColor: '',
              marginRight: '2rem',
            }}
          >
            <Typography align="left">
              Order #4431 was placed on March 02, 2023 and is currently
              Processing
            </Typography>
            <Typography variant="h5" align="left" sx={{padding:'2% 0',fontWeight:'bold'}}>
              Order Details
            </Typography>
            <Box sx={{textAlign:'left'}}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Abhyangam(Ayurvedix Message)x1</td>
                  <td>Rs 200.00</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td>Rs 200.00</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td>Cash on delivery</td>
                </tr>
                <tr>
                  <td>Total :</td>
                  <td>Rs 200.00</td>
                </tr>
              </tbody>
            </table>

            <Button sx={{margin:'2%',padding:'1%',backgroundColor:'#0B8140',color:'white'}}>Order Again</Button>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Billing address</th>
                
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Test Test</p>
                  <p>Test</p>
                  <p>Test</p>
                  <p>Test</p>
                  <p>Test 123456</p>
                  <p>123456789</p>
                  <p>test@test@gmail.com</p>
                  </td>
                  
                </tr>
               
              </tbody>
            </table>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Orderpage;