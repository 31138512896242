/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const ChoornaPindaSwedam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Choorna Pinda Swedam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Choorna Pinda Swedam ?</strong>
          </Typography>
          <Typography variant="body1">
        <p>Choorna Pinda Swedam,  is a traditional Ayurvedic
treatment method used for pain relief and rejuvenation. Ayurveda is an ancient holistic
system of medicine that originated in India and focuses on balancing the body's energies to
promote overall health and well-being.</p>
        <p>In Choorna Pinda Swedam, a poultice (bundle) of medicated herbal powders (Choornam) is
prepared and used to massage the body. The herbal powders are often mixed with various
herbal oils or medicated liquids to form a paste-like consistency. This poultice is then gently
massaged over the affected areas of the body. </p>
        <p>Choornapinda Swedam aims to alleviate pain and reduce inflammation by delivering the
medicinal properties of the herbs directly to the affected areas. The warmth of the poultice
also helps in relaxing the muscles and improving blood circulation, promoting natural healing.</p>
        <p>As with any Ayurvedic treatment, the specific herbs and oils used in Choornapinda Swedam
will vary based on the individual's dosha (constitution) and the condition being treated. It is
essential to consult with a qualified Ayurvedic practitioner to determine if this treatment is
suitable for you and to get personalized recommendations based on your unique health needs.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Benefits of Choorna Pinda Swedam </strong>
          </Typography>
          <Typography variant="body1">
          <ul className='vw-ul'>
          <li>Choorna Pinda Swedam(Podikizhi) increases the peripheral blood supply.</li>
          <li>It relieves body ache and strengthens muscles and skin.</li>
          <li>Reduces the inflammation of the joints</li>
          <li>It acts as muscle relaxant and relieves joint stiffness</li>
          <li>It is also a good technique for relaxation and reliving stress.</li>
          <li>It has detoxifier properties for the skin, removes the toxins through the pores of the skin, and lowers the tendency of skin diseases.</li>  
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr /> {/* Line before the third heading */}
        <Typography variant="h6" gutterBottom>
        <strong> Procedure of Choorna Pinda Swedam </strong>
        </Typography>
        <Typography variant="body1">
        <p><b>Preoperative procedure</b></p>
        <p>Initially, the medicinal powder is prepared the powder is fried in the vessel till it attains a golden-brown color. It is then divided into 4 equal parts and tied into 4 pottalis.</p>
        <p>The patient is seated on the table/droni and Rasnadi choorna is applied to the head.</p>
        <p>Other thalams (medicinal application on the head) like navaneetha (butter)+ bala(Boerhavia diffuse), amalaki (Phyllanthus emblica) + thakra (buttermilk), etc are considered according to condition. </p>
        <p><b>Procedure</b></p>
        <p>The prepared potali has to be checked for temperature by applying it on the dorsum of the hand of the therapist.</p>
        <p>We ask the patient to lie in a supine position (according to the area) and the hot potali is gently applied all over the body.</p>
        <p>With extra caution, without needing or messaging, we gently press the body with potali. Both potalis are used alternatively after the sting to maintain a uniform temperature throughout the procedures.</p>
        <p>The same potali can be used for three days. For the purpose of entire body perspiration, this is done for about 30-45 minutes.</p>
        <p><b>Post-operative procedure</b></p>
        <p>After the procedure, the medicines on the head are removed with cotton, and rasnadi choorna is applied to the head. </p>
        <p>We advise the patient to take complete rest for at least half an hour and take bath in hot water if indicated for the disease.</p>
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Can this be administered in all cases? </strong>
          </Typography>
          <Typography variant="body1">
          <p>This procedure is used in order to relax the stiffness muscles and alleviate pain and stiffness. In cases of vata and kapha dosha diseases, this procedure can be administered. Therefore, it shows exemplary effects in conditions like rheumatoid arthritis, osteoarthritis, back pain, cervical spondylosis, etc.</p>
          <p>But patients with pitta dosha disease and patients with pitta predominant body constitution must not indulge in this procedure as it may further increase the condition. Also, pregnant women and patients with fractures and dislocations are to avoid this treatment.</p>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default ChoornaPindaSwedam;
