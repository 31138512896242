import React from 'react';
function NotFound() {
  return (
    <div>
      <p>The Page you are looking for could Not found</p>
    </div>
  );
}

export default NotFound;
