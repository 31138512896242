import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import '../css/Subscribe.css';

function Subscribe() {
  return (
    <div className="subscribe">
      <Box className="nws-left">
        <Box className="subs" component="span">
          <h1>hii</h1>
          <h1>
            SUBSCRIBE TO <br />
            <Divider className="horizontal-rule" />
            <span>OUR </span>
            <span className="newsletter">NEWSLETTER</span>
          </h1>
        </Box>
      </Box>

      <Box className="nws-right">
        <form className="subscribe-form">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="email-label">
              EMAIL
            </label>
            <br />
            <input type="email" className="email" />
            <br />
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </form>
      </Box>
    </div>
  );
}

export default Subscribe;
