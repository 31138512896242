import React from 'react';
import { Typography, Grid } from '@mui/material';

const DegenerativeDiscDisease = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Degenerative Disc Disease </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Degenerative Disc Disease ? </strong>
        </Typography>
          <Typography variant="body1">
            Degenerative Disc Disease (DDD) refers to the age-related wear and
            tear of the intervertebral discs in the spine. It is characterized
            by the gradual breakdown and degeneration of the discs, leading to
            symptoms such as chronic back or neck pain, stiffness, and reduced
            flexibility. As the discs lose their cushioning ability, it can
            result in the compression of nerves, causing radiating pain,
            numbness, or weakness in the limbs. Causes of DDD include aging,
            repetitive stress on the spine, genetics, smoking, and obesity.{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Symptoms of Degenerative Disc Disease</strong>
          </Typography>
          <Typography variant="body1">
            The most common symptoms of degenerative disk disease are neck pain
            and back pain. You may experience pain that:{' '}
            <ul className='vw-ul'>
              <li>Comes and goes, lasting for weeks or months at a time.</li>
              <li>Leads to numbness or tingling in your arms or legs.</li>
              <li>Radiates down your buttocks and lower back.</li>
              <li>Worsens with sitting, bending or lifting.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Causes of Degenerative Disc Disease </strong>
          </Typography>
          <Typography variant="body1">
            Spinal disks wear down as a normal part of aging. Especially after
            age 40, most people experience some disk degeneration. You might
            have pain if your spinal disks:
            <ul className='vw-ul'>
              <li>
                <b>Dry out:</b> Your disks have a soft core that mostly contains
                water. As you get older, that core naturally loses some water.
                As a result, disks get thinner and don’t provide as much shock
                absorption as they used to.
              </li>
              <li>
                <b>Tear or crack:</b> Minor injuries can lead to small cracks in
                your spinal disks. These tears are often near nerves. Tears can
                be painful, even when they are minor. If the outer wall of your
                spinal disk cracks open, your disk may bulge out of place, known
                as a herniated disk, which may compress a spinal nerve.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong>Risk Factors for Degenerative Disc Disease</strong>
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Degenerative Disc Disease
            include:
            <ul className='vw-ul'>
              <li>
                <b>Excessive weight:</b> Obese people are more likely to have
                symptoms of degenerative disc disease.{' '}
              </li>
              <li>
                <b>Heavy physical work:</b> Labor such as repeated heavy lifting
                may put excess stress and strain on your discs.
              </li>
              <li>
                A family history of back or spine disorders increases your risk
                for degenerative disc disease.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" gutterBottom>
          <strong> Prevention and Management </strong>
          </Typography>
          <Typography variant="body1">
            You can prevent or slow the progression of spinal degeneration
            through lifestyle changes. Some of these include:{' '}
            <ul className='vw-ul'>
              <li>Achieving and maintaining a healthy body weight.</li>
              <li>Avoiding or quitting smoking.</li>
              <li>Exercising regularly to increase strength and flexibility.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default DegenerativeDiscDisease;
