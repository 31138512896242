/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // createBrowserRouter,
  // Outlet,
  // useLocation,
} from "react-router-dom";

import "./App.css";
import Footer from "./Components/Common/Footer";
import NavBar from "./Components/Common/NavBar";
import AyurvedicTherapies from "./Components/Common/AyurvedicTherapies";
import AddPages from "./Components/Common/AdminDasboard/AddPages";
import MediaLibrary from "./Components/Common/AdminDasboard/MediaLibrary";
import AdminProfilePage from "./Components/Common/AdminDasboard/AdminProfilePage";
import PatientProfilePage from "./Components/Common/AdminDasboard/PatientProfilepage";
import CouponCard from "./Components/Common/AdminDasboard/CouponCard";
import ProductCategoriesPage from "./Components/Common/AdminDasboard/ProductCategoriesPage";
import Home from "./Components/Common/AdminDasboard/Home/Home";
import ProductTagsPage from "./Components/Common/AdminDasboard/ProductTag";
import Blog from "./Components/Common/AdminDasboard/Blog";
import PageList from "./Components/Common/AdminDasboard/Pagelist";
import BlogList from "./Components/Common/AdminDasboard/BlogList";
import AddProductPage from "./Components/Common/AdminDasboard/AddproductPage";
import ProductListPage from "./Components/Common/AdminDasboard/ProductListPage";
import CustomersList from "./Components/Common/AdminDasboard/CustomersList";
import AddNewOrder from "./Components/Common/AdminDasboard/AddNewOrder";
import AllOrders from "./Components/Common/AdminDasboard/AllOrder";
import OrderInfo from "./Components/Common/AdminDasboard/OrderInfo";
import FeedbackPage from "./Components/Common/AdminDasboard/FeedbackPage";
import UsersList from "./Components/Common/AdminDasboard/UsersList";
import Diseasesntreatments from "./Pages/Diseasesntreatments";
import Slides from "./Components/Common/Slides";
import Usersection from "./Components/Common/Usersection";
import OrderSummary from "./Components/Common/OrderSummary";
import BillingForm from "./Components/Common/BillingForm";
import AboutUs from "./Components/Common/AboutUs";
import AboutHome from "./Components/Common/AboutHome";
import CartItems from "./Components/Common/CartItems";
import CartTotal from "./Components/Common/CartTotal";
import CartCount from "./Components/Common/CartCount";
import Packages from "./Components/Common/Packages";
import ProductDetailsCard from "./Components/Common/ProductDetailsCard";
import ProductImages from "./Components/Common/ProductImages";
import ProductListCard from "./Components/Common/ProductListCard";
import ProductSlideShow from "./Components/Common/ProductSlideShow";
// import OrderMgmt from "./Components/Common/AdminDasboard/OrderMgmt";
import Membership from "./Pages/Membership";
import Contact from "./Pages/Contact";
import Blogs from "./Pages/Blogs";
import Aboutuspage from "./Pages/Aboutuspage";
import Category from "./Pages/Category";
import CategoryList from "./Pages/CategoryList";
import ProductDetails from "./Pages/ProductDetails";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import Policiespage from "./Pages/Policiespage";
import TestimonialPage from "./Pages/Testimonial";

import Blogdetailpage from "./Pages/Blogdetailpage";
import Topics from "./Pages/Topics";
import Login from "./Pages/Login";
import Disclaimer from "./Pages/Disclaimer";
import Faq from "./Pages/Faq";
import Succesful from "./Pages/Succesful";
import Sevicesnproduct from "./Pages/Sevicesnproduct";
import Dashboard from "./Pages/Dashboard";
import Reset from "./Pages/Reset";
import Orders from "./Pages/Orders";
import Downloads from "./Pages/Downloads";
import Addresses from "./Pages/Addresses";
import Billingaddr from "./Pages/Billingaddr";
import Shippingadr from "./Pages/Shippingadr";
import Orderdetails from "./Pages/Orderdetails";
import Orderdetail1 from "./Pages/Orderdetail1";
import Orderpage from "./Pages/Orderpage";
import AccountDetails from "./Pages/UserAccountdetailspage";
import ForgotPassword from "./Pages/Forgot";
import NotFound from "../src/Pages/NotFound";
import AccessDenied from "../src/Pages/AccessDenied";
import Blogdetailpage1 from "./Pages/Blogdetailpage1";
import Blogdetailpage2 from "./Pages/Blogdetailpage2";
import Blogdetailpage5 from "./Pages/Blogdetailpage5";
import Blogdetailpage6 from "./Pages/Blogdetailpage6";
import Blogdetailpage7 from "./Pages/Blogdetailpage7";
import AccountDetailsPage from "./Pages/UserAccountdetailspage";
import Subscribe from "./Pages/Subscribe";
import Sitemap from "./Pages/Sitemap";

// import RoutesAuthenticated from './Routes/ProtectedRoutes';
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
// import Usersection from "./Components/Common/Usersection";
// import PackageCard from './Components/Common/PackageCard';
import AllergyRhinitis from "./Diseases&Treatments/AllergyRhinitis";
import AnkleSprain from "./Diseases&Treatments/AnkleSprain";
import Anxiety from "./Diseases&Treatments/Anxiety";
import Arthritis from "./Diseases&Treatments/Arthritis";
import Pimple from "./Diseases&Treatments/Pimple";
import PolycysticOvarySyndrome from "./Diseases&Treatments/PolycysticOvarySyndrome";
import RheumatoidArthritis from "./Diseases&Treatments/RheumatoidArthritis";
import Scabies from "./Diseases&Treatments/Scabies";
import DiscBulging from "./Diseases&Treatments/DiscBulging";
import BiliaryColic from "./Diseases&Treatments/BiliaryColic";
import BiliaryDisease from "./Diseases&Treatments/BiliaryDisease";
import BladderPainSyndrome from "./Diseases&Treatments/BladderPainSyndrome";
import BrachialPlexusInjuryIllness4 from "./Diseases&Treatments/BrachialPlexusInjuryIllness4";
import BronchialAsthma from "./Diseases&Treatments/BronchialAsthma";
import Cervical from "./Diseases&Treatments/Cervical";
import ChronicConstipation from "./Diseases&Treatments/ChronicConstipation";
import DegenerativeDiscDisease from "./Diseases&Treatments/DegenerativeDiscDisease";
import Diabetes from "./Diseases&Treatments/Diabetes";
// import DiabeticKidneyInfection from './Diseases&Treatments/DiabeticFootInfection';
import DiabeticFootInfection from "./Diseases&Treatments/DiabeticFootInfection";
import DiabeticKidneyDisease from "./Diseases&Treatments/DiabeticKidneyDisease";
import DiabeticRetinopathy from "./Diseases&Treatments/DiabeticRetinopathy";
import DiscProlapse from "./Diseases&Treatments/DiscProlapse";
import DryEyes from "./Diseases&Treatments/DryEyes";
import Dyspepsia from "./Diseases&Treatments/Dyspepsia";
import Dysphagia from "./Diseases&Treatments/Dysphagia";
import ElbowArthritis from "./Diseases&Treatments/ElbowArthritis";
import Endometriosis from "./Diseases&Treatments/Endometriosis";
import ExtremeObesity from "./Diseases&Treatments/ExtremeObesity";
import FecalIncontinence from "./Diseases&Treatments/FecalIncontinence";
import FrozenShoulder from "./Diseases&Treatments/FrozenShoulder";
import GastroesophagealRefluxDisease from "./Diseases&Treatments/GastroesophagealRefluxDisease";
import GlomerularDisease from "./Diseases&Treatments/GlomerularDisease";
import HeelPain from "./Diseases&Treatments/HeelPain";
import Hypertension from "./Diseases&Treatments/Hypertension";
import Hyperthyroidism from "./Diseases&Treatments/Hyperthyroidism";
import Hypoglycemia from "./Diseases&Treatments/Hypoglycemia";
import Hypothyroidism from "./Diseases&Treatments/Hypothyroidism";
import ImmunodeficiencyDisorder from "./Diseases&Treatments/ImmunodeficiencyDisorder";
import Influenza from "./Diseases&Treatments/Influenza";
import IrritableBowelSyndrome from "./Diseases&Treatments/IrritableBowelSyndrome";
import Jaundice from "./Diseases&Treatments/Jaundice";
import KneeFractures from "./Diseases&Treatments/KneeFractures";
import LipidDisorders from "./Diseases&Treatments/LipidDisorders";
import MaleInfertility from "./Diseases&Treatments/MaleInfertility";
import Menorrhagia from "./Diseases&Treatments/Menorrhagia";
import Migraine from "./Diseases&Treatments/Migraine";
import NerveInjury from "./Diseases&Treatments/NerveInjury";
import Oligomenorrhea from "./Diseases&Treatments/Oligomenorrhea";
import Osteoarthritis from "./Diseases&Treatments/Osteoarthritis";
import Osteoporosis from "./Diseases&Treatments/Osteoporosis";
import UrinaryTractInfection from "./Diseases&Treatments/UrinaryTractInfection";
import WeightLoss from "./Diseases&Treatments/WeightLoss";
import Abhyangam from "./Pages/Abhyangam";
import Thakradhara from "./Pages/Thakradhara";
import Swedanam from "./Pages/Swedanam";
import Sirodhara from "./Pages/Sirodhara";
import Lepanam from "./Pages/Lepanam";
import SarvangaDhara from "./Pages/SarvangaDhara";
import KatiVasti from "./Pages/KatiVasti";
import Januvasti from "./Pages/Januvasti";
import BundleMassage from "./Pages/BundleMassage";
import Gout from "./Diseases&Treatments/Gout";
import BackPain from "./Diseases&Treatments/BackPain";
import Spondyloarthritis from "./Diseases&Treatments/Spondyloarthritis";
import SkinAllergies from "./Diseases&Treatments/SkinAllergies";
import ChoornaPindaSwedam from "./Pages/ChoornaPindaSwedam";
import PatraPindaSwedam from "./Pages/PatraPindaSwedam";
import ShashtikasaliPindaSwedam from "./Pages/ShashtikasaliPindaSwedam";
import Vamana from "./Pages/Vamana";
import Virechana from "./Pages/Virechana";
import Nasyam from "./Pages/Nasyam";
import Vasti from "./Pages/Vasti";
import Raktamoksham from "./Pages/Raktamoksham";
// import PackagesVedanta from "./Pages/PackagesVedanta";
import "./Components/Common/style.css";
// import secureLocalStorage from 'react-secure-storage';
// import { v4 as uuidv4 } from 'uuid';
// import UploadsProductsToDB from './scripts/UploadProductsToDB';
import UploadCategoriesToDB from "./scripts/uploadCategoriesToDB";
import PatientDetailsForm from "./Components/Common/AdminDasboard/PatientsDetailsForm";
import PatientListPage from "./Components/Common/AdminDasboard/PatientListPage";
import SatisfactionGuarantee from "./Pages/SatisfactionGuarantee";
import TermsAndConditions from "./Pages/Termncondition";
import CommingSoon from "./Pages/CommingSoon";
import Sciatica from "./Diseases&Treatments/Sciatica";
import CareerPage from "./Components/Common/CareerPage";
// import TestimonialPage from "./Components/Common/Testimonial";
import DiabeticKidneyDesease from "./Diseases&Treatments/DiabeticKidneyDisease";
import Healthcare from "./Pages/Healthcare";
import Careers from "./Pages/Careers";
import ErrorBoundary from "./Components/Common/ErrorBoundary";

const ROLES = {
  User: "User",
  Editor: "Editor",
  Admin: "Admin",
};
const AppLayout = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console, no-undef
    ;
  }, []);
  // let storage = localStorage.getItem("Role");
  // // const location = useLocation();
  // const pathname = location.pathname;

  // useEffect(() => {
  //   // Check if the user exists in localStorage
  //   const existingUser = secureLocalStorage.getItem('user');

  //   // If the user exists, use their stored guestId
  //   if (existingUser) {
  //     // secureLocalStorage.setItem('users', newGuestId);
  //     // dispatching the createOrUpdate Cart with existing products from here using localstorage Cart

  //   } else {
  //     // If the user doesn't exist, generate a new guestId using UUID
  //     const newGuestId = uuidv4();
  //     secureLocalStorage.setItem('users', newGuestId);
  //   }
  // }, []);

  return (
    <Router>
      <React.Fragment>
        <ErrorBoundary>
        <NavBar />

        <Routes>
          {/* <Route element={<UploadsProductsToDB />} path="/UploadsProductsToDB" /> */}
          {/* <Route element={<UploadCategoriesToDB />} path="/UploadsProductsToDB" /> */}
          {/* </Private Route> */}

          <Route element={<ProtectedRoutes allowedRoles={[ROLES.Admin]} />}>
            <Route element={<Home />} path="/Home" />
            <Route element={<ProductTagsPage />} path="/ProductTagsPage" />
            <Route element={<AddPages />} path="/AddPages" />
            <Route element={<Blog />} path="/Blog" />
            <Route element={<PageList />} path="/PageList" />
            <Route element={<BlogList />} path="/BlogList" />
            <Route element={<AddProductPage />} path="/AddProductPage" />
            <Route element={<ProductListPage />} path="/ProductListPage" />
            <Route element={<CustomersList />} path="/CustomersList" />
            <Route element={<AddNewOrder />} path="/AddNewOrder" />
            <Route element={<AllOrders />} path="/AllOrders" />
            <Route element={<OrderInfo />} path="/OrderInfo/:orderId" />
            <Route element={<FeedbackPage />} path="/FeedbackPage" />
            <Route element={<UsersList />} path="/UsersList" />

            <Route
              element={<ProductCategoriesPage />}
              path="/ProductCategoriesPage"
            />
            <Route element={<CouponCard />} path="/CouponCard" />
            <Route
              element={<PatientProfilePage />}
              path="/PatientProfilePage"
            />

            <Route element={<AdminProfilePage />} path="/AdminProfilePage" />
            <Route element={<MediaLibrary />} path="/MediaLibrary" />
          </Route>

          {/* </Public Route> */}
          <Route element={<PublicRoutes allowedRoles={[ROLES.User]} />}>
            <Route element={<Slides />} path="/" exact />
            <Route element={<Blogs />} path="/blogs" />
            <Route element={<Aboutuspage />} path="/aboutus" />
            <Route element={<Abhyangam />} path="/Abhyangam" />
            <Route element={<Sitemap />} path="/sitemap" />
            <Route element={<TestimonialPage />} path="/testimonials" />
            <Route element={<Swedanam />} path="/Swedanam" />
            <Route element={<Thakradhara />} path="/Thakradhara" />
            <Route element={<Sirodhara />} path="/Sirodhara" />
            <Route element={<Lepanam />} path="/Lepanam" />
            <Route element={<SarvangaDhara />} path="/SarvangaDhara" />
            <Route element={<KatiVasti />} path="/KatiVasti" />
            <Route element={<Januvasti />} path="/Januvasti" />
            <Route element={<BundleMassage />} path="/BundleMassage" />
            <Route element={<ChoornaPindaSwedam />} path="/ChoornaPindaSwedam" />
            <Route element={<PatraPindaSwedam />} path="/PatraPindaSwedam" />
            <Route
              element={<ShashtikasaliPindaSwedam />}
              path="/ShashtikasaliPindaSwedam"
            />
            <Route element={<Vamana />} path="/Vamana" />
            <Route element={<Virechana />} path="/Virechana" />
            <Route element={<Nasyam />} path="/Nasyam" />
            <Route element={<Vasti />} path="/Vasti" />
            <Route element={<Raktamoksham />} path="/Raktamoksham" />
            <Route
              element={<AyurvedicTherapies />}
              path="/ayurvedic-therapies"
            />
            <Route element={<Slides />} path="/Slides" />
            {/* <Route element={<Blogdetailpage3 />} path="/Blogdetailpage3" />
            <Route element={<Blogdetailpage4 />} path="/Blogdetailpage4" />
            <Route element={<Blogdetailpage5 />} path="/Blogdetailpage5" />
            <Route element={<Blogdetailpage6/>} path="/Blogdetailpage6" /> */}
            <Route element={<Blogs />} path="/blogs" />
            {/* <Route element={<Sitemap />} path="/Sitemap" /> */}
            <Route element={<Aboutuspage />} path="/aboutuspage" />

            <Route
              element={<Diseasesntreatments />}
              path="/diseases-treatments"
            />

            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<CareerPage />} path="/CareerPage" />
            <Route element={<CategoryList />} path="/category-list/:id" />
            <Route element={<ProductDetails />} path="/product-details/:pid" />
            <Route element={<Cart />} path="/cart" />
            <Route element={<Downloads />} path="/downloads" />
            <Route element={<Checkout />} path="/checkout" />
            <Route element={<Addresses />} path="/address" />
            <Route element={<Billingaddr />} path="/bilingadr" />
            <Route element={<Shippingadr />} path="/shippingadr" />
            <Route element={<AccountDetails />} path="/Accountdetailspage" />
            <Route element={<Orderdetails />} path="/orderdetails" />
            <Route element={<Membership />} path="/membership" />
            <Route element={<Contact />} path="/contact-us" />
            <Route element={<Careers />} path="/careers" />
            <Route element={<AboutUs />} path="/AboutUs" />
            <Route element={<Blogdetailpage />} path="/Blogdetailspage" />
            <Route element={<Blogdetailpage1 />} path="/kapha-dosha-and-type-2-diabetes" />
            <Route element={<Blogdetailpage2 />} path="/breathing-out-pollution-with-ayurveda" />
            <Route element={<Blogdetailpage5 />} path="/hypertension-solutions-with-ayurveda-at-vedanta-wellness" />
            <Route element={<Blogdetailpage6 />} path="/rediscover-comfort-with-ayurvedic-joint-pain-treatment-at-vedanta-wellness" />
            <Route element={<Blogdetailpage7 />} path="/ayurvedic-treatment-for-low-blood-pressure-at-vedanta-wellness" />
            <Route element={<Healthcare />} path="/ayurveda-role-in-modern-healthcare" />
            <Route element={<Policiespage />} path="/privacy-policy" />
            <Route element={<TermsAndConditions />} path="/term-conditions" />
            <Route element={<Login />} path="/login" />
            <Route element={<Disclaimer />} path="/Disclaimer" />
            <Route element={<Faq />} path="/faq" />
            <Route element={<Succesful />} path="/sucessful" />
            <Route element={<ForgotPassword />} path="/Forgot" />
            <Route element={<Sevicesnproduct />} path="/services-product" />
            <Route element={<Usersection />} path="/user" />
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<TestimonialPage />} path="/Testimonial" />
            <Route element={<Topics />} path="/topics/rendering/" />
            <Route element={<Topics />} path="/topics/components" />
            <Route element={<Topics />} path="/topics/props-v-state" />
            <Route element={<Orderdetail1 />} path="/orderdetails1" />

            <Route element={<Orders />} path="/order" />
            <Route element={<Orderpage />} path="/orderdetails2" />
            <Route
              element={<SatisfactionGuarantee />}
              path="/SatisfactionGuarantee"
            />

            <Route element={<Blogdetailpage />} path="/the-ayurvedic-art-of-anti-ageing" />

            <Route element={<Reset />} path="/Reset" />

            <Route element={<OrderSummary />} path="/OrderSummary" />
            <Route element={<BillingForm />} path="/BillingForm" />
            <Route element={<AboutUs />} path="/AboutUs" />

            <Route element={<AboutHome />} path="/AboutHome" />

            <Route element={<CartItems />} path="/Cartitems" />
            <Route element={<CartTotal />} path="/Carttotals" />

            <Route element={<CartCount />} path="/CartCount" />

            <Route element={<ProductImages />} path="/ProductImages" />
            <Route element={<ProductListCard />} path="/ProductListCard" />

            <Route element={<Category />} path="/Category" />
            <Route element={<ProductSlideShow />} path="/ProductSlideShow" />
            <Route
              element={<AllergyRhinitis />}
              path="/Diseases&Treatments/AllergyRhinitis"
            />
            <Route element={<Anxiety />} path="/Diseases&Treatments/Anxiety" />
            <Route element={<UrinaryTractInfection />} path="/Diseases&Treatments/UrinaryTractInfection" />
            <Route element={<Sciatica />} path="/Diseases&Treatments/Sciatica" />
            <Route element={<WeightLoss />} path="/Diseases&Treatments/WeightLoss" />
            <Route element={<SkinAllergies />} path="/Diseases&Treatments/SkinAllergies" />
            <Route element={<DiscBulging />} path="/Diseases&Treatments/DiscBulging" />
            <Route element={<DiabeticKidneyDisease />} path="/Diseases&Treatments/DiabeticKidneyDisease" />
            
            <Route
              element={<AnkleSprain />}
              path="/Diseases&Treatments/AnkleSprain"
            />
            <Route
              element={<Arthritis />}
              path="/Diseases&Treatments/Arthritis"
            />
            <Route element={<Pimple />} path="/Diseases&Treatments/Pimple" />
            <Route
              element={<PolycysticOvarySyndrome />}
              path="/Diseases&Treatments/PolycysticOvarySyndrome"
            />
            <Route
              element={<RheumatoidArthritis />}
              path="/Diseases&Treatments/RheumatoidArthritis"
            />
            <Route element={<Scabies />} path="/Diseases&Treatments/Scabies" />
            <Route
              element={<BiliaryColic />}
              path="/Diseases&Treatments/BiliaryColic"
            />
            <Route element={<Gout />} path="/Diseases&Treatments/Gout" />
            <Route
              element={<BackPain />}
              path="/Diseases&Treatments/BackPain"
            />
            <Route
              element={<Spondyloarthritis />}
              path="/Diseases&Treatments/Spondyloarthritis"
            />
            <Route
              element={<BiliaryDisease />}
              path="/Diseases&Treatments/BiliaryDisease"
            />
            <Route
              element={<BladderPainSyndrome />}
              path="/Diseases&Treatments/BladderPainSyndrome"
            />
            <Route
              element={<BrachialPlexusInjuryIllness4 />}
              path="/Diseases&Treatments/BrachialPlexusInjuryIllness4"
            />
            <Route
              element={<BronchialAsthma />}
              path="/Diseases&Treatments/BronchialAsthma"
            />
            <Route
              element={<Cervical />}
              path="/Diseases&Treatments/Cervical"
            />
            <Route
              element={<ChronicConstipation />}
              path="/Diseases&Treatments/ChronicConstipation"
            />
            <Route
              element={<DegenerativeDiscDisease />}
              path="/Diseases&Treatments/DegenerativeDiscDisease"
            />
            <Route
              element={<Diabetes />}
              path="/Diseases&Treatments/Diabetes"
            />
            <Route
              element={<Sciatica />}
              path="/Diseases&Treatments/Sciatica"
            />
            <Route
              element={<DiscBulging />}
              path="/Diseases&Treatments/DiscBulging"
            />
            <Route
              element={<UrinaryTractInfection />}
              path="/Diseases&Treatments/Urinary-Tract-Infection"
            />
            <Route
              element={<WeightLoss />}
              path="/Diseases&Treatments/WeightLoss"
            />
            <Route
              element={<DiabeticKidneyDesease/>}
              path="/Diseases&Treatments/DiabeticKidneyDesease"
            />
            <Route
              element={<DiabeticFootInfection />}
              path="/Diseases&Treatments/DiabeticFootInfection"
            />
            <Route
              element={<DiabeticRetinopathy />}
              path="/Diseases&Treatments/DiabeticRetinopathy"
            />
            <Route
              element={<DiscProlapse />}
              path="/Diseases&Treatments/DiscProlapse"
            />
            <Route element={<DryEyes />} path="/Diseases&Treatments/DryEyes" />
            <Route
              element={<Dyspepsia />}
              path="/Diseases&Treatments/Dyspepsia"
            />
            <Route
              element={<Dysphagia />}
              path="/Diseases&Treatments/ Dysphagia"
            />
            <Route
              element={<ElbowArthritis />}
              path="/Diseases&Treatments/ElbowArthritis"
            />
            <Route
              element={<Endometriosis />}
              path="/Diseases&Treatments/Endometriosis"
            />
            <Route
              element={<ExtremeObesity />}
              path="/Diseases&Treatments/ExtremeObesity"
            />
            <Route
              element={<FecalIncontinence />}
              path="/Diseases&Treatments/FecalIncontinence"
            />
            <Route
              element={<FrozenShoulder />}
              path="/Diseases&Treatments/FrozenShoulder"
            />
            <Route
              element={<GastroesophagealRefluxDisease />}
              path="/Diseases&Treatments/GastroesophagealRefluxDisease"
            />
            <Route
              element={<GlomerularDisease />}
              path="/Diseases&Treatments/GlomerularDisease"
            />
            <Route
              element={<HeelPain />}
              path="/Diseases&Treatments/HeelPain"
            />
            <Route
              element={<Hypertension />}
              path="/Diseases&Treatments/HyperTension"
            />
            <Route
              element={<Hyperthyroidism />}
              path="/Diseases&Treatments/Hyperthyroidism"
            />
            <Route
              element={<Hypoglycemia />}
              path="/Diseases&Treatments/Hypoglycemia"
            />
            <Route
              element={<Hypothyroidism />}
              path="/Diseases&Treatments/Hypothyroidism"
            />
            <Route
              element={<ImmunodeficiencyDisorder />}
              path="/Diseases&Treatments/ImmunodeficiencyDisorder"
            />
            <Route
              element={<Influenza />}
              path="/Diseases&Treatments/Influenza"
            />
            <Route
              element={<IrritableBowelSyndrome />}
              path="/Diseases&Treatments/IrritableBowelSyndrome"
            />
            <Route
              element={<Jaundice />}
              path="/Diseases&Treatments/Jaundice"
            />
            <Route
              element={<LipidDisorders />}
              path="/Diseases&Treatments/LipidDisorders"
            />
            <Route
              element={<MaleInfertility />}
              path="/Diseases&Treatments/MaleInfertility"
            />
            <Route
              element={<Menorrhagia />}
              path="/Diseases&Treatments/Menorrhagia"
            />
            <Route
              element={<Migraine />}
              path="/Diseases&Treatments/Migraine"
            />
            <Route
              element={<NerveInjury />}
              path="/Diseases&Treatments/NerveInjury"
            />
            <Route
              element={<Oligomenorrhea />}
              path="/Diseases&Treatments/Oligomenorrhea"
            />
            <Route
              element={<Osteoarthritis />}
              path="/Diseases&Treatments/Osteoarthritis"
            />
            <Route
              element={<Osteoporosis />}
              path="/Diseases&Treatments/Osteoporosis"
            />
            <Route
              element={<KneeFractures />}
              path="/Diseases&Treatments/KneeFractures"
            />
            <Route
              element={<KneeFractures />}
              path="/Diseases&Treatments/KneeFractures"
            />
            <Route
              element={<KneeFractures />}
              path="/Diseases&Treatments/KneeFractures"
            />
            <Route element={<CommingSoon />} path="/coming-soon" />
            <Route element={<Subscribe />} path="/Subscribe" />
            <Route
              element={<AccountDetailsPage />}
              path="/UserAccountDetailsPage"
            />
            <Route element={<Packages />} path="/Packages" />
            <Route
              element={<ProductDetailsCard />}
              path="/ProductDetailsCard"
            />
          </Route>
          <Route element={<NotFound />} path="*" />
          <Route element={<AccessDenied />} path="access-denied" />
        </Routes>
          <Footer />
          </ErrorBoundary>
      </React.Fragment>
    </Router>
  );
};
export default AppLayout;
