import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility } from '@material-ui/icons';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './Layout';
import { ordersAction, ordersUnshowByAction } from '../../../redux/actions/orderActions';

const COMPLIMENTARY_STATUS = {
  in_cart_only: 'In cart',
  awaiting_manufacturers_response: 'Awaiting manufacturer\'s response',
  in_transit: 'In transition',
  out_for_delivery: 'Out for delivery',
  delivered: 'Delivered'
};

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginRight: theme.spacing(2),
  },
}));

function AllOrders() {
  const ordersSelector = useSelector(state => state?.orders?.data);

  const [ordersFromSelector, setOrdersFromSelector] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    { field: 'order_no', headerName: 'Order No', width: 150 },
    { field: 'order_name', headerName: 'Order Name', width: 175 },
    { field: 'createdBy', headerName: 'Created By', width: 175 },

    { field: 'createdAt', headerName: 'Created Date', width: 100 },
    { field: 'updatedAt', headerName: 'Modification time', width: 120 },

    { field: 'finalStatus', headerName: 'Status', width: 120 },
    { field: 'itemsGrandTotalCost', headerName: 'Grand Total (in INR)', width: 180 },
    { field: 'itemsGrandTotalQuantity', headerName: 'Total Quantity', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            component={Link}
            to={`/OrderInfo/${params?.row?._id}/`}
            aria-label="View More"
          >
            <Visibility />
          </IconButton>
          <IconButton onClick={() => handleDeleteOrder(params.row?._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    const ordersFetchPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllOrders() {
      await dispatch(ordersAction(ordersFetchPayload));
    }

    fetchAllOrders();
  }, []);

  useEffect(() => {
    if (ordersSelector && Array.isArray(ordersSelector)) {
      setOrdersFromSelector(ordersSelector.map(anOrder => ({
        ...anOrder,
        id: anOrder._id,
        createdBy: `${anOrder?.belongs_to?.firstname} ${anOrder?.belongs_to?.lastname}`,
        finalStatus: COMPLIMENTARY_STATUS[anOrder?.status],
        createdAt: anOrder?.createdAt?.split('T')[0],
        updatedAt: anOrder?.updatedAt?.split('T')[0],
      })));
      setFilteredOrders(ordersSelector.map(anOrder => ({
        ...anOrder,
        id: anOrder._id,
        createdBy: `${anOrder?.belongs_to?.firstname} ${anOrder?.belongs_to?.lastname}`,
        finalStatus: COMPLIMENTARY_STATUS[anOrder?.status],
        createdAt: anOrder?.createdAt?.split('T')[0],
        updatedAt: anOrder?.updatedAt?.split('T')[0],
      })));
    }
  }, [ordersSelector]);

  const handleDeleteOrder = async (id) => {
    const deleteOrderPayload = {
      id: id,
      isDeleted: true,
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
    };
    await dispatch(ordersUnshowByAction(deleteOrderPayload));
    setOrdersFromSelector(ordersFromSelector.filter(anOrder => anOrder._id !== id));
  };

  const handleFilterChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = ordersFromSelector.filter(
      (anOrder) =>
        anOrder.order_name.toLowerCase().includes(searchQuery) ||
        anOrder.order_no.toString().toLowerCase().includes(searchQuery) ||
        anOrder?.belongs_to?.firstname.toLowerCase().includes(searchQuery) ||
        anOrder?.belongs_to?.lastname.toLowerCase().includes(searchQuery) ||
        anOrder?.billing_address.toLowerCase().includes(searchQuery) ||
        anOrder?.status.toLowerCase().includes(searchQuery)
    );
    setFilteredOrders(filteredResults);
  };

  return (
    <Layout>
      <div style={{ marginTop: '80px', marginLeft: '30px' }} className="all-odrs">
        <h2>Order Management</h2>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button className="btn-bg"
              variant="contained"
              color="primary"
              component={Link}
              to="/AddNewOrder"
            >
              Add Order
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  label="Search"
                  className={classes.searchInput}
                  size="small"
                  value={searchTerm}
                  onChange={handleFilterChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Date</InputLabel>
              <Select label="Filter by Date" defaultValue="">
                <MenuItem value="">All Dates</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this-week">This Week</MenuItem>
                <MenuItem value="this-month">This Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Registered Customer</InputLabel>
              <Select label="Filter by Registered Customer" defaultValue="">
                <MenuItem value="">All Customers</MenuItem>
                <MenuItem value="registered">Registered Customers</MenuItem>
                <MenuItem value="non-registered">
                  Non-Registered Customers
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography variant="h6" gutterBottom>
          <strong>All Orders </strong>
        </Typography>

        <DataGrid
          rows={filteredOrders}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
          checkboxSelection
        />
      </div>
    </Layout>
  );
}

export default AllOrders;
