import React from 'react';
//import Divider from '@mui/material/Divider';
import '../css/Blogdetailpage1.css';
import Box from '@mui/material/Box';
//import { Link } from 'react-router-dom';
import { Typography, Grid } from "@mui/material";

export default function Blogdetailpage2() {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Breathing out Pollution with Ayurveda </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <div>
        <img src="./images/breathingout.png" style={{ width: '100%' }} alt="Breathing out Pollution with Ayurveda" />
      </div>

      <Grid container spacing={2}>
      <Grid item xs={12}>

      <Box>
      <br />
      <Typography variant="h6" gutterBottom>
      <strong>Nasal Cleansing </strong>
      </Typography>

      <Typography variant="body1">
      Neti and nasya are the Ayurvedic practices of cleansing the nasal passages. Neti is rinsing out the nostrils with salt water. Nasya is administering medicine in the nostrils, like oil, powders, etc to heal allergies, improve breathing, relieve headaches, and even improve quality of voice.
      </Typography>

       <br />
       <Typography variant="body1">
       Another Ayurvedic practice you could add to your nightly or morning routine is nasal
       cleansing. This is split into two practices: neti and nasya. Neti is the process of
       cleansing the nasal passages with salt water using a neti pot; nasya is the practice of lubricating the nasal cavities with oil. The two practices go hand in hand because one disinfects and then the other moisturizes and
       prevents the mucus from reforming.
      </Typography>

      <br />
      <Typography variant="h6" gutterBottom>
      <strong>Why Cleanse Your Nostrils? </strong>
      </Typography>

      <Typography variant="body1">
       You are constantly breathing in toxins, from pollution in the air to the
       fragrances in perfumes, air fresheners, and candles. As a result, your
       nasal passages become filled with toxicity and benefit from being cleaned out from time to time. All airborne illnesses begin in the nose, and when you clean your nostrils, you can prevent and heal allergies, colds, and flus. Ayurveda states that nasal cleansing also is important because the nose is the direct route to the brain and the doorway to your consciousness. By cleansing your nasal passages, you improve your breathing; cure headaches; release tension; and overcome sinus infections,
       colds, flus, and allergies.
       </Typography>

       <br />
      <Typography variant="h6" gutterBottom>
      <strong>Nasya </strong>
      </Typography>
      
      <Typography variant="body1">
       Nasya is the practice of lubricating the nasal passages with oil, and it’s best performed after neti cleansing. The salt water from neti can dry out your nasal passages, which stimulates your body to secrete more mucus to protect the membranes. Nasya lubricates those membranes with oil so your body doesn’t create more mucus. It’s a wonderful preventative measure to overcome a stuffy nose and is the reason why saline solution alone is not enough; you need both salt water and oil together. Nasya is said to improve the quality of your voice, improve your vision, promote mental clarity, release tension headaches, heal sinus congestion, and release stress.
      </Typography>
      <br />
      <Typography variant="body1">
      Nasya oil is a specific type of medicated oil typically comprised of sesame oil and medicinal herbs. You can find nasya oil on many Ayurvedic websites. If you can’t find it, you can use sesame oil. There are two ways you can practice nasya. In the first way, which is the most effective, you lie down on your back and administer the oil in your nose. You’ll eventually get used to it, but at first it might feel weird to have oil go up your nose.
      </Typography>

       {/* <Link to="/Blogdetailpage1" className="link2nn">{'<< before'}</Link> */}

        {/* <div className="box-bg-1">
          <textarea
            className="bd-form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="My Comment"
          ></textarea>
        </div>

        <div className="blg-detail-row">
          <input type="text" className="form-control" placeholder="Name" />
          <input type="text" className="form-control" placeholder="Email" />
        </div>
        <button type="" className="post-btn">
          POST COMMENT
        </button> */}
      </Box>
      </Grid>
      </Grid>
    </div>
    </>
  );
}