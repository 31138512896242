import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../actionTypes/orderActionTypes';
import orderApi from '../../apis/orderApi';

function* orders(action) {
  try {
    const ordersResponse = yield call(orderApi.getAll, action);
    yield put({ type: type.ALL_ORDER_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ALL_ORDER_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersLoggedInUserOrders(action) {
  try {
    const ordersResponse = yield call(orderApi.loggedInUserOrder, action);
    yield put({ type: type.LOGGED_IN_USER_ORDERS_ID_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.LOGGED_IN_USER_ORDERS_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersCreate(action) {
  try {
    const ordersResponse = yield call(orderApi.create, action);
    yield put({ type: type.ORDER_CREATE_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_CREATE_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersGetBy(action) {
  try {
    const ordersResponse = yield call(orderApi.getBy, action);
    yield put({ type: type.ORDER_GET_BY_ID_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_GET_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersUpdateBy(action) {
  try {
    const ordersResponse = yield call(orderApi.updateBy, action);
    yield put({ type: type.ORDER_UPDATE_BY_ID_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_UPDATE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersUnshowBy(action) {
  try {
    const ordersResponse = yield call(orderApi.unshowBy, action);
    yield put({ type: type.ORDER_UNSHOW_BY_ID_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_UNSHOW_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}
function* ordersDeleteBy(action) {
  try {
    const ordersResponse = yield call(orderApi.deleteBy, action);
    yield put({ type: type.ORDER_DELETE_BY_ID_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_DELETE_BY_ID_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* ordersWithSales(action) {
  try {
    const ordersResponse = yield call(orderApi.getOrdersWithSales, action);
    yield put({ type: type.ORDER_WITH_SALES_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_WITH_SALES_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* totalSalesComparedToPreviousMonth(action) {
  try {
    const ordersResponse = yield call(orderApi.totalSalesComparedToPreviousMonth, action);
    yield put({ type: type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* netSalesComparedToPreviousMonth(action) {
  try {
    const ordersResponse = yield call(orderApi.netSalesComparedToPreviousMonth, action);
    yield put({ type: type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* currentMonthOrdersVsPreviousMonth(action) {
  try {
    const ordersResponse = yield call(orderApi.currentMonthOrdersVsPreviousMonth, action);
    yield put({ type: type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* salesChartApiForPastYear(action) {
  try {
    const ordersResponse = yield call(orderApi.salesChartApiForPastYear, action);
    yield put({ type: type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* noOfOrdersChartApiForPastYear(action) {
  try {
    const ordersResponse = yield call(orderApi.noOfOrdersChartApiForPastYear, action);
    yield put({ type: type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_SUCCESS, ordersResponse: ordersResponse.data });
  } catch (e) {
    yield put({
      type: type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_FAILURE,
      Error: {
        msg: 'Error occurred', status: e,
      },
    });
  }
}

function* myOrderSaga() {
  yield takeEvery(type.ALL_ORDER_REQUESTING, orders);
  yield takeEvery(type.LOGGED_IN_USER_ORDERS_ID_REQUESTING, ordersLoggedInUserOrders);
  yield takeEvery(type.ORDER_CREATE_REQUESTING, ordersCreate);
  yield takeEvery(type.ORDER_GET_BY_ID_REQUESTING, ordersGetBy);
  yield takeEvery(type.ORDER_UPDATE_BY_ID_REQUESTING, ordersUpdateBy);
  yield takeEvery(type.ORDER_UNSHOW_BY_ID_REQUESTING, ordersUnshowBy);
  yield takeEvery(type.ORDER_DELETE_BY_ID_REQUESTING, ordersDeleteBy);
  yield takeEvery(type.ORDER_WITH_SALES_REQUESTING, ordersWithSales);
  yield takeEvery(type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING, totalSalesComparedToPreviousMonth);
  yield takeEvery(type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING, netSalesComparedToPreviousMonth);
  yield takeEvery(type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_REQUESTING, currentMonthOrdersVsPreviousMonth);
  yield takeEvery(type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_REQUESTING, salesChartApiForPastYear);
  yield takeEvery(type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_REQUESTING, noOfOrdersChartApiForPastYear);
}

export default myOrderSaga;
