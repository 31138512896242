import React from 'react';
import { Typography, Grid } from '@mui/material';

const Anxiety = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Anxiety </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Anxiety? </strong>
        </Typography>
            <Typography variant="body1">
              Anxiety is a mental health condition characterized by excessive
              worry, fear, and apprehension. It manifests in various physical,
              cognitive, and emotional symptoms. Common symptoms include
              restlessness, irritability, trouble concentrating, muscle tension,
              sleep disturbances, and a sense of impending doom. Anxiety can be
              caused by a combination of genetic, environmental, and
              psychological factors. Stressful life events, trauma, family
              history of anxiety, and certain medical conditions can contribute
              to its development. Imbalances in brain chemistry, specifically
              involving neurotransmitters like serotonin and dopamine, are also
              thought to play a role.
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr /> 
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Anxiety </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Anxiety include:
              <ul className='vw-ul'>
                <li>Increased heart rate.</li>
                <li>Rapid breathing.</li>
                <li>Feeling weak or tired.</li>
                <li>Restlessness.</li>
                <li>Trouble concentrating.</li>
                <li>Having trouble sleeping</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr /> 
        <Typography variant="h6" gutterBottom>
        <strong> Causes of Anxiety </strong>
        </Typography>
            <Typography variant="body1">
              Anxiety can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Any kind of trauma </li>
                <li>Some medical conditions like heart disease, diabetes, drug withdrawal etc. </li>
                <li>Anxiety disorders tend to run in families. You may inherit them from one or both parents, like eye color. </li>
                <li>Substance use</li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr /> 
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Anxiety </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Anxiety include:
              <ul className='vw-ul'>
                <li>Mental health issues like depression.</li>
                <li>Shyness in social situations, Oversensitivity to criticism,
                  Fixation on details. Moral rigidness are some kind of
                  personality which are more likely to have anxiety.</li>
                <li>Thyroid problems and other health conditions can make you prone to anxiety </li>
              </ul>
            </Typography>
       
        </Grid>
        <Grid item xs={12}>
        <hr /> 
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Anxiety cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>
                  <b>Complemental health techniques.</b> Mindfulness, yoga,
                  and self-management strategies such as stress management are
                  ways to treat your anxiety using alternative methods.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1">
            Book an appointment to learn how you can harness the power of Ayurveda for Stress from our experts.
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Anxiety;
