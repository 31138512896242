import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const orderApi = {
  getAll: (params) => {
    const uri = HOST_ADDRESS + envVars.ALL_ORDER;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  loggedInUserOrder: (params) => {
    const uri = HOST_ADDRESS + envVars.LOGGED_IN_USER_ORDER_ID;
    return axios.post(`${uri}?userId=${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_CREATE;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  getBy: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_GET_BY_ID;
    return axios.post(`${uri}${params?.payload?.id}`, params?.payload).then((response) => {
      return response;
    });
  },
  updateBy: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_UPDATE_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_UNSHOW_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_DELETE_BY_ID;
    return axios.delete(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  getOrdersWithSales: () => {
    const uri = HOST_ADDRESS + envVars.ORDER_WITH_SALES;
    return axios.get(uri).then((response) => {
      return response;
    });
  },
  totalSalesComparedToPreviousMonth: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  netSalesComparedToPreviousMonth: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  currentMonthOrdersVsPreviousMonth: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  salesChartApiForPastYear: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_SALES_CHART_API_FOR_PAST_YEAR;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  noOfOrdersChartApiForPastYear: (params) => {
    const uri = HOST_ADDRESS + envVars.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
};

export default orderApi;
