import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
export default function ProductDetailsCard({ pTitle, pDesc, pPrice, pid, imageUrl }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ minWidth: 275, boxShadow: 'none' }}>
      <CardContent>
        <Typography variant="h3" component="div" sx={{ textAlign: 'left', mb: 1.5 }}>
          {pTitle}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'left', mb: 1.5 }}>
          {pDesc}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
          <CurrencyRupeeIcon /> {pPrice}/-
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" sx={{ backgroundColor: '#0B8140', color: 'white', padding: '10px' }} size="small" onClick={() => navigate('/cart')}>ADD TO CART</Button>
      </CardActions>
    </Card>
  );
}

ProductDetailsCard.propTypes = {
  pTitle: PropTypes.string.isRequired,
  pDesc: PropTypes.string.isRequired,
  pPrice: PropTypes.number.isRequired,
  pid: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
