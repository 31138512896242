import * as type from '../actionTypes/mediaActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function mediaReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_MEDIA_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_MEDIA_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_MEDIA_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_MEDIA_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.LOGGED_IN_USER_MEDIA_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_MEDIA_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.MEDIA_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.MEDIA_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.MEDIA_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.MEDIA_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.MEDIA_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.MEDIA_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.MEDIA_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.MEDIA_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.MEDIA_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.MEDIA_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.MEDIA_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.MEDIA_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

      case type.MEDIA_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.MEDIA_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.MEDIA_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.mediaResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    default:
      return newState;
  }
}