/* eslint-disable no-undef */
const envVars = {
  PORT: process.env.PORT,
  HOST_ADDRESS: process.env.REACT_APP_PROJECT_ENVIRONMENT === 'development'
    ? process.env.REACT_APP_API_BASE_ADDRESS_DEV
    : process.env.REACT_APP_PROJECT_ENVIRONMENT === 'staging' ? process.env.REACT_APP_API_BASE_ADDRESS_STAGING : process.env.REACT_APP_API_BASE_ADDRESS_PROD,
  API_BASE_ADDRESS_DEV: process.env.REACT_APP_API_BASE_ADDRESS_DEV,
  API_BASE_ADDRESS_STAGING: process.env.REACT_APP_API_BASE_ADDRESS_STAGING,
  API_BASE_ADDRESS_PROD: process.env.REACT_APP_API_BASE_ADDRESS_PROD,
  PROJECT_ENVIRONMENT: process.env.REACT_APP_PROJECT_ENVIRONMENT,

  REDUCER_REQUEST: process.env.REACT_APP_REDUCER_STATUS_REQUEST,
  REDUCER_SUCCEEDED: process.env.REACT_APP_REDUCER_STATUS_SUCCEEDED,
  REDUCER_FAILURE: process.env.REACT_APP_REDUCER_STATUS_FAILURE,

  ALL_BLOGS: process.env.REACT_APP_ALL_BLOGS,
  LOGGED_IN_USER_BLOGS_ID: process.env.REACT_APP_LOGGED_IN_USER_BLOGS_ID,
  BLOGS_CREATE: process.env.REACT_APP_BLOGS_CREATE,
  BLOGS_GET_BY_ID: process.env.REACT_APP_BLOGS_GET_BY_ID,
  BLOGS_UPDATE_BY_ID: process.env.REACT_APP_BLOGS_UPDATE_BY_ID,
  BLOGS_UNSHOW_BY_ID: process.env.REACT_APP_BLOGS_UNSHOW_BY_ID,
  BLOGS_DELETE_BY_ID: process.env.REACT_APP_BLOGS_DELETE_BY_ID,

  CART_CREATE_OR_UPDATE_CART: process.env.REACT_APP_CART_CREATE_OR_UPDATE_CART,
  CART_GET_CART: process.env.REACT_APP_CART_GET_CART,
  CLEAR_CART_BY_ID: process.env.REACT_APP_CLEAR_CART_BY_ID,
  DELETE_ITEM_FROM_CART_BY: process.env.REACT_APP_DELETE_ITEM_FROM_CART_BY,
  UPDATE_CART_ITEM_QUANTITY: process.env.REACT_APP_UPDATE_CART_ITEM_QUANTITY,

  ALL_CATEGORIES: process.env.REACT_APP_ALL_CATEGORIES,
  LOGGED_IN_USER_CATEGORIES_ID: process.env.REACT_APP_LOGGED_IN_USER_CATEGORIES_ID,
  CATEGORIES_CREATE: process.env.REACT_APP_CATEGORIES_CREATE,
  CATEGORIES_GET_BY_ID: process.env.REACT_APP_CATEGORIES_GET_BY_ID,
  CATEGORIES_UPDATE_BY_ID: process.env.REACT_APP_CATEGORIES_UPDATE_BY_ID,
  CATEGORIES_UNSHOW_BY_ID: process.env.REACT_APP_CATEGORIES_UNSHOW_BY_ID,
  CATEGORIES_DELETE_BY_ID: process.env.REACT_APP_CATEGORIES_DELETE_BY_ID,
  FIND_MOST_SOLD_CATEGORY: process.env.REACT_APP_MOST_SOLD_CATEGORY,
  GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS: process.env.REACT_APP_GET_ALL_CATEGORIES_WITH_THEIR_ASSOCIATED_PRODUCTS,
  FIND_TOP_CATEGORIES_SOLD: process.env.REACT_APP_FIND_TOP_CATEGORIES_SOLD,

  ALL_COUPONS: process.env.REACT_APP_ALL_COUPONS,
  LOGGED_IN_USER_COUPONS_ID: process.env.REACT_APP_LOGGED_IN_USER_COUPONS_ID,
  COUPONS_CREATE: process.env.REACT_APP_COUPONS_CREATE,
  COUPONS_UPDATE_BY_ID: process.env.REACT_APP_COUPONS_UPDATE_BY_ID,
  COUPONS_GET_BY_ID: process.env.REACT_APP_COUPONS_GET_BY_ID,
  COUPONS_UNSHOW_BY_ID: process.env.REACT_APP_COUPONS_UNSHOW_BY_ID,
  COUPONS_DELETE_BY_ID: process.env.REACT_APP_COUPONS_DELETE_BY_ID,

  ALL_CUSTOMER: process.env.REACT_APP_ALL_CUSTOMER,
  LOGGED_IN_USER_CUSTOMERS_ID: process.env.REACT_APP_LOGGED_IN_USER_CUSTOMERS_ID,
  CUSTOMER_CREATE: process.env.REACT_APP_CUSTOMER_CREATE,
  CUSTOMER_GET_BY_ID: process.env.REACT_APP_CUSTOMER_GET_BY_ID,
  CUSTOMER_UPDATE_BY_ID: process.env.REACT_APP_CUSTOMER_UPDATE_BY_ID,
  CUSTOMER_UNSHOW_BY_ID: process.env.REACT_APP_CUSTOMER_UNSHOW_BY_ID,
  CUSTOMER_DELETE_BY_ID: process.env.REACT_APP_CUSTOMER_DELETE_BY_ID,

  ALL_FEEDBACK: process.env.REACT_APP_ALL_FEEDBACK,
  LOGGED_IN_USER_FEEDBACKS_ID: process.env.REACT_APP_LOGGED_IN_USER_FEEDBACKS_ID,
  FEEDBACK_CREATE: process.env.REACT_APP_FEEDBACK_CREATE,
  FEEDBACK_GET_BY_ID: process.env.REACT_APP_FEEDBACK_GET_BY_ID,
  FEEDBACK_UPDATE_BY_ID: process.env.REACT_APP_FEEDBACK_UPDATE_BY_ID,
  FEEDBACK_UNSHOW_BY_ID: process.env.REACT_APP_FEEDBACK_UNSHOW_BY_ID,
  FEEDBACK_DELETE_BY_ID: process.env.REACT_APP_FEEDBACK_DELETE_BY_ID,

  ALL_MEDIA: process.env.REACT_APP_ALL_MEDIA,
  LOGGED_IN_USER_MEDIA_ID: process.env.REACT_APP_LOGGED_IN_USER_MEDIA_ID,
  MEDIA_CREATE: process.env.REACT_APP_MEDIA_CREATE,
  MEDIA_GET_BY_ID: process.env.REACT_APP_MEDIA_GET_BY_ID,
  MEDIA_UPDATE_BY_ID: process.env.REACT_APP_MEDIA_UPDATE_BY_ID,
  MEDIA_UNSHOW_BY_ID: process.env.REACT_APP_MEDIA_UNSHOW_BY_ID,
  MEDIA_DELETE_BY_ID: process.env.REACT_APP_MEDIA_DELETE_BY_ID,

  ALL_NOTIFICATIONS: process.env.REACT_APP_ALL_NOTIFICATIONS,
  NOTIFICATIONS_CREATE: process.env.REACT_APP_NOTIFICATIONS_CREATE,
  NOTIFICATIONS_UNSHOW_BY_ID: process.env.REACT_APP_NOTIFICATIONS_UNSHOW_BY_ID,
  NOTIFICATIONS_DELETE_BY_ID: process.env.REACT_APP_NOTIFICATIONS_DELETE_BY_ID,

  ALL_ORDER: process.env.REACT_APP_ALL_ORDER,
  ORDER_CREATE: process.env.REACT_APP_ORDER_CREATE,
  LOGGED_IN_USER_ORDER_ID: process.env.REACT_APP_LOGGED_IN_USER_ORDER_ID,
  ORDER_GET_BY_ID: process.env.REACT_APP_ORDER_GET_BY_ID,
  ORDER_UPDATE_BY_ID: process.env.REACT_APP_ORDER_UPDATE_BY_ID,
  ORDER_UNSHOW_BY_ID: process.env.REACT_APP_ORDER_UNSHOW_BY_ID,
  ORDER_DELETE_BY_ID: process.env.REACT_APP_ORDER_DELETE_BY_ID,
  ORDER_WITH_SALES: process.env.REACT_APP_ORDER_WITH_SALES,
  ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH: process.env.REACT_APP_ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH,
  ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH: process.env.REACT_APP_ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH,
  ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH: process.env.REACT_APP_ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH,
  ORDER_SALES_CHART_API_FOR_PAST_YEAR: process.env.REACT_APP_ORDER_SALES_CHART_API_FOR_PAST_YEAR,
  ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR: process.env.REACT_APP_ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR,

  ALL_PAGES: process.env.REACT_APP_ALL_PAGES,
  LOGGED_IN_USER_PAGES_ID: process.env.REACT_APP_LOGGED_IN_USER_PAGES_ID,
  PAGES_CREATE: process.env.REACT_APP_PAGES_CREATE,
  PAGES_GET_BY_ID: process.env.REACT_APP_PAGES_GET_BY_ID,
  PAGES_UPDATE_BY_ID: process.env.REACT_APP_PAGES_UPDATE_BY_ID,
  PAGES_UNSHOW_BY_ID: process.env.REACT_APP_PAGES_UNSHOW_BY_ID,
  PAGES_DELETE_BY_ID: process.env.REACT_APP_PAGES_DELETE_BY_ID,

  ALL_PRODUCTS: process.env.REACT_APP_ALL_PRODUCTS,
  LOGGED_IN_USER_PRODUCTS_ID: process.env.REACT_APP_LOGGED_IN_USER_PRODUCTS_ID,
  PRODUCTS_CREATE: process.env.REACT_APP_PRODUCTS_CREATE,
  PRODUCTS_GET_BY_ID: process.env.REACT_APP_PRODUCTS_GET_BY_ID,
  PRODUCTS_UPDATE_BY_ID: process.env.REACT_APP_PRODUCTS_UPDATE_BY_ID,
  PRODUCTS_UNSHOW_BY_ID: process.env.REACT_APP_PRODUCTS_UNSHOW_BY_ID,
  PRODUCTS_DELETE_BY_ID: process.env.REACT_APP_PRODUCTS_DELETE_BY_ID,
  PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH: process.env.REACT_APP_PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH,

  ALL_TAGS: process.env.REACT_APP_ALL_TAGS,
  LOGGED_IN_USER_TAGS_ID: process.env.REACT_APP_LOGGED_IN_USER_TAGS_ID,
  TAGS_CREATE: process.env.REACT_APP_TAGS_CREATE,
  TAGS_GET_BY_ID: process.env.REACT_APP_TAGS_GET_BY_ID,
  TAGS_UPDATE_BY_ID: process.env.REACT_APP_TAGS_UPDATE_BY_ID,
  TAGS_UNSHOW_BY_ID: process.env.REACT_APP_TAGS_UNSHOW_BY_ID,
  TAGS_DELETE_BY_ID: process.env.REACT_APP_TAGS_DELETE_BY_ID,

  ALL_USERS: process.env.REACT_APP_ALL_USERS,
  USERS_CREATE: process.env.REACT_APP_USERS_CREATE,
  USERS_GET_BY_ID: process.env.REACT_APP_USERS_GET_BY_ID,
  USERS_UPDATE_BY_ID: process.env.REACT_APP_USERS_UPDATE_BY_ID,

  USERS_UNSHOW_BY_ID: process.env.REACT_APP_USERS_UNSHOW_BY_ID,
  USERS_DELETE_USER_BY_ID: process.env.REACT_APP_USERS_DELETE_USER_BY_ID,
  USERS_LOGIN: process.env.REACT_APP_USERS_LOGIN,
  USERS_FORGET_PASSWORD: process.env.REACT_APP_USERS_FORGET_PASSWORD,
  USERS_RESET_PASSWORD: process.env.REACT_APP_USERS_RESET_PASSWORD
  
};

export default envVars;