/* eslint-disable no-undef */
import * as type from '../actionTypes/userActionTypes';
export const loginRequest = function loginRequest(payload) {
  return {
    type: type.USERS_LOGIN_REQUESTING,
    payload,
  };
};
export const registerRequest = function registerRequest(payload) {
  console.log("hello from register");
  return {
    type: type.USERS_CREATE_REQUESTING,
    payload,
  };
};

export const hideModal = (payload) => {
  return {
    type: type.HIDE_MODAL,
    payload,
  };
};

// new APIs' actions integration below

export const usersAllAction = (payload) => {
  return {
    type: type.ALL_USERS_REQUESTING,
    payload,
  };
};

export const usersGetByAction = (payload) => {
  return {
    type: type.USERS_GET_BY_ID_REQUESTING,
    payload,
  };
};

export const usersUpdateByAction = (payload) => {
  return {
    type: type.USERS_UPDATE_BY_ID_REQUESTING,
    payload,
  };
};

export const usersUserByAction = (payload) => {
  return {
    type: type.USERS_DELETE_USER_BY_ID_REQUESTING,
    payload,
  };
};
