/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const MaleInfertility = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Male Infertility </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is  Male Infertility ? </strong>
        </Typography>
        <Typography variant="body1">
         Male infertility refers to the inability of a man to achieve pregnancy in a fertile female partner. It is often
diagnosed after a year of regular, unprotected intercourse without conception. Symptoms of male
infertility are usually not evident, but can include hormonal imbalances, sexual dysfunction, and
abnormalities in semen analysis. Causes of male infertility can stem from various factors such as low
sperm count, poor sperm motility, abnormal sperm shape, hormonal disorders, genetic conditions,
testicular damage, certain medications, and lifestyle factors like excessive alcohol consumption or
smoking. </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of  Male Infertility </strong>
        </Typography>
            <Typography variant="body1">
              The main sign of male infertility is the inability to conceive a child. There may be no other obvious signs or symptoms.
              In some cases, however, an underlying problem such as an inherited disorder, hormonal imbalance, dilated veins around the testicle or a condition that blocks the passage of sperm causes signs and symptoms. Signs and symptoms you may notice include:
              <ul className='vw-ul'>
                <li>Problems with sexual function - for example, difficulty with ejaculation or small volumes of fluid ejaculated, reduced sexual desire, or difficulty maintaining an erection (erectile dysfunction) </li>
                <li>Pain, swelling or a lump in the testicle area</li>
                <li>Recurrent respiratory infections</li>
                <li>Inability to smell</li>
                <li>Decreased facial or body hair or other signs of a chromosomal or hormonal abnormality</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Male Infertility </strong>
        </Typography>
            <Typography variant="body1">
               Male Infertility can be caused by various factors, including:
               <ul className='vw-ul'>
                <li>Sperm Disorders</li>
                <li>Varicoceles </li>
                <li>Retrograde Ejaculation</li>
                <li>Immunonologic Infertility </li>
                <li>Obstruction</li>
                <li>Hormones </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Male Infertility </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Male Infertility include:
              <ul className='vw-ul'>
                <li>History of prostatitis, genital infection, or sexually transmitted infections </li>
                <li>Regular contact with radiation, radioactivity, or welding</li>
                <li>Regular contact with toxins such as lead </li>
                <li>Regular contact with ethylene dibromide or vinyl chloride</li>
                <li>Cigarette or marijuana smoke</li>
                <li>Heavy alcohol drinking</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While  Male Infertility cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Don't smoke.</li>
                <li>Limit or abstain from alcohol.</li>
                <li>Maintain a healthy weight.</li>
                <li>Avoid things that lead to prolonged heat for the testicles.</li>
                <li>Reduce stress. </li>
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default MaleInfertility;
