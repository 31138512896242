import * as type from '../actionTypes/couponActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function couponReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_COUPONS_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_COUPONS_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_COUPONS_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_COUPONS_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.LOGGED_IN_USER_COUPONS_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_COUPONS_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.COUPONS_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.COUPONS_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.COUPONS_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.COUPONS_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.COUPONS_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.COUPONS_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.COUPONS_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.COUPONS_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.COUPONS_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.COUPONS_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.COUPONS_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.COUPONS_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.COUPONS_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.COUPONS_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.COUPONS_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.couponsResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    default:
      return newState;
  }
}