import React from 'react';
import { Typography, Grid } from '@mui/material';

const Lepanam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Lepanam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
           <strong> What is Lepanam?</strong>
          </Typography>
          <Typography variant="body1">
          <p> This is a process in which medicated herbal paste is applied on the affected part or whole body based on patients conditions. This is useful for various types of inflammatory conditions, arthritis, gout, skin diseases and eczema. continuous localized applications reduce inflammation, increase blood circulation, and relieve the person from inflammatory pains. Lepam paste bear high anti-inflammatory and analgesic properties and the liquids used are good for tissue nourishment.</p>
          <p>Lepanam is an Ayurvedic therapy recommended to the people suffering from the pain associated with the inflammatory conditions. In this treatment, medicines along with some herbal liquids are mixed in the form of paste (known as lepam), which is then applied to the affected parts of the body. The lepam acts as a body scrub used to smooth your skin, rejuvenate your blood circulation, and relive aches. It activates the natural purifying and healing abilities of the body.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Lepanam</strong>
          </Typography>
          <Typography variant="body1">
          Lepam has a wide range of benefits and purposes from cosmetic purposes to severe skin conditions:<br />
          <ul className='vw-ul'>
          <li>Effective in treating psoriasis, pimples, pigmentation, marks on the skin, arthritis with pain and swelling, skin infections, and injuries. </li>
          <li>Cleanses the skin and makes it smooth.</li>
          <li>Improves blood circulation and nourishes the skin.</li>
          <li>Helps to balance the doshas</li>
          <li>Reduces inflammation</li>
          <li>Nourishes the tissues </li>
          </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Lepanam - A Proven Method For Treating Skin Diseases</strong>
          </Typography>
          <Typography variant="body1">
          Lepanam is an Ayurvedic therapy recommended to the people suffering from the pain associated with the inflammatory conditions. In this treatment, medicines along with some herbal liquids are mixed in the form of paste (known as lepam), which is then applied to the affected parts of the body. The lepam acts as a body scrub used to smooth your skin, rejuvenate your blood circulation, and relive aches. It activates the natural purifying and healing abilities of the body.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong> Different Lepams Used In This Therapy </strong>
          </Typography>
          <Typography variant="body1">
            <p>We offer Lepanam treatment to cure varied kinds of inflammatory problems, skin diseases, Psoriasis, Eczema and others. Following are the lepams used in this treatment which help in drawing out toxins and relieving pain</p>
            <p>The process of Abhyanga therapy is done as follows:-</p>
            <ul className='vw-ul'>
            <li><b>NARIKELA</b><br />
            Coconut with a mix of other ingredient is applied in the form of paste, giving a cooling impact to the whole body.</li>
            <li><b>SURYAMUKHI KAPHI</b><br />
            Here, sunflower seeds are grounded to the paste form and then applied to remove dead skin cells, thus calming your mind and body.</li>
            </ul>
          </Typography>
        </Grid>
      
      </Grid>
    </div>
    </>
  );
};

export default Lepanam;
