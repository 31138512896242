import React from 'react';
import { Typography, Grid } from '@mui/material';

const KneeFractures = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Knee Fractures  </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is  Knee Fractures? </strong>
        </Typography>
            <Typography variant="body1">
            Knee fractures and injuries refer to damage or fractures that occur in the bones, ligaments, tendons,
or cartilage of the knee joint. They can be caused by various factors, including traumatic events
such as falls, sports injuries, or car accidents. Common knee injuries include fractures of the patella
(kneecap), femur (thighbone), or tibia (shinbone), as well as ligament tears (such as anterior
cruciate ligament or ACL tears) and meniscus tears. Symptoms of knee fractures and injuries
include severe pain, swelling, limited range of motion, instability, and difficulty bearing weight on the
affected leg.
</Typography>

        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Knee Fractures </strong>
        </Typography>
        <Typography variant="body1">
        Some common symptoms of  Knee Fractures include:
        <ul className='vw-ul'>
        <li>Pain,Swelling,Bruising.</li>
        <li>Palpable patellar defect (a change in your kneecap that you can feel through your skin).</li>
        <li>Inability to straighten your leg.</li>
        <li>Inability to raise your extended leg.</li>
        <li>Inability to walk.</li>
        </ul>
        </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Knee Fractures</strong>
        </Typography>
            <Typography variant="body1">
               Knee Fractures can be caused by various factors, including:
               <ul className='vw-ul'>
                <li>Falling directly on the knee, especially on a hard surface such as concrete</li>
                <li>Sports where the knee may be directly hit by a ball, bat or stick </li>
                <li>Car accidents where the knee hits the dashboard</li>
                <li>Sudden contractions (pulling) of the quadriceps muscle that causes the tendon to pull on the kneecap and break it apart</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Knee Fractures</strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing  Knee Fractures include:
              <ul className='vw-ul'>
                <li>Patients with osteoarthritis are at a higher risk for knee fractures.</li>
                <li>As the bones age, they become more brittle and are more susceptible to breaking.</li>
                <li>Participating in high-impact sports such as football, basketball and downhill skiing.</li>
                <li>Females are more likely to suffer from a knee fracture. </li>
                <li>Smoking and drinking alcohol impact hormones and bone structure, increasing risk of knee fractures. </li>
                <li>A previous injury or knee fracture doubles the risk of suffering another injury.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While  Knee Fractures cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Maintain a Healthy Weight.</li>
                <li>Warm up Before Exercising or Playing Sports.</li>
                <li>Strengthen Your Knees. </li>
                <li>Opt for Low-Impact Exercises if You Experience Knee Pain.</li>
                <li>Build up Your Workout Intensity. </li>
                <li>Wear Properly Fitting Shoes</li>
                <li>Consider Physical Therapy.</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default KneeFractures ;
