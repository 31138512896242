export const CART_CREATE_OR_UPDATE_CART_REQUESTING = 'CART_CREATE_OR_UPDATE_CART_REQUESTING';
export const CART_CREATE_OR_UPDATE_CART_SUCCESS = 'CART_CREATE_OR_UPDATE_CART_SUCCESS';
export const CART_CREATE_OR_UPDATE_CART_FAILURE = 'CART_CREATE_OR_UPDATE_CART_FAILURE';

export const CART_GET_CART_REQUESTING = 'CART_GET_CART_REQUESTING';
export const CART_GET_CART_SUCCESS = 'CART_GET_CART_SUCCESS';
export const CART_GET_CART_FAILURE = 'CART_GET_CART_FAILURE';

export const CLEAR_CART_BY_ID_REQUESTING = 'CLEAR_CART_BY_ID_REQUESTING';
export const CLEAR_CART_BY_ID_SUCCESS = 'CLEAR_CART_BY_ID_SUCCESS';
export const CLEAR_CART_BY_ID_FAILURE = 'CLEAR_CART_BY_ID_FAILURE';


export const DELETE_ITEM_FROM_CART_BY_REQUESTING = 'DELETE_ITEM_FROM_CART_BY_REQUESTING';
export const DELETE_ITEM_FROM_CART_BY_SUCCESS = 'DELETE_ITEM_FROM_CART_BY_SUCCESS';
export const DELETE_ITEM_FROM_CART_BY_FAILURE = 'DELETE_ITEM_FROM_CART_BY_FAILURE';

export const UPDATE_CART_ITEM_QUANTITY_REQUESTING = 'UPDATE_CART_ITEM_QUANTITY_REQUESTING';
export const UPDATE_CART_ITEM_QUANTITY_SUCCESS = 'UPDATE_CART_ITEM_QUANTITY_SUCCESS';
export const UPDATE_CART_ITEM_QUANTITY_FAILURE = 'UPDATE_CART_ITEM_QUANTITY_FAILURE';