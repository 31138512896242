import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

function createData(name, amount) {
  return { name, amount };
}

export default function CartTotal({ totalAmount }) {
  const history = useNavigate();
  const handleClick = () => {
    history('/checkout');
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 150);
  };

  const rows = [
    createData('Subtotal:', totalAmount),
    createData('Total:', totalAmount),

  ];

  
  return (
    <TableContainer component={Paper} sx={{ padding: '20px' }}>
      <Table sx={{ minWidth: 'auto', padding: '20px' }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{borderBottom: '1px solid #dddddd'}}>
            <TableCell variant="head" size="medium" sx={{fontWeight: 'bold'}}>Cart Totals</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>

              <TableCell align="right">
                {totalAmount ? `INR ${row.amount.toFixed(2)}` : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <hr />
      <Button 
        variant="contained" 
        size="medium" 
        sx={{backgroundColor: '#0B8140', color:'white', padding: '15px', width: '100%', borderRadius: '25px', fontWeight: 300}}
        onClick={handleClick}
      >PROCEED TO CHECKOUT</Button>
    </TableContainer>
  );
}

CartTotal.propTypes = {
  totalAmount: PropTypes.number.isRequired,
};