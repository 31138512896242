/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import { DeleteOutline } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { usersAllAction } from '../../../redux/actions/userActions';

import Layout from './Layout';

// Sample data for users and their orders
const usersData = [
  {
    id: 1,
    name: 'John Doe',
    username: 'johndoe',
    lastActive: '2023-05-15',
    dateRegistered: '2023-01-01',
    email: 'johndoe@example.com',
    numberOfOrders: 5,
    totalSpend: 500,
    country: 'United States',
    city: 'New York',
    region: 'NY',
    postalCode: '10001',
  },
  {
    id: 2,
    name: 'Jane Smith',
    username: 'janesmith',
    lastActive: '2023-05-12',
    dateRegistered: '2023-02-15',
    email: 'janesmith@example.com',
    numberOfOrders: 3,
    totalSpend: 300,
    country: 'Canada',
    city: 'Toronto',
    region: 'ON',
    postalCode: 'M5V 2S9',
  },
];

function UsersList() {
  const usersSelector = useSelector(state => state.users.data);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersFromSelector, setUsersFromSelector] = useState([]);

  const columns = [
    { field: 'Role', headerName: 'Role', width: 150 },
    { field: 'fullname', headerName: 'Name', width: 150 },
    { field: 'username', headerName: 'Username', width: 150 },
    {
      field: 'image', headerName: 'Profile picture', width: 120, sortable: false, renderCell: (params) => (
        typeof (params.row.image) === 'string' ?
          <p style={{ paddingTop: '14px' }}>
            <i>{params.row.image}
            </i>
          </p> :
          <img
            className="usersListImg"
            src={params.row.image}
            alt=""
          />
      )
    },
    { field: 'createdAt', headerName: 'Date Registered', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    // { field: 'phone_number', headerName: 'No. of orders', width: 120 },
    // { field: 'phone_number', headerName: 'Total Spend', width: 120 },
    // { field: 'phone_number', headerName: 'Country', width: 120 },
    // { field: 'phone_number', headerName: 'City', width: 120 },
    // { field: 'phone_number', headerName: 'Region', width: 120 },
    // { field: 'status', headerName: 'Postal Code', width: 100 },
    { field: 'phone', headerName: 'Contact No.', width: 120 },
    { field: 'address', headerName: 'Address', width: 100 },
    { field: 'lastLogin', headerName: 'Last logged In at', width: 100 },
    // { field: 'transaction', headerName: 'Transaction Volume', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <>
          HELLO THERE
          {/* <Link to={`/user/${params.row.id}`}>
            <button className="usersListEdit">Edit</button>
          </Link>
          <DeleteOutline
            className="usersListDelete"
            onClick={() => handleDelete(params.row.id)}
          /> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    // const userOrUserOrdersListPayload = {
    //   Role: secureLocalStorage.getItem('Role'),
    //   userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
    //   email: JSON.parse(secureLocalStorage.getItem('users')).email,
    // };

    async function fetchAllUsers() {
      await dispatch(usersAllAction()); // your action here without `()
    }
    fetchAllUsers();
  }, []);

  useEffect(() => {
    console.log('usersSelector', usersSelector);
    if (usersSelector && Array.isArray(usersSelector) && usersSelector.length !== 0) {
      // usersSelector.map((aUser) => ({
      //   ...aUser,
      //   id: aUser._id // Add id property based on _id
      // }));

      setUsersFromSelector(usersSelector.map((aUser) => ({
        ...aUser,
        id: aUser._id, // Add id property based on _id
        fullname: (aUser.fullname === undefined || aUser.fullname === null) ? `${aUser.firstname} ${aUser.lastname}` : aUser.fullname,
        image: aUser?.linkedMedia?.server_image_url || 'No profile picture',
        createdAt: aUser?.createdAt?.split('T')[0],
        lastLogin: aUser?.lastLogin?.split('T')[0],
      })));

      setFilteredUsers(usersSelector.map((aUser) => ({
        ...aUser,
        id: aUser._id, // Add id property based on _id
        fullname: (aUser.fullname === undefined || aUser.fullname === null) ? `${aUser.firstname} ${aUser.lastname}` : aUser.fullname,
        image: aUser?.linkedMedia?.server_image_url || 'No profile picture',
        createdAt: aUser?.createdAt?.split('T')[0],
        lastLogin: aUser?.lastLogin?.split('T')[0],
      })));
    }
  }, [usersSelector]);

  console.log('usersFromSelector', usersFromSelector);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredResults = usersFromSelector.filter(
      (user) =>
        user.fullname.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query) ||
        user.username.toLowerCase().includes(query)
    );
    setFilteredUsers(filteredResults);
  };

  const handleSearchClick = () => {
    const filteredResults = usersFromSelector.filter(
      (user) =>
        user.fullname.toLowerCase().includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery) ||
        user.username.toLowerCase().includes(searchQuery)
    );
    setFilteredUsers(filteredResults);
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6 style={{ marginBottom: '20px' }}> Users </h6>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginRight: '8px' }}
            />
            <Button variant="contained" className="btn-bg" style={{ padding: '15px 25px' }} onClick={handleSearchClick}>
              Search
            </Button>
          </div>
          <DataGrid
            rows={filteredUsers}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            checkboxSelection
          />
        </div>
      </div>
    </Layout>
  );
}

export default UsersList;
