import * as type from '../actionTypes/orderActionTypes';
import envVars from '../../utils/envVars';

const initialState = {
  loader: false,
  successful: false,
  messages: [],
  data: null,
};

export default function orderReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case type.ALL_ORDER_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.ALL_ORDER_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ALL_ORDER_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.LOGGED_IN_USER_ORDERS_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };
    case type.LOGGED_IN_USER_ORDERS_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.LOGGED_IN_USER_ORDERS_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_CREATE_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_CREATE_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_CREATE_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.ORDER_GET_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_GET_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_GET_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    case type.ORDER_UPDATE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_UPDATE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_UPDATE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_UNSHOW_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: false,

        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_UNSHOW_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };
    case type.ORDER_UNSHOW_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_DELETE_BY_ID_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_DELETE_BY_ID_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_DELETE_BY_ID_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_WITH_SALES_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_WITH_SALES_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_WITH_SALES_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_TOTAL_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_NET_SALES_COMPARED_TO_PREVIOUS_MONTH_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_CURRENT_MONTH_ORDERS_VS_PREVIOUS_MONTH_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_SALES_CHART_API_FOR_PAST_YEAR_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };

    case type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_REQUESTING:
      return newState = {
        ...state,
        loader: true,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_REQUEST,
      };

    case type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_SUCCESS:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_SUCCEEDED,
      };

    case type.ORDER_NO_OF_ORDERS_CHART_API_FOR_PAST_YEAR_FAILURE:
      return newState = {
        ...state,
        loader: false,
        data: action.ordersResponse,
        messages: [{ time: new Date() }],
        status: envVars.REDUCER_FAILURE,
      };
    default:
      return newState;
  }
}