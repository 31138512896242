import React, { useState } from 'react';
// import axios from 'axios';
import {
  makeStyles,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 1500,
        margin: 'auto',
        marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
    },
    heading: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    avatarContainer: {
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: 'auto',
      marginBottom: theme.spacing(1),
    },
    form: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
      '& .MuiButton-root': {
        margin: theme.spacing(2, 0),
      },
    },
    columnContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& > *': {
        flex: '0 0 calc(50% - 10px)',
        marginBottom: theme.spacing(2),
      },
    },
    uploadButton: {
      marginTop: theme.spacing(1),
      backgroundColor: '#0B8140', // Use the specified color
      color: theme.palette.secondary.contrastText, // Text color for contrast
      '&:hover': {
        backgroundColor: '#0B8140', // Darken the color on hover
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem', // Adjust font size for small screens
        padding: theme.spacing(1, 2), // Adjust padding for small screens
      },
    },
    fileInput: {
      display: 'none',
    },
  }));

// Inside the PatientForm component
const PatientsDetailsForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [treatmentName, setTreatmentName] = useState('');
  const [medicineName, setMedicineName] = useState('');

  const [profileData, setProfileData] = useState({
    avatar: null,
    date: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    gender: '',
    age: '',
    address: '',
    bloodPressure: '',
    weight: '',
    height: '',
    lifestyle: '',
    allergy: '',
    fever: '',
    smoking: '',
    drinking: '',
    pastDiseases: '',
    problemDescription: '',
    problemDuration: '',
    problemOtherDescription: '',
    treatmentOtherDescription: '',
    filledByName: '',
    filledByDesignation: '',
    filledByOtherDescription: '',
  });

  const handleProfileDataChange = (event, value, name) => {
    if (name === 'treatmentName') {
      setTreatmentName(value);
    } else if (name === 'medicineName') {
      setMedicineName(value);
    } else if (name === 'avatar') {
      const file = event.target.files[0];
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProfileData((prevData) => ({
          ...prevData,
          avatar: reader.result,
        }));
      };
    } else {
      const { name, value } = event.target;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // eslint-disable-next-line no-undef
      const response = await fetch('http://localhost:5000/api/addPatient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileData),
      });

      if (response.ok) {
        // Patient added successfully, you can redirect to another page or show a success message
        navigate('/PatientListPage'); // Redirect to patient list page
      } else {
        // Handle error
        // eslint-disable-next-line no-undef
        console.error('Failed to add patient');
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.error('Error:', error);
    }
  };
  

  return (
    <div className="add-pages-container">
      <div className="add-page-contents add-page-content r-txt">
        <h6
          style={{
            marginBottom: '10px',
            marginTop: '-30px',
            borderBottom: 'none',
            marginLeft: '650px',
            fontSize: '30px',
            fontFamily: 'sans-serif',
          }}
        >
          Patients Profile
        </h6>
        <Divider />
        <Paper className={classes.root}>
          <div className={classes.avatarContainer}>
            <Avatar
              alt="Profile Picture"
              src={profileData.avatar}
              className={classes.avatar}
            />
            <input
              type="file"
              accept="image/*"
              id="avatar-upload"
              className={classes.fileInput}
              onChange={(event) =>
                handleProfileDataChange(event, null, 'avatar')
              }
            />
            <label htmlFor="avatar-upload">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                component="span"
                className={classes.uploadButton}
              >
                Upload Profile Picture
              </Button>
            </label>
          </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography variant="h6" className={classes.heading}>
              User Details
            </Typography>
            <Grid container spacing={3} className={classes.columnContainer}>
              <Grid item xs={4}>
                <TextField
                  label="Date"
                  name="date"
                  value={profileData.date}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="First Name"
                  name="firstname"
                  value={profileData.firstname}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Last Name"
                  name="lastname"
                  value={profileData.lastname}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="phone Number"
                  name="phoneNumber"
                  value={profileData.phoneNumber}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="gender"
                  name="gender"
                  value={profileData.gender}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="age"
                  name="age"
                  value={profileData.age}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="address"
                  name="address"
                  value={profileData.address}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              {/* Repeat for Phone Number, Email, Gender, Age, and Address */}
            </Grid>

            <Typography variant="h6" className={classes.heading}>
              Initial Screening
            </Typography>

            <Grid container spacing={3} className={classes.columnContainer}>
              <Grid item xs={4}>
                <TextField
                  label="bloodPressure"
                  name="bloodPressure"
                  value={profileData.bloodPressure}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="weight"
                  name="weight"
                  value={profileData.weight}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="height"
                  name="height"
                  value={profileData.height}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="lifestyle"
                  name="lifestyle"
                  value={profileData.lifestyle}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="allergy"
                  name="allergy"
                  value={profileData.allergy}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="fever"
                  name="fever"
                  value={profileData.fever}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="smoking"
                  name="smoking"
                  value={profileData.smoking}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="drinking"
                  name="drinking"
                  value={profileData.drinking}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label=" Any Past Diseases"
                  name="pastDiseases"
                  value={profileData.pastDiseases}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography variant="h6" className={classes.heading}>
              Disease Details
            </Typography>
            <Grid container spacing={3} className={classes.columnContainer}>
              <Grid item xs={4}>
                <TextField
                  label=" What Problem are you here for today?"
                  name="problemDescription"
                  value={profileData.problemDescription}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label=" How Long You Are Facing This Problem?"
                  name="problemDuration"
                  value={profileData.problemDuration}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="If 'other' Please Describe!"
                  name="problemOtherDescription"
                  value={profileData.problemOtherDescription}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.heading}>
              Treatment Details
            </Typography>
            <Grid container spacing={3} className={classes.columnContainer}>
              <Grid item xs={6}>
                <Autocomplete
                  options={['Treatment 1', 'Treatment 2', 'Treatment 3']} // Replace with your actual treatment options
                  value={treatmentName}
                  onChange={(event, value) =>
                    handleProfileDataChange(event, value, 'treatmentName')
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Treatment Name" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={['Medicine 1', 'Medicine 2', 'Medicine 3']} // Replace with your actual medicine options
                  value={medicineName}
                  onChange={(event, value) =>
                    handleProfileDataChange(event, value, 'medicineName')
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Medicine Name" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="If 'other' Please Describe!"
                  name="treatmentOtherDescription"
                  value={profileData.treatmentOtherDescription}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.heading}>
              Filled By
            </Typography>
            <Grid container spacing={3} className={classes.columnContainer}>
              <Grid item xs={4}>
                <TextField
                  label="Name"
                  name="filledByName"
                  value={profileData.filledByName}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Designation"
                  name="filledByDesignation"
                  value={profileData.filledByDesignation}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="If 'Other' Please Describe!"
                  name="filledByOtherDescription"
                  value={profileData.filledByOtherDescription}
                  onChange={handleProfileDataChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              type="submit"
                          className="btn-bg"
                          onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default PatientsDetailsForm;
