/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { cartCreateOrUpdateCartAction } from '../../redux/actions/cartActions';
import './ProductListCard.css';
import AddToCart from './AddToCart';

const ProductListCard = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log('product', product);

  const { _id,
    product_name,
    description,
    reference,
    ingredients,
    indications,
    dosage,
    sku,
    stock,
    content_type,
    belongs_to,
    isDeleted,
    createdAt,
    updatedAt,
    categories,
    tags,
    linkedMedia,
    regular_price,
    sale_price,
    bottle_size } = product;

  const cardOnClickHandler = () => {
    navigate(`/product-details/${_id}`,
      {
        state: product
      }
    );

  };
  const addToCartHandler = async (cartItem) => {
    console.log('cartIteemememeeeeeemme', cartItem);

    // TODO: It's not logged in so got to handle login functionality with cart.
    // _id is not defined in JSON.parse(secureLocalStorage.getItem('users'))._id because it is not logged in.
    const cartPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      productId: cartItem?._id,
      quantity: 1
    };

    await dispatch(cartCreateOrUpdateCartAction(cartPayload));
    // eslint-disable-next-line no-console, no-undef
    console.log('cartPayload', cartPayload);

    // navigate('/cart');
  };

  return (

    <Card className="product-card"> {/* Apply the 'product-card' class */}
      <CardActionArea onClick={cardOnClickHandler}>
        <CardMedia
          component="img"
          alt={product_name}
          height="140"
          image={linkedMedia?.server_image_url}
          title={product_name}
          className="product-image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className="product-name"> {/* Apply the 'product-name' class */}
            {product_name}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="product-price"> {/* Apply the 'product-price' class */}
            ₹{sale_price}/- (₹<strike>{regular_price}</strike>)
          </Typography>
          <Typography variant="body2" color="text.secondary" className="product-bottle_size"> {/* Apply the 'product-size' class */}
            Size: {bottle_size}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="product-size"> {/* Apply the 'product-size' class */}
            Ingredients: {ingredients}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Link to={`/product-details/${_id}`} state={product} style={{ textDecoration: 'none', paddingRight: '8px' }}>
        <Button variant="contained" className="vw-main-btn"> {/* Apply the 'product-button' class */}
          View Details
        </Button>
      </Link>
      {/* <Link to="/cart" state={product} style={{ textDecoration: 'none' }}> */}
      <AddToCart variant="contained" className="vw-main-btn" style={{ textDecoration: 'none' }} product={product} route='/cart' />
      {/* <Button variant="contained" className="vw-main-btn" style={{ textDecoration: 'none' }} onClick={() => addToCartHandler(product)}> 
      
      // Apply the 'product-button' class 
        Add to Cart
      </Button> */}
      {/* </Link> */}
    </Card>
  );
};

ProductListCard.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    ingredients: PropTypes.string.isRequired,
    indications: PropTypes.string.isRequired,
    dosage: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    stock: PropTypes.string.isRequired,
    content_type: PropTypes.string.isRequired,
    belongs_to: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    linkedMedia: PropTypes.object.isRequired,
    regular_price: PropTypes.string.isRequired,
    sale_price: PropTypes.string.isRequired,
    bottle_size: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductListCard;
