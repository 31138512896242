import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const Pimple = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Pimple(Acne)
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              What is Pimple(Acne)?
            </Typography>
            <Typography variant="body1">
              Pimple(Acne) is a common skin condition that occurs when hair
              follicles become clogged with oil, dead skin cells, and bacteria.
              It primarily affects areas of the skin with a high density of oil
              glands, such as the face, chest, and back. Acne can manifest in
              various forms, including pimples, blackheads, whiteheads, cysts,
              or nodules. It is often influenced by hormonal changes, such as
              during puberty, pregnancy, or menstruation, as well as genetic
              factors and certain medications. Common symptoms of acne include
              the presence of lesions on the skin, redness, inflammation, and
              sometimes pain or discomfort{' '}
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Symptoms of Pimple
            </Typography>
            <Typography variant="body1">
              Some common symptoms of Pimple include:
              <ul>
                <li>Whiteheads (closed plugged pores).</li>
                <li>Blackheads (open plugged pores).</li>
                <li>Small red, tender bumps (papules).</li>
                <li>
                  Pimples (pustules), which are papules with pus at their tips.
                </li>
                <li>Large, solid, painful lumps under the skin (nodules).</li>
                <li>
                  Painful, pus-filled lumps under the skin (cystic lesions).
                </li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Causes of Pimple
            </Typography>
            <Typography variant="body1">
              Pimple can be caused by various factors, including:
              <ul>
                <li>Excessive production of serum or oil.</li>
                <li>
                  Hair follicles clogged by oils or dead skin, bacteria,
                  inflammation & hormonal change.
                </li>
                <li>Over use of cosmetics</li>
                <li>Excess intake of fried and junk food </li>
                <li>Stress, anxiety & insomnia.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Risk Factors for Pimple
            </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Pimple include:
              <ul>
                <li>
                  Hormone imbalance which causes irregular mensuration etc
                </li>
                <li>Applying pressure or friction on the skin</li>
                <li>Insufficient sleep and excessive use of cosmetics.</li>
                <li>Using oily and greasy products</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
            <Typography variant="h4" gutterBottom>
              Prevention and Management
            </Typography>
            <Typography variant="body1">
              some strategies that may help to Prevent the Pimple:
              <ul>
                <li>
                  Thorough washing of skin with lukewarm water for three or four
                  times a day.
                </li>
                <li>
                  Blood purifying and detoxifying herbs possessing astringent
                  taste helps to dry up the oil and bitters like neem, turmeric
                  etc.
                </li>
                <li>Drink more water.</li>
                <li>Avoid intake of spicy oily baked or jung foods. </li>
                <li>Increase intake of fruits & vegetables.</li>
                <li>
                  Apply turmeric, sandalwood, red sandal paste etc. gently on
                  skin.
                </li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pimple;
