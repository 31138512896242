import React from 'react';
import { Typography, Grid } from '@mui/material';

const ElbowArthritis = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Elbow Arthritis </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Elbow Arthritis? </strong>
        </Typography>
            <Typography variant="body1">
            Elbow arthritis refers to the inflammation and degeneration of the elbow joint, leading
to pain, stiffness, and limited range of motion. Symptoms of elbow arthritis include
persistent joint pain, swelling, tenderness, a sensation of grinding or clicking within
the joint, and difficulty in fully extending or flexing the elbow. The primary cause of
elbow arthritis is wear and tear of the joint cartilage over time, a condition known as
osteoarthritis. Other factors such as previous elbow injuries, autoimmune diseases,
and repetitive overuse of the joint can also contribute.            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Symptoms of Elbow Arthritis </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Elbow Arthritis include:
              <ul className='vw-ul'>
                <li>Swelling, Pain</li>
                <li>Stiffness</li>
                <li>A clicking or snapping feeling</li>
                <li>Symptoms that are worse on the outside of the joint</li>
                <li>More pain when you rotate or extend your arm</li>
                <li>Trouble moving your elbow</li>
                <li>The joint locks up or gives out when you move it</li>
                <li>Tingling in your elbow</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Elbow Arthritis</strong>
        </Typography>
            <Typography variant="body1">
              Elbow Arthritis can be caused by various factors, including:
              <ul className='vw-ul'>
              <li> Elbow arthritis occurs when the cartilage in the elbow becomes worn or damaged. </li> 
              <li> Damage can occur from overuse due to age and repetitive activities, or as a result of an injury, such as a fracture or dislocation. </li>
              <li> Elbow arthritis can be extremely painful and can interfere with daily tasks that involve bending your arm. </li>   
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Elbow Arthritis</strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Elbow Arthritis include:
              <ul className='vw-ul'>
                <li>who regularly perform strenuous manual activity, such as shoveling or hammering</li>
                <li>people with a history of injury or fracture to the elbow</li>
                <li>people whose family members have OA</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Elbow Arthritis cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>You can try to prevent elbow OA by protecting your elbows and your joints. Most people diagnosed with elbow OA have a history of damage, such as a fracture, ligament injury, or dislocation in the joint. The more damage your joint endures, the greater your risk for problems like OA.</li>
                <li>In some cases, continuous use of the joint can cause elbow OA. Occupations or hobbies that require continuous use of your elbow can lead to an OA diagnosis later in life.</li>
              
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default ElbowArthritis;
