import React from 'react';
import { Typography, Grid } from '@mui/material';

const PolycysticOvarySyndrome = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Polycystic Ovary Syndrome </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Polycystic Ovary Syndrome? </strong>
        </Typography>
            <Typography variant="body1">
            PCOS, or polycystic ovary syndrome, is a hormonal disorder that affects women of reproductive age. It is
characterized by enlarged ovaries containing small cysts, hormonal imbalances, and irregular or absent
menstrual periods. The exact cause of PCOS is not fully understood, but it is believed to involve a
combination of genetic and environmental factors. Symptoms of PCOS can include irregular periods,
excessive hair growth (hirsutism), acne, weight gain, and fertility issues. PCOS is also associated with an
increased risk of developing conditions like type 2 diabetes, high blood pressure, and heart disease.            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Symptoms of Polycystic Ovary Syndrome </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of PCOS include:
              <ul className='vw-ul'>
                <li><b>Irregular periods:</b> Abnormal menstruation involves missing periods or not having a period at all. It may also involve heavy bleeding during periods.</li>
                <li><b>Abnormal hair growth:</b> You may grow excess facial hair or experience heavy hair growth on your arms, chest and abdomen (hirsutism). This affects up to 70% of people with PCOS.</li>
                <li><b>Acne: </b>PCOS can cause acne, especially on your back, chest and face. This acne may continue past your teenage years and may be difficult to treat.</li>
                <li><b>Obesity:</b> Between 40% and 80% of people with PCOS have obesity and have trouble maintaining a weight that’s healthy for them.</li>
                <li><b>Cysts:</b> Many people with PCOS have ovaries that appear larger or with many follicles (egg sac cysts) on ultrasound.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Polycystic Ovary Syndrome </strong>
        </Typography>
            <Typography variant="body1">
              PCOS can be caused by various factors, including:
              <ul className='vw-ul'>
                <li><b>Higher levels of male hormones called androgens:</b>High androgen levels prevent your ovaries from releasing eggs, which causes irregular menstrual cycles. Irregular ovulation can also cause small, fluid-filled sacs to develop on your ovaries. High androgen also causes acne and excess hair growth in women and people.</li>
                <li><b>Insulin resistance: </b>An increase in insulin levels causes your ovaries to make and release male hormones (androgens). Increased male hormones suppress ovulation and contribute to other symptoms of PCOS.</li>
                <li><b>Low-grade inflammation: </b>People with PCOS tend to have chronic low-grade inflammation. Your healthcare provider can perform blood tests that measure levels of C-reactive protein (CRP) and white blood cells, which can indicate the level of inflammation in your body.</li>
                
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong> Risk Factors for Polycystic Ovary Syndrome </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing PCOS include:
              <ul className='vw-ul'>
                <li>Infertility</li>
                <li>Gestational diabetes or pregnancy-induced high blood pressure</li>
                <li>Miscarriage or premature birth
                  Having other allergic conditions, such as asthma or eczema</li>
                <li>Cancer of the uterine lining (endometrial cancer)</li>
                <li>Type 2 diabetes or prediabetes</li>
                <li>Depression, anxiety and eating disorders</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While PCOS cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Avoiding triggers, such as allergens or irritants</li>
                <li>Using nasal saline rinses to keep the nasal passages clear</li>
             </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default PolycysticOvarySyndrome;
