/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const ImmunodeficiencyDisorder = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Immunodeficiency Disorder </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Immunodeficiency Disorder? </strong>
        </Typography>
            <Typography variant="body1">
            Immunodeficiency disorders, also known as primary immunodeficiencies or immune deficiencies, refer to a group of disorders
characterized by a weakened or impaired immune system. These disorders can be inherited or acquired and can affect different
components of the immune system, such as B cells, T cells, or both. As a result, individuals with immunodeficiency disorders are more
susceptible to infections and may have difficulty fighting off infections effectively. Common symptoms include recurrent or severe
infections, slow healing of wounds, frequent or persistent illnesses, and increased susceptibility to opportunistic infections.            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Immunodeficiency Disorder</strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Immunodeficiency Disorder include:
              <ul className='vw-ul'>
                <li>Frequent and recurrent pneumonia, bronchitis, sinus infections, ear infections, meningitis or skin infections</li>
                <li>Inflammation and infection of internal organs</li>
                <li>Blood disorders, such as low platelet count or anemia</li>
                <li>Digestive problems, such as cramping, loss of appetite, nausea and diarrhea</li>
                <li>Delayed growth and development</li>
                <li>Autoimmune disorders, such as lupus, rheumatoid arthritis or type 1 diabetes</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Immunodeficiency Disorder</strong>
        </Typography>
            <Typography variant="body1">
              Many primary immunodeficiency disorders are inherited - passed down from one or both parents. Problems in the genetic code that acts as a blueprint for producing the cells of the body (DNA) cause many of these immune system defects.
              There are more than 300 types of primary immunodeficiency disorders, and researchers continue to identify more. They can be broadly classified into six groups based on the part of the immune system that's affected:
              <ul className='vw-ul'>
                <li>B cell (antibody) deficiencies</li>
                <li>T cell deficiencies</li>
                <li>Combination B and T cell deficiencies</li>
                <li>Defective phagocytes</li>
                <li>Complement deficiencies</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Immunodeficiency Disorder </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Immunodeficiency Disorder include:
              <ul className='vw-ul'>
                <li>If you have a type of primary immune deficiency disorder, you might want to seek genetic counseling if you plan to have a family. </li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
            Because primary immune disorders are caused by genetic changes, there's no way to prevent them. But when you or your child has a weakened immune system, you can take steps to prevent infections:
            <ul className='vw-ul'>
                <li>Wash your hands with mild soap after using the toilet and before eating. </li>
                <li>Brush your teeth at least twice a day. </li>
                <li>A healthy, balanced diet can help prevent infections. </li>
                <li>Staying fit is important to your overall health. </li>
                <li>Try to go to sleep and get up at the same time daily, and get the same number of hours of sleep every night.</li>
                <li> Some studies suggest that stress can hamper your immune system. Keep stress in check with massage, meditation, yoga, biofeedback or hobbies.</li>
                <li> Stay away from people with colds or other infections and avoid crowds. </li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default ImmunodeficiencyDisorder;
