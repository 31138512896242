/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const DiabeticKidneyDesease = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Diabetic Kidney Desease </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Diabetic Kidney Desease ? </strong>
        </Typography>
            <Typography variant="body1">
              Diabetic kidney disease, also known as diabetic nephropathy, is a
              complication of diabetes that affects the kidneys. It occurs when
              high blood sugar levels damage the blood vessels in the kidneys,
              leading to reduced kidney function over time. Symptoms may not be
              noticeable in the early stages, but as the disease progresses,
              symptoms can include swelling in the legs, ankles, feet, or hands;
              increased urination; fatigue; nausea; and decreased appetite. The
              primary cause of diabetic kidney disease is poorly controlled
              diabetes, especially over a long period of time. Other factors
              such as high blood pressure and genetic predisposition can also
              contribute..
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Diabetic Kidney Desease</strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Diabetic Kidney Desease include:
              <ul className='vw-ul'>
                <li>Worsening blood pressure control</li>
                <li>Protein in the urine</li>
                <li>Swelling of feet, ankles, hands or eyes</li>
                <li>Increased need to urinate</li>
                <li>Reduced need for insulin or diabetes medicine</li>
                <li>Nausea and vomiting</li>
                <li>Confusion or difficulty concentrating</li>
                <li>Shortness of breath</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Diabetic Kidney Desease</strong>
        </Typography>
            <Typography variant="body1">
              Diabetic Kidney Desease can be caused by various factors,
              including:
              <ul className='vw-ul'>
                <li> Diabetic nephropathy is a common complication of type 1 and type 2 diabetes. </li>
                <li>
                  Over time, poorly controlled diabetes can cause damage to
                  blood vessel clusters in your kidneys that filter waste from
                  your blood. This can lead to kidney damage and cause high
                  blood pressure.
                </li>
                <li>
                  High blood pressure can cause further kidney damage by
                  increasing the pressure in the delicate filtering system of
                  the kidneys.
                </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Diabetic Kidney Desease </strong>
         </Typography>
            <Typography variant="body1">
            If you're living with diabetes, factors that can increase your risk of diabetic nephropathy include:
            <ul className='vw-ul'>
                <li>
                Uncontrolled high blood sugar (hyperglycemia)                </li>
                <li>Uncontrolled high blood pressure (hypertension)</li>
                <li>
                Being a smoker
                </li>
                <li>High blood cholesterol</li>
                <li>Obesity</li>
                <li>A family history of diabetes and kidney disease
</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Diabetic Kidney Disease cannot always be prevented, some
              strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li><b>Keep regular appointments for diabetes management:</b> Keep annual appointments — or more-frequent appointments if recommended by your health care team — to monitor how well you are managing your diabetes and to screen for diabetic nephropathy and other complications.</li>
                <li>
                <b>Treat your diabetes:</b> With effective treatment of diabetes, you may prevent or delay diabetic nephropathy.
                </li>
                <li>
               <b> Manage high blood pressure or other medical conditions: </b>If you have high blood pressure or other conditions that increase your risk of kidney disease, work with your doctor to control them.
                </li>
                <li>
               <b> Maintain a healthy weight:</b> If you're at a healthy weight, work to maintain it by being physically active most days of the week. If you need to lose weight, talk with your doctor about weight-loss strategies, such as increasing daily physical activity and consuming fewer calories.
                </li>
                <li><b>Don't smoke </b>Cigarette smoking can damage your kidneys and make existing kidney damage worse. If you're a smoker, talk to your doctor about strategies for quitting smoking. Support groups, counseling and some medications can all help you to stop.</li>
              </ul>
            </Typography>
        
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default DiabeticKidneyDesease;
