/* eslint-disable no-console */
/* eslint-disable no-undef */
import { put, takeEvery ,call} from "redux-saga/effects";
import * as type from "../types";
import contactApi from "../../apis/contactApi";
// eslint-disable-next-line no-unused-vars
import secureLocalStorage from "react-secure-storage";

function* contactForm(action) {
  console.log("contact form saga..", action.payload);
    try {
        const response = yield call(contactApi.submitContact, action.payload);
        console.log("API Response:", response);
        if (response && response.data) {
            console.log("hello from saga");
            yield put({ type: type.CREATE_CONTACT_SUCCESS, response: response.data });
        } else {
            yield put({
                type: type.CREATE_CONTACT_FAILURE,
                Error: { msg: "invalid contact" },
                status: response.status,
              });
            }
          } catch (error) {
            console.error("API Error:", error);
            yield put({ type: type.CREATE_CONTACT_FAILURE });
          }
        }

// new TILL HERE

function* contactFormSaga() {
  yield takeEvery(type.CREATE_CONTACT_REQUESTING, contactForm);
}

export default contactFormSaga;
