import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  IconButton,
  TextField
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { productsAction, productsUnshowByAction } from '../../../redux/actions/productsActions';
import Layout from './Layout';


const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
    //  marginLeft: theme.spacing(7), // Add margin on the left side
  },
  tableHeading: {
    fontWeight: 'bold',
    backgroundColor: '#efefef',
    color: '#000000',
  },
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(6.5),
    // marginLeft: theme.spacing(14), // Add margin on the left side
  },
  filterDropdown: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(2),
  },
}));

const ProductListPage = () => {
  const productSelector = useSelector(state => state?.products?.data);
  const classes = useStyles();
  const [productsFromSelector, setProductsFromSelector] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();

  const columns = [
    {
      field: 'image',
      headerName: 'Product Image',
      width: 150,
      renderCell: (params) => (
        <>
          <img src={params.formattedValue} style={{ width: '100px' }} />
        </>
      )
    },
    { field: 'product_name', headerName: 'Product Name', width: 150 },
    { field: 'product_owner', headerName: 'Created By', width: 150 },
    { field: 'sku', headerName: 'SKU', width: 150 },
    { field: 'stock', headerName: 'Stock', width: 150 },
    {
      field: 'regular_price', headerName: 'Price', width: 150,
      renderCell: (params) => (
        <>
          ₹ {params.row.sale_price}&nbsp;(<strike>
            ₹ {params.row.regular_price}
          </strike>)
        </>
      )
    },
    { field: 'joinedCategories', headerName: 'Categories', width: 150 },
    { field: 'joinedTags', headerName: 'Tags', width: 150 },
    { field: 'createdAt', headerName: 'Created Date', width: 100 },
    { field: 'updatedAt', headerName: 'Modified Date', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <>
          {/* <IconButton>
            <Edit onClick={() => handleEditAPage(params?.row)} />
          </IconButton> */}
          <IconButton onClick={() => handleDeleteProduct(params?.row?._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      )
    },
  ];

  useEffect(() => {
    const allProductsPayload = {
      Role: secureLocalStorage.getItem('Role'),
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
    };

    async function fetchAllProducts() {
      await dispatch(productsAction(allProductsPayload));
    }

    fetchAllProducts();
  }, [dispatch]);

  useEffect(() => {
    if (productSelector && Array.isArray(productSelector) && productSelector.length !== 0) {
      setProductsFromSelector(productSelector.map((aProduct) => ({
        ...aProduct,
        id: aProduct._id, // Add id property based on _id
        product_owner: `${aProduct?.belongs_to?.firstname} ${aProduct?.belongs_to?.lastname}`,
        image: aProduct?.linkedMedia !== null ? aProduct?.linkedMedia?.server_image_url : '',
        finalPrice: `${aProduct.sale_price} ${aProduct.regular_price} ${<strike>{aProduct.regular_price}</strike>}`,
        joinedCategories: aProduct?.categories.map(aCategory => aCategory.name).join(', '),
        joinedTags: aProduct?.tags.map(aCategory => aCategory.name).join(', '),
        createdAt: aProduct?.createdAt?.split('T')[0],
        updatedAt: aProduct?.updatedAt?.split('T')[0],
      })));
      setFilteredProducts(productSelector.map((aProduct) => {
        return ({
          ...aProduct,
          id: aProduct._id, // Add id property based on _id
          product_owner: `${aProduct?.belongs_to?.firstname} ${aProduct?.belongs_to?.lastname}`,
          image: aProduct?.linkedMedia !== null ? aProduct?.linkedMedia?.server_image_url : '',
          finalPrice: `${aProduct.sale_price} ${aProduct.regular_price} (${<strike>{aProduct.regular_price}</strike>})`,
          joinedCategories: aProduct?.categories.map(aCategory => aCategory.name).join(', '),
          joinedTags: aProduct?.tags.map(aCategory => aCategory.name).join(', '),
          createdAt: aProduct?.createdAt?.split('T')[0],
          updatedAt: aProduct?.updatedAt?.split('T')[0],
        });
      }));
    }
  }, [productSelector]);

  const handleDeleteProduct = async (id) => {
    const deleteProductPayload = {
      id: id,
      isDeleted: true,
      content_type: 'product',
      userId: JSON.parse(secureLocalStorage.getItem('users'))._id,
      email: JSON.parse(secureLocalStorage.getItem('users')).email,
      belongs_to: JSON.parse(secureLocalStorage.getItem('users'))._id,
    };
    await dispatch(productsUnshowByAction(deleteProductPayload));
    setProductsFromSelector(productsFromSelector.filter(aProduct => aProduct._id !== id));
    setFilteredProducts(productsFromSelector.filter(aProduct => aProduct._id !== id));

    // setOriginalCategories(tags.filter(aTag => aTag._id !== id)); // Update original tags
  };

  const handleFilterChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);

    const filteredResults = productsFromSelector.filter(
      (aProduct) =>
        aProduct.product_name.toLowerCase().includes(searchQuery) ||
        aProduct.sku.toLowerCase().includes(searchQuery) ||
        aProduct?.belongs_to?.firstname.toLowerCase().includes(searchQuery) ||
        aProduct?.belongs_to?.lastname.toLowerCase().includes(searchQuery)
    );
    setFilteredProducts(filteredResults);
  };

  return (
    <Layout>
      <div className="add-pages-container">
        <div className="add-page-contents add-page-content">
          <h6>All Products / Services</h6>
          {' '}
          {/* Add margin to create space on the left side */}
          <div className={classes.filtersContainer}>
            {/* First filter: Select a category 
            <Select
              className={classes.filterDropdown}
              defaultValue=""
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a category
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>

            Second filter: Filter by product
            <Select
              className={classes.filterDropdown}
              defaultValue=""
              displayEmpty
            >
              <MenuItem value="" disabled>
                Filter by product
              </MenuItem>
              {filteringOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            Third filter: Filter by stock
            <Select
              className={classes.filterDropdown}
              defaultValue=""
              displayEmpty
            >
              <MenuItem value="" disabled>
                Filter by Stock
              </MenuItem>
              {filteringOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>


            Fourth filter: Filter by Rating
            <Select className={classes.filterDropdown} defaultValue="" displayEmpty>
              <MenuItem value="" disabled>Filter by Rating</MenuItem>
              {filteringOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select> */}

            {/* Search input box */}
            {/* <Input placeholder="Search..." /> */}
            <div
              className={classes.filterContainer}
              style={{ marginRight: '0px', marginBottom: '14px', }}
            >
              <TextField
                label="Search"
                value={searchTerm}
                onChange={handleFilterChange}
                className={classes.searchInput}
                size="small"
              />
            </div>
          </div>
          <DataGrid
            rows={filteredProducts}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            checkboxSelection
          />
        </div>
      </div>
    </Layout >
  );
};

export default ProductListPage;
