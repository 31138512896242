/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const IrritableBowelSyndrome = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Irritable Bowel Syndrome </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Irritable Bowel Syndrome?</strong>
        </Typography>
            <Typography variant="body1">
            Irritable bowel syndrome (IBS) is a common disorder that affects the large intestine (colon) and is
characterized by a group of symptoms related to bowel function and abdominal pain. The exact
cause of IBS is unknown, but it is believed to involve a combination of factors, including abnormal
muscle contractions in the intestines, heightened sensitivity to pain in the digestive system, and
disturbances in the gut-brain communication. Symptoms of IBS can vary but often include
abdominal pain or discomfort, changes in bowel habits (such as diarrhea, constipation, or both),
bloating, and gas. IBS is typically a chronic condition, but lifestyle changes, dietary modifications,
stress management, and medications can help manage symptoms and improve quality of life for
individuals with IBS. </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Irritable Bowel Syndrome </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Irritable Bowel Syndrome include:
              <ul className='vw-ul'>
                <li>Abdominal pain or cramps, usually in the lower half of the abdomen.</li>
                <li>Bloating.</li>
                <li>Bowel movements that are harder or looser than usual.</li>
                <li>Diarrhea, constipation or alternating between the two.</li>
                <li>Excess gas.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Irritable Bowel Syndrome</strong>
        </Typography>
            <Typography variant="body1">
            Researchers don’t exactly know what causes IBS. They think a combination of factors can lead to IBS, including:
            <ul className='vw-ul'>
                <li>Problems with how your GI muscles contract and move food through the GI tract.</li>
                <li>Extra-sensitive nerves in the GI tract.</li>
                <li> Miscommunication between nerves in the brain and gut.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Irritable Bowel Syndrome</strong>
        </Typography>
            <Typography variant="body1">
              The condition most often occurs in people in their late teens to early 40s. Women can be twice as likely than men to get IBS. IBS may happen to multiple family members. You may be at higher risk if you have:
              <ul className='vw-ul'>
              <li>Family history of IBS.</li>
              <li>Emotional stress, tension or anxiety.</li>
              <li>Food intolerance. </li>
              <li>History of physical or sexual abuse.</li>
              <li>Severe digestive tract infection</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Irritable Bowel Syndrome cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>participating in regular physical activity, such as exercising</li>
                <li>limiting consuming caffeinated beverages since caffeine stimulates the intestines</li>
                <li>minimizing stress (talk therapy, mindfulness, hypnotherapy, and meditation practices may help)
</li>
                <li>taking probiotics (“good” bacteria normally found in the intestines) to help relieve gas and bloating</li>
                <li>taking fiber supplements</li>
              </ul>
            </Typography>
       
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default  IrritableBowelSyndrome;
