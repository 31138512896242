import * as type from '../actionTypes/tagActionTypes';

export const tagsAction = (payload) => {
  return {
    type: type.ALL_TAGS_REQUESTING,
    payload
  };
};
export const tagsLoggedInUserTagsAction = (payload) => {
  return {
    type: type.LOGGED_IN_USER_TAGS_ID_REQUESTING,
    payload
  };
};
export const tagsCreateAction = (payload) => {
  return {
    type: type.TAGS_CREATE_REQUESTING,
    payload
  };
};
export const tagsGetByAction = (payload) => {
  return {
    type: type.TAGS_GET_BY_ID_REQUESTING,
    payload
  };
};
export const tagsUpdateByAction = (payload) => {
  return {
    type: type.TAGS_UPDATE_BY_ID_REQUESTING,
    payload
  };
};
export const tagsUnshowByAction = (payload) => {
  return {
    type: type.TAGS_UNSHOW_BY_ID_REQUESTING,
    payload
  };
};
export const tagsDeleteByAction = (payload) => {
  return {
    type: type.TAGS_DELETE_BY_ID_REQUESTING,
    payload
  };
};


