/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const LipidDisorders = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Lipid Disorders </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Lipid Disorders? </strong>
        </Typography>
            <Typography variant="body1">
            Lipid disorders, also known as dyslipidemia, refer to abnormalities in the levels of
lipids (fats) in the blood. There are different types of lipids, including cholesterol
and triglycerides. Dyslipidemia can involve high levels of total cholesterol, lowdensity lipoprotein (LDL) cholesterol (commonly known as "bad" cholesterol), or
triglycerides, as well as low levels of high-density lipoprotein (HDL) cholesterol
(known as "good" cholesterol). Lipid disorders can be caused by a combination of
genetic factors and lifestyle choices, such as an unhealthy diet, lack of physical
activity, smoking, obesity, and certain medical conditions like diabetes or
hypothyroidism. Dyslipidemia is a significant risk factor for cardiovascular
diseases, including heart disease and stroke.
</Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Lipid Disorders </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Lipid Disorders include:
              <ul className='vw-ul'>
                <li>yellowish, fatty bumps or yellow creases on the skin, formed by an accumulation of fatty deposits around tendons and joints </li>
                <li>white arcs around the cornea of the eye (arcus senilis), which sometimes occur in younger people with high cholesterol </li>
                <li>raised, yellow lumps at the inner corners of the eyes</li>
                <li>Some people feel pain in the legs when walking. It can also be a symptom of lipid disorders.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Lipid Disorders </strong>
        </Typography>
            <Typography variant="body1">
              Lipid Disorders can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Elevated low-density lipoprotein (LDL) cholesterol is a major risk factor for coronary artery disease (CAD).</li>
                <li>Triglycerides are another type of fat found in the blood. Like cholesterol, too many triglycerides can lead to plaque buildup in the arteries. It can increase heart disease, stroke, and other problems.</li>
                <li> Obesity is a major risk factor for lipid disorders. </li>
                <li>A diet high in saturated and trans fats can increase the risk for lipid disorders. These types of fats can raise cholesterol and triglyceride levels.</li>
                <li> A sedentary lifestyle can also increase the risk for lipid disorders. Exercise helps to improve cholesterol and triglyceride levels. </li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Lipid Disorders </strong>
        </Typography>
            <Typography variant="body1">
            Factors that can increase your risk of unhealthy cholesterol levels include:
            <ul className='vw-ul'>
                <li><b>Poor diet:</b> Eating too much saturated fat or trans fats can result in unhealthy cholesterol levels. Saturated fats are found in fatty cuts of meat and full-fat dairy products. Trans fats are often found in packaged snacks or desserts.</li>
                <li><b>Obesity:</b> Having a body mass index (BMI) of 30 or greater puts you at risk of high cholesterol. </li>
                <li><b>Lack of exercise:</b> Exercise helps boost your body's HDL, the "good," cholesterol.</li>
                <li><b>Smoking:</b> Cigarette smoking may lower your level of HDL, the "good," cholesterol.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Lipid Disorders cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Eat a low-salt diet that emphasizes fruits, vegetables and whole grains</li>
                <li>Limit the amount of animal fats and use good fats in moderation </li>
                <li>Lose extra pounds and maintain a healthy weight </li>
                <li>Quit smoking</li>
                <li>Exercise on most days of the week for at least 30 minutes</li>
                <li>Drink alcohol in moderation, if at all </li>
                <li>Manage stress</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default LipidDisorders;
