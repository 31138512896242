import React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

export default function Packages() {
    return (
        <Grid spacing={2} item xs={12} md={12} sx={{
            backgroundImage: 'url(\'../images/bg-1.jpg\')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <Grid p={5}>
                <Box item sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant="p" className='typo-heading-packages'>PACKAGES
                    </Typography>
                    <Box item spacing={2} sx={{ paddingTop: '20px', paddingBottom: '20px', width: '100%', textAlign: 'center' }}>
                        <Typography variant="p" sx={{ color: '#fff' }}>
                            <p>There are many treatments under Ayurveda for managing the locomotive diseases. Major treatments are mentioned here.</p>
                        </Typography>
                    </Box>
                </Box>
                <Grid container>
                    <Grid md={3} sm={6} xs={12}>
                        <Card className='card'>
                            <CardMedia
                                component="img"
                                height="180"
                                image="../images/Mask-Group-6-1.png"
                                alt="green iguana"
                            />
                            <CardContent className='cardContent'>
                                <Typography>Abhyangam (Ayurvedic Massage)</Typography>
                                <Typography variant="body1" color="#ffffff">
                                    In this treatment medicated oil will be applied all over the body or part of the body as per the suggestion of the physician seeing the disease condition.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid md={3} sm={6} xs={12}>
                        <Card className='card'>
                            <CardMedia
                                component="img"
                                height="180"
                                image="../images/Mask-Group-6-2.png"
                                alt="green iguana"
                            />
                            <CardContent className='cardContent'>
                                <Typography>Steaming (Swedanam)</Typography>
                                <Typography variant="body1" color="#ffffff">
                                    The patient is asked to sit in a steam box fully covered except the head. Medicines are put in boluses and kept inside the steam generator or cooker along with water.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid md={3} sm={6} xs={12}>
                        <Card className='card'>
                            <CardMedia
                                component="img"
                                height="180"
                                image="../images/Mask-Group-6.png"
                                alt="green iguana"
                            />
                            <CardContent className='cardContent'>
                                <Typography>Sirodhara / Kashaya Dhara</Typography>
                                <Typography variant="body1" color="#ffffff">
                                    In this treatment, medicated warm oil, medicated buttermilk, decoctions, etc. are poured continuously for stipulated timing with the suggestion of a doctor.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid md={3} sm={6} xs={12}>
                        <Card className='card'>
                            <CardMedia
                                component="img"
                                height="180"
                                image="../images/Mask-Group-6-3.png"
                                alt="green iguana"
                            />
                            <CardContent className='cardContent'>
                                <Typography>Lepanam (Application Of Medicinal Paste)</Typography>
                                <Typography variant="body1" color="#ffffff">
                                    In this treatment a paste is made of different medicines. The paste is applied over the affected area with bearable heat for stipulated time.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
