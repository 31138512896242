import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppLayout from '../src/App';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/**
 * React.StrictMode notes:
 * If you are using React.StrictMode in your index.js file, 
 * it can cause double rendering during development for the purpose of highlighting potential issues. 
 * In production, it should not cause double rendering. 
 * However, if you see double rendering in production, 
 * ensure you don't have it enabled there.
 */
// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> // Enabling this is causing double rendering of web pages, thus making 2 API requests, 2 times page reloading and so forth.
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <CssBaseline />
      <AppLayout />
    </Provider>
  </LocalizationProvider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
