/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  submitContactRequest,
  submitContactSuccess,
  submitContactFailure,
} from "../../src/redux/actions/contactActions";

import axios from "axios";

import { Box, Grid, InputBase, Typography } from "@mui/material";

import { Helmet } from "react-helmet"; // Import Helmet from the appropriate library

import "../css/Contact.css";
 import contactApi from "../apis/contactApi";

function Contact() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contact);
  const { isLoading , isSuccess ,isError} = data;
  const [formData, setFormData] = useState({
    fullName: "",

    email: "",

    contactNo: "",

    message: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",

    email: "",

    contactNo: "",

    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,

      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // submitContactRequest();
    console.log("msg..");
    const newErrors = {};

    // Validate full name

    if (!formData.fullName) {
      newErrors.fullName = "Full name is required";
    }

    // Validate email

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    // Validate contact number

    if (!formData.contactNo) {
      newErrors.contactNo = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contactNo)) {
      newErrors.contactNo = "Invalid contact number";
    }

    // Validate message

    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    // dispatch(submitContactRequest(formData));
    if (Object.keys(newErrors).length === 0) {
      console.log("you do not have any error............");
      dispatch(submitContactRequest(formData));
      console.log("Action dispatched with payload:", formData);
      try {
        const response = await contactApi.submitContact(formData); // Make the API request
  
        // Assuming the submission was successful
        dispatch(submitContactSuccess(response.data)); // Dispatch success action
      } catch (error) {
        // Handle submission failure
        dispatch(submitContactFailure(error)); // Dispatch failure action
      }

    }
  };
  // Handle success and failure within the component
  // if (isSuccess) {
  //   return (
  //     <div className="success-message">
  //       Your message has been sent successfully. 
  //     </div>
  //   );
  // } else if (isError) {
  //   return (
  //     <div className="error-message">
  //       There was an error while sending your message. Please try again later.
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>
          Best Ayurveda Center in Delhi | Ayurvedic Clinic & Massage in Noida{" "}
        </title>

        <meta
          name="description"
          content="Visit the best Ayurveda center in Delhi & Noida for top-notch treatments & massages, ensuring holistic well-being. Visit to know more."
        />
      </Helmet>

      <div className="inner-container">
        <h2 className="dnt-heading">Contact Us </h2>
      </div>

      <br />

      <div className="us-container">
        <Typography className="contact-txt">
          Free* Consultation with Ayurvedic Doctor – Find out your Prakriti and
          get a Nadi Pariksha done.
        </Typography>

        <Box container spacing={4}>
          <Grid
            className="contact-int"
            container
            spacing={2}
            sx={{ marginLeft: "1px" }}
          >
            <Grid item xs={6} sx={{}}>
              <form onSubmit={handleSubmit} className="form-container">
                <InputBase
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="FULL NAME *"
                />

                {errors.fullName && (
                  <span className="error">{errors.fullName}</span>
                )}

                <InputBase
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="EMAIL *"
                />

                {errors.email && <span className="error">{errors.email}</span>}

                <InputBase
                  type="text"
                  name="contactNo"
                  value={formData.contactNo}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="CONTACT NO *"
                />

                {errors.contactNo && (
                  <span className="error">{errors.contactNo}</span>
                )}

                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-control-msg"
                  placeholder="MESSAGE *"
                />

                {errors.message && (
                  <span className="error">{errors.message}</span>
                )}

                <button type="submit" className="ctn-btn" disabled={isLoading}>
                  {isLoading ? "Submitting..." : "Submit"}
                </button>

                {isSuccess && (
                  <p className="success-message">
                    Your message has been sent successfully.
                  </p>
                )}
              </form>
            </Grid>

            <Grid item xs={6}>
              <Box className="grid-2 contact-txtt">
                <Typography>
                  <span className="adr">Address:</span>
                </Typography>

                <Typography>
                  Vedanta Wellness, C-11, Sector-56, Noida, India
                </Typography>

                <Typography>
                  <strong>Call Us:</strong> +91-9811669606 / +91-9810781909
                </Typography>

                <Typography>
                  <strong>Email:</strong> cs@vedanta-wellness.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default Contact;
