/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const GastroesophagealRefluxDisease = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Gastroesophageal Reflux Disease </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Gastroesophageal Reflux Disease?</strong>
        </Typography>
            <Typography variant="body1">
            Gastroesophageal Reflux Disease (GERD) is a chronic digestive disorder where stomach acid and
other stomach contents flow back into the esophagus, causing symptoms such as heartburn,
regurgitation, chest pain, and difficulty swallowing. The main cause of GERD is a weakened lower
esophageal sphincter (LES), which normally prevents stomach acid from flowing back up. Factors
that contribute to LES dysfunction and GERD include obesity, hiatal hernia, pregnancy, smoking,
certain medications, and certain foods and beverages. Persistent and untreated GERD can lead to
complications like esophagitis, Barrett's esophagus, and even an increased risk of esophageal
cancer.</Typography>

        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Gastroesophageal Reflux Disease </strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Gastroesophageal Reflux Disease include:
              <ul className='vw-ul'>
                <li>A burning sensation in your chest (heartburn), usually after eating, which might be worse at night or while lying down.</li>
                <li>Backwash (regurgitation) of food or sour liquid.</li>
                <li>Upper abdominal or chest pain</li>
                <li>Trouble swallowing (dysphagia)</li>
                <li>Sensation of a lump in your throat.</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Gastroesophageal Reflux Disease</strong>
        </Typography>
            <Typography variant="body1">
              Gastroesophageal Reflux Disease can be caused by various factors, including:
              <ul className='vw-ul'>
                <li>Too much pressure on the abdomen. Some pregnant women experience heartburn almost daily because of this increased pressure.</li>
                <li>Particular types of food (for example, dairy, spicy or fried foods) and eating habits.</li>
                <li>Medications that include medicines for asthma, high blood pressure and allergies; as well as painkillers, sedatives and anti-depressants.</li>
                <li>A hiatal hernia. The upper part of the stomach bulges into the diaphragm, getting in the way of normal intake of food.</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Gastroesophageal Reflux Disease</strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Gastroesophageal Reflux Disease include:
              <ul className='vw-ul'>
                <li>Obesity</li>
                <li>Bulging of the top of the stomach up above the diaphragm (hiatal hernia)</li>
                <li>Pregnancy</li>
                <li>Connective tissue disorders, such as scleroderma</li>
                <li>Delayed stomach emptying</li>
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While Gastroesophageal Reflux Disease cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li>Achieve and maintain a healthy weight.s</li>
                <li>Eat small, frequent meals rather than huge amounts a few times a day.</li>
                <li>Reduce fat by decreasing the amount of butter, oils, salad dressings, gravy, fatty meats and full-fat dairy products such as sour cream, cheese and whole milk.</li>
                <li>Sit upright while eating and stay upright (sitting or standing) for 45 to 60 minutes afterward.</li>
                <li>Avoid eating before bedtime. Wait at least three hours after eating to go to bed.</li>
                <li>Try not to wear clothes that are tight in the belly area. They can squeeze your stomach and push acid up into the esophagus</li>
                <li>Try not to wear clothes that are tight in the belly area. They can squeeze your stomach and push acid up into the esophagus.</li>
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default GastroesophagealRefluxDisease;
