/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Jaundice = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Jaundice </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Jaundice?</strong>
        </Typography>
            <Typography variant="body1">
            Jaundice is a medical condition characterized by yellowing of the skin, mucous membranes,
and whites of the eyes. It occurs when there is an excess of bilirubin, a yellow pigment
produced during the breakdown of red blood cells, in the bloodstream. Jaundice can be
caused by various factors, including liver diseases such as hepatitis, alcoholic liver disease,
cirrhosis, or liver cancer. It can also result from other conditions that cause excessive
breakdown of red blood cells, such as hemolytic anemia, or blockage of the bile ducts, as
seen in gallstones or pancreatic cancer. Other symptoms of jaundice may include dark urine,
pale stools, fatigue, abdominal pain, and itching.             </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Jaundice</strong>
        </Typography>
            <Typography variant="body1">
              Some common symptoms of Jaundice include:
              <ul className='vw-ul'>
                <li>A yellow tinge to the skin, mucous membranes, and the whites of the eyes</li>
                <li>Fever, Chills, Abdominal pain.</li>
                <li>Dark-colored urine and/or clay-colored stool.</li>
                <li>Itchiness</li>
                <li>Weight loss</li>
                <li>Flu-like symptoms.</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Jaundice</strong>
        </Typography>
            <Typography variant="body1">
              Excess bilirubin (hyperbilirubinemia) is the main cause of jaundice. Bilirubin, which is responsible for the yellow color of jaundice, is a normal part of the pigment released from the breakdown of "used" red blood cells.
              Others causes include:
              <ul className='vw-ul'>
                <li>Internal bleeding (hemorrhage)</li>
                <li>An infection in your baby's blood (sepsis)</li>
                <li>Other viral or bacterial infections</li>
                <li>An incompatibility between the mother's blood and the baby's blood
                </li>
                <li>A liver malfunction</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Jaundice</strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing Jaundice include:
              <ul className='vw-ul'>
                <li>Certain people with hereditary conditions (for example, thalassemia or hereditary spherocytosis) are at an increased risk of developing jaundice from hemolysis.</li>
                <li>People who consume alcohol heavily are at an increased risk of developing alcoholic hepatitis, pancreatitis, and cirrhosis leading to jaundice.
</li>
                <li>Eating raw shellfish</li>
                <li>Drug injections of other substances</li>
                <li>Being unvaccinated (for hepatitis)</li>
              </ul>
            </Typography>
        
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              Jaundice is related to liver function.
              People can help take care of their liver with several lifestyle changes, such as:  
              <ul className='vw-ul'>
                <li>Eating a balanced diet </li>
                <li>Exercising regularly</li>
                <li>Limiting alcohol consumption</li>
                <li>Avoiding toxins from chemicals and other sources, both inhaled and touched</li>
              </ul>
            </Typography>
         
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Jaundice;
