/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const Nasyam = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Nasyam</h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          <strong>What is Nasyam ? </strong>
          </Typography>
          <Typography variant="body1">
          <p>Nasyam is an Ayurvedic treatment to cure headache or ENT related disorders such as sinusitis, migraine, ear, nose or throat. During this procedure, medicated powder or juice and herbal oils are applied through the nostrils. Nasyam therapy is very effective in removing the toxins from the head region and purifying the organs. The medicated oils and powders used in this process stimulate the crucial centers of your brain that controls your emotions. Frequent use of Nasyam therapy reduces stress, detoxifies the impurities in your skin and improves your skin tone. </p>
          <p>Nasyam therapy takes place in three phases- pre purificatory measures or Poorvakarma, the actual procedure known as Pradhanakarma, and post therapeutic measures or Paschatkarma. The first phase requires you to undergo facial oil massage or apply steam to your face, forehead, neck and ears. This is done to relax your muscles. The second stage is the most important stage where lukewarm medicinal oil is infused through your nostrils. Uncongested healthy breathing is crucial to make sure proper flow of prana throughout body and head. Nasyam protects and soothes the nasal passage and helps reduce sinus congestion. In the third and last stage your mouth will be cleaned with lukewarm water and you will be given medicated smoke to inhale.</p> 
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the second heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Benefits of Nasyam </strong>
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>Removes toxins from all the channels and cavities, neck, brain, eyes, nose, ear and throat. </li>
              <li>Cures nasal congestion, sinusitis and asthma.</li>
              <li>Guarantees complete immunity.</li>
              <li>Revitalize the brain and nervous system.</li>
              <li>Improves memory power.</li>
              <li>Strengthens sensory organs.</li>
              <li>Relieves headaches and stress.</li>
              <li>Prevents skin diseases.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the third heading */}
          <Typography variant="h6" gutterBottom>
          <strong> How is the Nasyam treatment done?</strong>
          </Typography>
          <Typography variant="body1">
          <p>Nasyam treatment follows a few simple steps. You will need to lie down on your back and keep your head tilted to the back so that your nostrils open towards the sky. You may place a pillow or a towel beneath your neck for support, or hang your head off the edge of the bed. Apply 5-10 drops of lukewarm nasyam oil in each nostril drop by drop. The temperature of the oil should not exceed your body temperature, otherwise it may harm the mucous membrane or nasal cavity. The different herbal oils used in the preparation of nasyam are- narayana, anu, shadbindu, guraadi, marichyadi etc. Some herbs commonly used in the therapy include- vidanga, hingu, apamarg, tulsi, lahsuna and maricha.</p>
          <p>After applying drops of the medicinal oil, cover your left nostril with one hand and breathe in through the right nostril. Now cover your right nostril and breathe out through the left one. After repeating this action for a while, you will need to stay back and relax for sometime. The medicated oils and powders used in this process stimulate the crucial centers of your nervous system and venous system, and thus reduce the blockage in channels to overcome certain ENT related disorders.</p> 
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr /> {/* Line before the fourth heading */}
          <Typography variant="h6" gutterBottom>
          <strong>Who Should Be Avoided?</strong>
          </Typography>
          <Typography variant="body1">
            <ul>
            <li>Breathe in gently and slowly. Do not inhale forcefully.</li>
            <li>Do not expose yourself to too much wind, cold, sun or strenuous activity immediately after the procedure.</li>
            <li>Avoid taking a bath or eating food immediately before or after the procedure.</li>
            <li>Avoid doing this procedure if you have a cold or fever.</li>
            <li>It is not advised for children, very old and weak people.</li>
            <li>Avoid if intoxicated by alcohol or narcotics.</li>
            <li>There should be a gap of at least two hours before/after the procedure from food and bath.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default Nasyam;
