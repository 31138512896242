/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const UrinaryTractInfection = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Urinary Tract Infection (UTI) </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong>What is Urinary Tract Infection (UTI)? </strong>
        </Typography>

        <Typography variant="body1">
        <p>A urinary tract infection (UTI) is an infection in any part of the urinary system. The urinary system includes the kidneys, ureters, bladder and urethra. Most infections involve the lower urinary tract the bladder and the urethra. Women are at greater risk of developing a UTI than are men. If an infection is limited to the bladder, it can be painful and annoying. But serious health problems can result if a UTI spreads to the kidneys.</p>
        <p>Health care providers often treat urinary tract infections with antibiotics. You can also take steps to lower the chance of getting a UTI in the first place.</p>
        </Typography>

      </Grid>

      <Grid item xs={12}>
      <hr />
      
      <Typography variant="h6" gutterBottom>
      <strong> Symptoms of Urinary Tract Infection (UTI) </strong>
      </Typography>

      <Typography variant="body1">
       Some common symptoms of Urinary Tract Infection (UTI) include:
       
       <ul className='vw-ul'>
       <li> A strong urge to urinate that doesn't go away </li>
       <li> A burning feeling when urinating </li>
       <li> Urinating often, and passing small amounts of urine </li>
       <li> Urine that looks cloudy </li>
       <li> Urine that appears red, bright pink or cola-colored - signs of blood in the urine </li>
       <li> Strong-smelling urine </li>
       <li> Pelvic pain, in women - especially in the center of the pelvis and around the area of the pubic bone </li>  
       </ul>
       </Typography>

     </Grid>
     <Grid item xs={12}>

     <hr />

     <Typography variant="h6" gutterBottom>
     <strong>Causes of Urinary Tract Infection (UTI) </strong>
     </Typography>

     <Typography variant="body1">
     Common causes of Urinary Tract Infection (UTI) include:
     <ul className='vw-ul'>
     <li>Urinary tract infections (UTIs) are usually caused by bacteria from poo entering the urinary tract.</li>
     <li>The bacteria enter through the tube that carries pee out of the body (urethra).</li>
     <li>Women have a shorter urethra than men. This means bacteria are more likely to reach the bladder or kidneys and cause an infection </li>
     <li>Not keeping the genital area clean and dry </li>
     <li>Having a weakened immune system - for example, people with diabetes or people having chemotherapy</li>
     </ul>

     </Typography>
     </Grid>

     <Grid item xs={12}>
      <hr />

      <Typography variant="h6" gutterBottom>
      <strong> Risk Factors for Urinary Tract Infection (UTI) </strong>
      </Typography>

      <Typography variant="body1">
      UTIs are common in women. Many women experience more than one UTI during their lifetimes.
      Risk factors for UTIs that are specific to women include:
      <ul className='vw-ul'>
      <li><b>Female anatomy.</b> Women have a shorter urethra than men do. As a result, there's less distance for bacteria to travel to reach the bladder.</li>
      <li><b>Sexual activity.</b> Being sexually active tends to lead to more UTIs. Having a new sexual partner also increases risk.</li>
      <li><b>Certain types of birth control.</b> Using diaphragms for birth control may increase the risk of UTIs. Using spermicidal agents also can increase risk.</li>
      <li><b>Menopause.</b> After menopause, a decline in circulating estrogen causes changes in the urinary tract. The changes can increase the risk of UTIs.</li>
      <li><b>Urinary tract problems.</b> Babies born with problems with their urinary tracts may have trouble urinating. Urine can back up in the urethra, which can cause UTIs. </li>
      <li><b>Blockages in the urinary tract.</b> Kidney stones or an enlarged prostate can trap urine in the bladder. As a result, risk of UTIs is higher. </li>
      <li><b>A suppressed immune system.</b> Diabetes and other diseases can impair the immune system - the body's defense against germs. This can increase the risk of UTIs. </li>
      </ul>
      </Typography>
      </Grid>

        <Grid item xs={12}>

        <Typography variant="h6" gutterBottom>
        <strong> Prevention and Management </strong>
        </Typography>

            <Typography variant="body1">
             It's not always possible to prevent Urinary Tract Infection (UTI), and the condition can come back. To protect your back:
             <ul className='vw-ul'>
              <li>Drink plenty of liquids, especially water.</li>
              <li>Try cranberry juice.</li>
              <li>Wipe from front to back.</li>
              <li>Empty your bladder soon after having sex. </li>
              <li>Avoid potentially irritating feminine products.</li>
              <li>Change your birth control method.</li>
              </ul>

            </Typography>
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default UrinaryTractInfection;