/* eslint-disable no-undef */
import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;

const appointmentApi = {
  
  submitAppointment: (appointmentData) => {
    const uri = HOST_ADDRESS + '/api/appointment/send-email'; // Adjust the endpoint as needed
    console.log('API Request Data:', appointmentData); // Debugging
    return axios.post(uri, appointmentData)
      .then((response) => response)
      .catch((error) => {
        throw error; // You can handle errors in the component
      });
  },
  // You can add more appointment-related API calls here if needed
};

export default appointmentApi;
