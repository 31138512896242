/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Grid } from '@mui/material';

const FrozenShoulder = () => {
  return (
    <>
    <div className="inner-container"><h2 className="dnt-heading">Frozen Shoulder </h2></div>
    <br />
    <div className="us-container" style={{ paddingLeft: '23px', paddingRight: '23px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
        <strong> What is Frozen Shoulder? </strong>
        </Typography>
            <Typography variant="body1">
            Frozen shoulder, also known as adhesive capsulitis, is a condition characterized by
stiffness, pain, and limited range of motion in the shoulder joint. Symptoms usually
develop gradually and progress through three stages: freezing (increased pain and
stiffness), frozen (persistent pain with limited movement), and thawing (gradual
improvement in motion). The exact cause of frozen shoulder is unclear, but risk
factors include age, gender (more common in women), shoulder injury or surgery,
certain medical conditions (such as diabetes or thyroid disorders), and prolonged
immobilization. The condition is characterized by the thickening and tightening of
the shoulder joint capsule.
</Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Symptoms of Frozen Shoulder </strong>
        </Typography>
        <Typography variant="body1">
        Frozen shoulder typically develops slowly in three stages.
        <ul className='vw-ul'>
        <li><b>Freezing stage:</b> Any movement of the shoulder causes pain, and the shoulder's ability to move becomes limited. This stage lasts from 2 to 9 months. </li>
        <li><b>Frozen stage:</b> Pain might lessen during this stage. However, the shoulder becomes stiffer. Using it becomes more difficult. This stage lasts from 4 to 12 months. </li>
        <li><b>Thawing stage:</b> The shoulder's ability to move begins to improve. This stage lasts from 5 to 24 months. </li>
        </ul>
        </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Causes of Frozen Shoulder </strong>
        </Typography>
            <Typography variant="body1">
               Frozen Shoulder can be caused by various factors, including:
               <ul className='vw-ul'>
                <li><b>Diabetes: </b>Frozen shoulder occurs much more often in people with diabetes. The reason for this is not known. In addition, diabetic patients with frozen shoulder tend to have a greater degree of stiffness that continues for a longer time before "thawing.</li>
                <li><b>Other diseases:</b> Some additional medical problems associated with frozen shoulder include hypothyroidism, hyperthyroidism, Parkinson's disease, and cardiac disease.</li>
                <li><b>Immobilization</b> Frozen shoulder can develop after a shoulder has been immobilized for a period of time due to surgery, a fracture, or other injury. Having patients move their shoulders soon after injury or surgery is one measure prescribed to prevent frozen shoulder</li>
                
              </ul>
            </Typography>
          
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Risk Factors for Frozen Shoulder </strong>
        </Typography>
            <Typography variant="body1">
              Some common risk factors for developing  Frozen Shoulder include:
              <ul className='vw-ul'>
                <li><b>Age:</b> Frozen shoulder most commonly affects people between the ages of 40 and 60, with the risk increasing as you get older.</li>
                <li><b>Gender: </b>Women are more likely to develop frozen shoulder than men.</li>
                <li><b>Certain Medical Conditions: </b>People with certain medical conditions, such as diabetes, cardiovascular disease, thyroid disorders, Parkinson's disease, and autoimmune diseases, have a higher risk of developing frozen shoulder.</li>
                <li><b>Shoulder Injuries or Surgeries:</b> Previous shoulder injuries or surgeries can increase the risk of developing frozen shoulder.

</li>
              </ul>
            </Typography>
         
        </Grid>
        <Grid item xs={12}>
        <hr />
        <Typography variant="h6" gutterBottom>
        <strong>Prevention and Management </strong>
        </Typography>
            <Typography variant="body1">
              While  Frozen Shoulder cannot always be prevented, some strategies that may help manage the symptoms include:
              <ul className='vw-ul'>
                <li><b>Maintain Shoulder Mobility: </b>Regularly perform gentle range-of-motion exercises for the shoulder joint to keep it mobile and prevent stiffness. Consult with a physical therapist to learn appropriate exercises for your specific condition.s</li>
                <li><b>Manage Medical Conditions: </b>If you have conditions such as diabetes or thyroid disorders, work closely with your healthcare provider to manage these conditions effectively. Properly managing these underlying conditions may reduce the risk of developing frozen shoulder.</li>
                <li><b>Early Treatment: </b>If you experience shoulder pain or stiffness, seek medical attention early. Early diagnosis and treatment of shoulder issues can prevent the condition from worsening and potentially developing into a frozen shoulder.</li>
                <li><b>Posture and Ergonomics:</b> Maintain good posture and practice proper ergonomics when performing repetitive shoulder movements or tasks to reduce the strain on the shoulder joint.</li>
              </ul>
            </Typography>
          
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default FrozenShoulder ;
