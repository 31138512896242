export const ALL_PRODUCTS_REQUESTING = 'ALL_PRODUCTS_REQUESTING';
export const ALL_PRODUCTS_SUCCESS = 'ALL_PRODUCTS_SUCCESS';
export const ALL_PRODUCTS_FAILURE = 'ALL_PRODUCTS_FAILURE';

export const LOGGED_IN_USER_PRODUCTS_ID_REQUESTING = 'LOGGED_IN_USER_PRODUCTS_ID_REQUESTING';
export const LOGGED_IN_USER_PRODUCTS_ID_SUCCESS = 'LOGGED_IN_USER_PRODUCTS_ID_SUCCESS';
export const LOGGED_IN_USER_PRODUCTS_ID_FAILURE = 'LOGGED_IN_USER_PRODUCTS_ID_FAILURE';

export const PRODUCTS_CREATE_REQUESTING = 'PRODUCTS_CREATE_REQUESTING';
export const PRODUCTS_CREATE_SUCCESS = 'PRODUCTS_CREATE_SUCCESS';
export const PRODUCTS_CREATE_FAILURE = 'PRODUCTS_CREATE_FAILURE';

export const PRODUCTS_GET_BY_ID_REQUESTING = 'PRODUCTS_GET_BY_ID_REQUESTING';
export const PRODUCTS_GET_BY_ID_SUCCESS = 'PRODUCTS_GET_BY_ID_SUCCESS';
export const PRODUCTS_GET_BY_ID_FAILURE = 'PRODUCTS_GET_BY_ID_FAILURE';

export const PRODUCTS_UPDATE_BY_ID_REQUESTING = 'PRODUCTS_UPDATE_BY_ID_REQUESTING';
export const PRODUCTS_UPDATE_BY_ID_SUCCESS = 'PRODUCTS_UPDATE_BY_ID_SUCCESS';
export const PRODUCTS_UPDATE_BY_ID_FAILURE = 'PRODUCTS_UPDATE_BY_ID_FAILURE';

export const PRODUCTS_UNSHOW_BY_ID_REQUESTING = 'PRODUCTS_UNSHOW_BY_ID_REQUESTING';
export const PRODUCTS_UNSHOW_BY_ID_SUCCESS = 'PRODUCTS_UNSHOW_BY_ID_SUCCESS';
export const PRODUCTS_UNSHOW_BY_ID_FAILURE = 'PRODUCTS_UNSHOW_BY_ID_FAILURE';

export const PRODUCTS_DELETE_BY_ID_REQUESTING = 'PRODUCTS_DELETE_BY_ID_REQUESTING';
export const PRODUCTS_DELETE_BY_ID_SUCCESS = 'PRODUCTS_DELETE_BY_ID_SUCCESS';
export const PRODUCTS_DELETE_BY_ID_FAILURE = 'PRODUCTS_DELETE_BY_ID_FAILURE';

export const PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_REQUESTING = 'PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_REQUESTING';
export const PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_SUCCESS = 'PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_SUCCESS';
export const PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_FAILURE = 'PRODUCTS_CURRENT_MONTH_PRODUCTS_VS_PREVIOUS_MONTH_FAILURE';
