/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Best Ayurveda Center in Delhi | Ayurvedic Healthcare Center</title>
    <meta name="description" content="Top Ayurveda Center in Delhi for wellness & balance. Experience the best Ayurvedic healthcare services at our renowned hub. Visit to know more." />
    </Helmet>

      <div className="inner-container inner-margin"><h2 className="dnt-heading">About Us </h2></div>
      <div className="us-container">
        <Box className="aboutus-page">
          <img src="./images/vedanta-wellness.png" className="about-img" alt="" />
          <Grid className="para-1">
            <Typography className="abt-txtt">
              In recent times, Ayurveda has become more associated with rituals and commercialization
              rather than being recognized as an ancient medical science. The modern practice of
              Ayurveda has become highly commercialized, leading to the gradual disappearance of
              traditional Ayurveda. However, there are still several renowned Ayurvedic centers that
              specialize in this field.
            </Typography>
            <br />
            <Typography className="abt-txtt">
              Although Ayurveda has a history of over 5,000 years in India, it has gained popularity as an
              alternative form of medicine in the Western world more recently. The principles of Ayurvedic
              medicine are derived from several sacred Indian Sanskrit texts written between 1500 BC and
              400 AD, making it one of the oldest healing systems known to humanity.
            </Typography>
            <br />
            <Typography className="abt-txtt">
              In Ayurveda, the focus is not solely on eliminating the ailment but on strengthening the body
              to its optimum level. By doing so, the body can take care of the ailment itself and address
              any underlying issues. The essence of Ayurvedic philosophy is captured in the Sanskrit
              saying, "स्वस्थस्य स्वास्थय रक्षण, आतरुस्य विकार प्रशमन" (Charaka Samhita, Sutra 30/26),
              which means that Ayurveda aims to maintain the health of a healthy person and manage or
              cure diseases that manifest in both the mind and body.
            </Typography>
            <br />
            <Typography className="abt-txtt">
              At Vedanta Wellness, we provide holistic and authentic classical Kerala-based Panchakarma
              therapies for various chronic ailments. Our doctors specialize in classical Ayurveda and
              Panchakarma therapies, catering to the unique individual needs of each patient. We
              customize treatment plans according to your prakriti (constitution) and dosha involvement.
            </Typography>
            <br />
            <Typography className="abt-txtt">
              The healthcare knowledge mentioned in the texts written by the ancient sages becomes more and more relevant globally and helps the
              modern mankind to lead a healthier life. The busy life and unhealthy lifestyle along with food habits made
              the world vulnerable to many diseases. Today’s world faces many lifestyle diseases like degenerative
              disorders, diabetes, hypertension and many more.
            </Typography>
            <br />
            <Typography className="abt-txtt">
              We, Vedanta Wellness with our expertise in the Ayurvedic field try
              our best to find solutions for orthopedic diseases like Arthritis,
              Osteoporosis, Slip Disc, Joint Pains, Spondylitis, Sciatica, Back
              Pain and many more. The treatments are done under the supervision of qualified and well
              experienced Ayurvedic Physicians by experienced treatment staffs.
              Treatment protocols are strictly maintained for maintaining quality.
            </Typography>
          </Grid>
        </Box>
      </div>

    </>
  );
}

