/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const BrachialPlexusInjuryIllness4 = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Brachial Plexus Injury
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            What is Brachial Plexus Injury?
          </Typography>
          <Typography variant="body1">
            A Brachial Plexus Injury (BPI) refers to damage or trauma to the
            brachial plexus, a network of nerves that extends from the neck to
            the shoulder and controls movement and sensation in the arm and
            hand. BPI can occur due to childbirth-related injuries, sports
            injuries, motor vehicle accidents, or falls. Symptoms include
            weakness or paralysis of the arm, loss of sensation, numbness,
            tingling, and difficulty controlling or moving the affected limb.
            The severity of symptoms varies depending on the extent of the
            injury.{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Symptoms of Brachial Plexus Injury
          </Typography>
          <Typography variant="body1">
            Some common symptoms of Brachial Plexus Injury include:
            <ul>
              <li>A limp or paralyzed arm</li>
              <li>Lack of muscle control in the arm, hand, or wrist</li>
              <li>A lack of feeling or sensation in the arm or hand</li>
              <li>
                Sudden pain in the shoulder or arm that may be stinging or
                burning
              </li>
              <li>
                A feeling like an electric shock or a burning sensation shooting
                down the arm
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Causes of Brachial Plexus Injury
          </Typography>
          <Typography variant="body1">
            Brachial Plexus Injury can be caused by various factors, including:
            <ul>
              <li>
                <b>Contact sports:</b> Many football players experience burners
                or stingers, which can occur when the nerves in the brachial
                plexus get stretched beyond their limit during collisions with
                other players.
              </li>
              <li>
                <b>Difficult births:</b> Newborns can sustain brachial plexus
                injuries. These may be associated with high birth weight, breech
                presentation or prolonged labor. If an infants shoulders get
                wedged within the birth canal, there is an increased risk of a
                brachial plexus palsy. Most often, the upper nerves are injured,
                a condition called Erbs palsy.
              </li>
              <li>
                <b>Trauma:</b> Several types of trauma — including motor vehicle
                accidents, motorcycle accidents, falls or bullet wounds — can
                result in brachial plexus injuries.
              </li>
              <li>
                <b>Tumors and cancer treatments:</b> Radiation treatment to the
                chest or neck may cause tumors to grow in or along the brachial
                plexus, or put pressure on the brachial plexus or spread to the
                nerves. Radiation treatment to the chest may cause damage to the
                brachial plexus.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Risk Factors for Brachial Plexus Injury
          </Typography>
          <Typography variant="body1">
            Some common risk factors for developing Brachial Plexus Injury
            include:
            <ul>
              <li>
                Shoulder dystocia (the baby shoulder being restricted on the
                mothers pelvis)
              </li>
              <li>Maternal diabetes</li>
              <li>Large gestational size</li>
              <li>Difficult delivery needing external assistance</li>
              <li>Breech presentation at birth</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h4" gutterBottom>
            Prevention and Management
          </Typography>
          <Typography variant="body1">
            Although damage to the brachial plexus often can`&apos;`t be
            prevented, you can take steps to reduce the risk of complications
            once an injury has occurred:{' '}
            <ul>
              <li>
                <b>For yourself</b> If you temporarily lose the use of the hand
                or arm, daily range-of-motion exercises and physical therapy can
                help prevent joint stiffness. Avoid burns or cuts, as you may
                not feel them if you're experiencing numbness.
              </li>
              <li>
                If you're an athlete who has experienced injuries to the
                brachial plexus area, your provider may suggest you wear
                specific padding to protect the area during sports.
              </li>
              <li>
                <b>For your child:</b> If you're the parent of a child with a
                brachial plexus palsy, it's important that you exercise your
                child's joints and functioning muscles every day, beginning when
                your baby is just a few weeks old. This helps prevent the joints
                from becoming permanently stiff and keeps your child's working
                muscles strong and healthy.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BrachialPlexusInjuryIllness4;
