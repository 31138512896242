import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export default function Coupon() {

    const updateCart = (e) => {
        e.preventDefault();
    };
    return (
        <Box
        component="form"
        sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
            alignItems:'left',
            marginTop: '20px',
            textAlign: 'left'
        }}
        noValidate
        autoComplete="off"
        >
            <Typography component="span" sx={{fontWeight: 'bold'}}>
                Coupon:
            </Typography>
            <TextField id="outlined-basic" label="Coupon code" variant="outlined" />
            <Button variant="contained" size="medium" 
            sx={{backgroundColor: '#0B8140', color:'white', padding: '15px', width: '100%', borderRadius: '25px', fontWeight: 300}}
            onClick={updateCart}>
                UPDATE CART</Button>
        </Box>
    );
}