export const ALL_COUPONS_REQUESTING = 'ALL_COUPONS_REQUESTING';
export const ALL_COUPONS_SUCCESS = 'ALL_COUPONS_SUCCESS';
export const ALL_COUPONS_FAILURE = 'ALL_COUPONS_FAILURE';

export const LOGGED_IN_USER_COUPONS_ID_REQUESTING = 'LOGGED_IN_USER_COUPONS_ID_REQUESTING';
export const LOGGED_IN_USER_COUPONS_ID_SUCCESS = 'LOGGED_IN_USER_COUPONS_ID_SUCCESS';
export const LOGGED_IN_USER_COUPONS_ID_FAILURE = 'LOGGED_IN_USER_COUPONS_ID_FAILURE';

export const COUPONS_CREATE_REQUESTING = 'COUPONS_CREATE_REQUESTING';
export const COUPONS_CREATE_SUCCESS = 'COUPONS_CREATE_SUCCESS';
export const COUPONS_CREATE_FAILURE = 'COUPONS_CREATE_FAILURE';

export const COUPONS_GET_BY_ID_REQUESTING = 'COUPONS_GET_BY_ID_REQUESTING';
export const COUPONS_GET_BY_ID_SUCCESS = 'COUPONS_GET_BY_ID_SUCCESS';
export const COUPONS_GET_BY_ID_FAILURE = 'COUPONS_GET_BY_ID_FAILURE';

export const COUPONS_UPDATE_BY_ID_REQUESTING = 'COUPONS_UPDATE_BY_ID_REQUESTING';
export const COUPONS_UPDATE_BY_ID_SUCCESS = 'COUPONS_UPDATE_BY_ID_SUCCESS';
export const COUPONS_UPDATE_BY_ID_FAILURE = 'COUPONS_UPDATE_BY_ID_FAILURE';

export const COUPONS_UNSHOW_BY_ID_REQUESTING = 'COUPONS_UNSHOW_BY_ID_REQUESTING';
export const COUPONS_UNSHOW_BY_ID_SUCCESS = 'COUPONS_UNSHOW_BY_ID_SUCCESS';
export const COUPONS_UNSHOW_BY_ID_FAILURE = 'COUPONS_UNSHOW_BY_ID_FAILURE';

export const COUPONS_DELETE_BY_ID_REQUESTING = 'COUPONS_DELETE_BY_ID_REQUESTING';
export const COUPONS_DELETE_BY_ID_SUCCESS = 'COUPONS_DELETE_BY_ID_SUCCESS';
export const COUPONS_DELETE_BY_ID_FAILURE = 'COUPONS_DELETE_BY_ID_FAILURE';
