import axios from 'axios';
import envVars from '../utils/envVars';

const HOST_ADDRESS = envVars.HOST_ADDRESS;
  
const notificationApi = {
  getAll: () => {
    const uri = HOST_ADDRESS + envVars.ALL_NOTIFICATIONS;
    return axios.get(uri).then((response) => {
      return response;
    });
  },
  create: (params) => {
    const uri = HOST_ADDRESS + envVars.NOTIFICATIONS_CREATE;
    return axios.post(uri, params.payload).then((response) => {
      return response;
    });
  },
  unshowBy: (params) => {
    const uri = HOST_ADDRESS + envVars.NOTIFICATIONS_UNSHOW_BY_ID;
    return axios.put(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
  deleteBy: (params) => {
    const uri = HOST_ADDRESS + envVars.NOTIFICATIONS_DELETE_BY_ID;
    return axios.delete(`${uri}${params?.payload?.id}`, params.payload).then((response) => {
      return response;
    });
  },
};

export default notificationApi;
