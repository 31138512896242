/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// import { } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import Divider from '@mui/material/Divider';
import Drawer from "@mui/material/Drawer";

import { Grid } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "./NavBar.css";

const drawerWidth = 240;
const navItems = [
  {
    url: "/",
    label: "Home",
  },
  {
    url: "aboutus",
    label: "About Us",
  },
  {
    url: "ayurvedictherapies",
    label: "Ayurvedic Therapies",
  },
  {
    url: "diseases-treatments",
    label: "Diseases & Treatments",
  },
  {
    url: "services-product",
    label: "Services & Products",
  },
  {
    url: "membership",
    label: "Membership",
  },
  {
    url: "Contact",
    label: "Contact Us",
  },
  {
    url: "blogs",
    label: "Blogs",
  },
];

export default function NavBar({ window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const cartData = useSelector((state) => state.cart);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const data = secureLocalStorage.getItem("users");
  const users = JSON.parse(data);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const logoutHandler = () => {
    secureLocalStorage.clear();
    navigate("/login");
  };
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener("onmouseout", handleOutsideClick);
  }, []);
  const handleOutsideClick = (event) => {
    // if (this.box && !this.box.current.contains(event.target)) {
    //   alert("you just clicked outside of box!");
    // }
    if (ref.current && !ref.current.contains(event.target)) {
      // eslint-disable-next-line no-undef
      alert("you just clicked outside of box!");
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      className="nav-full"
      sx={{ textAlign: "center", display: "flex" }}
    >
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> 
      <Divider /> */}
      <List>
        {navItems.map((item) => (
          <ListItem key={item.url} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const location = useLocation();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const excludedRoutes = [
    "/Home", // Add the private routes here that should exclude the navbar
    "/OrderChart",
    "/SalesChart",
    "/ProductTagsPage",
    "/Sidebar",
    "/Topbar",
    "/Layout",
    "/LeatherBoard",
    "/AddPages",
    "/Blog",
    "/PageList",
    "/BlogList",
    "/AddProductPage",
    "/ProductListPage",
    "/AddNewOrder",
    "/AllOrders",
    "/OrderInfo",
    "/FeedbackPage",
    "/FeaturedInfo",
    "/ProductCategoriesPage",
    "/CouponCard",
    "/PatientProfilepage",
    "/OrderMgmt",
    "/AdminProfilePage",
    "/MediaLibrary",
    "/UsersList",
    "/CustomersList",
  ];

  // Check if the current location matches any of the excluded routes
  const shouldRenderNavbar = !excludedRoutes.includes(location.pathname);

  if (!shouldRenderNavbar) {
    return null; // Do not render the navbar if the current location is an excluded route
  }
  console.log("cart data", cartData);
  return (
    <>
      {location.pathname.toLowerCase() == "/Home".toLowerCase() ? (
        ""
      ) : (
        <Box className="navbar-full">
          <AppBar position="static" className="navbar-first">
            <Toolbar className="left-icons">
              <Grid sm={12} sx={{ display: "flex" }}>
                <Typography className="Number" variant="h6" component="div">
                  <img
                    src="../images/phone-icon.png"
                    className="navfirst-icons"
                    alt="no images"
                  />
                  +91-9811669606
                </Typography>
                <Typography className="Email" variant="h6" component="div">
                  <img
                    src="../images/envelop-icon.png"
                    className="navfirst-icons"
                    alt="no images"
                  />
                  cs@vedanta-wellness.com
                </Typography>
                <Typography className="Sentence" variant="h6" component="div">
                  <img
                    src="../images/address-icon.png"
                    className="navfirst-icons"
                    alt="no images"
                  />
                  <Link
                    to="/Contact"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Click here to find the location
                  </Link>
                </Typography>
              </Grid>
              <Grid
                className="socialmedia-icon"
                direction="row"
                spacing={2}
                sm={6}
              >
                <Link
                  color="inherit"
                  className="facebook"
                  to="https://www.facebook.com/people/Vedanta-Wellness/100085145811122/"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  color="inherit"
                  className="twitter"
                  to="https://twitter.com/VedantaWellness"
                >
                  {" "}
                  <FaTwitter />
                </Link>
                <Link
                  color="inherit"
                  className="linkedinin"
                  to="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F83528379%2F"
                >
                  <FaLinkedinIn />
                </Link>
              </Grid>
            </Toolbar>
          </AppBar>

          {/* --------------------------------------------------------second nav---------------------------------------------------- */}

          <AppBar className="second-nav" component="nav">
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Grid container>
                <Grid md={2} xs={2} sm={2} sx={{ textAlign: "center" }}>
                  <Link to="/">
                    <img
                      src="../images/logo.png"
                      alt="not available"
                      className="vedanta-logo"
                    />
                  </Link>
                </Grid>
                <Grid md={8} xs={8} sm={8} className="m-nav-link">
                  <Box sx={{ marginLeft: "1%", display: "flex" }}>
                    <Link to="/aboutus" className="second-nav-link">
                      About Us
                    </Link>
                    <Link to="/ayurvedic-therapies" className="second-nav-link">
                     Ayurvedic Therapies
                    </Link>
                    <Link to="/diseases-treatments" className="second-nav-link">
                      Diseases & Treatments
                    </Link>
                    {/* <Link to="/services-product" className="second-nav-link">
                      Services & Products
                    </Link> */}
                    <Link to="/coming-soon" className="second-nav-link">
                      Services & Products
                    </Link>
                    <Link to="/membership" className="second-nav-link">
                      Membership
                    </Link>
                    <Link to="/contact-us" className="second-nav-link">
                      Contact Us
                    </Link>
                    <Link to="/blogs" className="second-nav-link">
                      Blogs
                    </Link>
                  </Box>
                </Grid>
                <Grid
                  className="nav-menu-br"
                  md={2}
                  sm={8}
                  xs={2}
                  sx={{
                    display: "flex",
                    /* display: "inline-block", */
                    flexDirection: "row",
                    gap: "1rem",
                    width: "90px",
                    paddingTop: "1.5%",
                    /* marginLeft: "150px", */
                  }}
                >
                  {!users ? (
                    <Box className="lndr-link">
                      <Link
                        to="login"
                        style={{
                          color: "black",
                          textDecoration: "none",
                          fontSize: "92%",
                        }}
                      >
                        Login
                      </Link>

                      <Link
                        to="/membership"
                        style={{
                          color: "black",
                          textDecoration: "none",
                          fontSize: "92%",
                        }}
                      >
                        &nbsp;/ Register
                      </Link>
                    </Box>
                  ) : (
                    <div>
                      <Button
                        id="basic-button"
                        // aria-controls={open ? "basic-menu" : undefined}
                        // aria-haspopup="true"
                        // aria-expanded={open ? "true" : undefined}
                        onMouseOver={handleClick}
                      >
                        <p className="user-Name">{users.firstname}</p>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onMouseLeave={handleClose}
                        // onMouseOut={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          {users && users.Role === "Admin" ? (
                            <Link to="/Home">Profile</Link>
                          ) : (
                            <Link to="/dashboard">Profile</Link>
                          )}
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          {users && users.Role === "Admin" ? (
                            <Link to="/Home">My account</Link>
                          ) : (
                            <Link to="/dashboard">My account</Link>
                          )}
                        </MenuItem>
                        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                      </Menu>

                      {/* <p>{users.firstname}</p>
                  <p className="logout-logic" onClick={logoutHandler}>
                    logout
                  </p> */}
                    </div>
                  )}

                  <Box component="span">
                    <Link
                      to="cart"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        marginRight: "10px",
                      }}
                    >
                      <ShoppingBagOutlinedIcon />
                      <Box component="span" sx={{ verticalAlign: "super" }}>
                        {cartData && cartData.quantity}
                      </Box>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none", md: "flex" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box component="">{/* <Toolbar /> */}</Box>
        </Box>
      )}
    </>
  );
}

NavBar.propTypes = {
  window: PropTypes.object.isRequired,
};
